.errro-not-found {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 75px;
  font-weight: 500;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
}
