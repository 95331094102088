@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap");

.MuiDialog-paperWidthSm {
  width: 600px;
}

body {
  //font-family: "Montserrat", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: left !important;
  background-color: #fff !important;
}

.loader-div {
  height: 100%;
  text-align: center;
  margin: auto;
  padding: 15%;
}
.footer-content .img-fluid {
  max-height: 40px;
}
.header-main-div .navbar-light .navbar-nav .nav-link{
  color: #fff;
}

// Year tags css
.yearTagsContainer {
  padding: 18px;
  background: #fff;
  box-shadow: 0px 0px 10px #ddd;
  margin: 15px 0px;
  border-radius: 5px;
  .birthHeading {
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
  ul {
    display: flex;
    margin: 0px -7px;
    flex-wrap: wrap;
    justify-content: center;
    li {
      padding: 10px 7px;
      list-style: none;
      span {
        background: #fff;
        box-shadow: 0px 0px 5px #ddd;
        padding: 5px 12px;
        border-radius: 5px;
        border: 1px solid #fff;
        cursor: pointer;
        overflow: hidden;
        &:hover {
          background-color: #1b283f;
          color: #fff;
        }
      }
    }
  }
}
// Year tags css end

// Trust brands logo section css
.trustedBrands {
  display: inline-block;
  width: 100%;
  padding: 70px 0px 50px 0px;
  ul {
    display: flex;
    justify-content: center;
    margin: 0px -15px;
    flex-wrap: wrap;
    li {
      list-style: none;
      padding: 0px 15px;
      flex: 0 0 15%;
      max-width: 15%;
      img {
        max-width: 100%;
        max-height: 100px;
      }
    }
  }
}
// Trust brands logo section css

//Thank you page css//
.thankYouText {
  background: #fff;
  color: #000;
  padding: 25px;
  border-radius: 5px;
  text-align: left;
  h4 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
  }
  ol {
    padding-left: 0px;
    margin-bottom: 0px;
    li {
      font-size: 16px;
      padding: 15px 10px 15px 50px;
      letter-spacing: 0.5px;
      list-style: none;
      color: #404040;
      position: relative;
      border-bottom: 1px solid #ccc;
      min-height: 70px;
      span {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0px;
        top: 15px;
        text-align: center;
        line-height: 30px;
        background: #333;
        color: #fff;
        border-radius: 50%;
        font-weight: bold;
      }
      &:last-child {
        border: none;
      }
    }
  }
}

//Do not Qualify page css
.notQualifySection {
  min-height: calc(100vh - 96px);
  padding-top: 80px;
  background-image: none !important;
  background-color: #eee;
  padding-bottom: 40px;
  .container {
    height: 100%;
    max-width: 750px !important;
    width: 100%;
    .row {
      height: 100%;
      .col-sm-12 {
        height: 100%;
        display: table;
        .descript-notQualify {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          color: #000;
          .pageImg {
            margin-bottom: 25px;
            img {
              max-width: 250px;
            }
          }
          h1 {
            letter-spacing: 1px;
            font-size: 50px;
            line-height: 50px;
          }
          .subText {
            font-size: 26px;
            line-height: 30px;
            width: 600px;
            max-width: 100%;
            margin: auto;
            margin-bottom: 30px;
          }
          .partnerOffer {
            display: inline-block;
            margin: auto;
            width: 600px;
            max-width: 100%;
            border: 1px solid #d9d9d9;
            padding: 40px;
            border-radius: 5px;
            background: #f4f4f4;
            text-align: left;
            h3 {
              color: #000;
            }
            p {
              font-size: 18px;
              color: #2b2b2b;
              line-height: 22px;
              &.desclaimerText {
                font-size: 12px;
                color: #3b3b3b;
                margin: auto;
                margin-top: 20px;
                display: inline-block;
              }
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                display: inline-block;
                width: 100%;
                border: 1px solid #ccc;
                padding: 15px;
                margin-bottom: 10px;
                border-radius: 5px;
                position: relative;
                padding-right: 130px;
                .offer_button {
                  position: absolute;
                  right: 15px;
                  top: 15px;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                  color: #fff;
                  font-size: 12px;
                  background: #cd232e;
                  padding: 10px 10px;
                  border-radius: 5px;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .notQualifySection {
    .container {
      .row {
        .col-sm-12 {
          .descript-notQualify {
            .partnerOffer {
              width: auto;
              padding: 15px;
              ul {
                li {
                  padding-right: 15px;
                  span {
                    display: inline-block;
                    width: 100%;
                  }
                  .offer_button {
                    position: relative;
                    right: 0px;
                    top: 0px;
                    margin-top: 15px;
                    display: inline-block;
                  }
                }
              }
            }
            h1 {
              font-size: 36px;
              line-height: 40px;
            }
            .subText {
              width: auto;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-default-theme {
  $base_color: #13ac8f;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: #fff;
            color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #333;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $base_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
      border-color: $base_color;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: #3c3c3c;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }

    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }

    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-first-theme {
  $base_color: #1b283f;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: #fff;
            color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #333;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $base_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: #3c3c3c;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-second-theme {
  $base_color: #7773fa;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: #fff;
            color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #333;
    color: #fff;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $base_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: #3c3c3c;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////
  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-third-theme {
  $base_color: #4094a9;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: #fff;
            color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #333;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $base_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: #3c3c3c;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////
  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-fourth-theme {
  $base_color: #f05249;
  $nav_color: #12122d;

  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
  body {
    font-family: "Poppins", sans-serif;
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $nav_color;
    .fixed-top {
      background: $nav_color !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
    .banner-div-section {
      margin-top: 75px;
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    // min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      padding: 80px 0px;
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 700px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        font-family: "Poppins", sans-serif;
        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: normal;
          color: #fff !important;
          border: 2px solid #000;
          width: auto;
          clear: both;
          display: inline-block;
          padding: 5px 30px;
          color: #000 !important;
        }
        .lead {
          h2 {
            font-size: 44px;
            font-weight: 400;
            border: none;
            padding: 0px;
            color: $base_color !important;
            line-height: 1.2;
            margin-bottom: 15px;
          }
          h3 {
            font-size: 20px;
            font-weight: 400;
            color: $nav_color;
          }
        }
        .homebtn {
          background: transparent;
          border: 2px solid $base_color;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: $base_color;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: $base_color;
            color: #fff;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    color: #fff;
    padding: 80px 0px 150px 0px;
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 2rem;
      color: $base_color;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    // padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 18px;
        font-weight: 500;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
        text-align: justify;
        text-align-last: center;
        -moz-text-align-last: center;
        -ms-text-align-last: center;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }

  .section-one-main {
    .sectionone.first.bgwhite {
      padding: 0px 0px 0px 0px;
      text-align: center;
      .container {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        padding: 0px;
        > .row {
          margin: 0px;
          padding: 0px;
          .col-sm-12 {
            background: $nav_color;
            margin: 0px;
            padding: 0px;
            .heading-section {
              max-width: 1400px;
              width: 100%;
              margin: auto;
            }
          }
          .block-multiples.col-sm-12 {
            background: $base_color;
            padding-bottom: 80px;
            .row {
              max-width: 1400px;
              width: 100%;
              margin: auto;
              margin-top: -70px;
            }
          }
        }
      }
    }
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .container {
      max-width: 1420px;
      margin: auto;
    }
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: $nav_color;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #080815;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $nav_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $nav_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 1em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fff;
    border-radius: 0px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: none;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: $base_color;
    text-decoration: none;
    background: transparent;
    border: 2px solid $base_color;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: $nav_color;
      color: #fff;
      border-color: $nav_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  // .carousel-item::before {
  //   content: "";
  //   height: 100%;
  //   position: absolute;
  //   left: 0px;
  //   top: 0px;
  //   width: 100%;
  //   background: transparent
  //     linear-gradient(61deg, $base_color 0%, #136fac2e 100%) 0% 0% no-repeat
  //     padding-box;
  // }

  .carousel-item {
    // height: 100vh;
    // background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: $base_color;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: $nav_color;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
    margin-top: 30px;
  }
  .tab {
    width: 100%;
    color: $nav_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: #ddd;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
      opacity: 0;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #ddd;
      border-bottom: none;
      opacity: 1;
    }
  }
  //******** Accordian css end**********//

  .section-second-main {
    .sectionone.first.bgblue {
      background-image: none !important;
      background-color: #fff;
      padding: 40px 0px 0px 0px;
      .secondSecRow {
        align-items: flex-end;
        .secondSecImg {
          max-width: 80%;
        }
        .order-md-2 {
          .heading-section {
            padding-bottom: 60px;
            width: 550px;
            max-width: 100%;
            h2 {
              font-size: 30px;
            }
          }
        }
      }

      &:before {
        display: none;
      }
      .heading-section {
        padding: 0px;
        color: $nav_color;
        h2 {
          color: $nav_color;
          margin-bottom: 0px;
        }
        p {
          width: 960px;
          max-width: 100%;
          margin-bottom: 30px;
          font-size: 18px;
          font-weight: 300;
        }
        .homebtn {
          border-color: $base_color;
          color: $base_color;
          display: inline-block;
          margin-top: 0px;
          &:hover {
            background-color: $nav_color;
            color: #fff;
            border-color: $nav_color;
          }
        }
      }
    }
  }

  .section-third-main {
    .sectionone.first.bgwhite.third-sec {
      background-color: $base_color;
      padding-bottom: 0px;
      padding-top: 40px;
      .row {
        align-items: end;
      }
      .heading-section {
        h2 {
          color: #fff;
        }
        p {
          color: #fff;
          div {
            color: #fff !important;
            font-size: 18px !important;
            text-align: justify;
          }
        }
      }
      .order-md-2 {
        text-align: center;
        .heading-section {
          img {
            width: 500px;
          }
        }
      }
      .order-md-1 {
        .heading-section {
          padding-bottom: 50px;
        }
      }
    }
  }

  .section-fourth-main {
    .sectionone.first.bg-light {
      background: #f8f8ff !important;
      padding: 60px 0px;
      .heading-section {
        padding-top: 0px;
        h2 {
          margin-bottom: 10px;
        }
        p {
          color: $nav_color;
          width: 100%;
          font-size: 18px;
        }
      }
      &.hideFaq {
        padding-bottom: 0px;
        .bottomStip {
          display: inline-block;
          background: $base_color;
          width: 100%;
          padding: 40px 0px;
          h2 {
            color: $nav_color;
            font-size: 38px;
          }
          p {
            color: $nav_color;
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 1.3;
          }
          a.homebtn.section-4 {
            border-color: $nav_color;
            color: $nav_color;
            &:hover {
              color: $nav_color;
              border-color: #fff;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .banner-div-section:after {
    content: "";
    position: absolute;
    width: 71px;
    height: 134px;
    background-repeat: no-repeat;
    left: 50%;
    bottom: -67px;
    z-index: 1;
    transform: translate(-35px, 0px);
    background-image: url(../../assets/images/icon-down-arrow.png);
  }

  .banner-div-section {
    position: relative;
  }
  .carousel-indicators {
    display: none;
  }
  .heading-section {
    padding: 100px 0px 150px 0px;
  }

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  .formlogo {
    position: absolute;
    bottom: 42px;
    font-size: 12px;
    text-align: center;
    color: #868686;
    right: 0;
    width: 100%;
    font-weight: 600;
    img {
      max-width: 160px;
    }
  }
  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $nav_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          height: auto;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: block;
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1425px) {
    .slider-home .carousel-item .slider-content h2 {
      font-size: 14px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12
      .row {
      max-width: 1140px;
    }
    .sectionone.first.bgwhite.third-sec .container {
      max-width: 1140px;
    }
    .section-second-main .sectionone.first.bgblue .secondSecRow .secondSecImg {
      max-width: 100%;
    }
    .slider-home .carousel-item {
      padding: 60px 0px 10px 0px;
    }
    .slider-home .carousel-item .slider-content .homebtn {
      padding: 12px 40px;
      margin: 15px 0px;
      font-size: 1.2rem;
    }
    .banner-div-section::after {
      width: 51px;
      height: 97px;
      background-size: 100%;
      bottom: -47px;
      transform: translate(-25.5px, 0px);
    }
    .trustedBrands {
      padding: 60px 0px 20px 0px;
    }
    .heading-section {
      padding: 50px 0px 150px 0px;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 20px 0px 0px 0px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      margin-bottom: 20px;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding-top: 40px;
    }
  }
  @media (max-width: 1200px) {
    .slider-home .carousel-item .slider-content {
      padding-top: 0px;
    }
    .trustedBrands ul li {
      flex: 0 0 20%;
      max-width: 20%;
      text-align: center;
    }
    .heading-section p {
      width: 90%;
    }
    .heading-section {
      padding: 50px 0px 130px 0px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item {
      padding: 20px 0px;
    }
    .slider-home .carousel-item .slider-content {
      padding: 2em 0em 0em 0em;
      width: 600px;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 20px;
      padding: 5px 5px;
    }
    .slider-home .carousel-item .slider-content .lead h2 {
      font-size: 34px;
    }
    .slider-home .carousel-item .slider-content .lead h3 {
      font-size: 18px;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
    .banner-div-section::after {
      width: 50px;
      height: 95px;
      bottom: -47.5px;
      transform: translate(-25px, 0px);
      background-size: 100%;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 30px 0px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding-bottom: 30px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 34px;
      margin-bottom: 20px;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding: 30px 0px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }
    .section-second-main
      .sectionone.first.bgblue
      .secondSecRow
      .order-md-2
      .heading-section
      h2 {
      font-size: 26px;
    }
    .section-second-main .sectionone.first.bgblue .heading-section p {
      margin-bottom: 10px;
      font-size: 16px;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 30px 0px 0px 0px;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 100px 20px;
        }
      }
    }
    .formlogo img {
      max-width: 100px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12
      .row {
      margin-top: 0px;
    }
    .heading-section {
      padding: 40px 15px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 0px;
    }
    .block-multiples .col-sm-3 {
      margin: 15px 0px;
    }
    .banner-div-section::after {
      width: 30px;
      height: 58px;
      bottom: -29px;
      transform: translate(-15px, 0px);
    }
    .heading-section p {
      width: 100%;
    }
    .heading-section h2 {
      margin-bottom: 1rem;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 15px;
    }
    .block-multiples .single-block p {
      text-align: center;
    }
    .section-second-main .sectionone.first.bgblue .heading-section p {
      margin-bottom: 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-1
      .heading-section {
      padding-bottom: 10px;
      text-align: center;
    }
    .footer-content .custome-links {
      margin-top: 10px;
    }
    .navbar-collapse {
      margin: 0px -15px;
      border-top: 1px solid rgba(256, 256, 256, 0.4);
      background-color: $nav_color;
    }
    .header-section .fixed-top {
      padding: 0.5rem 1rem 0px 0px !important;
    }
    .header-section.pageScroll .fixed-top {
      padding: 0.5rem 1rem 0px 0px !important;
    }
    .header-section .banner-div-section {
      margin-top: 68px;
    }
    .slider-home .carousel-item .slider-content {
      width: 510px;
    }
    .trustedBrands {
      padding: 40px 0px 20px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .heading-section
      p
      div {
      text-align-last: center;
      -moz-text-align-last: center;
      -ms-text-align-last: center;
    }
    .sectionone.first.bg-light .heading-section {
      flex: 0 0 100%;
      margin-left: 0px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip p {
      font-size: 22px;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      padding-bottom: 0px;
    }
    .section-third-main .sectionone.first.bgwhite.third-sec {
      padding-top: 20px;
    }
    .secondSecRow {
      .order-md-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        -ms-flex-order: 1;
        order: 1;
      }
      .order-md-1 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        -ms-flex-order: 1;
        order: 1;
      }
    }
    .section-second-main .sectionone.first.bgblue .secondSecRow .secondSecImg {
      width: 450px;
    }
    .section-second-main
      .sectionone.first.bgblue
      .secondSecRow
      .order-md-2
      .heading-section {
      margin: auto;
      padding-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    .block-multiples .row {
      display: flex;
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item {
      padding: 40px 0px;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 0em;
      .lead {
        h2 {
          font-size: 34px;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 16px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    // .sectionone.first.bgwhite.third-sec .heading-section img {
    //   box-shadow: 5px 5px 0px #eee;
    // }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 100px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 100px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding: 20px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-1
      .heading-section {
      padding-bottom: 0px;
    }
    // .slider-home .carousel-item {
    //   background-image: none !important;
    // }
    .slider-home .carousel-item .slider-content {
      text-align: center;
    }
    .section-third-main .sectionone.first.bgwhite.third-sec .heading-section p {
      text-align: center;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-2
      .heading-section
      img {
      top: 0px;
    }
    img.img-fluid-logo {
      height: 40px !important;
      max-width: 200px;
    }
    .header-section .banner-div-section {
      margin-top: 66px;
    }
    .slider-home .carousel-item .carousel-caption.d-md-block {
      display: inline-block !important;
    }
    .trustedBrands ul li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 26px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip h2 {
      font-size: 26px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip p {
      font-size: 18px;
    }
    .sectionone.first.bg-light .heading-section {
      padding: 0px 15px;
    }
    .footer-content {
      .col-sm-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .header-section .fixed-top,
    .header-section.pageScroll .fixed-top {
      padding: 0px 1rem !important;
    }
    .header-section .fixed-top .navbar-brand {
      min-height: 60px;
      line-height: 60px;
    }
    .navbar-brand img.img-fluid-logo {
      height: 33px !important;
      max-width: none;
    }
    .footer-content .custome-links {
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }

    .slider-home .carousel-item .slider-content .lead h2 {
      font-size: 30px;
    }
    .slider-home .carousel-item .slider-content .lead h3 {
      font-size: 16px;
    }
    .slider-home .carousel-item {
      padding: 40px 0px 20px 0px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 30px;
    }
    .tab-label {
      font-size: 18px;
    }
  }
}

.layout_first-demo-fifth-theme {
  $base_color: #f47621;
  $nav_color: #262f36;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
  body {
    font-family: "Poppins", sans-serif;
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $nav_color;
    .fixed-top {
      background: $nav_color !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
    .banner-div-section {
      margin-top: 75px;
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    // min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      padding: 80px 0px;
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 700px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        font-family: "Poppins", sans-serif;
        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: normal;
          color: #fff !important;
          border: 2px solid #000;
          width: auto;
          clear: both;
          display: inline-block;
          padding: 5px 30px;
          color: #000 !important;
        }
        .lead {
          h2 {
            font-size: 44px;
            font-weight: 400;
            border: none;
            padding: 0px;
            color: $base_color !important;
            line-height: 1.2;
            margin-bottom: 15px;
          }
          h3 {
            font-size: 20px;
            font-weight: 400;
            color: $nav_color;
          }
        }
        .homebtn {
          background: transparent;
          border: 2px solid $base_color;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: $base_color;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: $base_color;
            color: #fff;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    color: #fff;
    padding: 80px 0px 150px 0px;
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 2rem;
      color: $base_color;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    // padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 18px;
        font-weight: 500;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
        text-align: justify;
        text-align-last: center;
        -moz-text-align-last: center;
        -ms-text-align-last: center;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }

  .section-one-main {
    .sectionone.first.bgwhite {
      padding: 0px 0px 0px 0px;
      text-align: center;
      .container {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        padding: 0px;
        > .row {
          margin: 0px;
          padding: 0px;
          .col-sm-12 {
            background: $nav_color;
            margin: 0px;
            padding: 0px;
            .heading-section {
              max-width: 1400px;
              width: 100%;
              margin: auto;
            }
          }
          .block-multiples.col-sm-12 {
            background: $base_color;
            padding-bottom: 80px;
            .row {
              max-width: 1400px;
              width: 100%;
              margin: auto;
              margin-top: -70px;
            }
          }
        }
      }
    }
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .container {
      max-width: 1420px;
      margin: auto;
    }
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: $nav_color;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #080815;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $nav_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $nav_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 1em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fff;
    border-radius: 0px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: none;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: $base_color;
    text-decoration: none;
    background: transparent;
    border: 2px solid $base_color;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: $nav_color;
      color: #fff;
      border-color: $nav_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  // .carousel-item::before {
  //   content: "";
  //   height: 100%;
  //   position: absolute;
  //   left: 0px;
  //   top: 0px;
  //   width: 100%;
  //   background: transparent
  //     linear-gradient(61deg, $base_color 0%, #136fac2e 100%) 0% 0% no-repeat
  //     padding-box;
  // }

  .carousel-item {
    // height: 100vh;
    // background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: $base_color;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: $nav_color;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
    margin-top: 30px;
  }
  .tab {
    width: 100%;
    color: $nav_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: #ddd;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
      opacity: 0;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #ddd;
      border-bottom: none;
      opacity: 1;
    }
  }
  //******** Accordian css end**********//

  .section-second-main {
    .sectionone.first.bgblue {
      background-image: none !important;
      background-color: #fff;
      padding: 40px 0px 0px 0px;
      .secondSecRow {
        align-items: flex-end;
        .secondSecImg {
          max-width: 80%;
        }
        .order-md-2 {
          .heading-section {
            padding-bottom: 60px;
            width: 550px;
            max-width: 100%;
            h2 {
              font-size: 30px;
            }
          }
        }
      }

      &:before {
        display: none;
      }
      .heading-section {
        padding: 0px;
        color: $nav_color;
        h2 {
          color: $nav_color;
          margin-bottom: 0px;
        }
        p {
          width: 960px;
          max-width: 100%;
          margin-bottom: 30px;
          font-size: 18px;
          font-weight: 300;
        }
        .homebtn {
          border-color: $base_color;
          color: $base_color;
          display: inline-block;
          margin-top: 0px;
          &:hover {
            background-color: $nav_color;
            color: #fff;
            border-color: $nav_color;
          }
        }
      }
    }
  }

  .section-third-main {
    .sectionone.first.bgwhite.third-sec {
      background-color: $base_color;
      padding-bottom: 0px;
      padding-top: 40px;
      .row {
        align-items: end;
      }
      .heading-section {
        h2 {
          color: #fff;
        }
        p {
          color: #fff;
          div {
            color: #fff !important;
            font-size: 18px !important;
            text-align: justify;
          }
        }
      }
      .order-md-2 {
        text-align: center;
        .heading-section {
          img {
            width: 500px;
          }
        }
      }
      .order-md-1 {
        .heading-section {
          padding-bottom: 50px;
        }
      }
    }
  }

  .section-fourth-main {
    .sectionone.first.bg-light {
      background: #f8f8ff !important;
      padding: 60px 0px;
      .heading-section {
        padding-top: 0px;
        h2 {
          margin-bottom: 10px;
        }
        p {
          color: $nav_color;
          width: 100%;
          font-size: 18px;
        }
      }
      &.hideFaq {
        padding-bottom: 0px;
        .bottomStip {
          display: inline-block;
          background: $base_color;
          width: 100%;
          padding: 40px 0px;
          h2 {
            color: $nav_color;
            font-size: 38px;
          }
          p {
            color: $nav_color;
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 1.3;
          }
          a.homebtn.section-4 {
            border-color: $nav_color;
            color: $nav_color;
            &:hover {
              color: $nav_color;
              border-color: #fff;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .banner-div-section:after {
    content: "";
    position: absolute;
    width: 71px;
    height: 134px;
    background-repeat: no-repeat;
    left: 50%;
    bottom: -67px;
    z-index: 1;
    transform: translate(-35px, 0px);
    background-image: url(../../assets/images/icon-down-arrow.png);
  }

  .banner-div-section {
    position: relative;
  }
  .carousel-indicators {
    display: none;
  }
  .heading-section {
    padding: 100px 0px 150px 0px;
  }

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  .formlogo {
    position: absolute;
    bottom: 42px;
    font-size: 12px;
    text-align: center;
    color: #868686;
    right: 0;
    width: 100%;
    font-weight: 600;
    img {
      max-width: 160px;
    }
  }
  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $nav_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          height: auto;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: block;
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1425px) {
    .slider-home .carousel-item .slider-content h2 {
      font-size: 14px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12
      .row {
      max-width: 1140px;
    }
    .sectionone.first.bgwhite.third-sec .container {
      max-width: 1140px;
    }
    .section-second-main .sectionone.first.bgblue .secondSecRow .secondSecImg {
      max-width: 100%;
    }
    .slider-home .carousel-item {
      padding: 60px 0px 10px 0px;
    }
    .slider-home .carousel-item .slider-content .homebtn {
      padding: 12px 40px;
      margin: 15px 0px;
      font-size: 1.2rem;
    }
    .banner-div-section::after {
      width: 51px;
      height: 97px;
      background-size: 100%;
      bottom: -47px;
      transform: translate(-25.5px, 0px);
    }
    .trustedBrands {
      padding: 60px 0px 20px 0px;
    }
    .heading-section {
      padding: 50px 0px 150px 0px;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 20px 0px 0px 0px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      margin-bottom: 20px;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding-top: 40px;
    }
  }
  @media (max-width: 1200px) {
    .slider-home .carousel-item .slider-content {
      padding-top: 0px;
    }
    .trustedBrands ul li {
      flex: 0 0 20%;
      max-width: 20%;
      text-align: center;
    }
    .heading-section p {
      width: 90%;
    }
    .heading-section {
      padding: 50px 0px 130px 0px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item {
      padding: 20px 0px;
    }
    .slider-home .carousel-item .slider-content {
      padding: 2em 0em 0em 0em;
      width: 600px;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 20px;
      padding: 5px 5px;
    }
    .slider-home .carousel-item .slider-content .lead h2 {
      font-size: 34px;
    }
    .slider-home .carousel-item .slider-content .lead h3 {
      font-size: 18px;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
    .banner-div-section::after {
      width: 50px;
      height: 95px;
      bottom: -47.5px;
      transform: translate(-25px, 0px);
      background-size: 100%;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 30px 0px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding-bottom: 30px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 34px;
      margin-bottom: 20px;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding: 30px 0px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }
    .section-second-main
      .sectionone.first.bgblue
      .secondSecRow
      .order-md-2
      .heading-section
      h2 {
      font-size: 26px;
    }
    .section-second-main .sectionone.first.bgblue .heading-section p {
      margin-bottom: 10px;
      font-size: 16px;
    }
    .section-second-main .sectionone.first.bgblue {
      padding: 30px 0px 0px 0px;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 100px 20px;
        }
      }
    }
    .formlogo img {
      max-width: 100px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12
      .row {
      margin-top: 0px;
    }
    .heading-section {
      padding: 40px 15px;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 0px;
    }
    .block-multiples .col-sm-3 {
      margin: 15px 0px;
    }
    .banner-div-section::after {
      width: 30px;
      height: 58px;
      bottom: -29px;
      transform: translate(-15px, 0px);
    }
    .heading-section p {
      width: 100%;
    }
    .heading-section h2 {
      margin-bottom: 1rem;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 15px;
    }
    .block-multiples .single-block p {
      text-align: center;
    }
    .section-second-main .sectionone.first.bgblue .heading-section p {
      margin-bottom: 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-1
      .heading-section {
      padding-bottom: 10px;
      text-align: center;
    }
    .footer-content .custome-links {
      margin-top: 10px;
    }
    .navbar-collapse {
      margin: 0px -15px;
      border-top: 1px solid rgba(256, 256, 256, 0.4);
      background-color: $nav_color;
    }
    .header-section .fixed-top {
      padding: 0.5rem 1rem 0px 0px !important;
    }
    .header-section.pageScroll .fixed-top {
      padding: 0.5rem 1rem 0px 0px !important;
    }
    .header-section .banner-div-section {
      margin-top: 68px;
    }
    .slider-home .carousel-item .slider-content {
      width: 510px;
    }
    .trustedBrands {
      padding: 40px 0px 20px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .heading-section
      p
      div {
      text-align-last: center;
      -moz-text-align-last: center;
      -ms-text-align-last: center;
    }
    .sectionone.first.bg-light .heading-section {
      flex: 0 0 100%;
      margin-left: 0px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip p {
      font-size: 22px;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      padding-bottom: 0px;
    }
    .section-third-main .sectionone.first.bgwhite.third-sec {
      padding-top: 20px;
    }
    .secondSecRow {
      .order-md-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        -ms-flex-order: 1;
        order: 1;
      }
      .order-md-1 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        -ms-flex-order: 1;
        order: 1;
      }
    }
    .section-second-main .sectionone.first.bgblue .secondSecRow .secondSecImg {
      width: 450px;
    }
    .section-second-main
      .sectionone.first.bgblue
      .secondSecRow
      .order-md-2
      .heading-section {
      margin: auto;
      padding-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    .block-multiples .row {
      display: flex;
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item {
      padding: 40px 0px;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 0em;
      .lead {
        h2 {
          font-size: 34px;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 16px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    // .sectionone.first.bgwhite.third-sec .heading-section img {
    //   box-shadow: 5px 5px 0px #eee;
    // }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 100px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 100px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .section-fourth-main .sectionone.first.bg-light {
      padding: 20px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-1
      .heading-section {
      padding-bottom: 0px;
    }
    // .slider-home .carousel-item {
    //   background-image: none !important;
    // }
    .slider-home .carousel-item .slider-content {
      text-align: center;
    }
    .section-third-main .sectionone.first.bgwhite.third-sec .heading-section p {
      text-align: center;
    }
    .section-one-main
      .sectionone.first.bgwhite
      .container
      > .row
      .block-multiples.col-sm-12 {
      padding: 15px 0px;
    }
    .section-third-main
      .sectionone.first.bgwhite.third-sec
      .order-md-2
      .heading-section
      img {
      top: 0px;
    }
    img.img-fluid-logo {
      height: 40px !important;
      max-width: 200px;
    }
    .header-section .banner-div-section {
      margin-top: 66px;
    }
    .slider-home .carousel-item .carousel-caption.d-md-block {
      display: inline-block !important;
    }
    .trustedBrands ul li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 26px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip h2 {
      font-size: 26px;
    }
    .section-fourth-main .sectionone.first.bg-light.hideFaq .bottomStip p {
      font-size: 18px;
    }
    .sectionone.first.bg-light .heading-section {
      padding: 0px 15px;
    }
    .footer-content {
      .col-sm-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .header-section .fixed-top,
    .header-section.pageScroll .fixed-top {
      padding: 0px 1rem !important;
    }
    .header-section .fixed-top .navbar-brand {
      min-height: 60px;
      line-height: 60px;
    }
    .navbar-brand img.img-fluid-logo {
      height: 33px !important;
      max-width: none;
    }
    .footer-content .custome-links {
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }

    .slider-home .carousel-item .slider-content .lead h2 {
      font-size: 30px;
    }
    .slider-home .carousel-item .slider-content .lead h3 {
      font-size: 16px;
    }
    .slider-home .carousel-item {
      padding: 40px 0px 20px 0px;
    }
    .section-third-main .container .order-md-1 .heading-section h2 {
      font-size: 30px;
    }
    .tab-label {
      font-size: 18px;
    }
  }
}

.layout_first-demo-sixth-theme {
  $base_color: #5c0f8b;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          &:hover {
            background: #fff;
            color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
        padding-box;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #ffffff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        &:hover {
          color: $base_color;
          background: #ffffff;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $base_color !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 790px;
      max-width: 100%;
      color: #fff;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #333;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 45px;
      margin: 5px 0px;
      &::before {
        content: "";
        height: 3px;
        width: 30px;
        left: 0px;
        top: 13px;
        position: absolute;
        display: inline-block;
        background-color: #fff;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 3px;
                width: 30px;
                left: 0px;
                top: 13px;
                position: absolute;
                display: inline-block;
                background-color: $base_color;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      // margin-top: -70px;
      box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: #fff;
      color: $base_color;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      // box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 0px;
      padding-bottom: 90px;
      // &:before{
      //   content: "";
      //   width: 223px;
      //   height: 223px;
      //   left: -140px;
      //   top: -40px;
      //   position: absolute;
      //   z-index: -1;
      //   background-image: url(../../assets/images/dotted-bg.png);
      //   background-repeat: no-repeat;
      // }
      // &:after{
      //   content: "";
      //   width: 223px;
      //   height: 223px;
      //   right: -140px;
      //   bottom: -40px;
      //   position: absolute;
      //   z-index: -1;
      //   background-image: url(../../assets/images/dotted-bg.png);
      //   background-repeat: no-repeat;
      // }
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
        h2 {
          // font-family: 'Kaushan Script', cursive !important;
          background-image: linear-gradient(to right, #e10a0a 0, #5c0f8b 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          // font-size: 44px;
        }
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          // border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          // background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          border: none;
          position: relative;
          background: $base_color;
          &::after {
            background-image: url(../../assets/images/arrow-btn.png);
            background-repeat: no-repeat;
            content: "";
            right: 20px;
            width: 12px;
            height: 12px;
            position: absolute;
            transition-duration: 250ms;
            height: 12px;
            transform: translate(0, -50%);
            content: "";
            top: 50%;
          }
          &:hover {
            background: #e10a0a;
            // border-color: #e10a0a;
            &::after {
              right: 10px;
            }
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: #fff;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid #fff;
            border-radius: 5px;
            span {
              background-color: #fff;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: #3c3c3c;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #fff;
        h2 {
          font-size: 28px;
          color: #fff;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #eaeaea;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #eaeaea;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////
  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-seventh-theme {
  $base_color: #1b283f;
  $secondryColor: #ffc417;
  $lightColor: #f2fafd;
  $darkskyblue: #cae7f7;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: $lightColor;
        svg {
          path {
            fill: $lightColor;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            border-radius: 5px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
          border-radius: 5px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          border-radius: 5px;
          &:hover {
            background: $base_color;
            color: #fff;
            border-color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 600;
      font-size: 42px;
      line-height: normal;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
      font-size: 18px;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
    background: $lightColor;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #111;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $secondryColor 0%, $secondryColor 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.9;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #111;
        text-decoration: none;
        background: transparent;
        border: 2px solid #111;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          background: $base_color;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    background: $lightColor;
    .container {
      background: #cae7f7;
      padding: 80px;
      max-width: 1420px;
    }
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $secondryColor !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 950px;
      max-width: 100%;
      color: #111;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: $base_color;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 40px;
      margin: 15px 0px;
      &::before {
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        top: 3px;
        position: absolute;
        display: inline-block;
        background-image: url(../../assets/images/yes-white.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 20px;
                width: 20px;
                left: 0px;
                top: 3px;
                position: absolute;
                display: inline-block;
                background-image: url(../../assets/images/yes-black.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      margin-top: -120px;
      // box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #111;
    text-decoration: none;
    background: transparent;
    border: 2px solid #111;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    border-radius: 5px;
    &:hover {
      background: $base_color;
      color: #fff;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: $secondryColor;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid $secondryColor;
            border-radius: 5px;
            span {
              background-color: $secondryColor;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: $darkskyblue;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #111;
        h2 {
          font-size: 28px;
          color: #111;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #111;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #111;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
            border-radius: 5px;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
    .sectionone.first.bgwhite.third-sec .row {
      display: block;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      max-width: 100%;
      padding: 15px 15px;
      text-align: left;
    }
    .sectionone.first.bgwhite.third-sec .heading-section img {
      margin-top: 0px;
    }
    .sectionone.first.bgwhite.third-sec .container {
      padding: 15px;
      max-width: 990px;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-eighth-theme {
  $base_color: #cd232e;
  $secondryColor: #2c2e31;
  $lightColor: #fff3f3;
  $darkskyblue: #ffd5d5;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: $lightColor;
        svg {
          path {
            fill: $lightColor;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            border-radius: 5px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
          border-radius: 5px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          border-radius: 5px;
          &:hover {
            background: $base_color;
            color: #fff;
            border-color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 600;
      font-size: 42px;
      line-height: normal;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
      font-size: 18px;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
    background: $lightColor;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #111;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $secondryColor 0%, $secondryColor 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.9;
    }
    .heading-section {
      h2 {
        color: #fff;
      }
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
        color: #fff;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #fff;
        text-decoration: none;
        background: transparent;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          background: $base_color;
          border-color: $base_color;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    background: $lightColor;
    .container {
      background: #ffd4d4;
      padding: 80px;
      max-width: 1420px;
    }
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $secondryColor !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 950px;
      max-width: 100%;
      color: #111;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
        color: #fff;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
        color: #fff;
      }
      .homebtn {
        border-color: #fff;
        color: #fff;
        &:hover {
          border-color: $base_color;
        }
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: #1c1c1c;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 40px;
      margin: 15px 0px;
      &::before {
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        top: 3px;
        position: absolute;
        display: inline-block;
        background-image: url(../../assets/images/yes-white.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 20px;
                width: 20px;
                left: 0px;
                top: 3px;
                position: absolute;
                display: inline-block;
                background-image: url(../../assets/images/yes-black.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      margin-top: -120px;
      // box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #111;
    text-decoration: none;
    background: transparent;
    border: 2px solid #111;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    border-radius: 5px;
    &:hover {
      background: $base_color;
      color: #fff;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: $secondryColor;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid $secondryColor;
            border-radius: 5px;
            span {
              background-color: $secondryColor;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  /**************************************************************
                  New form layout with text css 
*************************************************************/
  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: $darkskyblue;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #111;
        h2 {
          font-size: 28px;
          color: #111;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #111;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #111;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
            border-radius: 5px;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
    .sectionone.first.bgwhite.third-sec .row {
      display: block;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      max-width: 100%;
      padding: 15px 15px;
      text-align: left;
    }
    .sectionone.first.bgwhite.third-sec .heading-section img {
      margin-top: 0px;
    }
    .sectionone.first.bgwhite.third-sec .container {
      padding: 15px;
      max-width: 990px;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}

.layout_first-demo-ninth-theme {
  $base_color: #00467f;
  $secondryColor: #ffc417;
  $lightColor: #f2fafd;
  $darkskyblue: #cae7f7;
  .notQualifySection {
    .button-home a {
      background: none;
      color: $base_color;
      text-transform: none;
      font-weight: 400;
      padding: 15px 40px;
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 2px;
      border: 2px solid $base_color;
      border-radius: 3px;
      font-weight: 500;
      svg {
        width: 16px;
        margin-left: 10px;
        path {
          fill: $base_color;
        }
      }
      &:hover {
        background: $base_color;
        color: $lightColor;
        svg {
          path {
            fill: $lightColor;
          }
        }
      }
    }
  }
  .form-heading-css {
    //margin: 5px 15px 0px 15px;
    padding: 10px 38px 20px 20px;
    font-size: 18px;
    // .contactHeading {
    //   padding: 10px 38px 0px 0px;
    // }
  }
  .section-one-main {
    background: #f9f9f9;
  }
  .section-third-main {
    background: #f9f9f9;
  }
  .contactDynamicForm {
    margin-right: 0px;
    .dynamic-form {
      margin: 0px;
      padding: 0px;
      background: none;
      border: none;
      .input-box__field {
        background: #fafafa;
        border: 1px solid #e1e1e1;
        &::placeholder {
          color: rgb(126, 126, 126);
        }
      }
      .space-between {
        justify-content: left !important;
      }
      input[type="button"],
      input[type="submit"] {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
      button.MuiButtonBase-root.MuiButton-root.jss90.MuiButton-contained.jss86.jss88 {
        color: $base_color;
        border: 2px solid $base_color;
        background: transparent;
        float: left;
        height: 55px;
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .container {
    max-width: 1420px;
  }
  // .controls.contactControl {
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
  .header-section {
    background: $base_color;
    .fixed-top {
      background: transparent !important;
      transition: 0.5s;
      padding: 1.5rem 1rem;
      ul {
        .nav-item {
          a {
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0px;
            font-size: 14px;
            cursor: pointer;
          }
          margin-left: 40px;
          padding-top: 8px;
          &:hover {
            .nav-link {
              &:hover {
                color: #acacac;
              }
            }
          }
        }
        .nav-item.lastbtn {
          a {
            background: transparent;
            border: 2px solid #fff;
            color: #fff;
            padding: 9px 30px;
            border-radius: 5px;
            &:hover {
              background: #ffffff !important;
              color: $base_color !important;
            }
          }
          position: relative;
          top: -4px;
          border-radius: 5px;
        }
        .nav-item.active {
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .padding-left-none {
    padding-left: 0 !important;
  }
  .carousel-item {
    min-height: 500px;
    //height: 500px;
    // background: no-repeat center center scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .slider-home {
    //background: url("../images/slider-bg.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    .carousel-item {
      .carousel-caption {
        position: relative;
        right: 0;
        left: 0;
      }
      .slider-content {
        width: 600px;
        float: left;
        text-align: left;
        padding-top: 0px;
        max-width: 100%;
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: normal;
        }
        .homebtn {
          background: transparent;
          border: 2px solid #fff;
          text-transform: uppercase;
          font-weight: 600;
          padding: 15px 64px;
          margin: 20px 0px 0px 0px;
          font-size: 1.1rem;
          letter-spacing: 1.26px;
          color: #ffffff;
          display: inline-block;
          background-position: center;
          transition: #000 0.8s;
          border-radius: 5px;
          &:hover {
            background: $base_color;
            color: #fff;
            border-color: $base_color;
          }
        }
      }
      .silder-image-new {
        float: right;
        width: 44%;
        position: relative;
        right: 0;
        bottom: -20px;
      }
    }
  }
  .address-block-1 {
    h4 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    margin: 0 10px 50px 0;
  }
  .contact_info {
    img {
      margin: 0 10px 0 0;
      width: 21px;
      width: 18px;
      margin: 4px 25px 0 0;
      height: 17px;
    }
    display: flex;
    margin: 0 0 10px 0;
    display: flex;
  }
  section.contact-block-inner {
    background: #f1f1ff;
    padding: 2% 10%;
  }
  .width80 {
    width: 80%;
    margin: 0 auto;
    .form-heading {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
        &:hover {
          color: #fff;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .contactWrapperinner {
    background: #e8e8e8;
    padding: 30px;
    margin-bottom: 0%;
    margin: 0 auto;
  }
  ul.contact_lists {
    li {
      display: flex;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .text_block {
    display: flex;
  }
  span.number_1 {
    margin-right: 10px;
  }
  strong.sub_title {
    margin: 20px 0 10px;
    display: block;
  }
  .heading-section {
    h2 {
      font-weight: 600;
      font-size: 42px;
      line-height: normal;
      margin-bottom: 0.3rem;
    }
    p {
      width: 65%;
      margin: 0px auto;
      font-size: 18px;
    }
    padding-bottom: 50px;
  }
  .block-multiples {
    .single-block {
      .img-fluid {
        width: 104px;
        //height: 146px;
        &.gifImg {
          display: none;
        }
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 30px;
      }
      p {
        font-weight: 300;
        color: #505050;
        font-size: 16px;
      }
      &:hover {
        .gifImg {
          display: inline-block;
        }
        .jpgImg {
          display: none;
        }
      }
    }
  }
  .sectionone.first.bgwhite {
    padding: 80px 0px 80px 0px;
    text-align: center;
    background: $lightColor;
  }
  .image-block {
    margin-bottom: 15px;
  }
  .help-block.with-errors {
    .list-unstyled {
      color: #c53e3e;
    }
  }
  .center-item {
    text-align: center;
  }
  .btn-custome-1 {
    background: #06124e;
    opacity: 9 !important;
    color: #fff;
    &:hover {
      background: #262e54;
      color: #fff;
    }
  }
  .contactWrapper1 {
    // background: #f1f1ff;
    // background: #f1f1ff;
    padding: 0px;
    margin-bottom: 10px;
    // background: #f1f1ff;
    // padding: 30px;
    margin-bottom: 0%;
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #7a7a7a !important;
    }
  }
  .sectionone.first.bgblue {
    background-color: $base_color;
    //background-image: url(../../assets/images/stip-img.jpg);
    background-position: left top;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: #111;
    padding: 130px 0%;
    position: relative;
    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background: transparent
        linear-gradient(284deg, $secondryColor 0%, $secondryColor 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.9;
    }
    .heading-section {
      p {
        padding-bottom: 20px;
        width: 730px;
        max-width: 100%;
        padding-top: 15px;
        margin-bottom: 60px;
      }
      padding-bottom: 0px;
      .homebtn {
        color: #111;
        text-decoration: none;
        background: transparent;
        border: 2px solid #111;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 60px;
        margin: 40px 0px 0px 0px;
        font-size: 18px;
        letter-spacing: 1.26px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          background: $base_color;
        }
      }
    }
  }
  .sectionone.first.bgwhite.third-sec {
    text-align: left;
    background: $lightColor;
    .container {
      background: #cae7f7;
      padding: 80px;
      max-width: 1420px;
    }
    .heading-section {
      p {
        width: 100%;
        br {
          padding-bottom: 10px;
          display: block;
          margin: 6px 0px;
        }
      }
      padding: 0px;
    }
  }
  .sectionone.first.bg-light {
    background: $secondryColor !important;
    padding: 80px 0%;
    .heading-section {
      text-align: center;
      width: 950px;
      max-width: 100%;
      color: #111;
      padding-bottom: 0px;
      h2 {
        line-height: normal;
      }
      p {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }
  }
  .descrioption-news {
    p {
      margin-bottom: 0px;
      br {
        display: block;
        margin: 6px 0px;
      }
    }
  }
  .footer-content {
    background: $base_color;
    color: #ddd;
    padding: 20px 0%;
    b {
      color: #585858;
      font-weight: 400;
      font-size: 12px;
    }
    .custome-links {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        float: right;
        li {
          display: inline-block;
          padding-left: 20px;
          a {
            color: #ddd;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
    .logo-blocks {
      .img-fluid {
        display: block;
      }
    }
    .img-fluid {
      display: block;
    }
  }
  .logo-blocks {
    p {
      margin-bottom: 0px;
    }
  }
  .copyright {
    background: #222;
    color: #9e9e9e;
    text-align: center;
    padding: 1% 0%;
    // border-top: 1px solid #d1d1d1;
    p {
      margin: 0px;
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $base_color !important;
      transition: 0.5s;
      padding: 0.5rem 1rem;
    }
  }
  .slider-section-2 {
    h4 {
      font-size: 35px;
    }
  }
  .block_wrapper {
    h2 {
      text-align: center;
      font-weight: 500;
      background: $base_color;
      padding: 15px 0;
      color: #fff;
    }
  }
  div#fragment-1 {
    padding: 0px 20px;
  }
  .header-section.headerInside {
    .fixed-top {
      background-color: $base_color !important;
    }
  }
  .form-survey-section {
    padding: 10px 0px 20px 0px;
    display: table;
    background: #eee;
    width: 100%;
    height: calc(100vh - 292px);
    .container {
      display: table-cell;
      vertical-align: middle;
    }
    .lasttab {
      .bottom-button-new.pre {
        float: none;
      }
    }
  }
  .form-servey-custome {
    box-shadow: 0px 0px 7px 1px #dbdbdb;
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    min-height: 230px;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 10px;
    position: relative;
    h2 {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      color: $base_color;
      padding: 30px 0px 20px 0;
    }
    #myTab {
      opacity: 0;
      .nav-item {
        font-size: 0px;
      }
    }
    #tabs {
      .ui-tabs-nav {
        opacity: 0;
        font-size: 0px;
        display: none;
      }
    }
    h3 {
      font-size: 26px;
      padding-bottom: 10px;
      display: flex;
      font-weight: 400;
      color: $base_color;
      span {
        background: #f55665;
        color: #fff;
        width: 30px;
        min-width: 30px;
        display: inline-block;
        text-align: center;
        height: 30px;
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    .ui-widget-content {
      .custom-control.custom-radio {
        padding: 8px 25px;
        font-size: 20px;
        margin: 0px 20px;
      }
      border: none;
      padding: 0px 20px;
      min-height: 250px;
      margin-top: 30px;
    }
    .yourpost-code {
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      padding-top: 18px;
    }
    .infor {
      padding-top: 10px;
      display: inline-block;
    }
    .progress {
      margin-top: 30px;
      border-radius: 20px;
      height: 15px;
      font-size: 10px;
      .progress-bar {
        background: #0ab90a;
      }
    }
  }
  .innerpageheader {
    text-align: center;
    color: #fff;
    text-align: center;
    color: #fff;
    padding: 40px 0;
    h4 {
      font-size: 35px;
    }
  }
  .bottom-button-new {
    a.mover {
      position: relative;
      font-size: 16px;
      font-weight: normal;
      border: 1px solid $base_color;
      padding: 7px 30px;
      margin-top: 30px;
      display: inline-block;
      border-radius: 0px;
      background: transparent;
      color: #222;
      &:hover {
        background: $base_color;
        color: #fff;
      }
    }
    display: inline-block;
  }
  .bottom-button-new.pre {
    float: left;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .bottom-button-new.next {
    float: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .yourpost-code.goodluck.This {
    p {
      border: 1px solid #ec2f2f;
      color: #ec2f2f;
    }
  }
  .yourpost-code.goodluck {
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
    p {
      margin: 0px;
      padding: 6px 30px;
      border: 1px solid #0fc10f;
      display: inline-block;
      color: #008600;
    }
  }
  .progressnew {
    display: inline-block;
    width: auto;
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 10%;
    right: 10%;
  }
  .progress {
    display: flex;
    height: 25px;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 100px;
  }
  .radioWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    label {
      font-weight: 500;
      color: #717171;
    }
  }
  ul.listing-1 {
    li {
      padding: 5px;
      color: #000;
      i {
        background: $base_color;
        padding: 4px 9px;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
    }
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 25px;
  }
  .body-content1 {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6em;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .contentAboutLeft {
      float: left;
      width: 40%;
      .text-red {
        color: #625f5f;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em !important;
      }
    }
    .contentAbout {
      float: right;
      width: 60%;
      color: #625f5f;
      ul {
        margin: 0px 0px 50px 0px;
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 10px 0px;
          &::before {
            content: "";
            height: 3px;
            width: 10px;
            left: 0px;
            top: 13px;
            position: absolute;
            display: inline-block;
            background-color: $base_color;
          }
        }
      }
    }
  }
  span.text-red {
    font-size: 18px;
    color: #030d4a;
    font-weight: 300;
  }
  span.mendatory {
    color: #b71515;
  }
  .lead {
    margin: 3em 0px;
    font-size: 1.2em;
    li {
      list-style: none;
      position: relative;
      padding-left: 40px;
      margin: 15px 0px;
      &::before {
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        top: 3px;
        position: absolute;
        display: inline-block;
        background-image: url(../../assets/images/yes-white.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .pageTitle {
    background: $base_color;
    padding-top: 140px;
    padding-bottom: 40px;
    color: #fff;
    text-align: center;
  }
  .surveyPagetitle {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .innerPageContent {
    padding: 50px 0px;
    .image-block {
      margin-bottom: 30px;
    }
  }
  .form-heading {
    margin-bottom: 40px;
    h2 {
      color: #000f2b;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    .messages {
      color: #7a7a7a;
      font-size: 16px;
    }
  }
  .form-control {
    font-weight: 300;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-send {
    border-radius: 0px;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 30px;
  }
  .aboutImg {
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-top: 3em;
  }

  /***new css***/
  * {
    //font-family: "Montserrat", sans-serif !important;
  }
  .single-block {
    background: #fffefe;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    transition: 0.5s;
    margin-bottom: 15px;
    //font-family: "Montserrat", sans-serif !important;
    &:hover {
      box-shadow: 0px 0px 20px #dbdff0;
      transition: 0.5s;
      transform: translate(0px, -1em);
    }
  }

  .section-third-main {
    .container {
      max-width: 1240px;
      .order-md-1 {
        .heading-section {
          width: 100%;
          h2 {
            line-height: normal;
            margin-bottom: 40px;
          }
          p {
            color: #2a2a2a;
          }
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              position: relative;
              padding-left: 45px;
              margin: 15px 0px;
              &::before {
                content: "";
                height: 20px;
                width: 20px;
                left: 0px;
                top: 3px;
                position: absolute;
                display: inline-block;
                background-image: url(../../assets/images/yes-black.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  .contactFields {
    margin-top: 50px;
    h5 {
      color: #000f2b;
      font-size: 22px;
      line-height: 1.5em;
      letter-spacing: 2.5px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        a {
          color: #7a7a7a;
          font-weight: 400;
          &:hover,
          &:hover span {
            color: $base_color;
            text-decoration: none;
          }
        }
        span {
          color: #7a7a7a;
          font-weight: 400;
        }
      }
    }
  }

  .sectionone.first.bgwhite.third-sec .heading-section {
    img {
      position: relative;
      margin-top: -120px;
      // box-shadow: 15px 15px 0px #eee;
    }
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 400;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
    h3 {
      font-weight: 300;
    }
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 15px 40px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #111;
    text-decoration: none;
    background: transparent;
    border: 2px solid #111;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    display: inline-block;
    border-radius: 5px;
    &:hover {
      background: $base_color;
      color: #fff;
    }
  }

  .sectionContentfourth {
    display: flex;
    flex-wrap: wrap;
    & + .heading-section {
      margin-top: 40px;
    }
  }

  /*********new survey form layout css *************/
  .newSurveyForm {
    display: inline-block;
    width: 100%;
    padding-top: 150px;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 185px);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
      position: absolute;
      height: 250px;
      background: $base_color;
      top: 0px;
      left: 0px;
      width: 100%;
      content: "";
    }
    .newSurveyPageContainer {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 50px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      padding-bottom: 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        //min-height: 300px;
        .form-control {
          height: 60px;
        }
        .formLabel {
          color: #888;
        }
        .questionSteps {
          display: inline-block;
          width: 100%;
          .MuiFormLabel-root {
            font-size: 20px;
          }
          .MuiFormGroup-root {
            flex-wrap: wrap;
            display: flex;
            flex-direction: inherit;
            label.MuiFormControlLabel-root {
              max-width: 100%;
              flex: 0 0 100%;
              margin: 0px;
              //display: inline-block;
              width: 100%;
              float: left;
            }
          }
          &.radioBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }

              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }

          &.checkboxBtn {
            margin-bottom: 15px;
            .form-group {
              width: 50%;
              float: left;
              margin-bottom: 0px;
              input {
                display: none;
                &:checked + label {
                  &:after {
                    border: 2px solid #fff;
                    border-top: none;
                    border-left: none;
                    width: 7px;
                    height: 12px;
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    content: " ";
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    transition: all 0.1s linear;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  &:before {
                    background: #000;
                  }
                }
              }
              label {
                width: 100%;
                float: left;
                position: relative;
                color: #888;
                padding: 5px 8px 7px 8px;
                &::before {
                  background: #fff;
                  border: 1px solid #999;
                  min-width: 20px;
                  width: 20px;
                  height: 20px;
                  margin: 3px 10px 0 0;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  -webkit-transition: all 0.1s linear;
                  transition: all 0.1s linear;
                  content: "";
                  display: inline-block;
                  border-radius: 0%;
                  top: 4px;
                  position: relative;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          .MuiInput-underline {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .MuiSelect-select:focus {
            &::after {
              display: none !important;
            }
          }
        }
      }
      .newSurveyFooter {
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        .backBtn {
          border: 2px solid #cccccc;
          color: #cccccc;
          padding: 12px 30px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: #ccc;
            color: #fff;
          }
        }
        .nextBtn {
          border: 2px solid $base_color;
          color: #ffffff;
          padding: 12px 30px;
          text-transform: uppercase;
          background: $base_color;
          letter-spacing: 1px;
          border-radius: 2px;
          &:hover {
            background: $base_color;
            border-color: $base_color;
          }
        }
      }
      .progressBar {
        background: $base_color;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: -1px;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        overflow: hidden;
        text-align: center;
        .progressBarContainer {
          height: 40px;
          overflow: hidden;
          margin: auto 0;
          display: inline-block;
          .progressBarText {
            color: $secondryColor;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 14px;
            height: 40px;
            line-height: 40px;
            float: left;
          }
          .bar {
            width: 200px;
            border-radius: 2px;
            height: 8px;
            float: left;
            margin-top: 16px;
            border: 1px solid $secondryColor;
            border-radius: 5px;
            span {
              background-color: $secondryColor;
              height: 8px;
              border-radius: 2px;
              float: left;
            }
          }
        }
      }
    }
    button.btn.btn-submit {
      border: 2px solid $base_color;
      color: $base_color;
      padding: 12px 30px;
      text-transform: uppercase;
      background: #fff;
      letter-spacing: 1px;
      border-radius: 2px;
    }
    input.input-box__field.input-box__field--survey.false {
      height: 35px;
    }
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
  }

  //Header banner css

  /**new css layout 1**/
  .carousel-item::before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: transparent
      linear-gradient(61deg, $base_color 0%, rgba(19, 111, 172, 0) 80%) 0% 0%
      no-repeat padding-box;
  }

  .carousel-item {
    height: 100vh;
  }
  .slider-home .carousel-item .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    display: table;
  }
  .slider-home .carousel-item .carousel-caption.d-md-block {
    display: table !important;
  }
  .slider-home .carousel-item .slider-content {
    padding-top: 0px;
    display: table-cell;
    vertical-align: middle;
    float: none !important;
  }

  .descrioption-news blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    min-height: 270px;
    margin-bottom: 30px;
  }

  .newSurveyForm {
    text-align: center;
    .surveyPageText {
      width: 1110px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      padding: 0px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      flex-direction: row-reverse;
      .surveyTextFields {
        float: left;
        max-width: 44%;
        padding: 50px;
        background: $darkskyblue;
        border-radius: 10px 0px 0px 10px;
        flex: 0 0 44%;
        color: #111;
        h2 {
          font-size: 28px;
          color: #111;
        }
        ul {
          margin: 0px 0px 20px 0px;
          padding: 0px 0px 0px 20px;
          li {
            list-style: disc;
            margin: 15px 0px;
            color: #111;
            // background:url(../../assets/images/smile.png);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: left 2px;
            // padding-left: 35px;
          }
        }
        .ourCustomers {
          margin-top: 3.5rem;
          blockquote {
            margin-top: 20px;
            .customerdetails {
              display: inline-block;
              width: 100%;
              margin-bottom: 15px;
              .customerImg {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #eee;
                float: left;
                img {
                  width: 100%;
                }
              }
              .customerName {
                margin-left: 90px;
                margin-top: 17px;
              }
            }
            p {
              color: #111;
              font-style: italic;
            }
          }
        }
      }
      .newSurveyPageContainer {
        float: left;
        max-width: 56%;
        box-shadow: none;
        border-left: 1px solid #eee;
        margin: 0px;
        flex: 0 0 56%;
        .progressBar {
          border-radius: 0px 0px 5px 0px;
        }
        .space-between {
          .makeStyles-root-2 {
            display: inline-block;
          }
        }
      }
    }
  }
  .footer_content {
    color: #333232;
  }
  .text-left {
    min-height: calc(100vh - 185px);
    justify-content: center;
    display: flex;
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }
  .dynamic-form {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 20px 20px 20px 20px;
    justify-content: left;
    width: 100%;
    background: white;
    border: solid 1px #c2c2c2;
    border-radius: 4px;
  }
  .date-box__field {
    width: -webkit-fill-available;
  }
  .date-box__field--survey {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #c5c5c5;
    border-left: none;
  }
  .date-box__field--survey_hoverColor {
    width: 100%;
    height: 44px;
    color: #7c7d7d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid $base_color;
    border-left: none;
    font-size: 16px !important;
    font-weight: 500;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    text-align: left;
  }
  .tab {
    width: 100%;
    color: $base_color;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: $base_color;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #fff;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;

      /* Icon */
      &:hover {
        background: $base_color;
        color: #fff;
        transition: 0.5s;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        font-size: 26px;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: $base_color;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: $base_color;
      color: #fff;
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      border: solid 1px #0e7e69;
    }
  }
  //******** Accordian css end**********//

  /////////**********************home survey form******************//////////////////
  .arrowIconForm {
    width: 60px;
    height: 60px;
    background: #fff;
    content: "";
    right: 415px;
    top: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 12;
    transform: translate(0px, -30px);
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    color: $base_color;
    // border: 5px solid #fff;
    i {
      animation: pulse 1.5s infinite;
      position: relative;
      left: 0;
    }
    @keyframes pulse {
      0% {
        left: 5px;
      }
      50% {
        left: -5px;
      }
      100% {
        left: 5px;
      }
    }
  }

  .bannerNewForm {
    .carousel-item {
      width: calc(100% - 450px);
    }
    .homeformOutside {
      min-height: 100vh;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 450px;
      max-width: 100%;
      display: inline-block;
      background: #fff;
      z-index: 11;
      padding-top: 70px;
      height: 100%;
      // overflow: auto;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .homeForm {
        float: left;
        height: 100%;
        overflow: auto;
        width: 100%;
        .homeFormInside {
          // background: #fff;
          padding: 15px 25px 25px 25px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          margin: 0px auto;
          color: #000;
          // position: relative;
          float: left;
          // max-height: 100%;
          // overflow: auto;
          padding-bottom: 50px;
          .previousBtn {
            padding: 0px;
            font-size: 14px;
            img {
              width: 7px;
              margin-right: 5px;
              float: left;
              margin-top: 4px;
            }
          }

          .progressBar {
            background: $base_color;
            position: absolute;
            bottom: 0;
            height: 40px;
            left: -1px;
            width: 100%;
            // border-radius: 0 0 5px 5px;
            overflow: hidden;
            text-align: center;
            z-index: 1;
            .progressBarContainer {
              height: 40px;
              overflow: hidden;
              margin: auto 0;
              display: inline-block;
              .progressBarText {
                color: #fff;
                font-size: 15px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50%;
                margin-right: 14px;
                height: 40px;
                line-height: 40px;
                float: left;
              }
              .bar {
                width: 200px;
                border-radius: 2px;
                height: 8px;
                float: left;
                margin-top: 16px;
                border: 1px solid #fff;
                border-radius: 5px;
                span {
                  background-color: #fff;
                  height: 8px;
                  border-radius: 2px;
                  float: left;
                }
              }
            }
          }
          .newSurveyHeading {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            color: $base_color;
            text-align: center;
          }

          .newSurveyContent {
            margin-top: 0px;
            display: inline-block;
            width: 100%;
            .customDropdown-default-theme_span {
              margin: 8px 4px 0 4px;
            }
            .MuiFormLabel-root {
              text-align: left;
              font-size: 16px;
            }
            .MuiFormControl-root {
              padding: 0px;
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px 5px;
              }
              .MuiFormControlLabel-root {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
            .input-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field {
                height: 58px;
                box-sizing: border-box;
                width: 100%;
              }
            }
            .textarea-box {
              label.MuiFormLabel-root {
                font-size: 16px;
                padding: 0px;
              }
              textarea.textarea-box__field--survey {
                min-height: 80px !important;
              }
            }

            button.btn.btn-submit {
              border: 2px solid $base_color;
              color: $base_color;
              padding: 12px 30px;
              text-transform: uppercase;
              background: #fff;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }

          .newSurveyFooter {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            padding: 0px 5px;
            .nextBtn {
              border: 2px solid $base_color;
              color: #fff;
              padding: 12px 30px;
              text-transform: uppercase;
              background: $base_color;
              letter-spacing: 1px;
              border-radius: 2px;
              float: right;
            }
            .backBtn {
              border: 2px solid #ccc;
              color: #ccc;
              padding: 12px 30px;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .input-box__field--error,
  .input-box__field--errord {
    border-color: $base_color !important;
    box-shadow: none;
  }
  .date-box__field {
    padding: 5px 5px;
  }
  .input-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  .textarea-box__field--survey {
    border-width: 2px;
    &:hover,
    &:focus {
      border: solid 2px $base_color;
    }
  }
  /////////**********************home survey form end******************//////////////////

  @media (max-width: 1425px) {
    .header-section {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 0rem;
      }
    }
    .container {
      max-width: 1140px;
    }
    .container-lg {
      max-width: 1140px;
    }
    .container-md {
      max-width: 1140px;
    }
    .container-sm {
      max-width: 1140px;
    }
    .container-xl {
      max-width: 1140px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 38px;
          }
          .homebtn {
            padding: 7px 30px;
            margin: 15px 0px;
            font-size: 1rem;
            border-radius: 5px;
          }
        }
      }
    }
    .lead {
      font-size: 1rem;
      margin: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 400px);
      }
      .homeformOutside {
        width: 400px;
        .customDropdown-default-theme_span {
          min-height: 114px;
          width: 114px;
        }
      }
      .arrowIconForm {
        right: 370px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 40px;
          }
          padding-top: 14%;
        }
        .silder-image-new {
          bottom: -83px;
        }
      }
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 60px 0 30px;
    }
    li.nav-item {
      margin-left: 30px !important;
    }
    .surveyContent {
      // padding: 15px !important;
      // background-image: none !important;
    }

    .bannerNewForm {
      .homeformOutside {
        height: 100%;
        min-height: auto;
      }
      .slider-home .homeformOutside .homeForm {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .arrowIconForm {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .width80 {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .contactWrapperinner {
      background: #e8e8e8;
      padding: 0;
      margin-bottom: 0%;
      width: 100%;
      margin: 0 auto;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 30px;
          }
          width: 56%;
          padding-top: 90px;
        }
        min-height: 360px;
        background-position: center center;
        .silder-image-new {
          bottom: -50px;
        }
      }
    }
    .sectionone.first.bgwhite.third-sec {
      .col-sm-6.order-md-2 {
        order: 1;
      }
      .row {
        display: block;
        .col-sm-6 {
          max-width: 100%;
          padding: 15px 15px;
          text-align: center;
        }
      }
    }
    .navbar-nav {
      .nav-item.active {
        a {
          color: #fff;
          font-weight: 600;
        }
      }
      .nav-item {
        a {
          color: #fff !important;
        }
      }
    }
    .header-section {
      .fixed-top {
        .navbar-toggler {
          background: #fff;
        }
        ul {
          .nav-item {
            margin-left: 0px;
            padding-top: 8px;
            a {
              padding: 10px 0px;
            }
          }
          .nav-item.lastbtn {
            position: relative;
            top: 0px;
            margin-bottom: 20px;
            .nav-link {
              background: transparent;
              padding: 10px 0px;
              border: none;
              color: #fff !important;
            }
          }
        }
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: 84vh;
      padding-bottom: 0;
      height: calc(100vh - 214px);
    }
    .header-section.pageScroll {
      .fixed-top {
        padding: 0.5rem 1rem;
        background-color: $base_color !important;
      }
    }
    .block-multiples {
      padding: 0px 15px;
      .single-block {
        .img-fluid {
          // height: 86px;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px;
    }
    .navbar-collapse {
      background-color: $base_color;
    }
    .lead {
      margin: 15px 0px;
    }
    body {
      font-size: 14px;
    }
    .heading-section {
      h2 {
        font-size: 30px;
      }
    }
    .single-block {
      p {
        text-align: justify;
      }
    }
    .sectionone.first.bgblue {
      padding: 40px 0px;
      .heading-section {
        .homebtn {
          font-size: 16px;
        }
      }
    }
    .sectionone.first.bg-light {
      padding: 40px 0px;
    }
    .innerPageContent {
      padding: 40px 0px;
    }
    .footer-content {
      .custome-links {
        ul {
          li {
            padding: 0px 10px 0px 0px;
          }
        }
      }
      padding: 20px 0px;
    }
    .bannerNewForm {
      .carousel-item {
        width: calc(100% - 320px);
      }
      .homeformOutside {
        width: 320px;
      }
      .homeformOutside
        .homeForm
        .homeFormInside
        .progressBar
        .progressBarContainer
        .bar {
        width: 120px;
      }
      .slider-home .carousel-item {
        min-height: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    .contactDynamicForm {
      margin-right: -40px;
    }
    .heading-section {
      h2 {
        font-size: 26px;
      }
      p {
        width: 100%;
      }
      padding-bottom: 0px;
    }
    .slider-section-2 {
      text-align: center;
      color: #fff;
      padding: 80px 0 10px;
    }
    .form-survey-section {
      padding: 10% 0% 3% 0%;
      display: table;
      background: #eee;
      width: 100%;
      height: calc(100vh - 171px);
      padding-bottom: 0;
    }
    .block_wrapper {
      h2 {
        text-align: center;
        font-weight: 500;
        background: $base_color;
        padding: 15px 0;
        color: #fff;
        font-size: 25px;
      }
    }
    div#fragment-1 {
      padding: 0;
    }
    .progressnew {
      display: inline-block;
      width: auto;
      max-width: 320px;
      margin: 0 auto;
      position: absolute;
      bottom: 80px;
      left: 20px;
      right: 20px;
    }
    .slider-home {
      .carousel-item {
        .carousel-caption {
          display: block !important;
        }
        .slider-content {
          width: 100%;
          min-height: 344px;
          float: none;
          text-align: center;
          padding-top: 20%;
        }
        .silder-image-new {
          display: none;
        }
        min-height: auto;
        background-position: center center;
      }
    }
    .block-multiples {
      .row {
        display: flex;
        .col-sm-3 {
          margin-bottom: 15px;
        }
        .col-sm-4 {
          max-width: 100%;
          max-width: 100%;
          box-shadow: 0px 0px 11px 1px #eee;
          padding: 20px;
          margin: 17px 0px;
          margin-bottom: 20px;
        }
      }
      padding: 0px 30px;
    }
    .sectionone.first.bg-light {
      .row {
        display: block;
        text-align: center;
      }
      .col-sm-6 {
        max-width: 100%;
      }
      .heading-section {
        text-align: center;
        padding: 0px 15px 20px;
        h2 {
          text-align: center;
        }
      }
    }
    .footer-content {
      .row {
        display: block;
        text-align: center;
        .img-fluid {
          margin: 0px auto;
        }
        .col-sm-4 {
          max-width: 100%;
        }
        .col-sm-8 {
          max-width: 100%;
        }
      }
      .custome-links {
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        ul {
          li {
            display: inline-block;
            padding-left: 0px;
          }
        }
      }
    }

    .sectionone.first.bgwhite.third-sec {
      .heading-section {
        p {
          width: 100%;
          text-align: left;
        }
        h2 {
          text-align: center;
        }
      }
      img {
        //box-shadow: 15px 15px 0px #ddd;
        position: relative;
        top: -40px;
      }
    }
    .descrioption-news {
      text-align: left;
    }

    .heading-section p {
      width: 100% !important;
    }

    section.contact-block-inner {
      background: #f1f1ff;
      padding: 0% 4%;
    }
    .width80 {
      .form-heading {
        margin-bottom: 0px;
        text-align: center;
        margin-left: -5px;
      }
    }
    .pageTitle {
      background: $base_color;
      padding-top: 100px;
      padding-bottom: 20px;
      color: #fff;
      text-align: center;
    }

    .dynamic-form {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: left;
      width: 100%;
    }

    .form-heading {
      margin-bottom: 0;
    }

    .contactWrapper1 {
      padding-left: 15px;
      padding-left: 15px;
      margin-bottom: 7%;
    }

    .dynamic-form {
      width: 95%;
      padding: 10px;
    }

    .makeStyles-mainDialogBox-40 {
      width: auto !important;
    }

    .makeStyles-formLabelrootInline-107 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .makeStyles-main-262 {
      margin-top: 0;
      min-height: auto;
      margin-bottom: 0;
    }

    .heading-section {
      h2 {
        font-size: 20px;
      }
    }
    .bannerNewForm {
      .carousel-item {
        width: 100%;
      }
      .slider-home .carousel-item .slider-content {
        height: auto;
        min-height: 344px;
      }
      .homeformOutside {
        position: relative;
        width: 100%;
        padding-top: 0px;
        float: left;
        clear: both;
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  @media (min-width: 576px) {
    .col-sm-3 {
      max-width: 100%;
    }
  }
  @media (max-width: 480px) {
    .contactDynamicForm {
      margin-right: -35px;
    }
    .slider-home {
      .carousel-item {
        .slider-content {
          h2 {
            font-size: 20px;
          }
          .lead {
            font-size: 16px;
          }
          padding-top: 26%;
        }
        background-position: center center;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  @media (min-width: 1051px) and (max-width: 1080px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 15px !important;
    }
  }
  @media (min-width: 1081px) and (max-width: 1425px) {
    .header-section .fixed-top ul .nav-item {
      margin-left: 20px !important;
    }
  }

  /**new media query**/
  @media (max-width: 1024px) {
    .carousel-item {
      height: auto;
    }
    .slider-home .carousel-item .slider-content {
      padding: 8em 0em 3em 0em;
    }
    .sectionone.first.bgwhite {
      padding: 40px 0px 20px 0px;
    }
    .block-multiples .single-block h4 {
      font-size: 20px;
    }

    .descript-thankyou h1 {
      font-size: 44px;
    }
    .descript-thankyou p {
      width: 100%;
      span {
        font-size: 26px !important;
      }
    }
    .descript-thankyou {
      height: calc(100vh - 162px);
      padding: 10rem 0%;
    }
    .sectionone.first.bgwhite.third-sec .row {
      display: block;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      max-width: 100%;
      padding: 15px 15px;
      text-align: left;
    }
    .sectionone.first.bgwhite.third-sec .heading-section img {
      margin-top: 0px;
    }
    .sectionone.first.bgwhite.third-sec .container {
      padding: 15px;
      max-width: 990px;
    }
  }

  @media (max-width: 992px) {
    .block-multiples .col-sm-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .sectionone.first.bgwhite.third-sec .row .col-sm-6 {
      text-align: left;
    }

    .order-md-2 .heading-section {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
    .body-content1 .contentAboutLeft {
      width: 100%;
    }
    .body-content1 .contentAbout {
      width: 100%;
    }
    .body-content1 .contentAbout > div {
      margin-left: 0px !important;
    }
    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-4 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    .descript-thankyou {
      padding: 35% 0%;
    }

    .newSurveyForm {
      .surveyPageText {
        .surveyTextFields {
          padding: 20px;
          h2 {
            font-size: 24px;
          }
        }
        .newSurveyPageContainer {
          padding: 20px 20px 60px 20px;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .block-multiples .col-sm-3 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .slider-home .carousel-item .slider-content {
      text-align: left;
    }
    .slider-home .carousel-item .slider-content {
      padding: 9em 0em 2em 0em;
    }
    .slider-home .carousel-item .slider-content h2 {
      font-size: 27px;
    }
    .heading-section h2 {
      font-size: 28px;
    }
    .sectionone.first.bgblue .heading-section p {
      margin-bottom: 40px;
    }
    .sectionone.first.bgblue .heading-section {
      padding-bottom: 20px;
    }
    .sectionone.first.bg-light .heading-section p {
      margin-bottom: 30px;
    }

    .innerPageContent {
      .col-md-6.offset-md-1 {
        margin-left: 0px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-md-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .contactDynamicForm {
      margin-right: 0px;
      margin-top: 20px;
    }
    .contactWrapper1 {
      //padding: 0px;
    }
    .footer-content .custome-links {
      display: inline-block;
    }
    .contactFields {
      margin-top: 30px;
    }
    .block-multiples {
      padding: 0px 15px;
    }

    .sectionone.first.bgwhite.third-sec .heading-section img {
      box-shadow: 5px 5px 0px #eee;
    }
    .contactFields ul li {
      font-size: 14px;
    }
    .contactFields h5 {
      font-size: 18px;
    }
    .newSurveyForm .newSurveyPageContainer {
      padding: 25px 15px 65px 15px;
    }
    .newSurveyForm .newSurveyPageContainer .newSurveyFooter {
      padding-top: 0px;
    }
    .questionSteps .MuiFormLabel-root {
      font-size: 20px;
      padding-left: 4px !important;
    }
    .descript-thankyou {
      padding: 35% 0%;
      height: auto;
    }
    .descript-thankyou h1 {
      font-size: 34px;
    }
    .descript-thankyou p span {
      font-size: 18px !important;
    }
    .sectionone.first.bgblue .heading-section .homebtn {
      padding: 13px 25px;
    }

    .newSurveyForm {
      padding-bottom: 20px;
      .surveyPageText {
        background: transparent;
        box-shadow: none;
        .surveyTextFields {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 15px;
        }
        .newSurveyPageContainer {
          max-width: 100%;
          flex: 0 0 100%;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
          padding: 15px 15px 60px 15px;
          .newSurveyContent {
            .questionSteps {
              .MuiFormGroup-root {
                margin-left: 0px;
              }
            }
          }
          .progressBar {
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
    .surveyPagelayout .surveyPagelayout-left {
      background-image: none !important;
    }
    .footer-content .custome-links {
      display: inline-block;
      width: auto;
    }
  }

  @media (max-width: 375px) {
    .newSurveyForm
      .newSurveyPageContainer
      .progressBar
      .progressBarContainer
      .bar {
      width: 120px;
    }
    .descript-thankyou p span {
      font-size: 16px !important;
    }
  }
}
