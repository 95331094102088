.file-uploads{
    &__field {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        padding: 5px;
        background-color: #fff;
        width: 97%;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ced4da;
        margin-bottom: 5px;
        height: 35px;
        &:focus {
            background: themed('input_focus');
            border: 1px solid #007fa3;
            outline: 0;
          }
          &--error {
            border: 1px solid #db0020;
          }
        }
}