.error-container {
  width: 100%;
  &__text {
    color: #ef566d;
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    position: relative;
    margin: 0 5px 0 5px;
    &_image {
      height: 14px;
      padding-top: 1px;
      margin-right: 6px;
      margin-bottom: 4px;
    }
  }
}
span.error-container__text.dateErrror {
  margin-left: -33px;
}
