.select-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6px 0 6px 0;
  &__label {
    color: themed("black_color");
  }
  &__field {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px 35px 10px 10px;
    min-height: 50px;
    font-size: 13px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../assets/images/down-arrow.svg) no-repeat 98.5% 9px #fff !important;

    background-size: 10px 30px !important;
    color: #7c7d7d;
    &:focus {
      background-color: themed("white_color");
      border: 1px solid themed("input_field__focus_border");
      outline: 0;
    }
    &--error {
      border: 1px solid themed("error_color");
    }
  }
}

.customDropdown-default-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #b6e4db !important;
    color: #108c74 !important;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      // background-color: #19ad91;
      // color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #19ad91 !important;
      color: #fff !important;
      &:hover {
        color: #fff !important;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
    }
    &_icon {
      background: #abdcd3;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #158e77 !important;
      padding: 1px;
      &_selected {
        background-color: #15987f !important;
        color: #ffffff !important;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff !important;
  }
  span.icon-span-main {
    color: #15987f !important;
    &:hover {
      color: #fff ;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       // color: #fff ;
  //     }
  //   }
  // }
}
.customDropdown-first-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center; old css
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #becde8;
    color: #091069;
    // height:124px; old css
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    // justify-content: space-between; old css
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    .icon-span-main {
      margin-top: 5px;
    }
    &:hover {
      // background-color: #1b283f;
      // color: #fff;

      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #1b283f;
      color: #fff;
      &:hover {
        color: #fff;
        span {
          color: #fff !important;
        }
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 0px;
    }
    &_icon {
      background: #8aa2cc;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #22324e;
      padding: 1px;
      &_selected {
        background-color: #1b283f;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: #1b283f;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       // color: #fff;
  //     }
  //   }
  // }
}
.customDropdown-second-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #e4e3ff;
    color: #5753c5;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      // color: #fff;
      // background-color: #7773fa;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #7773fa;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #cccbf9;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #7471da;
      padding: 1px;
      &_selected {
        background-color: #7773fa;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: #7773fa;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       // color: #fff;
  //     }
  //   }
  // }
}
.customDropdown-third-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #d2e8ec;
    color: #095669;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      // background-color: #4094a9;
      // color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #4094a9;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #bbdbe2;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #327282;
      &_selected {
        background-color: #357b8c;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: #357b8c;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #fff;
  //     }
  //   }
  // }
}
.customDropdown-fourth-theme {
  $light_bc: #eedddd;
  $dark_bc: #eb3c33;
  $hover_selected: #ff4e44;
  $color: #ff4e44;
  $bc: #ff4e44;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: $light_bc;
    color: $dark_bc;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      // background-color: $hover_selected;
      // color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: $hover_selected;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #bbdbe2;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $color;
      &_selected {
        background-color: $bc;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: $bc;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #fff;
  //     }
  //   }
  // }
}
.customDropdown-fifth-theme {
  $light_bc: #eedddd;
  $dark_bc: #f47621;
  $hover_selected: #ff771d;
  $color: #f47621;
  $bc: #f47621;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: $light_bc;
    color: $dark_bc;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      // background-color: $hover_selected;
      // color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: $hover_selected;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #bbdbe2;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $color;
      &_selected {
        background-color: $bc;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: $bc;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #fff;
  //     }
  //   }
  // }
}

.customDropdown-sixth-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #ead1ff;
    color: #5c0f8b;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    border: 1px solid #dbc2ff;
    &:hover {
      //background-color: #5c0f8b;
      //color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #5c0f8b;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #bbdbe2;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #5c0f8b;
      &_selected {
        background-color: #5c0f8b;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: #5c0f8b;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #fff;
  //     }
  //   }
  // }
}
.customDropdown-seventh-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #1b283f;
    color: #fff;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    border: 1px solid #dbc2ff;
    &:hover {
      background-color: #fbc417;
      color: #1b283f;
      border: 2px solid #fbc417 !important;
    }
    &_selected {
      background-color: #fbc417;
      color: #1b283f;
      &:hover {
        color: #1b283f;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      background: #1b283f;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #fff;
      &_selected {
        background-color: #fbc417;
        color: #1b283f;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }

  span.icon-span-main {
    color: #ffffff;
    &:hover {
      color: #1b283f;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #1b283f;
  //     }
  //   }
  // }
}
.customDropdown-eighth-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      background-color: #cd232e;
      color: #fff;
      border: 2px solid #fff !important;
    }
    &_selected {
      background-color: #cd232e;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
    }
    &_icon {
      background: #cac8c8;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #000;
      padding: 1px;
      &_selected {
        background-color: #cd232e;
        color: #ffffff;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #fff;
  }
  span.icon-span-main {
    color: #000;
    &:hover {
      color: #fff;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #fff;
  //     }
  //   }
  // }
}

.customDropdown-ninth-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: normal;
  width: 100%;
  flex-wrap: wrap;
  &_span {
    background-color: #00467f;
    color: #ffffff;
    min-height: 124px;
    width: 124px;
    border-radius: 4px;
    justify-content: normal;
    align-items: center;
    display: flex;
    margin: 10px 10px 0 10px;
    cursor: pointer;
    flex-flow: wrap;
    font-size: 14px;
    flex-direction: column;
    &:hover {
      background-color: #e97d00;
      color: #00467f;
    }
    &_selected {
      background-color: #e97d00;
      color: #00467f !important;
      &:hover {
        color: #00467f;
      }
    }

    &_label {
      padding: 4px;
      font-weight: 500;
      order: 2;
      font-size: 16px;
      font-weight: 500;
      top: 5px;
    }
    &_icon {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #ffffff;
      &_selected {
        background-color: #e97d00;
        color: #00467f !important;
      }
      &:hover {
        color: #00467f !important;
      }
    }

    &.effect2 {
      position: relative;
      transition: 0.1s;
    }
    &.effect2:before,
    &.effect2:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &.effect2:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  img.ddlIconImage {
    height: 30px;
  }
  span.icon-span-main.active {
    color: #00467f;
  }
  span.icon-span-main {
    color: #ffffff;
    &:hover {
      color: #00467f !important;
    }
  }
  // .effect2 {
  //   &:hover {
  //     span.icon-span-main {
  //       color: #00467f;
  //     }
  //   }
  // }
}
