
.beta_layout-theme{
  .betaBanner{
    position: relative;
    padding: 100px 0px 60px 0px;
    &:before {
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      content: "";
    }
    &:after {
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      content: "";
      background-position: right bottom;
    }
    .bannerBox {
      position: relative;
      z-index: 11;
      .bannerImg {
        display: inline-block;
        width: 100%;
        img {
          max-width: 100%;
        }
        ul {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          li {
            list-style: none;
            img {
              margin-right: 10px;
            }

            &:first-child {
              position: absolute;
              animation: upDown 1.5s infinite;
              bottom: 150px;
              left: 15px;
              @keyframes upDown {
                0% {
                  left: 15px;
                }
                50% {
                  left: -15px;
                }
                100% {
                  left: 15px;
                }
              }
            }

            &:nth-child(2) {
              position: absolute;
              animation: upDownSecond 1.5s infinite;
              bottom: 50px;
              right: 35px;
              @keyframes upDownSecond {
                0% {
                  bottom: 50px;
                }
                50% {
                  bottom: 60px;
                }
                100% {
                  bottom: 50px;
                }
              }
            }

            span {
              border-radius: 5px;
              display: inline-block;
              padding: 15px 15px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}





.beta_layout-first-theme{
  .betaBanner{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(242,253,254,1) 100%);
    &:before {
      background-image: url(../../../../assets/images/wave.svg);
    }
    &:after {
      background-image: url(../../../../assets/images/bg-mask-new.svg);
    }
    .bannerBox {
      .bannerImg {
        ul {
          li {
            span {
              background: #fff;
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}



.beta_layout-eighth-theme{
  .betaBanner{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, #fef2f2 100%);
    &:before {
      background-image: url(../../../../assets/images/wave-new.svg);
    }
    &:after {
      background-image: url(../../../../assets/images/bg-mask-new2.svg);
    }
    .bannerBox {
      .bannerImg {
        ul {
          li {
            span {
              background: #fff;
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}









// Media css started
@media (max-width: 1365px) {
  .beta_layout-theme{
    .betaBanner{
      padding: 80px 0px 40px 0px;
    }
  }
}

@media (max-width: 1024px) {
  .beta_layout-theme{
    .betaBanner{
      &:after{
        display:none;
      }
      .bannerBox {
        .bannerImg {
          ul {
            li {
              span{
                padding: 10px 15px;
                font-size: 14px;
              }
            }
          }
        }
      }
    } 
  }
  
}

@media (max-width: 992px) {
  .beta_layout-theme{
    .betaBanner {
      &:before{
        background-size: 60% auto;
      }
      .bannerBox {
        .bannerImg {
          ul {
            li {
              span{
                padding: 5px 10px;
              }
              img{
                width:16px;
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 767px) {
  .beta_layout-theme{
    .betaBanner {
      .bannerBox {
        .bannerImg{
          display: none;
        }
      }
    }
  }
}