.sixth_layout_global {
  body {
    color: #000;
    font-weight: 400;
  }
  .group-editible {
    text-align: center;
    padding: 50px;
    width: 650px;
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    position: relative;
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          // &:hover {
          // }
        }
      }
    }
    ul.birthYear {
      li {
        width: 84px;
        padding: 12px 5px;
        a {
          font-size: 18px !important;
        }
      }
    }
  }
  .group-editible {
    ul.logoLIsting {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      li {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 2.5px 5px;
        a {
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          height: 100%;
          text-align: center;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .group-editible {
    ul.partnerLogos {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      li {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 5px 5px;
        background: transparent !important;
        .partnerCard {
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          height: 100%;
          text-align: center;
          background: #fff;
          border-radius: 5px;
          cursor: pointer;
          border: 2px solid transparent;
          img {
            max-width: 100%;
          }
          &:hover {
            border: 2px solid red;
          }
        }
      }
      &.fourColumn{
        li{
          flex: 0 0 25%;
        max-width: 25%;
        }
      }
    }
  }

  .group-editible{
    ul.coverListing {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -5px;
      li {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding: 5px 5px;
        text-align: center;
        a {
          padding: 10px;
          height: 100%;
          font-size: 16px;
          img {
            max-width: 100%;
            margin-bottom: 5px;
          }
        }
      }
    }
  }


  

  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a,span {
      background: no-repeat;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        margin-bottom: 15px;
      }
      &:last-child {
        border: none;
      }
      p {
        font-size: 18px;
        line-height: 34px;
        font-weight: 300;
        letter-spacing: 0.5px;
        strong {
          font-weight: 500;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        strong {
          font-weight: 500;
        }
      }
      .boollets-points,
      .boollets-points * {
        line-height: 1.6em;
        strong {
          font-weight: 500;
        }
      }
    }
  }

  .foter-bottom {
    padding: 20px 0px 20px 0px;
    font-size: 12px;
    .copyright_footer {
      float: left;
    }
    .smallText {
      width: 1000px;
      max-width: 100%;
      margin: auto;
    }
    ul {
      float: right;
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 1rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          padding: 0px 2px;
          cursor: pointer;
          font-size: 12px;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        padding: 0px 4px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    .navbar-brand {
      img {
        max-height: 44px;
      }
    }
  }
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    background: transparent;
  }

  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    border-radius: 4px;
    text-transform: inherit;
    font-size: 14px;
    padding: 10px 30px;
  }

  .stick_scroll {
    position: fixed;
    padding-top: 100px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    position: relative;
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 50px;
    padding-bottom: 20px;
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        text-align: left;
        max-width: 100%;
        width: 100%;
        float: left;
        padding: 0px;
        margin-bottom: 15px;
        h1 {
          padding-bottom: 20px;
          text-align: left;
          font-size: 46px;
          line-height: 1.19;
          letter-spacing: -0.6px;
          max-width: 100%;
          font-weight: 400;
        }
      }
      .blogImg {
        width: 100%;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }

    ul {
      display: inline-block;
      width: 100%;
      text-align: left;
      padding: 0px 0px;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          float: left;
          margin: 0px;
          padding: 0px;
          border: none;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          + p {
            margin-left: 15px;
            padding-left: 15px;
          }
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 20px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }
  .section-footer {
    .footer-content {
      img {
        max-height: 50px;
      }
    }
  }


  // SVG css USA

  svg {
    g#US-OR {
      > path#OR {
        fill: #091d60;
      }
      &:hover {
        path#OR {
          fill: #D1D5E1;
        }
      }
    }
    g#US-WA {
      > path#WA {
        fill: #111F4E;
      }
      &:hover {
        path#WA {
          fill: #D3D5DE;
        }
      }
    }
    g#US-CA {
      > path#CA {
        fill: #021248;
      }
      &:hover {
        path#CA {
          fill: #D3D5DE;
        }
      }
    }

    g#US-NV {
      > path#NV {
        fill: #07164C;
      }
      &:hover {
        path#NV {
          fill: #D3D5DE;
        }
      }
    }

    g#US-ID {
      > path#ID {
        fill: #070E38;
      }
      &:hover {
        path#ID {
          fill: #D3D5DE;
        }
      }
    }

    g#US-UT {
      > path#UT {
        fill: #1C113E;
      }
      &:hover {
        path#UT {
          fill: #D5D3DB;
        }
      }
    }

    g#US-AZ {
      > path#AZ {
        fill: #101445;
      }
      &:hover {
        path#AZ {
          fill: #D3D3DC;
        }
      }
    }

    g#US-MT {
      > path#MT {
        fill: #210B2A;
      }
      &:hover {
        path#MT {
          fill: #D6D2D7;
        }
      }
    }


    g#US-WY {
      > path#WY {
        fill: #240D32;
      }
      &:hover {
        path#WY {
          fill: #D6D2D9;
        }
      }
    }

    g#US-CO {
      > path#CO {
        fill: #250C2E;
      }
      &:hover {
        path#CO {
          fill: #D6D2D8;
        }
      }
    }

    g#US-NM {
      > path#NM {
        fill: #3B0F34;
      }
      &:hover {
        path#NM {
          fill: #DBD2D9;
        }
      }
    }

    g#US-ND {
      > path#ND {
        fill: #3A0821;
      }
      &:hover {
        path#ND {
          fill: #DBD2D9;
        }
      }
    }


    g#US-SD {
      > path#SD {
        fill: #300924;
      }
      &:hover {
        path#SD {
          fill: #D8D1D6;
        }
      }
    }

    g#US-NE {
      > path#NE {
        fill: #4A0820;
      }
      &:hover {
        path#NE {
          fill: #DDD1D6;
        }
      }
    }

    g#US-KS {
      > path#KS {
        fill: #400922;
      }
      &:hover {
        path#KS {
          fill: #DBD1D6;
        }
      }
    }

    g#US-KS {
      > path#KS {
        fill: #400922;
      }
      &:hover {
        path#KS {
          fill: #DBD1D6;
        }
      }
    }

    g#US-OK {
      > path#OK {
        fill: #360A23;
      }
      &:hover {
        path#OK {
          fill: #DAD2D6;
        }
      }
    }

    g#US-TX {
      > path#TX {
        fill: #440B26;
      }
      &:hover {
        path#TX {
          fill: #DCD2D7;
        }
      }
    }

    g#US-MN {
      > path#MN {
        fill: #48081C;
      }
      &:hover {
        path#MN {
          fill: #DDD1D5;
        }
      }
    }

    g#US-IA {
      > path#IA {
        fill: #59061A;
      }
      &:hover {
        path#IA {
          fill: #E0D1D4;
        }
      }
    }

    g#US-MO {
      > path#MO {
        fill: #600719;
      }
      &:hover {
        path#MO {
          fill: #E1D1D4;
        }
      }
    }

    g#US-AR {
      > path#AR {
        fill: #58071A;
      }
      &:hover {
        path#AR {
          fill: #E0D1D4;
        }
      }
    }

    g#US-LA {
      > path#LA {
        fill: #6C0717;
      }
      &:hover {
        path#LA {
          fill: #E4D1D4;
        }
      }
    }

    g#US-WI {
      > path#WI {
        fill: #5A0619;
      }
      &:hover {
        path#WI {
          fill: #E0D1D4;
        }
      }
    }

    g#US-IL {
      > path#IL {
        fill: #6E0717;
      }
      &:hover {
        path#IL {
          fill: #E4D1D4;
        }
      }
    }



    g#US-IN {
      > path#IN {
        fill: #800517;
      }
      &:hover {
        path#IN {
          fill: #E7D1D4;
        }
      }
    }


    g#US-MI {
      > path#MI {
        fill: #690617;
      }
      &:hover {
        path#MI {
          fill: #E3D1D4;
        }
      }
    }


    g#US-OH {
      > path#OH {
        fill: #970513;
      }
      &:hover {
        path#OH {
          fill: #EBD1D3;
        }
      }
    }


    g#US-KY {
      > path#KY {
        fill: #7F0514;
      }
      &:hover {
        path#KY {
          fill: #E7D1D3;
        }
      }
    }


    g#US-TN {
      > path#TN {
        fill: #8E0512;
      }
      &:hover {
        path#TN {
          fill: #EAD1D3;
        }
      }
    }

    g#US-MS {
      > path#MS {
        fill: #6F0717;
      }
      &:hover {
        path#MS {
          fill: #E4D1D4;
        }
      }
    }

    g#US-AL {
      > path#AL {
        fill: #8A0513;
      }
      &:hover {
        path#AL {
          fill: #E9D1D3;
        }
      }
    }

    g#US-FL {
      > path#FL {
        fill: #C3040F;
      }
      &:hover {
        path#FL {
          fill: #F3D0D2;
        }
      }
    }

    g#US-GA {
      > path#GA {
        fill: #AB0410;
      }
      &:hover {
        path#GA {
          fill: #EFD0D3;
        }
      }
    }

    g#US-SC {
      > path#SC {
        fill: #A90411;
      }
      &:hover {
        path#SC {
          fill: #EFD0D3;
        }
      }
    }

    g#US-NC {
      > path#NC {
        fill: #AA0410;
      }
      &:hover {
        path#NC {
          fill: #EFD0D3;
        }
      }
    }

    g#US-VA {
      > path#VA {
        fill: #AE040F;
      }
      &:hover {
        path#VA {
          fill: #F0D0D2;
        }
      }
    }

    g#US-WV {
      > path#WV {
        fill: #9F0410;
      }
      &:hover {
        path#WV {
          fill: #EDD0D3;
        }
      }
    }

    g#US-PA {
      > path#PA {
        fill: #B1040F;
      }
      &:hover {
        path#PA {
          fill: #F0D0D2;
        }
      }
    }


    g#US-MD {
      > path#MD {
        fill: #E20816;
      }
      &:hover {
        path#MD {
          fill: #F9D1D4;
        }
      }
    }

    g#US-NJ {
      > path#NJ {
        fill: #E20816;
      }
      &:hover {
        path#NJ {
          fill: #F9D1D4;
        }
      }
    }

    g#US-NY {
      > path#NY {
        fill: #9F0410;
      }
      &:hover {
        path#NY {
          fill: #EDD0D3;
        }
      }
    }
    g#US-VT {
      > path#VT {
        fill: #B3040D;
      }
      &:hover {
        path#VT {
          fill: #EDD0D3;
        }
      }
    }
    

    g#US-CT {
      > path#CT {
        fill: #C1040E;
      }
      &:hover {
        path#CT {
          fill: #F3D0D2;
        }
      }
    }

    g#US-RI {
      > path#RI {
        fill: #DB000A;
      }
      &:hover {
        path#RI {
          fill: #EDD0D3;
        }
      }
    }

    g#US-MA {
      > path#MA {
        fill: #C1040C;
      }
      &:hover {
        path#MA {
          fill: #F3D0D2;
        }
      }
    }

    g#US-VT {
      > path#VT {
        fill: #B3040D;
      }
      &:hover {
        path#VT {
          fill: #F1D0D2;
        }
      }
    }

    g#US-NH {
      > path#NH {
        fill: #BC040C;
      }
      &:hover {
        path#NH {
          fill: #F2D0D2;
        }
      }
    }

    g#US-ME {
      > path#ME {
        fill: #C6020C;
      }
      &:hover {
        path#ME {
          fill: #F4D0D2;
        }
      }
    }

    g#US-AK {
      > path#AK {
        fill: #091D60;
      }
      &:hover {
        path#AK {
          fill: #D1D5E1;
        }
      }
    }

    g#US-HI {
      > path#HI {
        fill: #3B0F34;
      }
      &:hover {
        path#HI {
          fill: #DBD2D9;
        }
      }
    }



  }

   //state listing blog
   .allStateList{
    display:flex;
    flex-wrap:wrap;
    margin: 0px -7.5px;
    margin-bottom:30px;
    li{
      list-style: none;
      flex:0 0 25%;
      max-width:25%;
      padding: 7.5px;
      a {
        background: #cd232e;
        width: 100%;
        height: 100%;
        display: block;
        color: #fff;
        padding: 15px 15px;
        border-radius: 5px;
        text-decoration: none;
        &:hover{
          background: #000;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .blogImg {
      img {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .stick_scroll {
      right: 15px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: 0px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      padding-top: 0px;
      .container {
        padding: 0px;
        .blogHeaderText {
          padding: 20px 0px 20px 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 10px;
    }
    nav.header .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .advertisement-images {
      display: none;
    }
    .home-section-top {
      .col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto;
    }
    .navbar-nav {
      margin-top: 10px;
    }
    .headerContainer {
      display: flex;
      flex-direction: column;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          padding: 10px 17px 5px;
          margin-bottom: 0px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
            line-height: normal;
            padding-bottom: 0px;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
      h3 {
        font-size: 1.3em;
        margin-bottom: 5px;
      }
      h6 {
        font-size: 16px !important;
      }
      ol {
        margin-top: 10px;
      }
      .boollets-points {
        h2 {
          font-size: 1.3em;
          line-height: normal;
        }
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
      ul {
        li {
          a {
            justify-content: space-between;
            display: flex;
            img {
              max-height: 30px;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        li {
          a {
            display:inline-block;
          }
        }
      }
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
    .foter-bottom {
      text-align: center;
      .copyright_footer {
        float: none;
        margin-bottom: 5px;
      }
      ul {
        float: none;
      }
    }
    .allStateList li{
      flex:0 0 50%;
      max-width:50%;
    }
    .foter-bottom ul{
      display: inline-block;
      height: auto;
    }
    .section-footer {
      .footer-content {
        padding: 15px 0%;
        .footerDisclaimer{
          padding: 15px 0px 0px 0px;
          margin-top: 15px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
    .group-editible {
      padding: 15px;
      ul {
        li {
          a {
            padding: 15px 5px;
            font-size: 16px;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          flex: 0 0 25%;
          max-width: 25%;
        }
        &.fourColumn{
          li {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
          }
        }
      }
    }    
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a,span {
      font-size: 14px;
      padding: 5px 0px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
    .group-editible {
      ul.partnerLogos {
        li {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
        }
      }
    }
    .allStateList li{
      flex:0 0 100%;
      max-width:100%;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }

  .sixth_blog_seventh-theme {
    $secondryColor: #fbde07;
    $primary-color: #1d2c35;

    .group-editible {
      background: $primary-color;
      h4 {
        color: #fff;
      }
      ul {
        li {
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #000;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $primary-color !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #313c31;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #797522;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #c8b510;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #fcde05;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #f2ce05;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #e3ba04;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
     //state listing blog
    .allStateList{
      li{
        a {
          background: $secondryColor;
          color:#000;
          &:hover{
            background: $primary-color;
            color: #fff;
          }
        }
      }
    }
  }

  .sixth_blog_fifth-theme {
    $secondryColor: #f47621;
    $primary-color: #1d2c35;

    .group-editible {
      background: $primary-color;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: $secondryColor;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #000;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $secondryColor;
          &:hover{
            background: $primary-color;
          }
        }
      }
    }
  }

  .sixth_blog_eighth-theme {
    $secondryColor: #1c1c1c;
    $primary-color: #cd232e;
    $text-color: #ffffff;
    .group-editible {
      background: $primary-color;
      h4 {
        color: $text-color;
      }
      ul {
        li {
          a {
            background: #fff;
            color: $secondryColor;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: $text-color;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #000000;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #000000;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: $primary-color;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $text-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }

    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

  .sixth_blog_sixth-theme {
    $secondryColor: #5AB031;
    $primary-color: #1D1D1B;
    $text-color: #ffffff;
    .group-editible {
      background: $primary-color;
      h4 {
        color: #fff;
      }
      ul {
        li {
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: $text-color;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $text-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $secondryColor;
          color:#fff;
          &:hover{
            background: $primary-color;
            color: #fff;
          }
        }
      }
    }
  }

  .sixth_blog_third-theme {
    $secondryColor: #00bad2;
    $primary-color: #1d2c35;

    .group-editible {
      background: $secondryColor;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: $primary-color;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #000;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $secondryColor;
          color:#fff;
          &:hover{
            background: $primary-color;
            color: #fff;
          }
        }
      }
    }
  }

  .sixth_blog_ninth-theme {
    // $secondryColor: #f47621;
    // $primary-color: #1d2c35;
    $primary-color: #00467f;
    $secondryColor: #03233d;

    .group-editible {
      background: $primary-color;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: $secondryColor;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }



/********************************************************************************************************************
                                        ~~~~~~~~New color themes 2022~~~~~~~
********************************************************************************************************************/


  // Red and white color theme
  .sixth_blog_red-white {
    $secondryColor: #ae0000;
    $primary-color: #c70000;
    $text-color: #ffffff;
    .group-editible {
      background: $primary-color;
      h4 {
        color: $text-color;
      }
      ul {
        li {
          a {
            background: #fff;
            color: $secondryColor;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: $text-color;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #000000;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #000000;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: $primary-color;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $text-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }

    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

  // green and black color theme
  .sixth_blog_green-black {
    $secondryColor: #000000;
    $primary-color: #018001;
    $text-color: #ffffff;
    .group-editible {
      background: $primary-color;
      h4 {
        color: $text-color;
      }
      ul {
        li {
          a {
            background: #fff;
            color: $secondryColor;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: $text-color;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid $secondryColor;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #000000;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #000000;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: $primary-color;
      border: 2px solid #fff;
      &:hover {
        background: #fff;
        color: #1b283f;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $text-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }

    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

// green and drawblue color theme
  .sixth_blog_green-drawblue {
    // $secondryColor: #f47621;
    // $primary-color: #1d2c35;
    $primary-color: #35A753;
    $secondryColor: #4486F4;

    .group-editible {
      background: transparent
        linear-gradient(90deg, $secondryColor 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: transparent;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }


  // blue and white color theme
  .sixth_blog_blue-white {
    // $secondryColor: #f47621;
    // $primary-color: #1d2c35;
    $primary-color: #0000FE;
    $secondryColor: #4486F4;

    .group-editible {
      background: transparent
        linear-gradient(160deg, $secondryColor 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: transparent;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

  
  // pink and white color theme
  .sixth_blog_pink-white {
    // $secondryColor: #f47621;
    // $primary-color: #1d2c35;
    $primary-color: #F699CD;
    $secondryColor: #F474BC;

    .group-editible {
      background: transparent
        linear-gradient(160deg, $secondryColor 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: transparent;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

  // pink and black color theme
  .sixth_blog_pink-black {
    $primary-color: #F699CD;
    $secondryColor: #000000;

    .group-editible {
      background: transparent
        linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
        padding-box;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: transparent;
          a {
            background: #fff;
            color: #717171;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

   // blue and aqua color theme
  .sixth_blog_blue-aqua {
    $primary-color:  #0D49CD;
    $secondryColor: #00FFFF ;

    .group-editible {
      background: transparent
        linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
        padding-box;
      h4 {
        color: #fff;
      }
      ul {
        li {
          background: transparent;
          a {
            background: #fff;
            color: $primary-color;
            &:hover {
              &:hover {
                background: $secondryColor;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .group-editible {
      ul.logoLIsting {
        li {
          background: transparent;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          a:hover {
            background: #fff !important;
            border-color: $secondryColor;
          }
        }
      }
    }
    .group-editible {
      ul.partnerLogos {
        li {
          .partnerCard {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background: #fff;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #f47621;
            }
          }
        }
      }
    }
    .group-editible{
      ul.coverListing {
        background: transparent;
        li {
          background: transparent !important;
          a {
            border: 2px solid transparent;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            &:hover {
              border-color: $secondryColor;
              color: $secondryColor !important;
              background:#fff;
            }
          }
        }
      }
    }
    .boollets-points {
      ul {
        li {
          a {
            color: #131313;
          }
        }
      }
    }

    .comparebtn {
      a,span {
        color: $primary-color;
        border: 2px solid $primary-color;
        &:hover {
          color: #fff;
          background: $primary-color;
        }
      }
    }
    .averagetext {
      color: #777;
      background: #fff;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          color: #000;
        }
        p {
          color: #484848;
          strong {
            color: #000;
          }
        }
        h5 {
          strong {
            color: #000;
          }
        }
        h2 {
          color: #000;
          strong {
            color: #000;
          }
        }
        .boollets-points,
        .boollets-points * {
          color: #625f5f;
          strong {
            color: #000;
          }
        }
      }
    }

    .foter-bottom {
      color: #fff;
      background: $primary-color;
      ul {
        li {
          a {
            color: #fff;
          }
        }
        .fa {
          color: #999999;
        }
      }
      .col-sm-4.text-right {
        p {
          color: #ccc;
        }
      }
    }

    .header {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      background: $primary-color;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      background: $primary-color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .navbar-dark .navbar-toggler {
      background: transparent;
      border: 2px solid #fff;
    }

    a.header-button.nav-link {
      color: #fff;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: #1b283f !important;
      }
    }

    /*************blog page design update************************/
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            color: #000000;
          }
        }
        .blogImg {
          border: 1px solid #eee;
        }
      }

      ul {
        li {
          p {
            color: #000;
            span {
              color: #f13624;
            }
            + p {
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      color: #fff;
      &:hover {
        background: $secondryColor;
        color: $primary-color;
        border-color: $secondryColor;
      }
    }

    svg {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #1e2d35;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #dfe1e2;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #2a2b40;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #e2e3e2;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #362d40;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #ecebdf;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #673743;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #f7f5dd;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #a14245;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fffbdb;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #cd4b48;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #fdf8db;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #f05249;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #fbf5db;
          }
        }
      }
    }
    svg.greenGoldMap {
      g#AU-WA {
        > path#AU-WA-2 {
          fill: #438029;
        }
        &:hover {
          path#AU-WA-2 {
            fill: #ecf2e9;
          }
        }
      }

      g#AU-NT {
        > path#AU-NT-2 {
          fill: #619a46;
        }
        &:hover {
          path#AU-NT-2 {
            fill: #eff5ec;
          }
        }
      }

      g#AU-SA {
        > path#AU-SA-2 {
          fill: #7daa6a;
        }
        &:hover {
          path#AU-SA-2 {
            fill: #f1f6ef;
          }
        }
      }

      g#AU-QLD {
        > path#AU-QLD-2 {
          fill: #e1c158;
        }
        &:hover {
          path#AU-QLD-2 {
            fill: #fcf9ee;
          }
        }
      }

      g#AU-NSW {
        > path#AU-NSW-2 {
          fill: #d4af37;
        }
        &:hover {
          path#AU-NSW-2 {
            fill: #fbf7ea;
          }
        }
      }

      g#AU-VIC {
        > path#AU-VIC-2 {
          fill: #b29700;
        }
        &:hover {
          path#AU-VIC-2 {
            fill: #f7f4e5;
          }
        }
      }

      g#AU-TAS {
        > path#AU-TAS-2 {
          fill: #96810c;
        }
        &:hover {
          path#AU-TAS-2 {
            fill: #f4f2e6;
          }
        }
      }
    }
    svg {
      g#CA-NU {
        > path#CA-NU-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-NU-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-SK {
        > path#CA-SK-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-SK-2 {
            fill: #ffd7db;
          }
        }
      }
      g#CA-QC {
        > path#CA-QC-2 {
          fill: #b91f26;
        }
        &:hover {
          path#CA-QC-2 {
            fill: #ffd7db;
          }
        }
      }

      g#CA-NT {
        > path#CA-NT-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-NT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-ON {
        > path#CA-ON-2 {
          fill: #da2b2b;
        }
        &:hover {
          path#CA-ON-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-YT {
        > path#CA-YT-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-YT-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NB {
        > path#CA-NB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-MB {
        > path#CA-MB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-MB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NL {
        > path#CA-NL-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-NL-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-AB {
        > path#CA-AB-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-AB-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-PE {
        > path#CA-PE-2 {
          fill: #ee3f3f;
        }
        &:hover {
          path#CA-PE-2 {
            fill: #ffc7c7;
          }
        }
      }

      g#CA-BC {
        > path#CA-BC-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-BC-2 {
            fill: #ffc7c7;
          }
        }
      }
      g#CA-NS {
        > path#CA-NS-2 {
          fill: #f26764;
        }
        &:hover {
          path#CA-NS-2 {
            fill: #ffc7c7;
          }
        }
      }
    }
    .allStateList{
      li{
        a {
          background: $primary-color;
          color:#fff;
          &:hover{
            background: $secondryColor;
            color: #fff;
          }
        }
      }
    }
  }

     // red, white and black color theme
     .sixth_blog_red-white-black {
      $primary-color:  #ed1b2e;
      $secondryColor: #ff374a;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
              &:hover {
                &:hover {
                  background: $secondryColor;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #f47621;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #f13624;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }
  

     // red, white and blue color theme
     .sixth_blog_red-blue {
      $primary-color:   #eb2227;
      $secondryColor: #0f2f66 ;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
              &:hover {
                &:hover {
                  background: $secondryColor;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #f47621;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #f13624;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }

     // Aqua Green and blue color theme
     .sixth_blog_aqua-green-black  {
      $primary-color:    #87CDC5;
      $secondryColor: #1f1c1d ;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
              &:hover {
                &:hover {
                  background: $secondryColor;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #1f1c1d;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #1f1c1d;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }


    // midnightblue and green color theme
     .sixth_blog_midnight-blue-green {
      $primary-color:    #033f5d;
      $secondryColor: #69bd49 ;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
              &:hover {
                &:hover {
                  background: $secondryColor;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #1f1c1d;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #1f1c1d;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }


    //magenta color theme
    .sixth_blog_magenta {
      $primary-color:    #562463;
      $secondryColor: #2f0b38 ;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
              &:hover {
                &:hover {
                  background: $secondryColor;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #1f1c1d;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #1f1c1d;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }

    //Cerulean color theme
    .sixth_blog_cerulean {
      $primary-color:    #004A8E;
      $secondryColor: #0b60b7 ;
  
      .group-editible {
        background: transparent
          linear-gradient(160deg,  $primary-color 0%, $secondryColor 100%) 0% 0% no-repeat
          padding-box;
        h4 {
          color: #fff;
        }
        ul {
          li {
            background: transparent;
            a {
              background: #fff;
              color: $primary-color;
                &:hover {
                  background: #fff;
                  color: $primary-color;
                }
            }
          }
        }
      }
      .group-editible {
        ul.logoLIsting {
          li {
            background: transparent;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            a:hover {
              background: #fff !important;
              border-color: $secondryColor;
            }
          }
        }
      }
      .group-editible {
        ul.partnerLogos {
          li {
            .partnerCard {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              background: #fff;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid #1f1c1d;
              }
            }
          }
        }
      }
      .group-editible{
        ul.coverListing {
          background: transparent;
          li {
            background: transparent !important;
            a {
              border: 2px solid transparent;
              background: #fff;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              &:hover {
                border-color: $secondryColor;
                color: $secondryColor !important;
                background:#fff;
              }
            }
          }
        }
      }
      .boollets-points {
        ul {
          li {
            a {
              color: #131313;
            }
          }
        }
      }
  
      .comparebtn {
        a,span {
          color: $primary-color;
          border: 2px solid $primary-color;
          &:hover {
            color: #fff;
            background: $primary-color;
          }
        }
      }
      .averagetext {
        color: #777;
        background: #fff;
      }
      .mid-content {
        .mi-section-content {
          .big-text {
            color: #000;
          }
          p {
            color: #484848;
            strong {
              color: #000;
            }
          }
          h5 {
            strong {
              color: #000;
            }
          }
          h2 {
            color: #000;
            strong {
              color: #000;
            }
          }
          .boollets-points,
          .boollets-points * {
            color: #625f5f;
            strong {
              color: #000;
            }
          }
        }
      }
  
      .foter-bottom {
        color: #fff;
        background: $primary-color;
        ul {
          li {
            a {
              color: #fff;
            }
          }
          .fa {
            color: #999999;
          }
        }
        .col-sm-4.text-right {
          p {
            color: #ccc;
          }
        }
      }
  
      .header {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background: $primary-color;
      }
      nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
        background: $primary-color;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
      .navbar-dark .navbar-toggler {
        background: transparent;
        border: 2px solid #fff;
      }
  
      a.header-button.nav-link {
        color: #fff;
        border: 2px solid #fff;
        &:hover {
          background: #fff !important;
          color: #1b283f !important;
        }
      }
  
      /*************blog page design update************************/
      .blogHeader {
        .container {
          .blogHeaderText {
            h1 {
              color: #000000;
            }
          }
          .blogImg {
            border: 1px solid #eee;
          }
        }
  
        ul {
          li {
            p {
              color: #000;
              span {
                color: #1f1c1d;
              }
              + p {
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
  
      .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        &:hover {
          background: $secondryColor;
          color: $primary-color;
          border-color: $secondryColor;
        }
      }
  
      svg {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #1e2d35;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #dfe1e2;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #2a2b40;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #e2e3e2;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #362d40;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #ecebdf;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #673743;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #f7f5dd;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #a14245;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fffbdb;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #cd4b48;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #fdf8db;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #f05249;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #fbf5db;
            }
          }
        }
      }
      svg.greenGoldMap {
        g#AU-WA {
          > path#AU-WA-2 {
            fill: #438029;
          }
          &:hover {
            path#AU-WA-2 {
              fill: #ecf2e9;
            }
          }
        }
  
        g#AU-NT {
          > path#AU-NT-2 {
            fill: #619a46;
          }
          &:hover {
            path#AU-NT-2 {
              fill: #eff5ec;
            }
          }
        }
  
        g#AU-SA {
          > path#AU-SA-2 {
            fill: #7daa6a;
          }
          &:hover {
            path#AU-SA-2 {
              fill: #f1f6ef;
            }
          }
        }
  
        g#AU-QLD {
          > path#AU-QLD-2 {
            fill: #e1c158;
          }
          &:hover {
            path#AU-QLD-2 {
              fill: #fcf9ee;
            }
          }
        }
  
        g#AU-NSW {
          > path#AU-NSW-2 {
            fill: #d4af37;
          }
          &:hover {
            path#AU-NSW-2 {
              fill: #fbf7ea;
            }
          }
        }
  
        g#AU-VIC {
          > path#AU-VIC-2 {
            fill: #b29700;
          }
          &:hover {
            path#AU-VIC-2 {
              fill: #f7f4e5;
            }
          }
        }
  
        g#AU-TAS {
          > path#AU-TAS-2 {
            fill: #96810c;
          }
          &:hover {
            path#AU-TAS-2 {
              fill: #f4f2e6;
            }
          }
        }
      }
      svg {
        g#CA-NU {
          > path#CA-NU-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-NU-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-SK {
          > path#CA-SK-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-SK-2 {
              fill: #ffd7db;
            }
          }
        }
        g#CA-QC {
          > path#CA-QC-2 {
            fill: #b91f26;
          }
          &:hover {
            path#CA-QC-2 {
              fill: #ffd7db;
            }
          }
        }
  
        g#CA-NT {
          > path#CA-NT-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-NT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-ON {
          > path#CA-ON-2 {
            fill: #da2b2b;
          }
          &:hover {
            path#CA-ON-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-YT {
          > path#CA-YT-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-YT-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NB {
          > path#CA-NB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-MB {
          > path#CA-MB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-MB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NL {
          > path#CA-NL-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-NL-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-AB {
          > path#CA-AB-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-AB-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-PE {
          > path#CA-PE-2 {
            fill: #ee3f3f;
          }
          &:hover {
            path#CA-PE-2 {
              fill: #ffc7c7;
            }
          }
        }
  
        g#CA-BC {
          > path#CA-BC-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-BC-2 {
              fill: #ffc7c7;
            }
          }
        }
        g#CA-NS {
          > path#CA-NS-2 {
            fill: #f26764;
          }
          &:hover {
            path#CA-NS-2 {
              fill: #ffc7c7;
            }
          }
        }
      }
      .allStateList{
        li{
          a {
            background: $primary-color;
            color:#fff;
            &:hover{
              background: $secondryColor;
              color: #fff;
            }
          }
        }
      }
    }
  
    
/********************************************************************************************************************
                                      ~~~~~~~~New color themes 2022 end~~~~~~~
********************************************************************************************************************/




  @media (max-height: 1100px) {
    .blogHeader .container .blogImg {
      overflow: hidden;
      height: 480px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }

    .mid-content .blogImg {
      overflow: hidden;
      height: auto;
      margin-bottom:20px;
      img {
        max-width: 100%;
        // transform: translate(0%, -50%);
        // top: 50%;
        position: relative;
      }
    }
  }

  @media (max-height: 950px) {
    .blogHeader .container .blogImg {
      overflow: hidden;
      height: 450px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
    .mid-content .blogImg {
      overflow: hidden;
      height: 450px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
  }

  @media (max-height: 900px) {
    .blogHeader .container .blogImg {
      overflow: hidden;
      height: 400px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
    .mid-content .blogImg {
      overflow: hidden;
      height: 400px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
  }
  @media (max-height: 899px){
  .mid-content{
    .blogImg{
      height:auto;
      img{
        transform: none;
        top: 0;
      }
    }
  }
}
  @media (max-height: 780px) {
    // .blogHeader .blogHeaderText h1 {
    //   font-size: 38px;
    // }
    // .blogHeader .container .blogHeaderText h1 {
    //   font-size: 42px;
    // }
    .blogHeader .container .blogImg {
      overflow: hidden;
      height: 400px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
    .mid-content .blogImg {
      overflow: hidden;
      height: 400px;
      img {
        max-width: 100%;
        transform: translate(0%, -50%);
        top: 50%;
        position: relative;
      }
    }
  }

  @media (max-width: 1024px) {
    .blogHeader .container .blogImg {
      height: auto;
      img {
        max-width: 100%;
        transform: inherit;
        top: 0%;
        position: relative;
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader .container .blogImg {
      height: auto;
      img {
        max-width: 100%;
        transform: inherit;
        top: 0%;
        position: relative;
      }
    }
    nav.header .row {
      width: auto;
      min-width: 100%;
      padding-left: 0px;
      margin-right: 0px;
      margin-left: 0px;
    }
  }
  .mid-content{
    .blogImg{
      height:auto;
      img{
        transform: none;
        top: 0;
      }
    }
  }
  .blogHeader{
    .blogHeaderText{
      h1{
        font-size:32px;
      }
    }
  }

  svg.goldGreenMap {
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #b29700;
      }
      &:hover {
        path#AU-WA-2 {
          fill: #f3eed6;
        }
      }
    }

    g#AU-NT {
      > path#AU-NT-2 {
        fill: #d4af37;
      }
      &:hover {
        path#AU-NT-2 {
          fill: #faf5e4;
        }
      }
    }

    g#AU-SA {
      > path#AU-SA-2 {
        fill: #e1c158;
      }
      &:hover {
        path#AU-SA-2 {
          fill: #faf5e4;
        }
      }
    }

    g#AU-QLD {
      > path#AU-QLD-2 {
        fill: #7daa6a;
      }
      &:hover {
        path#AU-QLD-2 {
          fill: #eaf1e7;
        }
      }
    }

    g#AU-NSW {
      > path#AU-NSW-2 {
        fill: #619a46;
      }
      &:hover {
        path#AU-NSW-2 {
          fill: #e5efe1;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill: #438029;
      }
      &:hover {
        path#AU-VIC-2 {
          fill: #e0ebdc;
        }
      }
    }

    g#AU-TAS {
      > path#AU-TAS-2 {
        fill: #e1c158;
      }
      &:hover {
        path#AU-TAS-2 {
          fill: #faf5e4;
        }
      }
    }
  }
}
 