body{
    overflow-x: hidden;
}
.seventh_layout_global{    
    .container{
        max-width:1480px;
        width:100%;
    }
    .header-section{
        z-index: 11;
        .fixed-top{
            ul{
                .nav-item{
                    a.nav-link{
                        text-transform: uppercase;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                        font-weight:500;
                    }
                }
            }
        }
    }
    
    .seventhThemeBody{
        display:inline-block;
        width:100%;
        .seventhTheme_FirstSection{
            min-height:593px;
            float:left;
            width:100%;
            padding:95px 0px 100px 0px;
            .homepageForm{
                border-radius:5px;
                display:flex;
                .homeFormImg{
                    max-width:550px;
                    flex:0 0 550px;
                    display:flex;
                    align-items: flex-end;
                    img{
                        max-width:100%;
                        vertical-align:bottom;
                    }
                }
                .homeSurveyForm{
                    width:100%;
                    padding:40px;
                    .homeSurveyFormInside{
                        display: table;
                        height: 100%;
                        width: 100%;
                        .mainFormSurvey{
                            display:table-cell;
                            vertical-align:middle;
                            text-align:center;
                        }
                    }
                }
            }
        }
    
        .otherImportantInfo{
            float:left;
            width:100%;
            margin-top: -80px;
            .otherInfo{
                border-radius:5px;
                padding:25px;
                ul{
                    display:flex;
                    flex-wrap:wrap;
                    margin-bottom:0px;
                    li{
                        flex:0 0 17.5%;
                        max-width:17.5%;
                        list-style:none;
                        text-align:center;
                        padding:0px 20px;
                        transition: 0.5s;
                        cursor: pointer;
                        &:hover{
                            transform:translate(0px, -10px);
                        }
                        &:last-child{
                            flex:0 0 30%;
                            max-width:30%;
                            text-align:left;
                            border-right:none !important;
                            padding:0px 50px;
                        }
                        h4{
                            font-size:16px;
                            font-weight:600;
                            margin-top: 5px;
                            margin-bottom:5px;
                        }
                        p{
                            font-size:14px;
                            margin-bottom:0px;
                            font-weight:500;
                        }
                        span{
                            font-size:14px;
                            font-weight:500;
                            margin-top:10px;
                            display:inline-block;
                        }
                    }
                    
                }
            }
        }
        .seventhHowItWorks{
            display:inline-block;
            width:100%;
            padding:50px 0px;
            .howWorksList{
                margin-top:30px;
                transition: 0.5s;
                border-radius: 5px;
                &:hover{
                    transform: translate(0, -15px);
                }
                h4{
                    font-size:20px;
                    font-weight:600;
                    margin-top:5px;
                }
                p{
                    font-size:16px;
                    letter-spacing:0.5px;
                    margin-bottom:0px;
                    margin-top:20px;
                }
            }
        }
        .sectionHeadSeventh{
            text-align:center;
            width: 100%;
            display:inline-block;
            margin-bottom:40px;
            h2{
                font-size:34px;
                margin-bottom:0px;
            }
            p{
                font-size:18px;
                margin-bottom:0px;
            }
        }
        .ourCustomersSection{
            display:inline-block;
            padding:0px 60px;
            width:100%;
            .ourCustomersSectionInside{
                display:inline-block;
                width:100%;
                padding:50px 0px;
                border-radius:5px;
                .testimonialsBlock{
                    display:inline-block;
                    width:100%;
                    border-radius:5px;
                    padding:35px;
                    .testimonialsListing{
                        display:flex;
                        margin:0px -35px;
                        blockquote{
                            padding:0px 35px;
                            flex:0 0 33.3333%;
                            max-width:33.3333%;
                            &:last-child{
                                border-right:none;
                            }
                            p{
                                font-size:16px;
                                letter-spacing:0.5px;;
                            }
                            .customerdetails{
                                float:left;
                                width:100%;
                                .customerImg{
                                    width:40px;
                                    height:40px;
                                    float:left;
                                    border-radius:50%;
                                    text-align:center;
                                    line-height:40px;
                                    font-size:28px;
                                    font-weight:600;
                                }
                                .customerName{
                                    margin-left:55px;
                                    font-weight:500;
                                    .rating{
                                        display:inline-block;
                                        width:100%;
                                        img{
                                            max-width:100px;
                                            float:left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .googleReview{
                    float:right;
                    max-width:100px;
                    margin-top:15px;
                }
            }
        }
        .benefitSection{
            padding:50px 0px;
            display:inline-block;
            width:100%;
            .benefirSectionInside{
                position: relative; 
                .benefitTextBlock{                    
                    min-height:550px;
                    padding-right:60px;
                    .benefirText{
                        display: table;
                        height: 100%;
                        width: 100%;
                        .benefitTextCenter{
                            display:table-cell;
                            vertical-align: middle;
                            h2{
                                font-size:34px;
                                margin-bottom:30px;
                            }
                            ul{
                                li{
                                    background-repeat:no-repeat;
                                    background-size:29px 29px;
                                    padding-left:45px;
                                    list-style:none;
                                    margin:15px 0px;
                                    min-height:30px;
                                    float:left;
                                    font-size:16px;
                                    letter-spacing:0.5px;
                                }
                            }
                        }
                    }
                }               
                
                .benefitSectionImg{
                    position:absolute;
                    right: 0px;
                    top:0px;
                    overflow:hidden;
                    padding-right:0px;
                    height:100%;
                    img{
                        height:100%;
                    }
                }
            }
        }
        .takeActionSection{
            display:inline-block;
            width:100%;
            padding:0px 0px 50px 0px;
            .takeAction{
                border-radius:5px;
                padding:40px 60px;
                display:flex;
                align-items: center;
                min-width:100%;
                h2{
                    letter-spacing:0.5px;
                    font-weight:600;
                    margin-bottom:0px;
                    width:100%;
                }
                .checkEligibilityBtn{
                    flex:0 0 400px;
                    max-width:400px;
                    a{
                        font-size: 16px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        float: right;
                        padding: 18px 40px;
                        border-radius: 5px;
                        font-weight: 600;
                        margin-top:8px;
                        &:hover{
                            text-decoration:none;
                        }
                    }
                }
            }
        }
    }
    
    .section-footer{
        display:inline-block;
        width:100%;
        padding: 40px 0px;
        text-align: center;
        p{
            font-size:14px;
            margin-bottom:0px;
        }
        .footer-content{
            float:left;
            width:100%;
        }
        .custome-links{
            display:inline-block;
            width:100%;
            ul{
                float:left;
                width:100%;
                li{
                    list-style:none;
                    display:inline-block;
                    font-size:14px;
                    padding:0px 10px;
                    margin-top:5px;
                    &:last-child{
                        border-right:none;
                    }
                }
            }
        }
    }
    
    .btn:link,
    .btn:visited, .form_button {
        text-transform: uppercase;
        text-decoration: none;
        padding: 15px 40px;
        display: inline-block;
        border-radius: 100px;
        transition: all .2s;
        position: relative;
        box-shadow: none;
    }
    
    .btn:hover, .form_button:hover {
        transform: translateY(-3px);
        outline: none;
    }
    
    .btn:active, .form_button:active {
        transform: translateY(-1px);
        outline: none;
    }
    
    
    
    .btn::after, .form_button::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
        outline: none;
    }
    
    .btn-white::after {
        outline: none;
        box-shadow:none;
    }
    
    .btn:hover::after, .form_button:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
        outline: none;
    }
    
    .btn-animated {
        animation: moveInBottom 5s ease-out;
        animation-fill-mode: backwards;
        outline: none;
    }
    
    @keyframes moveInBottom {
        0% {
            opacity: 0;
            transform: translateY(30px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }


    .newThemeBgBlue{
        display:none;
    }
    .newThemeinnerPage{
        padding-top:120px;
        min-height:calc(100vh - 54px);
        box-shadow:none;
        padding-bottom:50px;
        margin:auto;
        width:1280px;
        max-width: 100%;
        .pageTitleInner{
            text-align: center;            
            margin:auto;
            h1{
                font-size:16px;
                letter-spacing:1.5px;
                text-transform:uppercase;
                margin-bottom:0px;                
            }
            h2{
                font-size:40px;
            }
        }
        .aboutPageContentNew{
            display:flex;
            flex-wrap:wrap;
            min-height:500px;
            margin:30px auto 30px auto;
            position: relative;
            max-width:1280px;
            .aboutPageLeftContent{
                flex:0 0 70%;
                max-width:70%;
                padding:60px 150px 60px 60px;
                align-self: center;
                h2{
                    font-size:3rem;
                    margin-bottom:20px;
                }
            }
        }
        .aboutPageImg{
            width:400px;
            height:400px;
            position: absolute;
            right:0px;
            top:50px;
        }
        .contentAreaInner{
            h3{
                font-size:28px;
            }
            ul{
                margin: 0px -1%;
                padding:0px;
                display: flex;
                flex-wrap: wrap;
                li{
                    list-style:none;
                    margin:5px 1%;
                    padding-left:30px;
                    background-repeat:no-repeat;
                    background-size:16px 16px;
                    background-position: left 5px;
                    flex: 0 0 48%;
                    max-width: 48%;
                }
            }
            p{
                margin-top:40px;
            }
        }
    }
    
    
    /***Contact Us page***/
    .newThemeContactUs{
        padding-top:80px;
        min-height:calc(100vh - 37px);
        box-shadow:none;
        padding-bottom:50px;
        margin:auto;
        width:1280px;
        max-width: 100%; 
        .contactTitleText{
            align-self: center;
            h1{
                margin: 0 auto 30px;
                font-size: 52px;
                line-height: 1.1em;
                font-weight: 700;
                text-align: left;
                span{
                    display:inline-block;
                    width:100%;
                    font-weight: 700;
                }
            }
            p{
                margin: 0 auto 0 0;
                font-size: 18px;
                line-height: 1.2em;
                font-weight: 500;
                text-align: left;
            }
            .mailToText{
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
                transform-style: preserve-3d;
                width: 80%;
                padding: 30px 20px;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-top: 20px;
                display: flex;
                -webkit-box-pack: start;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                border-radius: 16px;
                transition: .4s cubic-bezier(.25, .46, .45, .94);
                text-decoration: none;
                &:hover{
                    border-width: 2px;
                    -webkit-transform: translate(0,-4px);
                    -ms-transform: translate(0,-4px);
                    transform: translate(0,-4px);
                }
                .contactIcon{
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border-radius: 12px;
                }
                .profile-details{
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: start;
                    -webkit-align-items: flex-start;
                    -ms-flex-align: start;
                    align-items: flex-start;
                    .profileContact{
                        display: inline-block;
                        margin-bottom: 0;
                        font-size: 20px;
                        line-height: 22px;
                    }
                }
            }
        }
        .contactFormNewTheme{
            h2{
                margin-bottom: 10px;
                font-size: 30px;
                line-height: 1.2em;
                font-weight: 600;
                text-align: left;
            }
            .controls{
                border-radius: 30px;
                padding: 40px;
                .dynamic-form{
                    margin:0px;
                    .space-between {
                        .MuiFormLabel-root{
                            display:flex !important;
                            margin-top: 15px;
                            margin-bottom: 8px;
                            font-weight: 500;
                            font-size:14px;
                        }
                        .textarea-box{
                            .MuiFormLabel-root{
                                font-size:14px;
                            }
                            textarea{
                                border-width:1px;
                                width:100%;
                                border-radius:5px; 
                                min-height:100px !important;
                            }
                        }
                        .form_button{
                            border-radius:5px;
                            -webkit-transform: translate(0,-4px);
                            -ms-transform: translate(0,-4px);
                            transform: translate(0,-4px);
                            font-weight: 600;
                            letter-spacing: 2px;
                            margin-top:30px;
                            height: 60px;
                            &:hover{
                                -webkit-transform: translate(0,-4px);
                                -ms-transform: translate(0,-4px);
                                transform: translate(0,-4px);
                            }
                        }
                    }
                }
            }
        }
    }
    
    .privacyAreaInner{
        margin-top:50px;
        h4{
            margin-top:40px;
        }
        ul{
            padding-left:20px;
            display: inline-block;
            width:100%;
            li{
                list-style:disclosure-closed;
                padding-left:10px;
                margin:5px 0px;
                float:left;
                width:100%;
            }
        }
        p{
            display:flex;
            strong{
                font-weight:500;
                margin-right:15px;
    
            }
        }
    }


    /**thank you**/
    .thankyou-section {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        padding-top:80px;
        background-size: cover;

        .tankkyou-container{
            align-self:center;
            max-width:800px;
            text-align: center;
        }
        .button-home{
            a{
                border-radius: 5px;
                display: inline-block;
                padding: 15px 40px;
                transition: 0.5s;
                text-decoration: none;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1.3px;
                margin-top:50px;
            }
        }
    }



    /***Responsive CSS***/

    @media (max-width: 1680px) {
        .container{
            max-width: 1280px;
        }
        .seventhThemeBody{
            .benefitSection {
                .benefirSectionInside {
                    .benefitSectionImg{
                        height:100%;
                        img{
                            height:100%;
                            width: auto;
                        }
                    }
                }
            }
        }
        .newThemeContactUs .contactTitleText {
            align-self: auto;
        }
    }

    @media (max-width: 1365px) {
        .container{
            max-width: 1140px;
        }
        .seventhThemeBody{
            .seventhTheme_FirstSection {
                .homepageForm {
                    .homeFormImg {
                        max-width: 500px;
                        flex: 0 0 500px;
                        img{
                            max-width:100%;
                        }
                    }
                }
            }
            .otherImportantInfo{
                .otherInfo{
                    ul {
                        li{
                            padding: 0px 10px;
                        }
                    }
                }
            }
            .seventhHowItWorks{
                .howWorksList {
                    p{
                        font-size:14px;
                    }
                }
            }
        }
    }
    @media (max-width: 1200px) {
        .container{
            max-width: 960px;
            width:100%;
        }
        .seventhThemeBody{
            .seventhTheme_FirstSection {
                .homepageForm {
                    .homeFormImg {
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .seventhThemeBody{
            .seventhTheme_FirstSection{
                .homepageForm {
                    .homeSurveyForm{
                        padding: 30px;
                        .titleSurveyHeading{
                            h1{
                                font-size: 52px;
                                line-height: 1.1em;
                                font-weight: 700;
                            }
                            p{
                                font-size:18px;
                            }
                        }
                    }
                    .homeFormImg {
                        max-width: 400px;
                        flex: 0 0 400px;
                    }
                }
            }
            .ourCustomersSection{
                padding:0px;
                .ourCustomersSectionInside{
                    .testimonialsBlock {
                        .testimonialsListing {
                            blockquote{
                                p{
                                    font-size:14px;
                                }
                            }
                        }
                    }
                }
            }
            .benefitSection {
                .benefirSectionInside {
                    .benefitTextBlock {
                        min-height: 440px;
                        padding-right: 15px;
                        .benefirText {
                            .benefitTextCenter {
                                h2{
                                    font-size:30px;
                                }
                                ul{
                                    li{
                                        font-size:14px;
                                        background-size: 20px 20px;
                                        padding-left: 30px;
                                        margin:5px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .takeActionSection {
                .takeAction{
                    padding:20px;
                    h2{
                        font-size:26px;
                    }
                }
            }
        } 
        .newThemeContactUs{
            .contactFormNewTheme {
                .controls{
                    padding:30px;
                    .dynamic-form {
                        .space-between{
                            margin:5px 0px;
                            .textarea-box {
                                textarea{
                                    min-height:80px !important;
                                }
                            }
                        }
                    }
                }
                h2{
                    margin-bottom:0px;
                }
            }
        }       
    }

    @media (max-width: 992px) {
        .container{
            max-width:720px;
            width:100%;
        }
        .seventhThemeBody{
            .seventhTheme_FirstSection {
                min-height:auto;
                .homepageForm {
                    .homeFormImg{
                        display:none;
                    }
                }
            }
            .otherImportantInfo {
                .otherInfo{
                    padding: 10px 5px;
                    ul{
                        li {
                            padding: 0px 5px;
                        }
                    }
                }
            }     
            .otherImportantInfo {
                .otherInfo {
                    ul {
                        li {
                            h4{
                                font-size: 14px;
                            }
                            p{
                                font-size:12px;
                                line-height:normal;
                            }
                            img{
                                max-width: 36px;
                            }
                            &:last-child{
                                padding: 0px 5px;
                            }
                        }
                    }
                }
            }  
            .seventhHowItWorks {
                .howWorksList{
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-top:15px;
                }
            }  
            .ourCustomersSection {
                .ourCustomersSectionInside {
                    .testimonialsBlock {
                        .testimonialsListing {
                            flex-wrap:wrap;
                            margin:0px -15px;
                            blockquote{
                                flex: 0 0 100%;
                                max-width: 100%;
                                border-bottom:1px solid #ddd;
                                border-right:none !important;
                                padding:15px;
                                &:first-child{
                                    padding-top:0px;
                                }
                                &:last-child{
                                    border-bottom:none;
                                    padding-bottom:0px;
                                }
                            }
                        }
                    }
                }
            } 
            .benefitSection{
                padding-top: 0px;
                .benefirSectionInside {
                    .benefitTextBlock{
                        flex: 0 0 100%;
                        max-width: 100%;    
                    }
                    .benefitSectionImg{
                        position: relative;
                        flex: 0 0 100%;
                        max-width: 100%;
                        height:auto;
                        padding-right:15px;
                        img{
                            width:100%;
                            height:auto;
                        }  
                    }
                }
            }  
            .takeActionSection {
                .takeAction {
                    .checkEligibilityBtn{
                        flex: 0 0 200px;
                        max-width: 200px;
                        a{
                            font-size:14px;
                            padding:15px;
                        }
                    }
                }
            }
        }
        .newSurveyFooter{
            text-align: center;
            .nextBtn{
                float: none !important;
                display: inline-block !important;
            }
        }

        .newThemeinnerPage{
            padding-top: 70px;
            .pageTitleInner {
                h2 {
                    font-size: 34px;
                }
            }
            .aboutPageContentNew{
                min-height: 400px;
                .aboutPageLeftContent{
                    padding: 15px 110px 15px 15px;
                    h2 {
                        font-size: 1.7rem;
                    }
                }
            }
            .aboutPageImg {
                width: 300px;
                height: 300px
            }
        }
        .newThemeContactUs {
            .contactTitleText{
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                h1{
                    text-align:center;
                }
                p{
                    text-align:center;
                }
                .mailToText{
                    margin: 15px auto auto auto;
                    width:420px;
                    max-width:80%;
                }
            }
            .contactFormNewTheme{
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top:20px;
            }
        }
        
    }

    @media (max-width: 767px) {
        .container{
            max-width:540px;
            width:100%;
        }
        .seventhThemeBody {
            .seventhTheme_FirstSection {
                .homepageForm {
                    .homeSurveyForm {
                        .homeSurveyFormInside{
                            display: inline-block;
                            width: 100%;
                            .mainFormSurvey{
                                display:inline-block;
                                width:100%;
                            }
                        }
                    }
                }
            }
            .otherImportantInfo {
                .otherInfo {
                    ul {
                        li{
                            flex:0 0 25%;
                            max-width:25%;
                            border-right:none;
                            border-left:1px solid #ddd;
                            &:last-child{
                                display:none;
                            }
                            &:first-child{
                                border-left:none;
                            }
                        }
                    }
                }
            }
            .sectionHeadSeventh {
                h2{
                    font-size:30px;
                }
                p{
                    font-size:16px;
                }
            }
        }
        .newThemeinnerPage{
            .aboutPageContentNew{
                min-height:auto;
                .aboutPageLeftContent{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding:15px;
                }
                .aboutPageImg{
                    display:none;
                }
            }
            .contentAreaInner{
                ul {
                    li{
                        flex: 0 0 100%;
                        max-width: 100%;  
                    }
                }
            }
        }
        .section-footer{
            padding:15px 0px;
            .custome-links{
                ul{
                    margin-bottom:0px;
                }
            }
        }
        .newThemeContactUs{
            padding-top:60px;
            .contactTitleText {
                h1{
                    font-size: 40px;
                }
                .mailToText {
                    .profile-details {
                        .profileContact{
                            font-size:16px;
                        }
                    }
                }
            }
            .contactFormNewTheme {
                h2{
                    text-align:center;
                }
                .controls{
                    .dynamic-form {
                        .space-between{
                            justify-content:left !important;
                        }
                    }
                }
            }
        }
    
    }
    @media (max-width: 560px) {
        .container{
            max-width:100%;
        }
        .seventhThemeBody{
            .takeActionSection{
                .takeAction{
                    display:inline-block;
                    text-align:center;
                    .checkEligibilityBtn{
                        display: inline-block;
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top:15px;
                        a{
                            float: none;
                            display: inline-block;
                        }
                    }
                }
                
            }
            .benefitSection {
                .benefirSectionInside {
                    .benefitTextBlock {
                        .benefirText{
                            padding: 30px 0px;
                        }
                    }
                }
            }
            .ourCustomersSection {
                .ourCustomersSectionInside {
                    padding: 20px 0px;
                    .testimonialsBlock{
                        padding:15px;
                    }
                }
            }
            .seventhHowItWorks {
                .howWorksList{
                    flex: 0 0 100%;
                    max-width: 100%; 
                    text-align: center;
                    margin-bottom:20px;
                    &:last-child{
                        margin-bottom:0px;
                    }
                }
            }
            .otherImportantInfo {
                .otherInfo {
                    ul {
                        li{
                            flex: 0 0 50%;
                            max-width: 50%;
                            margin-bottom:10px;
                            &:nth-child(3){
                                border-left:none;
                            }
                        }
                    }
                }
            }
            .seventhTheme_FirstSection {
                .homepageForm {
                    .homeSurveyForm {
                        padding: 30px 15px;
                        .titleSurveyHeading {
                            h1{
                                font-size:30px;
                            }
                        }
                        .homeSurveyFormInside {
                            display:inline-block;
                            width:100%;
                            .mainFormSurvey{
                                display:inline-block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .section-footer p{
            font-size:12px;
        }
        .section-footer .custome-links ul li{
            font-size:12px;
        }
        .section-footer{
            padding:15px 0px;
        }
        .newThemeinnerPage{
            .pageTitleInner {
                h2 {
                    font-size: 28px;
                }
            }
            .aboutPageContentNew {
                .aboutPageLeftContent{
                    h2 {
                        font-size: 1.4rem;
                    }
                }
            }
            .contentAreaInner{
                ul{
                    li{
                        margin:5px 0px;
                    }
                }
            }
        }
        .newThemeContactUs{
            .contactTitleText {
                h1{
                    font-size: 30px;
                }
            }
            .contactFormNewTheme {
                h2{
                    font-size:26px;
                }
            }
        }
        
    }




    /***********************************************************************
                                    Theme CSS
    ***********************************************************************/
    .layout_seventh-default-theme{   
        $base-color: #44CB86;  
        $section-background: #fafafb;  
        .header-section{
            .fixed-top{
                background:$base-color;
                ul{
                    .nav-item{
                        a.nav-link{
                            color:#fff;
                        }
                    }
                }
            }
            &.pageScroll{
                .fixed-top{
                    background:$base-color;
                }
            }
            .navbar-toggler{
                background:#fff;
            }
        }

        .seventhThemeBody{
            .seventhTheme_FirstSection{
                background:$section-background;
                .homepageForm{
                    background-color:#fff;
                    background-image:linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url(../../assets/images/rays.svg);
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                }
            }

            .otherImportantInfo{
                .otherInfo{
                    background:#fff;
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                    ul{
                        li{
                            border-right:1px solid #CFC6C9;
                            &:last-child{
                                p{
                                    color:#212121;
                                }
                            }
                            h4{
                                color:#212121;
                            }
                            p{
                                color:#9A9A9A;
                            }
                            span{
                                color:#9A9A9A; 
                            }
                        }
                        
                    }
                }
            }
            .seventhHowItWorks{
                .howWorksList{
                    h4{
                        color:#1D1D1D;
                    }
                    p{
                        color:#535353;
                    }
                }
            }
            .sectionHeadSeventh{
                h2{
                    color:#1E1E1E;
                }
                p{
                    color:#505050;
                }
            }
            .ourCustomersSection{
                .ourCustomersSectionInside{
                    background:$section-background;
                    .testimonialsBlock{
                        background:#fff;
                        box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                        .testimonialsListing{
                            blockquote{
                                border-right:1px solid #DDD7D9;
                                p{
                                    color:#535353;
                                }
                                .customerdetails{
                                    .customerImg{
                                        background:#ddd;
                                        color:#fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .benefitSection{
                .benefirSectionInside{ 
                    background:#f9f9f9;
                    .benefitTextBlock{
                        .benefirText{
                            .benefitTextCenter{
                                h2{
                                    color:#1E1E1E;
                                }
                                ul{
                                    li{
                                        background-image:url(../../assets/images/checkMark-green.svg);
                                    }
                                }
                            }
                        }
                    }               
                    
                }
            }
            .takeActionSection{
                .takeAction{
                    background:$base-color;
                    h2{
                        color:#fff;
                    }
                    .checkEligibilityBtn{
                        a{
                            background: #fff;
                            color: $base-color;
                        }
                    }
                }
            }
        }

        .section-footer{
            border-top:1px solid #ddd;
            p{
                color: #A8A8A8;
            }
            .custome-links{
                ul{
                    li{
                        border-right:1px solid #ddd;
                        a{
                            color: #A8A8A8;         
                            &:hover{
                                color:$base-color;
                            }               
                        }
                        &:last-child{
                            border-right:none;
                        }
                    }
                }
            }
        }



        .btn:hover, .form_button:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }

        .btn:active, .form_button:active {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        .btn-white {
            background-color: #fff;
            color: #777;
        }

        .btn-white::after {
            background-color: #fff;
        }


        
        .newThemeinnerPage{
            .aboutPageContentNew{
                .aboutPageLeftContent{
                    background:#ebf0f3;
                }
            }
            .aboutPageImg{
                background: $base-color;
            }
            .contentAreaInner{
                ul{
                    li{
                        background-image: url(../../assets/images/yes-green.svg);
                    }
                }
            }
        }


        /***Contact Us page***/
        .newThemeContactUs{
            background:#fefeff;
            .contactTitleText{
                h1{
                    color: #0a0535;
                }
                p{
                    color: #4a5a83;
                }
                .mailToText{
                    border: 2px solid transparent;
                    border-radius: 16px;
                    background-color: #fff;
                    box-shadow: 6px 6px 16px 4px rgba(0,0,0,.02);
                    &:hover{
                        border-color: $base-color;
                        box-shadow: 11px 11px 16px 4px rgba(0,0,0,.04);
                    }
                    .contactIcon{
                        background-color: #f2f0ff;
                    }
                    .profile-details{
                        .profileContact{
                            color: #4a5a83;
                            strong{
                                color: $base-color; 
                            }
                        }
                    }
                }
            }
            .contactFormNewTheme{
                h2{
                    color: #263047;
                }
                .controls{
                    border: 2px solid #f2f0ff;
                    background-color: #fff;
                    .dynamic-form{
                        .space-between {
                            .MuiFormLabel-root{
                                color: #313131 !important;
                                .mendatory{
                                    color:red;
                                }
                            }
                            .input-box__field{
                                background: #fafafb;
                                border-color:transparent;
                                &:hover, &:focus{
                                    border-color:$base-color;
                                }
                            }
                            .textarea-box{
                                textarea{
                                    background: #fafafb;
                                    border-color:transparent;
                                    &:hover, &:focus{
                                        border-color:$base-color;
                                    }
                                }
                            }
                            .form_button{
                                background-color:$base-color;
                                border-color: transparent;
                                box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);
                                color:#fff;
                                &:hover{
                                    background-color: $base-color;
                                    box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);   
                                    border-color:transparent;
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        /**thank you**/
        .thankyou-section {
            .button-home{
                a{
                    background: $base-color;
                    color: #fff;
                    box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                }
            }
        }


    }



    .layout_seventh-first-theme{   
        $base-color: #1b283f;  
        $section-background: #fafafb;  
        .header-section{
            .fixed-top{
                background:$base-color;
                ul{
                    .nav-item{
                        a.nav-link{
                            color:#fff;
                        }
                    }
                }
            }
            &.pageScroll{
                .fixed-top{
                    background:$base-color;
                }
            }
            .navbar-toggler{
                background:#fff;
            }
        }

        .seventhThemeBody{
            .seventhTheme_FirstSection{
                background:$section-background;
                .homepageForm{
                    background-color:#fff;
                    background-image:linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url(../../assets/images/rays.svg);
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                }
            }

            .otherImportantInfo{
                .otherInfo{
                    background:#fff;
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                    ul{
                        li{
                            border-right:1px solid #CFC6C9;
                            &:last-child{
                                p{
                                    color:#212121;
                                }
                            }
                            h4{
                                color:#212121;
                            }
                            p{
                                color:#9A9A9A;
                            }
                            span{
                                color:#9A9A9A; 
                            }
                        }
                        
                    }
                }
            }
            .seventhHowItWorks{
                .howWorksList{
                    h4{
                        color:#1D1D1D;
                    }
                    p{
                        color:#535353;
                    }
                }
            }
            .sectionHeadSeventh{
                h2{
                    color:#1E1E1E;
                }
                p{
                    color:#505050;
                }
            }
            .ourCustomersSection{
                .ourCustomersSectionInside{
                    background:$section-background;
                    .testimonialsBlock{
                        background:#fff;
                        box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                        .testimonialsListing{
                            blockquote{
                                border-right:1px solid #DDD7D9;
                                p{
                                    color:#535353;
                                }
                                .customerdetails{
                                    .customerImg{
                                        background:#ddd;
                                        color:#fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .benefitSection{
                .benefirSectionInside{ 
                    background:#f9f9f9;
                    .benefitTextBlock{
                        .benefirText{
                            .benefitTextCenter{
                                h2{
                                    color:#1E1E1E;
                                }
                                ul{
                                    li{
                                        background-image:url(../../assets/images/checkMark-green.svg);
                                    }
                                }
                            }
                        }
                    }               
                    
                }
            }
            .takeActionSection{
                .takeAction{
                    background:$base-color;
                    h2{
                        color:#fff;
                    }
                    .checkEligibilityBtn{
                        a{
                            background: #fff;
                            color: $base-color;
                        }
                    }
                }
            }
        }

        .section-footer{
            border-top:1px solid #ddd;
            p{
                color: #A8A8A8;
            }
            .custome-links{
                ul{
                    li{
                        border-right:1px solid #ddd;
                        a{
                            color: #A8A8A8;         
                            &:hover{
                                color:$base-color;
                            }               
                        }
                        &:last-child{
                            border-right:none;
                        }
                    }
                }
            }
        }



        .btn:hover, .form_button:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }

        .btn:active, .form_button:active {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        .btn-white {
            background-color: #fff;
            color: #777;
        }

        .btn-white::after {
            background-color: #fff;
        }


        
        .newThemeinnerPage{
            .aboutPageContentNew{
                .aboutPageLeftContent{
                    background:#ebf0f3;
                }
            }
            .aboutPageImg{
                background: $base-color;
            }
            .contentAreaInner{
                ul{
                    li{
                        background-image: url(../../assets/images/yes-green.svg);
                    }
                }
            }
        }


        /***Contact Us page***/
        .newThemeContactUs{
            background:#fefeff;
            .contactTitleText{
                h1{
                    color: #0a0535;
                }
                p{
                    color: #4a5a83;
                }
                .mailToText{
                    border: 2px solid transparent;
                    border-radius: 16px;
                    background-color: #fff;
                    box-shadow: 6px 6px 16px 4px rgba(0,0,0,.02);
                    &:hover{
                        border-color: $base-color;
                        box-shadow: 11px 11px 16px 4px rgba(0,0,0,.04);
                    }
                    .contactIcon{
                        background-color: #f2f0ff;
                    }
                    .profile-details{
                        .profileContact{
                            color: #4a5a83;
                            strong{
                                color: $base-color; 
                            }
                        }
                    }
                }
            }
            .contactFormNewTheme{
                h2{
                    color: #263047;
                }
                .controls{
                    border: 2px solid #f2f0ff;
                    background-color: #fff;
                    .dynamic-form{
                        .space-between {
                            .MuiFormLabel-root{
                                color: #313131 !important;
                                .mendatory{
                                    color:red;
                                }
                            }
                            .input-box__field{
                                background: #fafafb;
                                border-color:transparent;
                                &:hover, &:focus{
                                    border-color:$base-color;
                                }
                            }
                            .textarea-box{
                                textarea{
                                    background: #fafafb;
                                    border-color:transparent;
                                    &:hover, &:focus{
                                        border-color:$base-color;
                                    }
                                }
                            }
                            .form_button{
                                background-color:$base-color;
                                border-color: transparent;
                                box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);
                                color:#fff;
                                &:hover{
                                    background-color: $base-color;
                                    box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);   
                                    border-color:transparent;
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        /**thank you**/
        .thankyou-section {
            .button-home{
                a{
                    background: $base-color;
                    color: #fff;
                    box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                }
            }
        }


    }

    .layout_seventh-second-theme{   
        $base-color: #7773fa;  
        $section-background: #fafafb;  
        .header-section{
            .fixed-top{
                background:$base-color;
                ul{
                    .nav-item{
                        a.nav-link{
                            color:#fff;
                        }
                    }
                }
            }
            &.pageScroll{
                .fixed-top{
                    background:$base-color;
                }
            }
            .navbar-toggler{
                background:#fff;
            }
        }

        .seventhThemeBody{
            .seventhTheme_FirstSection{
                background:$section-background;
                .homepageForm{
                    background-color:#fff;
                    background-image:linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url(../../assets/images/rays.svg);
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                }
            }

            .otherImportantInfo{
                .otherInfo{
                    background:#fff;
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                    ul{
                        li{
                            border-right:1px solid #CFC6C9;
                            &:last-child{
                                p{
                                    color:#212121;
                                }
                            }
                            h4{
                                color:#212121;
                            }
                            p{
                                color:#9A9A9A;
                            }
                            span{
                                color:#9A9A9A; 
                            }
                        }
                        
                    }
                }
            }
            .seventhHowItWorks{
                .howWorksList{
                    h4{
                        color:#1D1D1D;
                    }
                    p{
                        color:#535353;
                    }
                }
            }
            .sectionHeadSeventh{
                h2{
                    color:#1E1E1E;
                }
                p{
                    color:#505050;
                }
            }
            .ourCustomersSection{
                .ourCustomersSectionInside{
                    background:$section-background;
                    .testimonialsBlock{
                        background:#fff;
                        box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                        .testimonialsListing{
                            blockquote{
                                border-right:1px solid #DDD7D9;
                                p{
                                    color:#535353;
                                }
                                .customerdetails{
                                    .customerImg{
                                        background:#ddd;
                                        color:#fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .benefitSection{
                .benefirSectionInside{ 
                    background:#f9f9f9;
                    .benefitTextBlock{
                        .benefirText{
                            .benefitTextCenter{
                                h2{
                                    color:#1E1E1E;
                                }
                                ul{
                                    li{
                                        background-image:url(../../assets/images/checkMark-green.svg);
                                    }
                                }
                            }
                        }
                    }               
                    
                }
            }
            .takeActionSection{
                .takeAction{
                    background:$base-color;
                    h2{
                        color:#fff;
                    }
                    .checkEligibilityBtn{
                        a{
                            background: #fff;
                            color: $base-color;
                        }
                    }
                }
            }
        }

        .section-footer{
            border-top:1px solid #ddd;
            p{
                color: #A8A8A8;
            }
            .custome-links{
                ul{
                    li{
                        border-right:1px solid #ddd;
                        a{
                            color: #A8A8A8;         
                            &:hover{
                                color:$base-color;
                            }               
                        }
                        &:last-child{
                            border-right:none;
                        }
                    }
                }
            }
        }



        .btn:hover, .form_button:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }

        .btn:active, .form_button:active {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        .btn-white {
            background-color: #fff;
            color: #777;
        }

        .btn-white::after {
            background-color: #fff;
        }


        
        .newThemeinnerPage{
            .aboutPageContentNew{
                .aboutPageLeftContent{
                    background:#ebf0f3;
                }
            }
            .aboutPageImg{
                background: $base-color;
            }
            .contentAreaInner{
                ul{
                    li{
                        background-image: url(../../assets/images/yes-green.svg);
                    }
                }
            }
        }


        /***Contact Us page***/
        .newThemeContactUs{
            background:#fefeff;
            .contactTitleText{
                h1{
                    color: #0a0535;
                }
                p{
                    color: #4a5a83;
                }
                .mailToText{
                    border: 2px solid transparent;
                    border-radius: 16px;
                    background-color: #fff;
                    box-shadow: 6px 6px 16px 4px rgba(0,0,0,.02);
                    &:hover{
                        border-color: $base-color;
                        box-shadow: 11px 11px 16px 4px rgba(0,0,0,.04);
                    }
                    .contactIcon{
                        background-color: #f2f0ff;
                    }
                    .profile-details{
                        .profileContact{
                            color: #4a5a83;
                            strong{
                                color: $base-color; 
                            }
                        }
                    }
                }
            }
            .contactFormNewTheme{
                h2{
                    color: #263047;
                }
                .controls{
                    border: 2px solid #f2f0ff;
                    background-color: #fff;
                    .dynamic-form{
                        .space-between {
                            .MuiFormLabel-root{
                                color: #313131 !important;
                                .mendatory{
                                    color:red;
                                }
                            }
                            .input-box__field{
                                background: #fafafb;
                                border-color:transparent;
                                &:hover, &:focus{
                                    border-color:$base-color;
                                }
                            }
                            .textarea-box{
                                textarea{
                                    background: #fafafb;
                                    border-color:transparent;
                                    &:hover, &:focus{
                                        border-color:$base-color;
                                    }
                                }
                            }
                            .form_button{
                                background-color:$base-color;
                                border-color: transparent;
                                box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);
                                color:#fff;
                                &:hover{
                                    background-color: $base-color;
                                    box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);   
                                    border-color:transparent;
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        /**thank you**/
        .thankyou-section {
            .button-home{
                a{
                    background: $base-color;
                    color: #fff;
                    box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                }
            }
        }


    }

    .layout_seventh-third-theme{   
        $base-color: #4094a9;  
        $section-background: #fafafb;  
        .header-section{
            .fixed-top{
                background:$base-color;
                ul{
                    .nav-item{
                        a.nav-link{
                            color:#fff;
                        }
                    }
                }
            }
            &.pageScroll{
                .fixed-top{
                    background:$base-color;
                }
            }
            .navbar-toggler{
                background:#fff;
            }
        }

        .seventhThemeBody{
            .seventhTheme_FirstSection{
                background:$section-background;
                .homepageForm{
                    background-color:#fff;
                    background-image:linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url(../../assets/images/rays.svg);
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                }
            }

            .otherImportantInfo{
                .otherInfo{
                    background:#fff;
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                    ul{
                        li{
                            border-right:1px solid #CFC6C9;
                            &:last-child{
                                p{
                                    color:#212121;
                                }
                            }
                            h4{
                                color:#212121;
                            }
                            p{
                                color:#9A9A9A;
                            }
                            span{
                                color:#9A9A9A; 
                            }
                        }
                        
                    }
                }
            }
            .seventhHowItWorks{
                .howWorksList{
                    h4{
                        color:#1D1D1D;
                    }
                    p{
                        color:#535353;
                    }
                }
            }
            .sectionHeadSeventh{
                h2{
                    color:#1E1E1E;
                }
                p{
                    color:#505050;
                }
            }
            .ourCustomersSection{
                .ourCustomersSectionInside{
                    background:$section-background;
                    .testimonialsBlock{
                        background:#fff;
                        box-shadow:0px 0px 10px rgba(0, 0, 0, 0.08);
                        .testimonialsListing{
                            blockquote{
                                border-right:1px solid #DDD7D9;
                                p{
                                    color:#535353;
                                }
                                .customerdetails{
                                    .customerImg{
                                        background:#ddd;
                                        color:#fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .benefitSection{
                .benefirSectionInside{ 
                    background:#f9f9f9;
                    .benefitTextBlock{
                        .benefirText{
                            .benefitTextCenter{
                                h2{
                                    color:#1E1E1E;
                                }
                                ul{
                                    li{
                                        background-image:url(../../assets/images/checkMark-green.svg);
                                    }
                                }
                            }
                        }
                    }               
                    
                }
            }
            .takeActionSection{
                .takeAction{
                    background:$base-color;
                    h2{
                        color:#fff;
                    }
                    .checkEligibilityBtn{
                        a{
                            background: #fff;
                            color: $base-color;
                        }
                    }
                }
            }
        }

        .section-footer{
            border-top:1px solid #ddd;
            p{
                color: #A8A8A8;
            }
            .custome-links{
                ul{
                    li{
                        border-right:1px solid #ddd;
                        a{
                            color: #A8A8A8;         
                            &:hover{
                                color:$base-color;
                            }               
                        }
                        &:last-child{
                            border-right:none;
                        }
                    }
                }
            }
        }



        .btn:hover, .form_button:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }

        .btn:active, .form_button:active {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        .btn-white {
            background-color: #fff;
            color: #777;
        }

        .btn-white::after {
            background-color: #fff;
        }


        
        .newThemeinnerPage{
            .aboutPageContentNew{
                .aboutPageLeftContent{
                    background:#ebf0f3;
                }
            }
            .aboutPageImg{
                background: $base-color;
            }
            .contentAreaInner{
                ul{
                    li{
                        background-image: url(../../assets/images/yes-green.svg);
                    }
                }
            }
        }


        /***Contact Us page***/
        .newThemeContactUs{
            background:#fefeff;
            .contactTitleText{
                h1{
                    color: #0a0535;
                }
                p{
                    color: #4a5a83;
                }
                .mailToText{
                    border: 2px solid transparent;
                    border-radius: 16px;
                    background-color: #fff;
                    box-shadow: 6px 6px 16px 4px rgba(0,0,0,.02);
                    &:hover{
                        border-color: $base-color;
                        box-shadow: 11px 11px 16px 4px rgba(0,0,0,.04);
                    }
                    .contactIcon{
                        background-color: #f2f0ff;
                    }
                    .profile-details{
                        .profileContact{
                            color: #4a5a83;
                            strong{
                                color: $base-color; 
                            }
                        }
                    }
                }
            }
            .contactFormNewTheme{
                h2{
                    color: #263047;
                }
                .controls{
                    border: 2px solid #f2f0ff;
                    background-color: #fff;
                    .dynamic-form{
                        .space-between {
                            .MuiFormLabel-root{
                                color: #313131 !important;
                                .mendatory{
                                    color:red;
                                }
                            }
                            .input-box__field{
                                background: #fafafb;
                                border-color:transparent;
                                &:hover, &:focus{
                                    border-color:$base-color;
                                }
                            }
                            .textarea-box{
                                textarea{
                                    background: #fafafb;
                                    border-color:transparent;
                                    &:hover, &:focus{
                                        border-color:$base-color;
                                    }
                                }
                            }
                            .form_button{
                                background-color:$base-color;
                                border-color: transparent;
                                box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);
                                color:#fff;
                                &:hover{
                                    background-color: $base-color;
                                    box-shadow: -5px 30px 20px -16px rgba(46,89,204,.45);   
                                    border-color:transparent;
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        /**thank you**/
        .thankyou-section {
            .button-home{
                a{
                    background: $base-color;
                    color: #fff;
                    box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                }
            }
        }


    }
    
}



    