@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap");

.MuiDialog-paperWidthSm {
  width: 600px;
}

body {
  //font-family: "Montserrat", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: left !important;
  background-color: #fff !important;
}

.loader-div {
  height: 100%;
  text-align: center;
  margin: auto;
  padding: 15%;
}

.header-main-div{
  .navbar-light {
    .navbar-nav {
      .nav-link{
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }
}


.beta_layout-theme{
  .header-section{
    .fixed-top{
      ul{
        .nav-item{
          a{
            font-weight:600;
          }
        }
      }
      .lastbtn{
        .nav-link{
          letter-spacing: 0.5px;
          position: relative;
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 5px;
        }
      }
    }
  }

  // divider css
  .sectionDivider{
    display: -ms-flexbox;
    display: flex;
    .divider__left, .divider__right {
      position: relative;
      display: block;
      height: 30px;
      width: 50%;
    }
    .divider__left {
      border-top-right-radius: 70px;
    }
    .divider__right {
      border-top-left-radius: 70px;
    }
  }


  // Survey form css
  .betaSurvey{
    .newSurveyHeading{
      h2{
        font-size:48px;
        font-weight: bold;
      }
    }
    .betaLayoutFormContainer{
      border-radius:10px;
      padding:70px;
      position: relative;
      margin-top:30px;
      .progressBar {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 14px;
      }

      .questionSteps{
        .space-between{
          .MuiFormLabel-root{
            font-size:24px; 
            font-weight:600; 
            width:100%;      
            div{
              font-weight:600;
            }
          }
          .MuiFormGroup-root{
            flex-direction: row;
          }
          .MuiFormControlLabel-root{
            border-radius: 5px;
            width: 48%;
            margin: 1%;
            .MuiFormControlLabel-label{
              font-size:16px;
              font-weight:500;
              div{
                font-size:16px;
              font-weight:500;
              }
            }
          }
          .date-box__field{
            height:44px;
          }
          .customDropdown-first-theme_span, .customDropdown-eighth-theme_span{
            min-height: 64px;
            width: 48%;
            flex-direction: inherit;
            padding: 5px 15px;
            margin: 1%;   
            border-radius:5px; 
            .customDropdown-first-theme_span_label,  .customDropdown-eighth-theme_span_label{
              font-size:16px;
              font-weight:500;
              center{
                font-weight:500;
                margin-top:0px !important;
              }
            }
            span.icon-span-main{
              i{
                font-size:30px !important;
              }
            }
          }
          
          
        }
      }
      .privacypolicy{
        margin-top:10px;
        text-align:center;
      }
      .newSurveyFooter{
        display:inline-block;
        width:100%;
        margin-top:20px;
        .nextBtn{
          width: auto;
          font-size: 24px;
          font-weight: 600;
          border-radius: 10px;
          padding: 11px 30px;
          float: right;
          &.submit-btn{
            width:100%;
          }
        }
      }
    }
  }

  // How it works section css
  .howItWorks{ 
    background-repeat:no-repeat;
    background-position:right bottom;
    .howItWorksContainer{
      padding:50px 0px 70px 0px;
      .row{
        justify-content: center;
      }
      .smileIcon{
        text-align: center;
        width:100%;
        text-align:center;
      }
      .heading-section{
        text-align:center;
        width:850px;
        max-width:100%;
        margin:auto;
        h2{
          font-size:44px;
          font-weight:bold;
          line-height:normal;
        }
        p{
          width:100%;
          font-size:18px;
        }
      }
      .block-multiples{
        margin-top:50px;
        .single-block{
          text-align:center;
          &:hover{
            box-shadow:none;
          }
          .singleBlockImg{
            width:100%;
            max-width:250px;
            border-radius:50%;
            padding:10px;
            margin:auto;
            position: relative;
            img{
              border-radius:50%;
              position:relative;
              z-index: 1;
            }
            &:before{
              position: absolute;
              right: -20px;
              bottom: 0;
              width: 100px;
              height: 100px;
              content: "";
              border-radius: 50%;
              z-index: 0;
              opacity: 0.1;
            }
            &:after{
              position: absolute;
              left: 0;
              top: 10px;
              width: 50px;
              height: 50px;
              content: "";
              border-radius: 50%;
              z-index: 0;
              opacity: 0.1;
            }
          }
          h4{
            margin-top:25px;
          }
        }
      }
    }  
  }

  // beta Section second css
  .beta-Section-second{
    padding:0px 0px;
    .beta-sectionSecondContainer{
      padding:20px 0px 70px 0px;
      .betaSectionContainer{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding: 78px 5% 41px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        border-radius: 20px;
        background-size: auto;
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        &:before{
          opacity:1;
          content: "";
          position:absolute;
          height:100%;
          width:100%;
          left:0;
          top:0;
          border-radius: 20px;
          z-index:1;
        }
        .heading-section{
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          max-width: 622px;
          margin-bottom: 62px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
          position: relative;
          z-index:2;
          h2{
            margin-top: 0;
            margin-bottom: 34px;
            font-size: 50px;
            line-height: 60px;
            font-weight: 600;
          }
          p{
            font-size: 24px;
            line-height: 32px;
          }
          .homebtn{
            max-width: 100%;
            font-size: 24px;
            font-weight: 600;
            border-radius: 10px;
            padding: 10px 15px;
            min-width: 300px;
            text-align: center;
            margin-top: 20px;
          }
        }
      }
    }
    
  }

  // beta section third
  .beta-thirdSection{
    .beta-thirdContainer{
      padding:40px 0px 70px 0px;
      .beta-thirdheader{
        text-align: center;
        width: 850px;
        max-width: 100%;
        margin: auto;
        margin-bottom:50px;
        h2{
          font-size: 44px;
          font-weight: bold;
          line-height: normal;
        }
      }
      .beta-thirdImgSection{
        border-radius:20px;
        overflow: hidden;
      }
      .beta-thirdContentSection{
        p{
          font-size:18px;
          line-height:29px;
        }
        ul{
          margin:30px 0px;
          padding:0px;
          li{
            padding-left:40px;
            list-style:none;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 26px;
          }
        }
      }
    }
  }


  // Beta section fourth
  .beta-fourthSection{
    .beta-fourthContainer{
      padding:50px 0px 70px 0px;
      .beta-fourthHeader{
        text-align: center;
        width: 850px;
        max-width: 100%;
        margin: auto;
        h2{
          font-size: 44px;
          font-weight: bold;
          line-height: normal;
          margin-bottom:20px;
        }
        p{
          font-size: 18px;
          line-height: 29px;
        }
      }
      .btnCheck{
        text-align:center;
        width:100%;
        margin-top:20px;
        .homebtn{
          max-width: 100%;
          font-size: 24px;
          font-weight: 600;
          border-radius: 10px;
          padding: 10px 25px;
          min-width: 300px;
          text-align: center;
          margin-top: 20px;
        }
      }        
    }
  }

  // footer css
  .section-footer{
    padding:30px 0px;
    font-size:14px;
    .custome-links{
      float:right;
      ul{
        margin-bottom:0px;
        li{
          display:inline-block;
          margin:0px 15px;
        }
      }
    }
  }


  // Thank you page css
  .thankyou-section{
    height:calc(100vh - 98px);
    background-size:cover;
    .tankkyou-container{
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .descript-thankyou{
        border-radius: 10px;
        padding: 70px;
        text-align: center;
      }
    }
  }

  // Privacy policy css
  .pageTitle{
    padding:90px 0px 50px 0px;
    text-align:center;
  }
  .innerPageContent{
    padding: 50px 0px;
    .container{
      max-width:1240px;
    }
  }
 
}














// beta theme first
.beta_layout-first-theme{
  $primaryColor:#172e6f;
  $secondryColor:#0fbfd5;
  $secondryLightColor:#b1e5f1;
  $lightbg:#f5fdfe;
  $white:#ffffff;
  $actionBtnBg:#FF9F0E;
  $lightBg:#f2fdfe;


  .header-main-div{
    .navbar-light {
      .navbar-nav {
        .nav-link{
          color:#000000;
          &:hover{
            color:$primaryColor;
          }
        }
      }
    }
  }

  .header-section{
    background: transparent;
    .fixed-top{
      background:transparent !important;
      ul{
        .nav-item{
          a{
            color:$primaryColor;
            &:hover{
              color:$secondryColor;
            }
          }
        }
      }
      .lastbtn{
        .nav-link{
          background: $actionBtnBg;
          color: $white;
          box-shadow: 0px 0px 5px #ffc34d;
          &:hover{
            background-color: $primaryColor;
            color:$white;
            box-shadow:0px 5px 5px #cfdcff;
          }
        }
      }
    }
    &.pageScroll{
      .fixed-top{
        background-color: $white !important;
        box-shadow:0px 0px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  // divider css
  .sectionDivider{
    background: $white;
    .divider__left, .divider__right {
      background: $primaryColor;
    }
  }


  // Survey form css
  .betaSurvey{
    .newSurveyHeading{
      h2{
        color:$primaryColor;
      }
    }
    .betaLayoutFormContainer{
      background:$white;
      box-shadow:0px 5px 10px rgba(235, 240, 255, 1);
      border: 2px solid $secondryLightColor;
      .questionSteps{
        .space-between{
          .MuiFormLabel-root{
            color: $primaryColor !important;     
            div{
              color: $primaryColor;
            }
          }
          .MuiFormGroup-root{
            flex-direction: row;
          }
          .inputContainer{
            border-color: $secondryLightColor !important;
            &:hover, &:focus{
              border-color:$primaryColor !important;
            }
          }
          .MuiFormControlLabel-root{
            border: 2px solid $secondryLightColor;
            background-color: $lightbg;
            .MuiFormControlLabel-label{
              color:$primaryColor;
              div{
                i{
                  color:$primaryColor;
                }
              }
              
            }

            &:hover{
              background:$primaryColor;
              color:$white;
              border-color:$primaryColor !important;
               & > div{
                  i{
                    color:$white !important;
                  }
                }
                .MuiFormControlLabel-label{
                  color:$white;
                }
            }
          }
          .input-box__field{
            border: 2px solid $secondryLightColor;
            &:hover, &:focus{
              border-color:$primaryColor;
            }
          }
          .customDropdown-first-theme_span{
            border: 2px solid $secondryLightColor !important;
            background-color: $lightbg;           
            &:hover, &.customDropdown-first-theme_span_selected{
              background:$primaryColor;
              color:$white;
              border-color:$primaryColor !important;
            }
          }
          
        }
      }
      .newSurveyFooter{
        .nextBtn{
          background: $actionBtnBg;
          border-color: $actionBtnBg;
          box-shadow: 0px 5px 5px #ebae33;
          &:hover{
            background-color: $primaryColor;
            border-color: $primaryColor;              
            box-shadow: 0px 5px 5px #cfdcff;
          }
        }
      }
    }
  }

  // How it works section css
  .howItWorks{  
    background-color: $primaryColor; 
    background-image:url(../../assets/images/wave2.svg);
    .howItWorksContainer{
      .heading-section{
        h2{
          color:$white;
        }
        p{
          color:$white;
        }
      }
      .block-multiples{
        .single-block{
          background:transparent;
          &:hover{
            box-shadow:none;
          }
          .singleBlockImg{
            border:2px solid $secondryColor;
            &:before{
              background: $secondryLightColor;
            }
            &:after{
              background: $secondryLightColor;
            }
          }
          h4{
            color:$white;
          }
          p{
            color:$white;
          }
        }
      }
    }  
  }

  // beta Section second css
  .beta-Section-second{
    background:$lightBg;
    .sectionDivider{
      background: $primaryColor;
      .divider__left, .divider__right{
        background:$lightBg;
      }
    }
    .beta-sectionSecondContainer{
      .betaSectionContainer{
        background-color: $primaryColor;
        &:before{
          background:$primaryColor;
          background: linear-gradient(267deg, rgba(62,110,246,0.19341743533350846) 0%, $primaryColor 100%);
        }
        .heading-section{
          h2{
            color:$white;
          }
          p{
            color:$white;
          }
          .homebtn{
            background: $actionBtnBg;
            border:none;
            box-shadow: 0px 5px 5px #684a0e;
            color: $white;
          }
        }
      }
    }
    
  }

  // beta section third
  .beta-thirdSection{
    background:$primaryColor;
    .sectionDivider{
      background: $lightBg;
      .divider__left, .divider__right{
        background:$primaryColor;
      }
    }
    .beta-thirdContainer{
      .beta-thirdheader{
        h2{
          color: $white;
        }
      }
      .beta-thirdContentSection{
        p{
          color:$white;
        }
        ul{
          li{
            background:url(../../assets/images/checkIcon.svg);
            background-repeat: no-repeat;
            background-size: 15px
          }
        }
      }
    }
  }


  // Beta section fourth
  .beta-fourthSection{
    background:$lightBg;
    .sectionDivider{
      background: $primaryColor;
      .divider__left, .divider__right{
        background:$lightBg;
      }
    }
    .beta-fourthContainer{
      .beta-fourthHeader{
        h2{
          color: $primaryColor;
        }
        p{
          color: #000;
        }
      }
      .btnCheck{
        .homebtn{
          background: $actionBtnBg;
          border:none;
          box-shadow: 0px 5px 5px #ffc34d;
          color: $white;
        }
      }        
    }
  }

  // footer css
  .section-footer{
    border-top:1px solid #eee;
  }


  // Thank you page css
  .thankyou-section{
    .tankkyou-container{
      .descript-thankyou{
        background: rgba(256, 256, 256, 0.8);
        border: 2px solid $secondryLightColor;
      }
    }
  }

  // Privacy policy css
  .pageTitle{
    background:#f4fdfe;
  } 


 
}




// beta theme second
.beta_layout-eighth-theme{
  $primaryColor:#cb232e;
  $secondryColor:#ff4955;
  $secondryLightColor:#f1b1b7;
  $lightbg:#fef5f5;
  $white:#ffffff;
  $actionBtnBg:#FF9F0E;
  $lightBg:#fef2f2;
  
  .header-main-div{
    .navbar-light {
      .navbar-nav {
        .nav-link{
          color:#000000;
          &:hover{
            color:$primaryColor;
          }
        }
      }
    }
  }

  .header-section{
    background: transparent;
    .fixed-top{
      background:transparent !important;
      ul{
        .nav-item{
          a{
            color:$primaryColor;
            &:hover{
              color:$secondryColor;
            }
          }
        }
      }
      .lastbtn{
        .nav-link{
          background: $actionBtnBg;
          color: $white;
          box-shadow: 0px 0px 5px #ffc34d;
          &:hover{
            background-color: $primaryColor;
            color:$white;
            box-shadow:0px 5px 5px #cfdcff;
          }
        }
      }
    }
    &.pageScroll{
      .fixed-top{
        background-color: $white !important;
        box-shadow:0px 0px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  // divider css
  .sectionDivider{
    background: $white;
    .divider__left, .divider__right {
      background: $primaryColor;
    }
  }


  // Survey form css
  .betaSurvey{
    .newSurveyHeading{
      h2{
        color:$primaryColor;
      }
    }
    .betaLayoutFormContainer{
      background:$white;
      box-shadow:0px 5px 10px rgb(255, 235, 235);
      border: 2px solid $secondryLightColor;
      .questionSteps{
        .space-between{
          .MuiFormLabel-root{
            color: $primaryColor !important;     
            div{
              color: $primaryColor;
            }
          }
          .MuiFormGroup-root{
            flex-direction: row;
          }
          .inputContainer{
            border-color: $secondryLightColor !important;
            &:hover, &:focus{
              border-color:$primaryColor !important;
            }
          }
          .MuiFormControlLabel-root{
            border: 2px solid $secondryLightColor;
            background-color: $lightbg;
            &:hover{
              background:$primaryColor;
              color:$white;
              border-color:$primaryColor !important;
              .MuiFormControlLabel-label{
                color:$white;
                i{
                  color:$white !important;
                }
              }
            }
          }
          .input-box__field{
            border: 2px solid $secondryLightColor;
            &:hover, &:focus{
              border-color:$primaryColor;
            }
          }
          .customDropdown-eighth-theme_span{
            border: 2px solid $secondryLightColor !important;
            background-color: $lightbg;           
            &:hover, &.customDropdown-eighth-theme_span_selected{
              background:$primaryColor;
              color:$white;
              border-color:$primaryColor !important;
            }
          }
          
        }
      }
      .newSurveyFooter{
        .nextBtn{
          background: $actionBtnBg;
          border-color: $actionBtnBg;
          box-shadow: 0px 5px 5px #ebae33;
          &:hover{
            background-color: $primaryColor;
            border-color: $primaryColor;              
            box-shadow: 0px 5px 5px #ffcfcf;
          }
        }
      }
    }
  }

  // How it works section css
  .howItWorks{  
    background-color: $primaryColor; 
    background-image:url(../../assets/images/wave2-new.svg);
    .howItWorksContainer{
      .heading-section{
        h2{
          color:$white;
        }
        p{
          color:$white;
        }
      }
      .block-multiples{
        .single-block{
          background:transparent;
          &:hover{
            box-shadow:none;
          }
          .singleBlockImg{
            border:2px solid $secondryColor;
            &:before{
              background: $secondryLightColor;
            }
            &:after{
              background: $secondryLightColor;
            }
          }
          h4{
            color:$white;
          }
          p{
            color:$white;
          }
        }
      }
    }  
  }

  // beta Section second css
  .beta-Section-second{
    background:$lightBg;
    .sectionDivider{
      background: $primaryColor;
      .divider__left, .divider__right{
        background:$lightBg;
      }
    }
    .beta-sectionSecondContainer{
      .betaSectionContainer{
        background-color: $primaryColor;
        &:before{
          background:$primaryColor;
          background: linear-gradient(267deg, rgba(62,110,246,0.19341743533350846) 0%, $primaryColor 100%);
        }
        .heading-section{
          h2{
            color:$white;
          }
          p{
            color:$white;
          }
          .homebtn{
            background: $actionBtnBg;
            border:none;
            box-shadow: 0px 5px 5px #680e0e;
            color: $white;
            &:hover{
              background:$primaryColor;
              text-decoration:none;
              box-shadow: 0px 5px 5px #680e0e;
            }
          }
        }
      }
    }
    
  }

  // beta section third
  .beta-thirdSection{
    background:$primaryColor;
    .sectionDivider{
      background: $lightBg;
      .divider__left, .divider__right{
        background:$primaryColor;
      }
    }
    .beta-thirdContainer{
      .beta-thirdheader{
        h2{
          color: $white;
        }
      }
      .beta-thirdContentSection{
        p{
          color:$white;
        }
        ul{
          li{
            background:url(../../assets/images/checkIcon-white.svg);
            background-repeat: no-repeat;
            background-size: 15px
          }
        }
      }
    }
  }


  // Beta section fourth
  .beta-fourthSection{
    background:$lightBg;
    .sectionDivider{
      background: $primaryColor;
      .divider__left, .divider__right{
        background:$lightBg;
      }
    }
    .beta-fourthContainer{
      .beta-fourthHeader{
        h2{
          color: $primaryColor;
        }
        p{
          color: #000;
        }
      }
      .btnCheck{
        .homebtn{
          background: $actionBtnBg;
          border:none;
          box-shadow: 0px 5px 5px #ffc34d;
          color: $white;
          &:hover{
            background:$primaryColor;
            text-decoration:none;
            box-shadow: 0px 5px 5px #680e0e;
          }
        }
      }        
    }
  }

  // footer css
  .section-footer{
    border-top:1px solid #eee;
  }


  // Thank you page css
  .thankyou-section{
    .tankkyou-container{
      .descript-thankyou{
        background: rgba(256, 256, 256, 0.8);
        border: 2px solid $secondryLightColor;
      }
    }
  }

  // Privacy policy css
  .pageTitle{
    background:#fef4f4;
  } 


 
}


































// beta version New blog 2022 css
.beta-blog-theme{
  .header{
    &.active{      
      top: 0;
      z-index: 99;
      position: fixed;
      width: 100%;
      transition: .5s;
      min-height: 66px;
    }
    .row{
      width:100%;
    }
    .header-button.nav-link{
      letter-spacing: 0.5px;
      position: relative;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
  .beta-blog-layout{
    .beta-blogHeader{
      padding:50px 0px;
      background-position:right bottom;
      background-repeat:no-repeat;
      .blogHeaderText{
        width: 630px;
        max-width: 100%;
        h1{
          font-size: 52px;
          font-weight: bold;
        }
        .postDetails{
          display:inline-block;
          width:100%;
          margin-top:15px;
          .postedBy{
            float:left;
            img{
              width:14px;
              margin-right:10px;
            }
          }
          .postDate{
            float:left;
            margin-left:25px;
            img{
              width:14px;
              margin-right:10px;
            }
          }
        }
      }
      .betaBlogImg{
        width:100%;
        img{
          max-width:100%;
          border-radius:20px;
        }
      }
    }
    .beta-mainContent{
      padding:50px 0px;
      .container{
        max-width:1255px;
        .mid-content {
          .mi-section-content {
            p{
              font-size: 18px;
              line-height: 34px;
              font-weight: 300;
              letter-spacing: .5px;
            }
            h5{
              margin-top: 30px;
              margin-bottom: 0;
              strong{
                font-weight:600;
              }
            }
            h2 {
              font-size: 1.8em;
              margin-top: 3rem;
              font-weight: 500;
              strong{
                font-weight:600;
              }
            }
            .big-text{
              float: left;
              font-size: 70px;
              font-weight: 700;
              margin: 17px 0 15px;
            }
            .group-editible{
              width: 700px;
              border-radius: 20px;
              padding: 50px;
              margin: 40px auto;
              max-width: 100%;
              h3{
                text-align: center;
                margin-bottom:30px;
              }
              ul{
                display: flex;
                flex-wrap: wrap;
                li{
                  list-style: none;
                  padding:10px 15px;
                  border-radius:10px;
                  display:inline-block;
                  flex:0 0 31.3%;
                  max-width:31.3%;
                  margin:1%;
                  text-align: center;
                  transition: 0.2s;
                  a{
                    font-weight:500;
                    text-decoration:none;
                  }
                  .imgCircle {
                    width: 80px;
                    height: 80px;
                    display: block;
                    margin: auto;
                    border-radius: 50%;
                    padding: 5px;
                    .imgCircleInside{
                      border-radius:50%;
                      height:100%;
                      width:100%;
                      display: inline-block;
                      padding:10px;
                      img{
                        max-width:100%;
                      }
                    }
                  }
                  &:hover{
                    transform: scale(1.1);
                  }
                }
              }
            }
            .choosearea-section{
              text-align: center;
              .comparebtn{
                margin-top: 30px;
                margin-bottom:15px;
                display:inline-block;
                width:100%;
                a, span{
                  letter-spacing: 0.5px;
                  position: relative;
                  font-weight: 600;
                  padding: 15px 30px;
                  border-radius: 5px;
                  font-size:18px;
                  display:inline-block;                  
                }
              }
            }
            .averagetext{
              margin-top: 15px;
              p{
                font-size:12px;
                line-height: normal;
              }
            }
          }
        }
      }
    }
    .advertisement-images {
      list-style: none;
      padding: 0px 0 0;
      width: 255px;
      max-width: 100%;
      li{
        list-style:none;
        .img-responsive {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &.stick_scroll {
        position: fixed;
        padding-top: 100px;
        height: 50%;
        width: 255px;
        transition: .7s ease;
        margin-bottom: 20px;
        top:0px
      }
    }
    
  }
  .foter-bottom{
    padding:30px 0px;
    p{
      font-size:12px;
      margin-bottom:0px;
    }
    ul{
      float:right;
      margin-bottom:0px;
      li{
        display:inline-block;
        margin:0px 15px;
        font-size: 12px;
      }
    }
  }

}

.beta-blog_first-theme{
  $primaryColor:#172e6f;
  $secondryLightColor:#b1e5f1;
  $white:#ffffff;
  $actionBtnBg:#FF9F0E;
  $actionBtnshadow:#ffc34d;
 

  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }
  }


  .header{
    background:$white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    .header-button.nav-link{
      background: $actionBtnBg;
      color: $white;
      box-shadow: 0px 0px 5px $actionBtnshadow;
      &:hover{
        background-color: $primaryColor;
        color:$white;
        box-shadow:0px 5px 5px #cfdcff;
      }
    }
  }
  .beta-blog-layout{
    .beta-blogHeader{
      background-color:#f2fdfe;
      background-image:url(../../assets/images/wave2.svg);
      .blogHeaderText{
        h1{
          color: $primaryColor;
        }
      }
    }
    .beta-mainContent{
      .container{
        .mid-content {
          .mi-section-content {
            .group-editible{
              background: #f2fdfe;
              border:2px solid $secondryLightColor;
              ul{
                li{
                  background:$white;
                  border:1px solid $secondryLightColor;
                  box-shadow:0px 0px 5px $secondryLightColor;
                  a{
                    color:$primaryColor;
                  }
                  .imgCircle {
                    border: 1px solid $secondryLightColor;
                    .imgCircleInside{
                      background:$primaryColor;
                    }
                  }
                }
              }
            }
            .choosearea-section{
              .comparebtn{
                a, span{
                  background: $actionBtnBg;
                  color: $white;
                  box-shadow: 0px 0px 5px $actionBtnshadow; 
                  &:hover{
                    background:$primaryColor;
                    box-shadow: 0px 0px 5px $secondryLightColor;
                  }                
                }
              }
            }
          }
        }
      }
    }
    
  }
  .foter-bottom{
    border-top:1px solid #eee;
  }

}




.beta-blog_eighth-theme{
  $primaryColor:#cb232e;
  $secondryLightColor:#f1b1b1;
  $white:#ffffff;
  $actionBtnBg:#FF9F0E;
  $actionBtnshadow:#ffc34d;
 
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }
  }


  .header{
    background:$white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    .header-button.nav-link{
      background: $actionBtnBg;
      color: $white;
      box-shadow: 0px 0px 5px $actionBtnshadow;
      &:hover{
        background-color: $primaryColor;
        color:$white;
        box-shadow:0px 5px 5px #cfdcff;
      }
    }
  }
  .beta-blog-layout{
    .beta-blogHeader{
      background-color:#fef2f2;
      background-image:url(../../assets/images/wave2-new.svg);
      .blogHeaderText{
        h1{
          color: $primaryColor;
        }
      }
    }
    .beta-mainContent{
      .container{
        .mid-content {
          .mi-section-content {
            .group-editible{
              background: #fef2f2;
              border:2px solid $secondryLightColor;
              ul{
                li{
                  background:$white;
                  border:1px solid $secondryLightColor;
                  box-shadow:0px 0px 5px $secondryLightColor;
                  a{
                    color:$primaryColor;
                  }
                  .imgCircle {
                    border: 1px solid $secondryLightColor;
                    .imgCircleInside{
                      background:$primaryColor;
                    }
                  }
                }
              }
            }
            .choosearea-section{
              .comparebtn{
                a, span{
                  background: $actionBtnBg;
                  color: $white;
                  box-shadow: 0px 0px 5px $actionBtnshadow; 
                  &:hover{
                    background:$primaryColor;
                    box-shadow: 0px 0px 5px $secondryLightColor;
                  }                
                }
              }
            }
          }
        }
      }
    }
    
  }
  .foter-bottom{
    border-top:1px solid #eee;
  }

}









// Media css started
@media (max-width: 1365px) {
  .beta_layout-theme{
    .betaSurvey {
      .newSurveyHeading{
        h2{
          font-size:46px;
        }
      }
    }
    .beta-Section-second {
      .beta-sectionSecondContainer {
        .betaSectionContainer {
          background-size:cover;
          .heading-section{
            h2{
              font-size:46px;
              line-height:54px;
            }
            p{
              font-size:20px;
              line-height:26px;
            }
          }
        }
      }
    }
    .container{
      max-width:1240px;
    }
  }

  // blog responsive
  .beta-blog-theme{
    .beta-blog-layout{
      .container{
        max-width:1240px;
      }
      .beta-blogHeader {
        .blogHeaderText {
          h1 {
            font-size: 36px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .beta_layout-theme{
    .betaSurvey{
      .newSurveyHeading {
        h2{
          font-size:36px;
        }
      }
      .betaLayoutFormContainer{
        padding:30px;
        .progressBar {
          right: 15px;
          top: 15px;
          font-size: 12px;
          z-index: 1;
        }
        .questionSteps {
          .space-between {
            .MuiFormLabel-root{
              font-size: 20px;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn{
            font-size:20px;
          }
        }
      }
    }
    .sectionDivider{
      .divider__left, .divider__right{
        height: 30px;
      }
    }
    .howItWorks {
      .howItWorksContainer {
        padding: 20px 0px 40px 0px;
        .heading-section {
          h2{
            font-size:36px;
          }
        }
        .block-multiples {
          .single-block {
            .singleBlockImg{
              max-width:150px;
            }
          }
        }
      }
    }

    .beta-Section-second {
      .beta-sectionSecondContainer {
        padding:20px 0px 50px 0px;
        .betaSectionContainer {
          padding:40px;
          .heading-section {
            margin-bottom:0px;
            h2{
              font-size:36px;
              line-height:44px;
            }
            p{
              font-size:16px;
            }
          }
        }
      }
    }


    .beta-thirdSection {
      .beta-thirdContainer {
        .beta-thirdheader {
          h2{
            font-size:36px;
          }
        }
        .beta-thirdContentSection {
          p{
            font-size:16px;
            line-height: 24px;
          }
          ul{
            margin:15px 0px;
            li{
              margin-bottom: 15px;
            }
          }
        }
      }
    }


    .beta-fourthSection {
      .beta-fourthContainer {
        padding:30px 0px 50px 0px;
        .beta-fourthHeader {
          h2{
            font-size:36px;
          }
          p{
            font-size:16px;
            line-height:24px;
          }
        }
      }
    }

  }

  // blog responsive
  .beta-blog-theme{
    .header {
      .container{
        padding:0px;
        .row{
          margin:0px;
        }
      }
    }
    .beta-blog-layout{
      .beta-mainContent{
        .col-md-9{
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-md-3{
          display: none;
        }
      }
    }
  }
}


@media (max-width: 992px) {
  .beta_layout-theme{
    .header-section .fixed-top {
      background: #fff !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
    .betaSurvey {
      .betaLayoutFormContainer {
        padding: 15px;
        .questionSteps{
          .space-between {
            .MuiFormControlLabel-root {
              width: 100%;
              margin: 1% 0%;
            }
            .customDropdown-first-theme_span, .customDropdown-eighth-theme_span{
              width:100%;
              margin:1% 0%;
            }
          }
        }
      }
    }
    .beta-thirdSection {
      .beta-thirdContainer {
          .col-sm-6.order-md-2, .col-sm-6.order-md-1{
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
          .beta-thirdImgSection{
            margin-top:20px;
            img{
              width:100%;
            }
          }
      }
    }

    .beta-fourthSection {
      .beta-fourthContainer {
        padding: 30px 15px 50px 15px;
      }
    }

  }   
  
  // blog responsive
  .beta-blog-theme{
    .header {
      .container{
        .row{
          justify-content: space-between;
        }
      }
    }
    .beta-blog-layout {
      .beta-blogHeader{
        padding: 20px 0px;
      }
      .beta-mainContent {
        padding: 20px 0px;
        .container {
          .mid-content {
            .mi-section-content {
              p{
                font-size: 16px;
                line-height: 28px;
              }
              .group-editible{
                padding:15px;
                h3{
                  font-size:24px;
                }
                ul{
                  li{
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: 1% 0%;
                  }
                }
              }
              .choos-boollets-points{
                svg{
                  max-width:100%;
                  height: auto;
                }
                .choosearea-section{
                  .comparebtn{
                    a{
                      font-size: 16px;
                      padding: 10px 30px;                      
                    }
                  }
                }
              }
            }
          }
        }
      }      
    }
    .foter-bottom {
      ul {
        float: none;
        margin:auto;
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }


}



@media (max-width: 767px) {
  
  .beta_layout-theme {
    .betaSurvey {
      .newSurveyHeading {
        h2 {
          font-size: 28px;
        }
      }
      .betaLayoutFormContainer {
        .questionSteps {
          .space-between {
            .MuiFormControlLabel-root {
              width: 100%;
              margin: 1% 0%;
            }
            .date-box__field {
              height: 58px;
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .howItWorks {
      .howItWorksContainer {
        .heading-section{
          h2 {
            font-size: 28px;
          }
        }
        .block-multiples {
          .single-block {
            margin-bottom: 20px;
            display:inline-block;
            width:100%;
          }
        }
      }
    }

    .beta-Section-second {
      .beta-sectionSecondContainer {
        .betaSectionContainer {
          padding: 15px;
          .heading-section {
            h2{
              font-size:28px;
              line-height:34px;
            }
            .homebtn{
              font-size:20px;
            }
          }
        }
      }
    }

    .beta-thirdSection{
      .beta-thirdContainer {
        .beta-thirdheader {
          h2 {
            font-size: 28px;
          }
        }
        .beta-thirdImgSection {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    }

    .beta-fourthSection {
      .beta-fourthContainer {
        .beta-fourthHeader {
          h2 {
            font-size: 28px;
          }
        }
      }
    }
    .footer-content{
      text-align: center;
      font-size:12px;
      .custome-links {
        width:100%;
        ul{
          text-align: center;
          width: 100%;
        }
      }
    }

  }
}