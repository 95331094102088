.seventh_layout_global{
.newLayoutSurvey{
    .questionSteps{
        display:inline-block;
        width:100%;
        .textarea-box {
            padding-top:0px !important;
            textarea{
                width:100%;
                border-width:2px;
                border-radius:5px !important;
                min-height:90px !important;
            }
        }
        .topNavigationSurvey{
            margin-top:15px;
            display:inline-block;
            width:100%;
            .progressBarText{
                font-size:14px;
                font-weight:500;                
                span{
                    font-weight:500;
                }
            }
            .bar{
                display:none;
            }
        }   
        .MuiFormLabel-root {
            font-size: 20px;
            font-weight: 600;
            display: flex;
            padding:0px 0px 10px 0;
            line-height: 1;
            letter-spacing: .00938em;
            text-align: center;
            justify-content: center;
            margin-bottom: 1rem;
        }
        .radioBtnsGroup{            
            .MuiFormGroup-root{
                flex-direction: row;
                margin: 0 -1%;
                label.MuiFormControlLabel-root{
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 1%;
                    border-radius: 5px;
                    text-align: left;
                    padding: 12px;
                    transition: 0.3s;
                    padding-right:40px;
                    &:hover{
                        transform: scale(1.03);
                        transition: 0.3s;
                        &::after{
                            display:block;
                        }
                    }
                    &:after{
                        width:22px;
                        height:22px;
                        position: absolute;
                        right:10px;
                        top:19px;
                        border-radius:50%;
                        content:"";
                        background-size: 16px;
                        background-repeat: no-repeat;
                        background-position: 3px 3px;
                        display:none;
                    }
                }
            }
        }
        .dropdownMain{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: normal;
            width: 100%;
            flex-wrap: wrap;
            .dropdownMainList{
                width: 30.3333%;
                border-radius: 5px;
                background: transparent;
                margin: 1.5%;
                min-height: 80px;
                position: relative;
                transition: .1s;
                justify-content: normal;
                align-items: center;
                display: flex;
                cursor: pointer;
                flex-flow: wrap;
                font-size: 14px;
                flex-direction: column-reverse;
                &.dropdownMainListSelected, &:hover {
                    &::after{
                        display:block;

                    }
                }
                center{
                    font-weight: 500;
                    font-size: 16px;
                    padding: 0 0 17px;
                    margin-top:0px !important;
                }
                &:after{
                    width:22px;
                    height:22px;
                    position: absolute;
                    right:10px;
                    top:10px;
                    border-radius:50%;
                    content:"";
                    background-size: 16px;
                    background-repeat: no-repeat;
                    background-position: 3px 3px;
                    display:none;
                }
            }
        }
        .dynamic-form{
            width:480px;
            display:inline-block;
            max-width:100%;
            margin: 0px 15px;
            padding: 00px 0px 0px 0px;
            .space-between {
                margin: 0px 0 20px 0;
                .MuiFormLabel-root{
                    display:none !important;
                }
                input{
                    border-width:2px;
                }
            }
            .surveyFormBtnFull{
                .form_button{
                    width:100%;
                    display:inline-block;
                    padding:15px 20px;
                    border-radius:5px;
                    font-weight:500;
                    text-transform:uppercase;
                    letter-spacing:1px;
                }
            }
        }
        .select-box{
            width:480px;
            max-width:100%;
        }        
        .chkbox_survey{
            .MuiFormGroup-root{
                flex-direction: row;
                margin: 0 -1%;
                label.MuiFormControlLabel-root{
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 1%;
                    border-radius: 5px;
                    text-align: left;
                    padding: 12px;
                    transition: 0.3s;
                    padding-right:40px;
                    &:hover,&.checkbox-selected{
                        transform: scale(1.03);
                        transition: 0.3s;
                        &::after{
                            display:block;
                        }
                    }
                    &:after{
                        width:22px;
                        height:22px;
                        position: absolute;
                        right:10px;
                        top:19px;
                        border-radius:50%;
                        content:"";
                        background-size: 16px;
                        background-repeat: no-repeat;
                        background-position: 3px 3px;
                        display:none;
                    }
                    .icon-span {
                        margin-right: 10px;
                    }
                }
            }
        }  
    }
    .newSurveyFooter{
        display:inline-block;
        width:100%;
        max-width:100%;
        margin-top:20px;
        text-align:center;
        .nextBtn{
            padding:15px 20px;
            border-radius:5px;
            display:inline-block;
            text-transform: uppercase;
            letter-spacing:1px;
            font-weight:500;
            min-width:170px;
            float:none !important;
            display:inline-block !important;
        }
    }


    @media (max-width: 1200px) {
        
        .questionSteps{
            .dynamic-form{
                margin:0px;
            }
            .radioBtnsGroup{
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        padding: 10px;
                        padding-right:30px;
                        .MuiFormControlLabel-label{
                            font-size:14px;
                        }
                    }
                }
            }
            .dropdownMain {
                .dropdownMainList {
                    center{
                        font-size: 14px;
                    }
                }
            }
            .chkbox_survey{
                .MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                        padding: 10px;
                        padding-right:30px; 
                        .MuiFormControlLabel-label{
                            font-size:14px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 560px) {
        .questionSteps{
            .radioBtnsGroup {
                .MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                        max-width: 100%;
                        flex: 0 0 100%;
                        margin:5px 0px;
                    }
                }
            }
            .dropdownMain {
                .dropdownMainList{
                    width: 46%;
                    margin: 2%;
                }
            }
            .chkbox_survey {
                .MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                        max-width: 98%;
                        flex: 0 0 98%;
                        margin: 1%;
                    }
                }
            }
        }
        
    }
}





/************************************************************************
                        Theme CSS
***********************************************************************/

.layout_seventh-default-theme{
    $base-color:#44CB86;
    .newLayoutSurvey{
        .questionSteps{
            .textarea-box {
                textarea{
                    &:hover, &:focus{
                        border-color:$base-color;
                    }
                }
            }
            .topNavigationSurvey{
                .progressBarText{
                    color:#B5B5B5;
                }
            }   
            .MuiFormLabel-root {
                color:#000 !important;
            }
            .radioBtnsGroup{            
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent;
                        &:hover{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }
            .dropdownMain{
                .dropdownMainList{
                    border: 2px solid #ddd !important;
                    background: transparent !important;                
                    &.dropdownMainListSelected, &:hover {
                        border-color: $base-color !important;
                        background: #fff !important;
                        box-shadow: 0 0 5px #ccc;
                        .dropdownLIstLabel{
                            center{
                                color:$base-color;
                            }
                        }
                        
                    }
                    center{
                        color: #717171;
                    }
                    &:after{
                        background-color:$base-color;
                        content:"";
                        background-image:url(../../assets/images/yes-white.svg);
                    }
                }
            }
            .dynamic-form{
                .space-between {
                    input{
                        &.input-box__field--errord{
                            border-color:red;
                        }
                        &:hover, &:focus{
                            border-color: $base-color;
                        }
                    }
                }
                .surveyFormBtnFull{
                    .form_button{
                        background-color:$base-color;
                        border:none;
                        color:#fff;
                    }
                }
            }        
            .chkbox_survey{
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent !important;
                        &:hover,&.checkbox-selected{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff !important;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }  
        }
        .newSurveyFooter{
            .nextBtn{
                border:2px solid $base-color;
                background:transparent;
                color:$base-color;
                &:hover{
                    background:$base-color;
                    color:#fff;
                }
            }
        }
    }
}


.layout_seventh-first-theme{
    $base-color:#1b283f;
    .newLayoutSurvey{
        .questionSteps{
            .textarea-box {
                textarea{
                    &:hover, &:focus{
                        border-color:$base-color;
                    }
                }
            }
            .topNavigationSurvey{
                .progressBarText{
                    color:#B5B5B5;
                }
            }   
            .MuiFormLabel-root {
                color:#000 !important;
            }
            .radioBtnsGroup{            
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent;
                        &:hover{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }
            .dropdownMain{
                .dropdownMainList{
                    border: 2px solid #ddd !important;
                    background: transparent;                
                    &.dropdownMainListSelected, &:hover {
                        border-color: $base-color !important;
                        background: #fff !important;
                        box-shadow: 0 0 5px #ccc;
                        .dropdownLIstLabel{
                            center{
                                color:$base-color;
                            }
                        }
                    }
                    center{
                        color: #717171;
                    }
                    &:after{
                        background-color:$base-color;
                        content:"";
                        background-image:url(../../assets/images/yes-white.svg);
                    }
                }
            }
            .dynamic-form{
                .space-between {
                    input{
                        &.input-box__field--errord{
                            border-color:red;
                        }
                        &:hover, &:focus{
                            border-color: $base-color;
                        }
                    }
                }
                .surveyFormBtnFull{
                    .form_button{
                        background-color:$base-color;
                        border:none;
                        color:#fff;
                    }
                }
            }        
            .chkbox_survey{
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent !important;
                        &:hover,&.checkbox-selected{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff !important;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }  
        }
        .newSurveyFooter{
            .nextBtn{
                border:2px solid $base-color;
                background:transparent;
                color:$base-color;
                &:hover{
                    background:$base-color;
                    color:#fff;
                }
            }
        }
    }
}


.layout_seventh-second-theme{
    $base-color:#7773fa;
    .newLayoutSurvey{
        .questionSteps{
            .textarea-box {
                textarea{
                    &:hover, &:focus{
                        border-color:$base-color;
                    }
                }
            }
            .topNavigationSurvey{
                .progressBarText{
                    color:#B5B5B5;
                }
            }   
            .MuiFormLabel-root {
                color:#000 !important;
            }
            .radioBtnsGroup{            
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent;
                        &:hover{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }
            .dropdownMain{
                .dropdownMainList{
                    border: 2px solid #ddd !important;
                    background: transparent;                
                    &.dropdownMainListSelected, &:hover {
                        border-color: $base-color !important;
                        background: #fff !important;
                        box-shadow: 0 0 5px #ccc;
                        .dropdownLIstLabel{
                            center{
                                color:$base-color;
                            }
                        }
                    }
                    center{
                        color: #717171;
                    }
                    &:after{
                        background-color:$base-color;
                        content:"";
                        background-image:url(../../assets/images/yes-white.svg);
                    }
                }
            }
            .dynamic-form{
                .space-between {
                    input{
                        &.input-box__field--errord{
                            border-color:red;
                        }
                        &:hover, &:focus{
                            border-color: $base-color;
                        }
                    }
                }
                .surveyFormBtnFull{
                    .form_button{
                        background-color:$base-color;
                        border:none;
                        color:#fff;
                    }
                }
            }        
            .chkbox_survey{
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent !important;
                        &:hover,&.checkbox-selected{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff !important;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }  
        }
        .newSurveyFooter{
            .nextBtn{
                border:2px solid $base-color;
                background:transparent;
                color:$base-color;
                &:hover{
                    background:$base-color;
                    color:#fff;
                }
            }
        }
    }
}


.layout_seventh-third-theme{
    $base-color:#4094a9;
    .newLayoutSurvey{
        .questionSteps{
            .textarea-box {
                textarea{
                    &:hover, &:focus{
                        border-color:$base-color;
                    }
                }
            }
            .topNavigationSurvey{
                .progressBarText{
                    color:#B5B5B5;
                }
            }   
            .MuiFormLabel-root {
                color:#000 !important;
            }
            .radioBtnsGroup{            
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent;
                        &:hover{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }
            .dropdownMain{
                .dropdownMainList{
                    border: 2px solid #ddd !important;
                    background: transparent;                
                    &.dropdownMainListSelected, &:hover {
                        border-color: $base-color !important;
                        background: #fff !important;
                        box-shadow: 0 0 5px #ccc;
                        .dropdownLIstLabel{
                            center{
                                color:$base-color;
                            }
                        }
                    }
                    center{
                        color: #717171;
                    }
                    &:after{
                        background-color:$base-color;
                        content:"";
                        background-image:url(../../assets/images/yes-white.svg);
                    }
                }
            }
            .dynamic-form{
                .space-between {
                    input{
                        &.input-box__field--errord{
                            border-color:red;
                        }
                        &:hover, &:focus{
                            border-color: $base-color;
                        }
                    }
                }
                .surveyFormBtnFull{
                    .form_button{
                        background-color:$base-color;
                        border:none;
                        color:#fff;
                    }
                }
            }        
            .chkbox_survey{
                .MuiFormGroup-root{
                    label.MuiFormControlLabel-root{
                        color: #717171 !important;
                        border: 2px solid #c5c5c5;
                        background-color: transparent !important;
                        &:hover,&.checkbox-selected{
                            color: $base-color !important;
                            border: 2px solid $base-color;
                            background-color: #fff !important;
                        }
                        &:after{
                            background-color:$base-color;
                            background-image:url(../../assets/images/yes-white.svg);
                        }
                    }
                }
            }  
        }
        .newSurveyFooter{
            .nextBtn{
                border:2px solid $base-color;
                background:transparent;
                color:$base-color;
                &:hover{
                    background:$base-color;
                    color:#fff;
                }
            }
        }
    }
}



}