.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 55px;
  padding: 10px 10px 10px 5px;
  font-weight: 300;
  font-size: 16px;
  border: none;
  font-size: 18px !important;
  font-weight: 500;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 400;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 400;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 400;
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.inputContainer {
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 24px;
  height: 24px;
}
