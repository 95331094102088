.base-card {
  width: 100%;
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1131;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  .ProgressLoader {
    background: #fff;
    width: 600px;
    padding: 50px;
    border-radius: 7px;
    h2 {
      margin-bottom: 40px;
    }
    .progressGroup {
      display: flex;
      text-align: left;
      padding: 5px 0px;
      label {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 0px;
      }
      .progressGroupBar {
        max-width: 50%;
        flex: 0 0 50%;
        padding-right: 30px;
        position: relative;
        .MuiLinearProgress-root {
          height: 7px;
          border-radius: 5px;
          margin-top: 10px;
          .MuiLinearProgress-colorPrimary {
            background-color: rgba(19, 172, 143, 0.3);
          }
          .MuiLinearProgress-barColorPrimary {
            background-color: #13ac8f;
          }
        }
        .StatusIcons {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 20px;
          height: 20px;
          img {
            max-width: 100%;
          }

          .card-Input {
            border: 2px solid #c5c5c5 !important;
            color: black;
            padding-left: 10px !important;
            margin: 0;
            font-family: inherit;
            font-size: 13px;
            font-weight: 500;
            line-height: inherit;
            border-radius: 5px !important;
            margin-bottom: 2px !important;
            height: 35px;
            overflow: visible;
            min-width: 100%;
            box-sizing: border-box !important;
          }
        }
      }
    }
    .progressBtnBar {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 30px;
      span {
        background: #13ac8f;
        color: #fff;
        padding: 15px 40px;
        display: inline-block;
        font-weight: 500;
        border-radius: 5px;
        text-decoration: none;
        min-width: 175px;
      }
    }
  }
}

// otp popup css
.OtpPopup {
  width: 420px;
  background: #fff;
  max-width: 100%;
  border-radius: 7px;
  overflow: hidden;
  .otpHeader {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 15px 35px;
    text-align: center;
    background-color: #f47621;
    &:before{
      position:absolute;
      left:0px;
      bottom:0px;
      height:50px;
      width:100%;
      content: "";
      background-color: #fff;
    }
    .clossIcon {
      position: absolute;
      right: 20px;
      top: 0px;
      font-size: 24px;
      &:hover {
        cursor: pointer;
      }
    }
    h2 {
      font-size: 18px;
      margin-bottom: 0px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .phoneIcon{
      height: 130px;
      position: relative;
      z-index: 1;
      margin-top:20px;
    }
  }
  .otpBody {
    padding: 0px 35px 15px 35px;
    display: inline-block;
    width: 100%;
    text-align: center;    
    p{
      font-size:16px;
      color:#717171;
      line-height: normal;
    }
    label {
      width: 100%;
      font-size: 14px;
      color: #8b8b8b;
      margin-top: 10px;
    }
    input {
      font-size: 24px;
      height: 50px;
      font-weight: 600;
      text-align: center;
      width: 45px;
      max-width: 100%;
      margin: 10px;
    }
    .error-container{
      text-align: center;
    }
  }
  .otpBtnBar {
    width: 100%;
    padding: 10px 25px 35px 25px;
    text-align: center;
    .otpBtn {
      width: 50px;
      background: #f47621;
      color: #fff;
      padding: 0px 5px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      height:50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius:50px;
      border: none;
      box-shadow:0px 5px 5px #ffa061;
      transition:0.2s;
      &:hover{
        background-color:#ca5608 ;
        box-shadow:0px 5px 10px #ffa061;
      }
      img{
        width:20px;
      }
    }

    .otpTimer {
      margin-top: 10px;
      div span {
        font-family: inherit !important;
        color: #8a8a8a;
      }
      button {
        background: transparent !important;
        color: #f47621 !important;
        text-decoration: underline;
        border: none;
        text-decoration:none;
        span {
          color: rgb(19, 129, 255) !important;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}




.base-card.progressLoading{
  display: flex;
  height:100%;
  justify-content: center;
  align-items: center;
  width:100%;
} 

@media (max-width: 767px) {
  .base-card {
    padding: 15px;
    padding-top:100px;
    .ProgressLoader {
      padding: 15px;
    }
  }
}

@media (max-width: 480px) {
  .base-card {
    
    display: inline-block;
    
  }
}
