.input-box {
  &__field {
    margin: 0;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    line-height: inherit;
    padding: 5px;
    //margin: 5px;
    width: 97%;
    box-sizing: unset;
    border-radius: 5px !important;
    border: 1px solid #ced4da;
    margin-bottom: 2px !important;
    height: 35px;
    &:focus {
      background: themed("input_focus");
      border: 1px solid #007fa3;
      outline: 0;
    }
    &--error {
      border: 1px solid #db0020 !important;
    }
    &--survey {
      font-size: 18px !important;
      font-weight: 500;
    }
    &::-webkit-input-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &::-moz-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &:-ms-input-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &::placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
  }
}
