/* Remove the navbar's default margin-bottom and rounded borders */
/* Add a gray background color and some padding to the footer */
/* Hide the carousel text when the screen is less than 600 pixels wide */
/*=========top header=============*/
/*==afix====*/
/*==end afix====*/
/*====end top header=========*/
/*=====foooter========*/
/*======end foter=======*/
/*=========responsive code===========*/
/*=en dresponsiove code=============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap");
body {
  color: #000;
  // font-family: "Open Sans", sans-serif;
  // font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
}

/**new zealand map**/
svg g#NZ-NTH:hover > path#NZ-NTL,
svg g#NZ-GIS:hover > path#NZ-GIS-2,
svg g#NZ-MWT:hover > path#NZ-MWT-2,
svg g#NZ-TAS:hover > path#NZ-TAS-2,
svg g#NZ-STL:hover > path#NZ-STL-2{
  fill:#D2E9B1;
}
svg g#NZ_AUK:hover > path#NZ-AUK, 
svg g#NZ-BOP:hover > path#NZ-BOP-2,
svg g#NZ-WGN:hover > path#NZ-WGN-2,
svg g#NZ-CAN:hover > path#NZ-CAN-2{  
  fill:#BDDDB7;
}

svg g#NZ-WKO:hover > path#NZ-WKO-2,
svg g#NZ-WTC:hover > path#NZ-WTC-2{
  
  fill:#A5D4B7;
}

svg g#NZ-HKB:hover > path#NZ-HKB-2,
svg g#NZ-TKI:hover > path#NZ-TKI-2,
svg g#NZ-MBH:hover > path#NZ-MBH-2,
svg g#NZ-OTA:hover > path#NZ-OTA-2{
  fill:#BBE0B2;
}






body .group-editible ul.birthYear {
  text-align: center;
  li {
    display: inline-block;
    width: 66px;
    color: rgb(250, 255, 174);
    padding: 5px 10px;
    border-radius: 3px;
    margin: 4px 2px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: rgba($color: #000000, $alpha: 1);
    }
  }
}

body .group-editible {
  ul.selectState {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    li {
      display: inherit;
      max-width: 25%;
      padding: 0px 5px;
      flex: 0 0 25%;
    }
  }
}
.tablePopup {
  thead {
    tr {
      th {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        border: none;
        vertical-align: top;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        background: #ffffff;
        font-size: 14px;
      }
      &:hover {
        td {
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
    }
  }
}




.electicitySaving{
  display: inline-block;
  width: 100%;
  padding: 5%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);

h2{
  color: #000 !important;
  text-align: center;
  margin-top:0px !important;
  font-weight:500;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
}
p{
  text-align: center;
  letter-spacing: 1px;
  margin-bottom:30px;
}
.table-responsive{
  border-radius:5px;
}
.tablePopup{
  margin-bottom:0px;
  thead{
    tr{
      th{
        color: #fff !important;
        font-size:16px;
        span{
          color:#fff !important;
        }
      }
    }
  }
  tbody{
    tr{
      td{
        font-size:16px;
      }
    }
  }
}
}

.copyright_footer {
  text-align:left;
  span{
    text-align: left;
  }
}
.footerDisclaimer{
  padding:30px 0px 0px 0px;
  border-top:1px solid rgba(256, 256, 256, 0.3);
  margin-top:20px;
  text-align: center;
  p{
    max-width:1024px;
    width:100%;
    margin: auto;
    strong{
      font-weight: bold;
      span{
        font-weight: bold;
      }
    }
  }
  
}

.standard_blog_first-theme {
  $base_color: #1b283f;
  $box_color: #eaf2ff;
  $border_color: #c5d9ff;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //    font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //    font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          ///   font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    // font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //   font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit !important;
    //  font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #1b283f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //@at-root  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }

  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  // svg css
  svg g#AU-NT > path#AU-NT-2 {
    fill: #2a2b40;
  }
  svg g#AU-WA > path#AU-WA-2 {
    fill: #1b283f;
  }
  svg g#AU-SA > path#AU-SA-2 {
    fill: #362d40;
  }
  svg g#AU-QLD > path#AU-QLD-2 {
    fill: #673743;
  }
  svg g#AU-NSW > path#AU-NSW-2 {
    fill: #a14245;
  }
  svg g#AU-VIC > path#AU-VIC-2 {
    fill: #cd4b48;
  }
  svg g#AU-TAS > path#AU-TAS-2 {
    fill: #f05249;
  }

  //hover svg
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #d4d3d5;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #d2d3d5;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #d7d4d5;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #dcd4d5;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #e0d6d6;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e2d6d5;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e4d6d5;
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          margin: 0px 0px 5px 0;
          line-height: 58px;
        }
        p {
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

@media (max-width: 767px) {
  .group-editible::after {
    right: 0px !important;
    bottom: -60px !important;
  }
  .group-editible::before {
    left: 0px !important;
    top: -60px !important;
  }

}

/***********template standard_blog_second****************/
.standard_blog_second-theme {
  $base_color: #7773fa;
  $box_color: #f0f0ff;
  $border_color: #d3d2fd;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //  font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      // font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        //    font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //  font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //    font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //      font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit !important;
    //   font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #7773fa !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    width: 255px;
    height: 50%;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(38, 40, 105, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //  font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
    table td,
    .table th {
      padding: 0.5rem !important;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      height: 50%;
      width: 100%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

/***********template standard_blog_default****************/
.standard_blog_default-theme {
  $base_color: #13ac8f;
  $box_color: #d1f5f3;
  $border_color: #b3f7f2;
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //   font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //   font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          //     font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //    font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //       font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //  font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //  font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      // font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    // font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #13ac8f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(4, 84, 81, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }

  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

/***********template standard_blog_third****************/
.standard_blog_third-theme {
  $base_color: #4094a9;
  $box_color: #ebfbff;
  $border_color: #c0f2ff;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      // font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //   font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        //  font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          //        font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //    font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //     font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //   font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //   font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(75, 66, 66, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    // font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #4094a9 !important;
    }
  }

  .stick_scroll_demo {
    position: fixed;
  }
  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 6% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(28, 58, 76, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //font-family: "Montserrat", sans-serif ;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 12.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 12.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

/***********template standard_blog_sixth****************/
.standard_blog_sixth-theme {
  $base_color: #5c0f8b;
  $box_color: #f4e3ff;
  $border_color: #e7c1ff;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      // font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: left;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //   font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        //  font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          //        font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //    font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //     font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //   font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //   font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(75, 66, 66, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    // font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: $base_color !important;
    }
  }

  .stick_scroll_demo {
    position: fixed;
  }
  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 6% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(28, 58, 76, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //font-family: "Montserrat", sans-serif ;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  //**SVG map color update 2021**//
  svg path#AU-NT-2 {
    fill: #81057b;
  }
  svg path#AU-WA-2 {
    fill: #6e0269;
  }
  svg path#AU-SA-2 {
    fill: #91188b;
  }
  svg path#AU-QLD-2 {
    fill: #ac1192;
  }
  svg path#AU-NSW-2 {
    fill: #c20c6b;
  }
  svg path#AU-VIC-2 {
    fill: #d91958;
  }
  svg path#AU-TAS-2 {
    fill: #f80d55;
  }

  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #e0c9df;
  }
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #e4cae3;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #e8cee6;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #edcce8;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #f2cbdf;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #f7cedb;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #fecbdb;
  }
  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }

  @media (max-width: 1400px) {
    .stick_scroll {
      width: 12.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 12.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

/***********template standard_blog_third****************/
.standard_blog_fourth-theme {
  $base_color: #12122d;
  $box_color: #ffe0df;
  $border_color: #ffbfbc;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
    img {
      max-height: 50px;
    }
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      // font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    display: none;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //   font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        //  font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          //        font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //    font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //     font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //   font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //   font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(75, 66, 66, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    // padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    // font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #4094a9 !important;
    }
  }

  .stick_scroll_demo {
    position: fixed;
  }
  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .mid-content {
    .blogImg {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      img {
        max-height: none;
        // transform: translate(-18%, 0px);
        max-width: 100%;
      }
    }
  }
  .blogHeader {
    padding: 30px 0px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    // &:before {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(28, 58, 76, 0.5);
    //   position: absolute;
    //   left: 0px;
    //   top: 0px;
    //   z-index: 0;
    // }
    .blogHeaderText {
      padding: 0px 0%;
      h1 {
        font-size: 48px;
        font-weight: 600;
        padding-bottom: 10px;
        //font-family: "Montserrat", sans-serif ;
        color: #000000;
        text-align: left;
        line-height: normal;
      }
    }
    ul {
      display: inline-block;
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 0px;
          padding: 0px 10px 0px 0px;
          float: left;
          color: $base_color;
          border-right: 1px solid #ddd;
          font-size: 14px;
          & + p {
            padding-left: 10px;
            border-right: none;
          }
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 20px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  //-----------------
  .group-editible ul.coverListing {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      padding: 5px 5px;
      background: transparent;
      text-align: center;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        height: 100%;
        font-size: 16px;
        &:hover {
          border-color: $base_color;
          color: $base_color !important;
        }
        img {
          max-width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }
  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }

  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }

  @media (max-width: 1400px) {
    .stick_scroll {
      width: 12.5%;
      right: 15px;
    }
  }
  @media (max-width: 1366px) {
    .stick_scroll {
      width: 255px;
      right: 5%;
    }
  }
  @media (max-width: 1200px) {
    .stick_scroll {
      right: 2.5%;
    }
  }

  @media (max-width: 1024px) {
    .stick_scroll {
      width: 226px;
      right: 15px;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
    .navbar-nav {
      width: 100%;
      padding: 0px 10px;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
    .navbar {
      padding: 0rem 1rem;
    }
    .header.navbar {
      .navbar-brand {
        padding: 0px 0px;
        height: 70px;
        background: url(../../assets/images/my-life-logo.png);
        width: 190px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px 10px;
        margin-left: 15px;
        img {
          display: none;
        }
      }
    }
    .navbar-dark .navbar-toggler {
      top: 13px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 0px;
    }
  }
}

/***********template standard_blog_third****************/
.standard_blog_fifth-theme {
  $base_color: #262f36;
  $box_color: #ffe7df;
  $border_color: #f8ccaf;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
    img {
      max-height: 50px;
    }
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      // font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    display: none;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //   font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        //  font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          //        font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          //    font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //    font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //     font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    //   font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //   font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(75, 66, 66, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    // padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    // font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #4094a9 !important;
    }
  }

  .stick_scroll_demo {
    position: fixed;
  }
  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .mid-content {
    .blogImg {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      img {
        max-height: none;
        // transform: translate(-18%, 0px);
        max-width: 100%;
      }
    }
  }
  .blogHeader {
    padding: 30px 0px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    // &:before {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(28, 58, 76, 0.5);
    //   position: absolute;
    //   left: 0px;
    //   top: 0px;
    //   z-index: 0;
    // }
    .blogHeaderText {
      padding: 0px 0%;
      h1 {
        font-size: 48px;
        font-weight: 600;
        padding-bottom: 10px;
        //font-family: "Montserrat", sans-serif ;
        color: #000000;
        text-align: left;
        line-height: normal;
      }
    }
    ul {
      display: inline-block;
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 0px;
          padding: 0px 10px 0px 0px;
          float: left;
          color: $base_color;
          border-right: 1px solid #ddd;
          font-size: 14px;
          & + p {
            padding-left: 10px;
            border-right: none;
          }
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 20px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }
  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  //-----------------
  .group-editible ul.coverListing {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      padding: 5px 5px;
      background: transparent;
      text-align: center;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        height: 100%;
        font-size: 16px;
        &:hover {
          border-color: $base_color;
          color: $base_color !important;
        }
        img {
          max-width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 12.5%;
      right: 15px;
    }
  }
  @media (max-width: 1366px) {
    .stick_scroll {
      width: 255px;
      right: 5%;
    }
  }
  @media (max-width: 1200px) {
    .stick_scroll {
      right: 2.5%;
    }
  }

  @media (max-width: 1024px) {
    .stick_scroll {
      width: 226px;
      right: 15px;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
    .navbar-nav {
      width: 100%;
      padding: 0px 10px;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
    .navbar {
      padding: 0rem 1rem;
    }
    .header.navbar {
      .navbar-brand {
        padding: 0px 0px;
        height: 70px;
        background: url(../../assets/images/my-life-logo.png);
        width: 190px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px 10px;
        margin-left: 15px;
        img {
          display: none;
        }
      }
    }
    .navbar-dark .navbar-toggler {
      top: 13px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 0px;
    }
  }
}

/****************************template standard_blog_seventh**************/

.standard_blog_seventh-theme {
  $base_color: #1b283f;
  $box_color: #eaf2ff;
  $border_color: #c5d9ff;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //    font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //    font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          ///   font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    // font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //   font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    //  font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #1b283f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //@at-root  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }

  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  // svg css
  svg g#AU-NT > path#AU-NT-2 {
    fill: #2a2b40;
  }
  svg g#AU-WA > path#AU-WA-2 {
    fill: #1b283f;
  }
  svg g#AU-SA > path#AU-SA-2 {
    fill: #362d40;
  }
  svg g#AU-QLD > path#AU-QLD-2 {
    fill: #673743;
  }
  svg g#AU-NSW > path#AU-NSW-2 {
    fill: #a14245;
  }
  svg g#AU-VIC > path#AU-VIC-2 {
    fill: #cd4b48;
  }
  svg g#AU-TAS > path#AU-TAS-2 {
    fill: #f05249;
  }

  //hover svg
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #d4d3d5;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #d2d3d5;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #d7d4d5;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #dcd4d5;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #e0d6d6;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e2d6d5;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e4d6d5;
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          margin: 0px 0px 5px 0;
          line-height: 58px;
        }
        p {
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}


/****************************template standard_blog_eighth**************/

.standard_blog_eighth-theme {
  $base_color: #cd232e;
  $box_color: #feeeee;
  $border_color:#fed1d3;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #cb232e;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //    font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //    font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          ///   font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #000000;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    // font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: #1c1c1c;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //   font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    //  font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #1b283f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //@at-root  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }

  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  // svg css
  svg g#AU-NT > path#AU-NT-2 {
    fill: #2a2b40;
  }
  svg g#AU-WA > path#AU-WA-2 {
    fill: #1b283f;
  }
  svg g#AU-SA > path#AU-SA-2 {
    fill: #362d40;
  }
  svg g#AU-QLD > path#AU-QLD-2 {
    fill: #673743;
  }
  svg g#AU-NSW > path#AU-NSW-2 {
    fill: #a14245;
  }
  svg g#AU-VIC > path#AU-VIC-2 {
    fill: #cd4b48;
  }
  svg g#AU-TAS > path#AU-TAS-2 {
    fill: #f05249;
  }

  //hover svg
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #d4d3d5;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #d2d3d5;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #d7d4d5;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #dcd4d5;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #e0d6d6;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e2d6d5;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e4d6d5;
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          margin: 0px 0px 5px 0;
          line-height: 58px;
        }
        p {
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}

/****************************template standard_blog_ninth**************/
.standard_blog_ninth-theme {
  $base_color: #00467f;
  $box_color: #f0f0f0;
  $border_color: #c5d9ff;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #cb232e;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //    font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //    font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          ///   font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    // font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //   font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    //  font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #1b283f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //@at-root  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }

  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  // svg css
  svg g#AU-NT > path#AU-NT-2 {
    fill: #2a2b40;
  }
  svg g#AU-WA > path#AU-WA-2 {
    fill: #1b283f;
  }
  svg g#AU-SA > path#AU-SA-2 {
    fill: #362d40;
  }
  svg g#AU-QLD > path#AU-QLD-2 {
    fill: #673743;
  }
  svg g#AU-NSW > path#AU-NSW-2 {
    fill: #a14245;
  }
  svg g#AU-VIC > path#AU-VIC-2 {
    fill: #cd4b48;
  }
  svg g#AU-TAS > path#AU-TAS-2 {
    fill: #f05249;
  }

  //hover svg
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #d4d3d5;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #d2d3d5;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #d7d4d5;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #dcd4d5;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #e0d6d6;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e2d6d5;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e4d6d5;
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          margin: 0px 0px 5px 0;
          line-height: 58px;
        }
        p {
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}
 

/****************************template standard_blog_ninth**************/
.standard_blog_ninth-theme {
  $base_color: #1D2C35;
  $box_color: #fffbea;
  $border_color: #fdf5d0;

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    + {
      .container-fluid {
        padding-top: 70px;
      }
    }
  }
  label.switch.mob {
    float: right;
    margin-top: 8px;
    display: none;
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation.header.clearfix.active.affix {
    top: 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #ddd;
    z-index: 99;
  }
  .top-header {
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      a {
        color: #000;
      }
    }
    p.text-right {
      float: right;
    }
    background: #fff;
    padding: 6px 0px;
  }
  .navigation {
    .navbar.navbar-inverse {
      background: transparent;
      border: none;
    }
    //padding: 15px 0px;
  }
  #navbarResponsive {
    ul {
      li.active {
        a {
          color: #4094a9;
          background: transparent;
        }
      }
      li {
        font-size: 18px;
        text-transform: capitalize;
        a {
          color: #131313;
          &:hover {
            color: #4094a9;
          }
          &:active {
            color: #4094a9;
          }
          &:focus {
            color: #4094a9;
          }
        }
      }
    }
    .nav.navbar-nav.navbar-right {
      li {
        a {
          background: #4094a9;
          border-radius: 4px;
          padding: 10px 15px;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .header-or-banner.clearfix {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
    }
  }
  .nav.navbar-nav.navbar-right {
    li {
      .img-responsive {
        display: inline-block;
        padding: 0px 5px;
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
        li.active {
          background: #e9c87b;
          border: 1px solid #e9c87b;
          a {
            color: #000;
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .navigation.active {
    background: #fff !important;
    padding: 10px 0px;
  }
  .group-editible {
    text-align: center;
    // box-shadow: 0px 0px 6px 0px #ddd;
    padding: 50px;
    // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    background: $box_color;
    // color: #fff;
    width: 550px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 0px auto;
    max-width: 100%;
    border: 1px solid $border_color;
    h4 {
      strong {
        font-weight: 500;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 35px;
      text-align: left;
      margin-bottom: 0px;
      li {
        display: inline-block;
        width: 100%;
        a {
          // background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
          background: #fff;
          color: #717171;
          text-transform: capitalize;
          padding: 15px 25px;
          display: inline-block;
          margin: 4px 0px;
          width: 100%;
          font-size: 18px;
          text-decoration: none;
          border-radius: 5px;
          font-weight: 500;
          &:hover {
            color: #fff !important;
            // height: 40px;
            transition: height 1s ease;
            background: $base_color;
          }
        }
      }
    }
  }
  .boollets-points {
    ul {
      li {
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
        padding: 6px 0px;
      }
      padding: 0px 0px 0px 15px;
    }
  }
  .choos-boollets-points {
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $border_color;
    background: $box_color;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 5px;
  }
  .map__desc.clearfix {
    position: relative;
    top: -90px;
    width: 200px;
    left: 36px;
  }
  .comparebtn {
    text-align: center;
    margin-top: 40px;
    a, span {
      background: no-repeat;
      color: $base_color;
      font-size: 16px;
      padding: 13px 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
      border: 2px solid $base_color;
      text-transform: uppercase;
      letter-spacing: 1px;
      //    font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $base_color;
      }
    }
  }
  .averagetext {
    text-align: center;
    font-size: 14px;
    color: #777;
    background: #fff;
    padding: 15px;
    margin-top: 25px;
    word-break: break-all;
    p {
      margin-bottom: 0px;
    }
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn.btn-default {
      background: #4094a9;
      border: 1px solid #4094a9;
      color: #fff;
      font-size: 16px;
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 38px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      //    font-family: "Montserrat", sans-serif !important;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      // border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      .big-text {
        float: left;
        font-size: 70px;
        font-weight: bold;
        margin: 17px 0px 20px 0;
        color: #000;
      }
      &:last-child {
        border: none;
      }
      p {
        // font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        font-weight: 400;
        strong {
          font-weight: 500;
          color: #000;
          // font-family: "Montserrat", sans-serif !important;
        }
      }
      h5 {
        margin-top: 30px;
        margin-bottom: 0px;
        strong {
          font-weight: 500;
          color: #000;
          ///   font-family: "Montserrat", sans-serif !important;
        }
      }
      h2 {
        font-size: 1.8em;
        margin-top: 3rem;
        font-weight: 500;
        //   font-family: "Montserrat", sans-serif !important;
        strong {
          font-weight: 500;
          color: #000 !important;
        }
      }
      .boollets-points,
      .boollets-points * {
        //   font-family: "Montserrat", sans-serif !important;
        color: #625f5f;
        line-height: 1.6em;
        strong {
          font-weight: 500;
          color: #000;
          //   font-family: "Montserrat", sans-serif !important;
        }
      }
    }
  }
  .left-clinet-list {
    // font-family: "Montserrat", sans-serif !important;
    ul {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      li {
        display: inline-block;
        p {
          b {
            padding-right: 3px;
            color: #000000;
          }
          background: transparent;
          padding: 0px 8px 0px 0px;
          font-size: 14px;
          text-transform: capitalize;
          float: left;
          margin-bottom: 0px;
          margin-right: 15px;
          //     font-family: "Montserrat", sans-serif !important;
          color: #717171;
        }
      }
      padding: 0px;
      list-style: none;
    }
  }
  .aqualheight {
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
    height: 660px;
    display: table;
    padding-top: 5%;
  }
  .col-sm-6.left-sec {
    .aqualheight {
      float: right;
      text-align: center;
      margin: 0px auto;
      margin-right: 8%;
    }
    background: #f2f2f2;
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
        &:hover {
          color: #e9c87b;
        }
        &:focus {
          color: #e9c87b;
        }
        &:active {
          color: #e9c87b;
        }
      }
    }
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3.noppading.formnews {
    p {
      color: #ccc;
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: $base_color;
    font-size:12px;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      margin-bottom:0px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
          cursor: pointer;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4.text-right {
      p {
        color: #ccc;
      }
    }
    .col-sm-12.text-center{
      p{
        margin-bottom:0px;
      }
    }
  }
  .advertisement-images {
    padding: 0px;
    padding-top: 0px;
    list-style: none;
    padding-top: 25px;
    width: 255px;
    max-width: 100%;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: #4094a9;
    height: 240px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -180px;
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0px 0px 7px 1px #ddd;
    margin-bottom: 5%;
    h2 {
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: #4094a9;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
      color: #4094a9;
      text-transform: capitalize;
      font-weight: 600;
      position: relative;
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //-------------------------------------------
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .header {
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: $base_color;
  }
  //nav.header.navbar.navbar-expand-lg.navbar-dark.bg-light.active {
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
    top: 0px;
    background: $base_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    height: 80px;
  }
  .navbar-dark .navbar-toggler {
    // background: #3da4ba;
    background: transparent;
    border: 2px solid #fff;
  }
  //   .standard_blog .navbar-dark .navbar-brand {
  //     margin-left: 0px;
  //   }
  .navbar-dark {
    .navbar-nav .nav-link {
      color: #fff;
      text-transform: uppercase;
      //   font-family: "Montserrat", sans-serif !important;
      letter-spacing: 1px;
    }
    .navbar-brand {
      color: #fff;
      //margin-left: 6rem;
    }
  }

  .pd_o {
    padding: 0 1rem 0 1rem;
  }
  svg:not(:root) {
    overflow: hidden;
    width: 100%;
  }
  nav.header .row {
    /* display: block; */
    width: 100%;
    padding-left: 15px;
  }

  a.header-button.nav-link {
    background: transparent;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid #fff;
    text-transform: inherit;
    //  font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
    padding: 10px 30px;
    &:hover {
      background: #fff;
      color: #1b283f !important;
    }
  }

  .stick_scroll {
    position: fixed;
    padding-top: 80px;
    height: 50%;
    width: 255px;
    transition: 0.7s ease;
    margin-bottom: 20px;
  }

  .center-big-image {
    border: 1px solid #eee;
  }

  /*************blog page design update************************/
  .blogHeader {
    padding: 8% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .container {
      z-index: 10;
      position: relative;
      .blogHeaderText {
        padding: 0px 10%;
        h1 {
          font-size: 48px;
          font-weight: 600;
          padding-bottom: 20px;
          //@at-root  font-family: "Montserrat", sans-serif !important;
          color: #fff;
          text-align: center;
          line-height: normal;
        }
      }
    }
    ul {
      display: inline-block;
      text-align: center;
      width: 100%;
      li {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        p {
          margin: 0px 5px;
          padding: 5px 10px;
          float: left;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .blogTextaria {
    margin-top: 50px;
  }
  .blogSectionTop {
    padding-top: 0px;
  }
  .pointsListing {
    padding: 0px 0px 0px 50px;
  }
  .stick_scroll {
    padding-top: 0px;
    top: 100px;
  }

  //-----------------
  .group-editible ul.logoLIsting {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 2.5px 5px;
      background: transparent;
      a {
        border: 2px solid transparent;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          border-color: $base_color;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  // svg css
  svg g#AU-NT > path#AU-NT-2 {
    fill: #2a2b40;
  }
  svg g#AU-WA > path#AU-WA-2 {
    fill: #1b283f;
  }
  svg g#AU-SA > path#AU-SA-2 {
    fill: #362d40;
  }
  svg g#AU-QLD > path#AU-QLD-2 {
    fill: #673743;
  }
  svg g#AU-NSW > path#AU-NSW-2 {
    fill: #a14245;
  }
  svg g#AU-VIC > path#AU-VIC-2 {
    fill: #cd4b48;
  }
  svg g#AU-TAS > path#AU-TAS-2 {
    fill: #f05249;
  }

  //hover svg
  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #d4d3d5;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #d2d3d5;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #d7d4d5;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #dcd4d5;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #e0d6d6;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e2d6d5;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e4d6d5;
  }

  .group-editible ul.birthYear {
    li {
      background: $base_color;
    }
  }
  .group-editible {
    ul.selectState {
      li {
        a {
          background-color: $box_color;
          color: $base_color;
          text-align: center;
          padding: 8px 0px;
          border-color: $border_color;
          &:hover {
            background-color: $base_color;
          }
        }
      }
    }
  }
  .tablePopup {
    thead {
      tr {
        th {
          background: $base_color;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background: #ffffff;
          color: $base_color;
          border-top: 1px solid $border_color;
          a {
            color: $base_color;
          }
        }
        &:hover {
          td {
            background: $box_color;
          }
        }
      }
    }
  }
  .electicitySaving{
    .table-responsive{
      border: 1px solid $border_color;
    }
    }
  @media (max-width: 1400px) {
    .stick_scroll {
      width: 22.5%;
      right: 15px;
    }
  }

  @media (max-width: 1200px) {
    .stick_scroll {
      width: 22.5%;
    }
  }
  @media (max-width: 992px) {
    .stick_scroll {
      width: 22%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    a.header-button.nav-link {
      background: transparent;
      border-radius: 4px;
      color: #fff !important;
      display: block;
      display: inline-block;
      padding: 8px 15px;
    }
    .navbar-nav {
      display: block !important;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 28px;
    }
    .blogHeader ul li p {
      padding: 5px 5px;
      font-size: 12px;
    }
    .mid-content {
      .mi-section-content {
        .big-text {
          margin: 0px 0px 5px 0;
          line-height: 58px;
        }
        p {
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .stick_scroll {
      width: 21%;
    }
    .navbar-dark .navbar-toggler {
      position: absolute;
      right: 15px;
      top: 20px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: auto !important;
      padding-bottom: 20px;
    }
    .navbar-nav {
      display: block !important;
    }
    .top-header {
      p.text-right {
        float: none;
      }
      p {
        display: block;
        text-align: center !important;
      }
    }
    .header-or-banner.clearfix {
      background: #fff;
      position: relative;
    }
    .nav.navbar-nav.navbar-right {
      li {
        padding-left: 15px;
        .img-responsive {
          padding: 0px 5px 0px 0px;
        }
      }
    }
    .article-heading {
      h1 {
        font-size: 20px;
        //    font-family: "Montserrat", sans-serif !important;
      }
    }
    .choosearea-section {
      svg {
        width: 100%;
      }
    }
    .navbar-inverse {
      .navbar-toggle {
        margin-left: 10px;
        background: #131313;
      }
    }
    .navigation {
      padding: 4px 0px;
      background: #fff;
      a.navbar-brand {
        img.img-responsive {
          width: auto;
          height: 40px;
          padding-left: 15px;
          padding-top: 6px;
        }
      }
    }
    .navigation.active {
      padding: 0px 0px;
    }
    #navbarResponsive {
      .nav.navbar-nav.navbar-right {
        li {
          a {
            display: inline-block;
          }
        }
      }
    }
    .advertisement-images {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .stick_scroll {
      position: relative !important;
      padding-top: 0;
      width: 100%;
      height: 50%;
      transition: 0.7s ease;
      right: 0;
    }
    .advertisement-images {
      display: none;
    }
    .blogHeader .container .blogHeaderText {
      padding: 0px;
    }
    .blogHeader .container .blogHeaderText h1 {
      font-size: 22px;
    }
    .blogHeader ul li p {
      display: inline-block;
      float: none;
      margin-top: 10px;
    }
    .group-editible {
      padding: 15px;
    }
    .group-editible ul li a {
      padding: 15px;
    }
  }
}



#australiaHigh * {
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}
.maplabels {
  /*transistion:1s;*/
  /*-ms-transform: translateY(-10px);*/
  /*-webkit-transform: translateY(-10px);*/
  /*transform: translateY(-10px);*/
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.state-map:hover .maplabels {
  opacity: 1;
  /*   -ms-transform: translateY(00px);*/
  /*-webkit-transform: translateY(-0px);*/
  /*transform: translateY(-0px);*/
}
// svg g#AU-NT > path#AU-NT-2{
//   fill:#F9A618;
//   transition:0.5s;
// }
svg g#AU-NT:hover > path#AU-NT-2 {
  fill: #ccdcc7;
  transition: 0.5s;
}
svg g#AU-WA:hover > path#AU-WA-2 {
  fill: #bad8c8;
  transition: 0.5s;
}
svg g#AU-SA:hover > path#AU-SA-2 {
  fill: #cbdccd;
  transition: 0.5s;
}
svg g#AU-QLD:hover > path#AU-QLD-2 {
  fill: #d3dec5;
  transition: 0.5s;
}
svg g#AU-NSW:hover > path#AU-NSW-2 {
  fill: #dbe0c3;
  transition: 0.5s;
}
svg g#AU-VIC:hover > path#AU-VIC-2 {
  fill: #ddee9d;
  transition: 0.5s;
}
svg g#AU-TAS:hover > path#AU-TAS-2 {
  fill: #e3fbc3;
  transition: 0.5s;
}

// Blog page UI chnages 2021 ---------standard_blog_default-theme
.standard_blog_default-theme {
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_first-theme
.standard_blog_first-theme {
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }

  svg.goldGreenMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-WA-2 {
          fill:#F3EED6;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#D4AF37;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#FAF5E4;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#E1C158;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#FAF5E4;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #7DAA6A;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #EAF1E7;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #619A46;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#E5EFE1;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#438029;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#E0EBDC;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #FAF5E4;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  .group-editible{
    &.expendEditable {
      width:850px;
      .tableGrid{
        .tableGridRow{
          display: flex;
          flex-wrap: wrap;
          margin:0px -5px;
          .tableGridColumn{
            max-width:25%;
            flex:0 0 25%;
            padding:5px;
            height:100%;
            transition:0.5s;
            &:hover{
              p{
                border-color:#1b283f;
                strong{
                  color:#fff;
                  background:#1b283f;
                }
              }
            }
            p{
              border:2px solid #eaf2ff;
              border-radius:5px;
              height:100%;
              display:inline-block;
              overflow: hidden;
              line-height:18px;
              a{
                display: inline-block;
                color:#000;
              }
              strong{
                background:#eaf2ff;
                padding:15px 5px;
                text-align:center;
                font-weight:600;
                font-size:18px;
                width:100%;
                display:inline-block;
              }
              span{
                text-align:center;
                font-size:14px;
                padding: 15px 5px; 
                background:#fff;    
                min-height:60px;   
                display:inline-block;
                width:100%;   
                &:nth-of-type(2n+2){
                  background:#eee;
              }   
              }
              
            }
            
          }
        }
    }
  }
  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }


  .group-editible.expendEditable {
    .tableGrid {
      .tableGridRow{
        .tableGridColumn {
          p {
            span{
              font-size:12px;
            }
          }
        }
      }
    }
  }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
    .group-editible.expendEditable {
      .tableGrid {
        .tableGridRow{
          .tableGridColumn {
            max-width: 50%;
            flex: 0 0 50%;
            p {
              margin-bottom:0px;
              span{
                font-size:14px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_second-theme
.standard_blog_second-theme {
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_third-theme
.standard_blog_third-theme {
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }
  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_sixth-theme
.standard_blog_sixth-theme {
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 700px;
    max-width:100%;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }
  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }
  svg.goldGreenMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-WA-2 {
          fill:#F3EED6;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#D4AF37;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#FAF5E4;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#E1C158;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#FAF5E4;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #7DAA6A;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #EAF1E7;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #619A46;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#E5EFE1;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#438029;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#E0EBDC;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #FAF5E4;
        }
      }
    }
  }
  @media (min-width: 2000px) {
    .container {
      max-width: 2150px;
    }
    .navbar-dark {
      .navbar-brand {
        img {
          min-height: 100px;
        }
      }
    }
    a.header-button.nav-link {
      font-size: 22px;
      padding: 15px 50px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      height: 125px;
    }
    svg:not(:root) {
      height: 70% !important;
      width: 70% !important;
      margin: auto;
    }
    .choosearea-section {
      text-align: center;
    }
    .comparebtn a, .comparebtn span {
      font-size: 24px;
      padding: 15px 50px;
    }
    .blogHeader {
      .container {
        .blogHeaderText {
          min-height: 712px;
          border-radius: 10px;
          padding: 70px 70px 100px 70px;
          h1 {
            font-size: 4.2rem;
            line-height: normal;
          }
          ul {
            bottom: 40px;
            left: 40px;
            li {
              p {
                font-size: 1.2rem;
              }
            }
          }
        }
        .blogImg {
          .post_featured_image {
            height: 712px;
          }
        }
      }
    }
    .advertisement-images {
      width: 100%;
    }
    .stick_scroll {
      width: 507.7px;
      transition: none;
    }
    .mid-content {
      .mi-section-content {
        font-size: 1.7rem;
        .averagetext p {
          span {
            font-size: 16px !important;
            line-height: normal;
          }
        }
      }
    }
    .group-editible {
      width: 1000px;
      h4 {
        font-size: 2.5rem;
      }
      ul {
        li {
          a {
            padding: 28px 25px;
            margin: 10px 0px;
            font-size: 1.6rem;
            border-radius: 10px;
          }
        }
      }
    }
    .choos-boollets-points > div > span {
      font-size: 1.7rem !important;
    }
  }
  @media (max-width: 1440px){
    .blogHeader{
      padding-top: 40px;
      .container {
        .blogHeaderText{
          min-height: 420px;
          h1{
            font-size:38px;
            line-height:48px;
          }
        }
        .blogImg{
          top: -20px;
          .post_featured_image {
            height: 420px;
          }
        }
      }
    }
    .blogTextaria{
      margin-top:30px;
    }
  }
  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      padding:20px 20px 60px 20px;
      h1 {
        font-size: 32px;
        line-height: normal;
      }
    }
    .blogHeader .container .blogImg .post_featured_image{
      height: 382px;
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }   
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_fourth-theme
.standard_blog_fourth-theme {
  .blogHeader {
    // background-image: url(../../assets/images/dot-bg.png);
    // background-repeat: repeat-x;
    // background-size: auto;
    // padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #e8e8ee;
    transition: 0.5s;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #e8e8ee;
    transition: 0.5s;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #e7e7eb;
    transition: 0.5s;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #ebebf3;
    transition: 0.5s;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #feedec;
    transition: 0.5s;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e7e7eb;
    transition: 0.5s;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e5e5f1;
    transition: 0.5s;
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      // padding-top:80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      // padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}

// Blog page UI chnages 2021 ---------.standard_blog_fifth-theme
.standard_blog_fifth-theme {
  .blogHeader {
    // background-image: url(../../assets/images/dot-bg.png);
    // background-repeat: repeat-x;
    // background-size: auto;
    // padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg g#AU-NT:hover > path#AU-NT-2 {
    fill: #e8e8ee;
    transition: 0.5s;
  }
  svg g#AU-WA:hover > path#AU-WA-2 {
    fill: #e8e8ee;
    transition: 0.5s;
  }
  svg g#AU-SA:hover > path#AU-SA-2 {
    fill: #e7e7eb;
    transition: 0.5s;
  }
  svg g#AU-QLD:hover > path#AU-QLD-2 {
    fill: #ebebf3;
    transition: 0.5s;
  }
  svg g#AU-NSW:hover > path#AU-NSW-2 {
    fill: #feedec;
    transition: 0.5s;
  }
  svg g#AU-VIC:hover > path#AU-VIC-2 {
    fill: #e7e7eb;
    transition: 0.5s;
  }
  svg g#AU-TAS:hover > path#AU-TAS-2 {
    fill: #e5e5f1;
    transition: 0.5s;
  }
  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      // padding-top:80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      // padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}


// Blog page UI chnages 2021 ---------.standard_blog_seventh-theme
.standard_blog_seventh-theme {
  $secondryColor:#ffc316;
  .header, .foter-bottom{
    background:#1d2c35;
  }
  nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active{
    background:#1d2c35; 
  }
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
          &:hover{
            background:$secondryColor;
            color:#000 !important;
          }
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #153f82;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #BEC6D2;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#174793;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#BFC8D5;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#386cb9;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#C5CEDC;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill:#729ed9;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #CFD8E2;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #ffdf01;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#E9E3BB;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#fcc100;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#E9E3BB;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #f1a300;
      }
      &:hover{
        path#AU-TAS-2 {
          fill:#E9E3BB;
        }
      }
    }
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }

  .blogNewDesign{
    .blogHeader{
      background-image:none;
      padding:0px;
      min-height:450px;
      .container {
        position:initial;
        .blogHeaderText{
          min-height: auto;  
          background: transparent;
          box-shadow: none; 
          border-radius:0px;   
          padding:90px 0 110px;  
          h1{
            font-size: 49px;
            font-weight: bold;
            line-height: 59px;
          }   
          ul{
            padding: 0px 0px;
            position:relative;
          }
        }
        .blogImg{
          right:0px;
          top:0px;
          height:450px;
          width:59.717949%;
          border-radius:0px;
          border:none;
          &:before{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            content: '';
            background: linear-gradient(270deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0.58) 56.52%,#fff 100%);
            width: 28%;
            z-index: 0;
          }
          .post_featured_image{
            height:100%;
          }
        }
      }
    }
    .advertisement-images{
      position: relative;
      top: -100px;
      z-index: 1;
      padding-top:0px;
      &.stick_scroll{
        position:fixed;
        top:100px;
      }
      li{
        a{
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          overflow: hidden;
          width: 100%;
          display: inline-block;
          img{
            margin-bottom:0px;
          }
        }
        & + li{
          margin-top:15px;
        }
      }
    }
    .blogTextaria{
      margin-top:0px;
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
    .blogNewDesign{
      .blogHeader {
        margin-bottom:15px;
        .container {
          .blogHeaderText {
            h1 {
              font-size: 40px;
              font-weight: bold;
              line-height: 48px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
    .blogNewDesign{
      .blogHeader {
        min-height:380px;
        .container {
          .blogHeaderText {
            width:46%;
            padding:70px 0 80px;
          }
          .blogImg{
            height:380px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
    .blogNewDesign{
      .blogHeader {
        min-height:300px;
        .container {
          .blogHeaderText {
            width:50%;
            padding:50px 0 60px;
            h1 {
              font-size: 32px;
              line-height: 40px;
            }
          }
          .blogImg{
            height:300px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
    
    .blogNewDesign{
      .blogHeader {
        .container {
          .blogHeaderText {
            width:100%;
            padding:50px 63px 60px;
            h1{
              text-align:center;
            }
            ul{
              text-align: center;
            }
          }
          .blogImg{
            height:360px;
            width:100%;
            position:absolute;
            &:before{
              position: absolute;
              left: 0;
              top: -130px;
              bottom: 0px;
              content: '';
              width: 100%;
              z-index: 0;
              height: 100%;
              background: linear-gradient(180deg,#fff .08%,rgba(255,255,255,0.99) 63.1%,rgba(255,255,255,0) 100%);
            }
          }
        }
      }
      .container{
        .mid-content{
          position: relative;
          z-index: 1;
          background:#fff;
          padding:15px;
          border-radius:5px;
        }
      }
    }

  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
    .blogNewDesign{
      .blogHeader {
        .container {
          .blogHeaderText {
            width:100%;
            padding:50px 15px 60px;
            h1{
              text-align:center;
            }
            ul{
              text-align: center;
            }
          }
          .blogImg{
            
          }
        }
      }
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}


// Blog page UI chnages 2021 ---------.standard_blog_eighth-theme
.standard_blog_eighth-theme {  
  $secondryColor:#e81f1f;
  .foter-bottom{
    font-size:12px;
  }
  .foter-bottom ul li a{
    font-size:12px;
  }
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #000000;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
          &:hover{
            background:$secondryColor;
            color:#fff !important;
          }
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #000000;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #C8C8C8;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#3D0000;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#D5C8C8;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#6A0000;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#DEC8C8;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill:#910000;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #E7C8C8;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #B60000;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#EFC8C8;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#DA0000;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7C8C8;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #FF0000;
      }
      &:hover{
        path#AU-TAS-2 {
          fill:#FFC8C8;
        }
      }
    }
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}


// Blog page UI chnages 2021 ---------.standard_blog_eighth-theme
.standard_blog_ninth-theme {
  $secondryColor:#fbde07;
  .blogHeader {
    background-image: url(../../assets/images/dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto;
    padding-top: 100px;
    padding-bottom: 20px;
    &::before {
      display: none;
    }
    .container {
      .blogHeaderText {
        background: #fff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        text-align: left;
        max-width: 100%;
        padding: 38.5px 34px 60px 32.5px;
        position: relative;
        width: 38.17094%;
        z-index: 2;
        min-height: 520px;

        h1 {
          color: #000000;
          text-align: left;
          line-height: 58px;
        }
        ul {
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 0px 30px;
          li {
            p {
              margin: 0px;
              padding: 0px;
              border: none;
              color: #000;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              span {
                color: #5b87ff;
              }
              + p {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #ccc;
              }
            }
          }
        }
      }
      .blogImg {
        width: 72.265%;
        right: 15px;
        position: absolute;
        top: -50px;
        z-index: 1;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        .post_featured_image {
          height: 520px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .mid-content .mi-section-content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: #484848;
    letter-spacing: 0.5px;
  }
  .group-editible {
    position: relative;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    h4 {
      text-align: left;
      strong {
        font-weight: bold;
      }
    }
    ul {
      li {
        a {
          border: 1px solid #d5d5d5;
          font-weight: 500;
          &:hover{
            background:$secondryColor;
            color:#000 !important;
          }
        }
      }
    }

    &::before {
      content: "";
      width: 223px;
      height: 223px;
      left: -140px;
      top: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      width: 223px;
      height: 223px;
      right: -140px;
      bottom: -40px;
      position: absolute;
      z-index: -1;
      background-image: url(../../assets/images/dotted-bg.png);
      background-repeat: no-repeat;
    }
  }
  .mid-content .mi-section-content h2 strong {
    font-weight: 600;
  }
  .choos-boollets-points {
    background: none;
    border: none;
  }
  .mid-content .mi-section-content .averagetext p {
    font-size: 14px;
    line-height: 18px;
  }
  .mid-content .mi-section-content .big-text {
    margin-bottom: 15px;
  }

  svg{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #1E2D35;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #DFE1E2;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#313C31;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#E2E3E2;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#797522;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#ECEBDF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill:#C8B510;
      }
      &:hover{
        path#AU-QLD-2 {
          fill:#F7F5DD;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #FCDE05;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FFFBDB;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#F2CE05;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#FDF8DB;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #E3BA04;
      }
      &:hover{
        path#AU-TAS-2 {
          fill:#FBF5DB;
        }
      }
    }
  }

  svg.greenGoldMap{
    g#AU-WA {
      > path#AU-WA-2 {
        fill: #438029;
      }
      &:hover{
        path#AU-WA-2 {
          fill: #ECF2E9;
        }
      }
    }

    g#AU-NT{
      > path#AU-NT-2 {
        fill:#619A46;
      }
      &:hover{
        path#AU-NT-2 {
          fill:#EFF5EC;
        }
      }
    }

    g#AU-SA{
      > path#AU-SA-2 {
        fill:#7DAA6A;
      }
      &:hover{
        path#AU-SA-2 {
          fill:#F1F6EF;
        }
      }
    }

    g#AU-QLD{
      > path#AU-QLD-2 {
        fill: #E1C158;
      }
      &:hover{
        path#AU-QLD-2 {
          fill: #FCF9EE;
        }
      }
    }

    g#AU-NSW{
      > path#AU-NSW-2 {
        fill: #D4AF37;
      }
      &:hover{
        path#AU-NSW-2 {
          fill:#FBF7EA;
        }
      }
    }

    g#AU-VIC {
      > path#AU-VIC-2 {
        fill:#B29700;
      }
      &:hover{
        path#AU-VIC-2 {
          fill:#F7F4E5;
        }
      }
    }

    g#AU-TAS{
      > path#AU-TAS-2 {
        fill: #96810c;
      }
      &:hover{
        path#AU-TAS-2 {
          fill: #F4F2E6;
        }
      }
    }
  }
  svg {
    g#CA-NU {
      > path#CA-NU-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-NU-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-SK {
      > path#CA-SK-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-SK-2 {
          fill: #ffd7db;
        }
      }
    }
    g#CA-QC {
      > path#CA-QC-2 {
        fill: #b91f26;
      }
      &:hover {
        path#CA-QC-2 {
          fill: #ffd7db;
        }
      }
    }

    g#CA-NT {
      > path#CA-NT-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-NT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-ON {
      > path#CA-ON-2 {
        fill: #da2b2b;
      }
      &:hover {
        path#CA-ON-2 {
          fill: #ffc7c7;
        }
      }
    }

    g#CA-YT {
      > path#CA-YT-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-YT-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NB {
      > path#CA-NB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-MB {
      > path#CA-MB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-MB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NL {
      > path#CA-NL-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-NL-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-AB {
      > path#CA-AB-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-AB-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-PE {
      > path#CA-PE-2 {
        fill: #ee3f3f;
      }
      &:hover {
        path#CA-PE-2 {
          fill: #ffc7c7;
        }
      }
    }


    g#CA-BC {
      > path#CA-BC-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-BC-2 {
          fill: #ffc7c7;
        }
      }
    }
    g#CA-NS {
      > path#CA-NS-2 {
        fill: #f26764;
      }
      &:hover {
        path#CA-NS-2 {
          fill: #ffc7c7;
        }
      }
    }


  }

  @media (max-width: 1300px) {
    .blogHeader .container .blogHeaderText {
      min-height: 442px;
    }
  }

  @media (max-width: 1199px) {
    .blogHeader .container .blogHeaderText {
      min-height: 382px;
      h1 {
        font-size: 34px;
      }
    }
    .group-editible::before {
      width: 100px;
      height: 100px;
      left: -68px;
      top: -20px;
    }
    .group-editible::after {
      width: 100px;
      height: 100px;
      right: -68px;
      bottom: -30px;
    }
  }

  @media (max-width: 1024px) {
    .blogHeader {
      padding-top: 80px;
      background-position: left -150px;
      .container {
        .blogImg {
          top: -30px;
          .post_featured_image {
            height: 400px;
          }
        }
        .blogHeaderText {
          min-height: 400px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          padding: 20px 24px 60px 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .headerContainer {
      display: flex;
      flex-direction: column-reverse;
    }

    .blogHeader {
      padding-top: 20px;
      .container {
        .blogHeaderText {
          min-height: 100%;
          max-width: calc(100% - 20px);
          margin: -20px auto 0;
          padding: 28px 17px 26px;
          width: 100%;
        }
        .blogImg {
          right: 0;
          position: relative;
          top: 0;
          margin-top: 00px;
          width: 100%;
          .post_featured_image {
            height: 232px;
          }
        }
      }
    }

    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      padding-bottom: 5px;
    }
    .blogHeader {
      padding-top: 0px;
      .container {
        .blogHeaderText {
          padding: 18px 15px 36px;
          h1 {
            font-size: 30px;
            line-height: normal;
          }
        }
        .headerContainer {
          margin: 0px -15px;
        }
        .blogImg {
          border-radius: 0px;
        }
      }
    }
    .blogTextaria {
      margin-top: 0px;
    }
    .mid-content .mi-section-content {
      p {
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-size: 1.5em;
      }
    }
    .group-editible {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 540px) {
    .choosearea-section svg {
      height: 400px;
    }
    .group-editible h4 {
      margin-top: 20px;
    }
  }
  @media (max-width: 413px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 26px;
          }
        }
        .blogImg {
          .post_featured_image {
            height: 172px;
          }
        }
      }
    }
    .comparebtn a, .comparebtn span {
      font-size: 14px;
      padding: 13px 20px;
    }
    .choosearea-section svg {
      height: auto;
    }
    .choos-boollets-points {
      padding: 0px;
      margin: 0px;
    }
  }
  @media (max-width: 360px) {
    .navbar-dark .navbar-brand img {
      max-width: 200px;
    }
  }
}



/***Global css***/
body {
  .group-editible {
    .logoLIsting {
      &.cardImage {
        margin: -40px;
        li {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          a {
            padding: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  body {
    .group-editible {
      .logoLIsting {
        &.cardImage {
          margin: -25px;
          // li {
          //   flex: 0 0 100%;
          //   max-width: 100%;
          // }
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .header {
    .img-responsive {
      max-width: 170px;
    }
  }
}
@media (max-width: 480px) {
  table td,
  .table th {
    padding: 0.5rem !important;
  }
}










/*******************************
        Blog Theme Css
*******************************/

.BlogLayout{
  nav.header .row {
    width: 100%;
    padding-left: 15px;
  }

  .header-section{   
    .fixed-top{
      ul{
        .nav-item{
          a{
            text-transform:none;
            letter-spacing:1px;
            position: relative;
          }
          &.active{
            a{
              &:after{
                width:23px;
                height:5px;
                left:50%;
                transform: translate(-50%, 0%);
                bottom:-12px;
                content: "";
                position: absolute;
                border-radius:5px;
              }
            }
          }
          &:hover{
            a{
              &:after{
                width:23px;
                height:5px;
                left:50%;
                transform: translate(-50%, 0%);
                bottom:-12px;
                content: "";
                position: absolute;
                border-radius:5px;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      border-radius: 4px;
      color: #fff;
      text-transform: inherit;
      font-size: 14px;
      padding: 10px 30px;
      letter-spacing: 1px;
      font-weight:500;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
      z-index: 99;
      position: fixed;
      width: 100%;
      transition: .5s;
  }
  }
  .home-section-top{
    margin-bottom:70px;
    .group-editible {
      position: relative;
      background: #fff;
      border: none; 
      padding: 50px;
      width: 600px;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 80px;
      &::before{
        width: 100px;
        height: 200px;
        position: absolute;
        z-index: -1;
        left:-50px;
        top:-50px;
        opacity:0.5;
      }
      &::after{
        width: 200px;
        height: 100px;
        position: absolute;
        z-index: -1;
        right:-50px;
        bottom:-50px;
        opacity:0.5;
      }
      h4{
        font-size:30px;
        letter-spacing:1px;
        strong{
          font-weight:500;
        }
      }
      ul {
        padding: 0;
        list-style: none;
        margin-top: 35px;
        text-align: left;
        margin-bottom: 0;
        li {
          display: inline-block;
          width: 100%;
          a{
            background: #fff;
            color: #717171;
            text-transform: capitalize;
            padding: 15px 25px;
            display: inline-block;
            margin: 4px 0;
            width: 100%;
            font-size: 18px;
            text-decoration: none;
            border-radius: 5px;
            border: 1px solid #d5d5d5;
            font-weight: 500;
            &:hover {
              color: #fff !important;
              transition: height 1s ease;
             }
          }
        }
      }
    }
  }
  .blogHeader{
    .container{
      max-width: 1620px;
      .blogHeaderText{
        width:1040px;
        max-width:100%;
        margin:auto;
        text-align: center;
        margin-top:40px;
        h1{
          font-size:50px;
          letter-spacing: 1.5px;
          font-weight: 700;
          margin-bottom:20px;
        }
        ul{
          margin:0px;
          padding:0px;
          li{
            display:inline-block;
            margin: 0px 15px;
            color: #000;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            p{
              margin-bottom:0px;
            }
          }
        }
      }
      .blogImg{
        width:100%;
        display:inline-block;
        margin-bottom:30px;
        height:650px;
        .post_featured_image{
          width:100%;
          height: 100%;
          overflow: hidden;
          img{
            width:100%;
          }
        }
      }
    }
  }
  .mi-section-content{
    .big-text{
      float: left;
      font-size: 70px;
      font-weight: 700;
      margin: 0px 10px 0px 0px;
      color: #000;
      line-height: 70px;
    }
    h2{
      margin-top:40px;
      font-weight:600;
      strong{
        font-weight:600;
      }
    }
    .svgMap{
      display:inline-block;
      text-align: center;
      width:100%;
      .svg-holder{
        display:inline-block;
        position: relative;
        background: #fff;
        border: none; 
        padding: 50px;
        width: 720px;
        max-width:100%;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 20px;
        &::before{
          width: 100px;
          height: 200px;
          position: absolute;
          z-index: -1;
          right:-50px;
          bottom:-50px;
          opacity:0.5;
        }
        &::after{
          width: 200px;
          height: 100px;
          position: absolute;
          z-index: -1;
          left:-50px;
          top:-50px;
          opacity:0.5;
        }
      }
    }
    .comparebtn {
      text-align: center;
      margin-top: 40px;
      a, span {
        background: no-repeat;
        font-size: 16px;
        padding: 13px 40px;
        border-radius: 4px;
        display: inline-block;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 500;
        &:hover{
          color:#fff;
        }
      }
    }
    .averagetext{
      margin-top:20px;
      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        color: #484848;
        letter-spacing: .5px;
        strong{
          font-weight:600;
          color:#000;
        }
      }
    }
    
  }

  .footer{
    padding:30px 0px;
    position: relative;
    color:#ffffff;
    &::after{
      position: absolute;
      left:0px;
      bottom:100%;
      background-size: cover;
      content: "";
      width:100%;
      height: 86px;
    }
    .custome-links{
      ul{
        float:right;
        li{
          list-style: none;
          float:left;
          margin-left:25px;
        }
      }
    }
  }

  .advertisement-images {
    list-style: none;
    padding: 25px 0 0;
    width: 255px;
    max-width: 100%;
    z-index: 1;
    &.stick_scroll {
      position: fixed;
      padding-top: 0;
      top: 90px;
      height: 50%;
      width: 255px;
      transition: .7s ease;
      margin-bottom: 20px;
    }
    img{
      max-width:100%;
    }
  }


  @media (max-width: 1440px) {
    .footer{
      font-size:12px;
      &::after{
        height: 66px;
      }
    }
  }
  @media (max-width: 1200px) {
    .advertisement-images{
      &.stick_scroll {
        width: 22.5%;
      }
    } 
    .footer{
      &::after{
        height: 40px;
      }
    }  
    .home-section-top {
      .group-editible{
        max-width:100%;
      }
    } 
  }

  @media (max-width: 992px) {
    .navbar-dark{
      .navbar-toggler {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }
    .blogHeader{
      .container {
        .blogHeaderText{
          h1{
            font-size:40px;
          }
        }
        .blogImg{
          height: auto;
        }
      }
    }
    .advertisement-images{
      &.stick_scroll {
        width: 22%;
      }
    }
    .mi-section-content{
      .svgMap {
        .svg-holder{
          svg{
          width:100%;
          height: auto;
        }
      }
      }
    }
  }
  @media (max-width: 767px) {
    .advertisement-images {
      display: none;
     }
  }
  @media (max-width: 560px) {
    .blogHeader {
      .container {
        .blogHeaderText {
          h1 {
            font-size: 32px;
          }
        }
      }
    }
    .mi-section-content {
      h2 {
        font-size: 24px;
      }
      .svgMap {
        .svg-holder{
          padding:10px;
        }
      }
    }
    .home-section-top{
      .group-editible {
        padding: 15px;
        h4 {
          font-size: 20px;
          margin-top:10px;
        }
        ul{
          margin-top: 15px;
        }
      }
    }
  }
}


/*************************************
     Blog theme green Color 2021
*************************************/
.blog_default-theme{
  $primaryColor:#212837;
  $secondryColor:#1FC560;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}


/*************************************
     Blog theme Purple Color 2021
*************************************/
.blog_default-theme-v2{
  $primaryColor:#212837;
  $secondryColor:#7773fa;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}


/*************************************
  Blog theme Sunset Orange Color 2021
*************************************/
.blog_default-theme-v3{
  $primaryColor:#212837;
  $secondryColor:#ff4e44;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}


/*************************************
     Blog theme Orange Color 2021
*************************************/
.blog_default-theme-v4{
  $primaryColor:#212837;
  $secondryColor:#f47621;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}


/*************************************
Blog theme Mountain Meadow Color 2021
*************************************/
.blog_default-theme-v5{
  $primaryColor:#212837;
  $secondryColor:#13ac8f;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}

/*************************************
Blog theme Aqua Pearl Color 2021
*************************************/
.blog_default-theme-v6{
  $primaryColor:#212837;
  $secondryColor:#4094a9;
  @mixin shadow{
    box-shadow:0px 0px 15px rgba(92, 135, 228, 0.2);
  }
  @mixin smallDotts{
    background-image: radial-gradient($secondryColor 20%, transparent 20%), radial-gradient($secondryColor 20%, transparent 20%);
    background-color: transparent;
    background-position: 0px 1px, 0px 1px;
    background-size: 14px 14px;
    content: "";
  }
  @mixin backgroundDotts {
    background-image: radial-gradient($secondryColor 8%, transparent 8%), radial-gradient($secondryColor 8%, transparent 8%);
    background-color:transparent;
    background-position: 0px 0px, 0px 0px;
    background-size: 25px 25px;
  }
  @mixin h2{
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  @mixin p {
    font-size:16px;
    letter-spacing:0.8px;
    line-height:30px;
  }
  @mixin button{
    background: $secondryColor;
    text-transform: none;
    letter-spacing: 1px;
    border-radius: 3px;
    color: #fff;
    padding: 12px 40px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    border:none;
  }


  .header-section{
    background: $primaryColor;    
    .fixed-top{
      background: $primaryColor;
      ul{
        .nav-item{
          &.active{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
          &:hover{
            a{
              color: $secondryColor;
              &:after{
                background:$secondryColor;
              }
            }
          }
        }
      }
    }
    a.header-button.nav-link{
      background: $secondryColor;
      border: 2px solid $secondryColor;
    }
    nav.header.navbar.navbar-expand-lg.navbar-dark.bg-ligth.active {
      top: 0;
      background:$primaryColor;
  }
  }
  .home-section-top{
    .group-editible {
      @include shadow;
      &::before{
        @include smallDotts;
      }
      &::after{
        @include smallDotts;
      }
      ul {
        li {
          a{
            &:hover {
              background:$secondryColor;
              border-color:$secondryColor;
             }
          }
        }
      }
    }
  }
  .mi-section-content{
    @include p;
    p{
      @include p;
    }
    h2{
      @include h2;
    }
    .svgMap{
      .svg-holder{
        @include shadow;
        &::before{
          @include smallDotts;
        }
        &::after{
          @include smallDotts;
        }
      }
    }
    .comparebtn {
      a, span {
        color: $secondryColor;
        border: 2px solid $secondryColor;
        &:hover{
          background:$secondryColor;
        }
      }
    }
  
  }

  .footer{
    background: $primaryColor;
    // &::after{
    //   background:url(../../assets/images/footer-curve.svg);
    // }
  }
}