@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap");
/*h1, h2, h3, h4, h5, h6{
    color:#030B33;
}
p{
    color:#030B33;
}*/
/*header.header-section {
    margin-top: 3%;
}*/
/*==================media============================*/
@media (min-width: 1201px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px !important;
  }
}
.MuiDialog-paperWidthSm {
  width: 600px;
}

body {
  // font-family: "Work Sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: left !important;
  background-color: #fff !important;
}
body * {
  // font-family: "Work Sans", sans-serif !important;
}
body {
  color: #000;
  // font-family: "Open Sans", sans-serif;
}
.loader-div {
  height: 100%;
  text-align: center;
  margin: auto;
  padding: 15%;
}
.layout-fourth-default-theme {
  $base_color: #13ac8f;
  @mixin banner-color {
    background: transparent
      linear-gradient(284deg, $base_color 0%, #1a84bba1 100%) 0% 0% no-repeat
      padding-box;
  }

  body {
    color: #000;
    //  font-family: "Open Sans", sans-serif;
  }
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a,
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    top: 20px;
    z-index: 9999 !important;
    & + .container-fluid {
      padding-top: 70px;
    }
  }
  label {
    &.switch {
      &.mob {
        float: right;
        margin-top: 8px;
        display: none;
      }
    }
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation {
    padding: 15px 0px;
    &.header {
      &.clearfix {
        &.active {
          &.affix {
            top: 0px;
            background: #fff;
            box-shadow: 0px 0px 12px 0px #ddd;
            z-index: 99;
            #navbarResponsive {
              ul {
                li {
                  a {
                    color: #131313;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar {
      &.navbar-inverse {
        background: transparent;
        border: none;
      }
    }
    &.active {
      background: #fff !important;
      padding: 10px 0px;
    }
  }
  .top-header {
    background: #fff;
    padding: 6px 0px;
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      &.text-right {
        float: right;
      }
      a {
        color: #000;
      }
    }
  }
  #navbarResponsive {
    ul {
      li {
        font-size: 18px;
        text-transform: capitalize;
        &.active {
          a {
            color: #e9c87b;
            background: transparent;
          }
        }
        a {
          color: #fff;
        }
      }
    }
    .nav {
      &.navbar-nav {
        &.navbar-right {
          li {
            a {
              background: #e9c87b;
              border-radius: 4px;
              padding: 10px 15px;
              color: #fff !important;
              margin-top: 5px;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .header-or-banner {
    &.clearfix {
      width: 100%;
      z-index: 9;
      box-shadow: none;
      position: absolute;
    }
  }
  .active {
    .navbar-header {
      .img-responsive {
        width: auto;
        height: 52px;
      }
    }
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
      .img-responsive {
        max-height: 70px;
      }
    }
  }
  .form-wrapper {
    &.nav-stacked {
      background: #fff;
      padding: 40px;
      border-radius: 15px;
      width: 100%;
      float: right;
      position: relative;
      top: 0px;
      margin-left: 28px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      .btn {
        &.btn-primary {
          margin: 20px 0px;
          background: #e9c87b;
          border: 1px solid #e9c87b;
          font-size: 16px;
        }
      }
      h5 {
        font-size: 30px;
        text-align: left;
        padding-bottom: 13px;
        margin-top: 0px;
        color: #636363;
        line-height: 38px;
      }
    }
  }
  .brands-images {
    background: #f9fbff;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    .featureeText {
      color: #8a8a8a;
      font-size: 25px;
      width: 1034px;
      margin: auto;
      text-align: center;
      max-width: 100%;
    }
    .textContent {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
      img {
        float: left;
        max-width: 170px;
      }
      p {
        margin-left: 200px;
        text-align: left;
        color: #8a8a8a;
        font-size: 18px;
        span {
          color: #8a8a8a !important;
          font-size: 18px !important;
        }
      }
    }
    ul {
      list-style: none;
      margin-top: 8%;
      box-shadow: 0px 0px 7px 0px #bbb;
      padding: 15px 12px;
      text-align: center;
    }
    .seperation-new {
      padding: 20px 0px;
    }
  }
  .quickeasy {
    //background: url("https://cdn.databowlpages.com/assets/54b8676c-ddcf-4ee1-8aa7-033e66e8ccfd.jpg");
    background-position: left top;
    background-size: cover;
    position: relative;
    color: #8a8a8a;
    padding: 3% 0%;
    margin-top: 0%;
    // &::before {
    //   content: "";
    //   background: #1b4a98;
    //   background: -moz-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: -webkit-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
    //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
    //   height: 100%;
    //   position: absolute;
    //   width: 100%;
    //   top: 0px;
    // }
    .image-new-brnad {
      text-align: center;
      background: #fff;
      width: 50%;
      margin: 0px auto;
      border-radius: 22px;
      padding: 15px 0px;
      margin-top: 17px;
      .img-responsive {
        width: 100px;
        height: auto;
        margin: 0px auto;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      font-size: 25px;
      font-weight: 600;
      li {
        padding: 6px 0px;
        .fa {
          margin-right: 9px;
        }
      }
    }
  }
  .content-footer {
    background: #fff;
    color: #8a8a8a;
    font-size: 16px;
    padding: 0% 0% 2% 0%;
  }
  .copyright-footer {
    background: #000;
    color: #fff;
    position: relative;
    z-index: 9999;
    padding: 15px 0px;
    p {
      margin: 0px;
      padding: 0px;
    }
    .col-sm-6 {
      &:last-child {
        text-align: right;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin: 0px;
      li {
        display: inline-block;
        padding-left: 20px;
        a {
          color: #fff;
        }
      }
    }
  }
  .seperation-new {
    text-align: center;
    .getlink {
      vertical-align: middle;
      display: inline-flex;
      padding-top: 5px;
      // a {
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   font-size: 18px;
      //   padding: 7px 20px;
      //   border-radius: 4px;
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   font-size: 16px;
      // }
    }
    .row {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }
  }
  .paragraph-alignment-new {
    .getlink {
      text-align: center;
      padding-top: 24px;
      // a {
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   padding: 7px 20px;
      //   font-size: 16px;
      //   border-radius: 4px;
      //   display: inline-block;
      // }
    }
  }
  .brands-section {
    h2 {
      font-size: 40px;
      color: #333;
      width: 700px;
      max-width: 100%;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 50px;
    }
    .sideContent {
      & > span {
        font-size: 25px !important;
        color: #818181 !important;
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        width: 100%;
        li {
          width: 100%;
          list-style: none;
          padding-left: 30px;
          position: relative;
          color: #8a8a8a;
          font-size: 18px;
          margin: 10px 0px;
          &:before {
            background-image: url(../../assets/images/list-bullet.png);
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            left: 0px;
            top: 10px;
            content: "";
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .brands-images {
      h4 {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0px;
      }
      li {
        width: 140px;
        display: inline-block;
        margin: 0px 15px;
        padding: 5px 0px;
      }
    }
  }
  .step2 {
    &.form-custome-new {
      display: none;
    }
  }
  .mystyle {
    .step2 {
      &.form-custome-new {
        display: block;
      }
    }
    .step1 {
      &.form-custome-new {
        display: none;
      }
    }
  }
  .lock-icon {
    &.d-inline-flex {
      color: #777;
      margin-bottom: 0px;
    }
  }
  .form-group {
    text-align: left;
    label {
      font-weight: 500;
      color: #3b3b3b;
      .mendatory {
        color: red;
      }
    }
  }
  .form-container {
    text-align: center;
  }
  .checkbox {
    text-align: left;
  }
  .form-step-text {
    text-align: left;
    font-size: 12px;
    color: #636363;
    line-height: normal;
    margin-top: -20px;
  }
  .container-footer {
    text-align: center;
    font-size: 12px;
  }
  #navbarResponsive ul li a:hover,
  #navbarResponsive ul li a:active,
  #navbarResponsive ul li a:focus {
    color: #e9c87b !important;
  }
  .nav {
    &.navbar-nav {
      &.navbar-right {
        li {
          .img-responsive {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
  }
  .hero {
    min-height: 100vh;
    .form-wrapper {
      &.stuck {
        .form-container {
          position: fixed;
          top: 20px;
          width: inherit;
          max-width: inherit;
        }
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
          &.active {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .group-editible {
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 20px;
    background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    color: #fff;
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 15px;
      li {
        display: inline-block;
        width: 100%;
        a {
          background: #fff;
          color: #4094a9;
          text-transform: capitalize;
          padding: 7px 15px;
          display: inline-block;
          margin: 4px 0px;
          width: 200px;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }
  .boollets-points {
    ul {
      padding: 0px 0px 0px 15px;
      li {
        padding: 6px 0px;
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
      }
    }
  }
  .choos-boollets-points {
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 30px;
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .map__desc {
    &.clearfix {
      position: relative;
      top: -90px;
      width: 200px;
      left: 36px;
    }
  }
  .comparebtn {
    text-align: center;
    a {
      background: #4094a9;
      color: #fff;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .averagetext {
    text-align: center;
    font-size: 15px;
    color: #777;
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn {
      &.btn-default {
        background: #4094a9;
        border: 1px solid #4094a9;
        color: #fff;
        font-size: 16px;
      }
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 30px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      &:last-child {
        border: none;
      }
    }
  }
  .left-clinet-list {
    ul {
      padding: 0px;
      list-style: none;
      li {
        p {
          background: #e1e1e1;
          padding: 10px 8px;
          font-size: 16px;
          text-transform: capitalize;
          b {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .aqualheight {
    height: 660px;
    display: table;
    padding-top: 5%;
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .col-sm-6 {
    &.left-sec {
      background: #f2f2f2;
      .aqualheight {
        float: right;
        text-align: center;
        margin: 0px auto;
        margin-right: 8%;
      }
    }
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .banner-home {
    &.hero {
      width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      // background-image: url("https://cdn.databowlpages.com/assets/7a051f70-35ea-4022-8226-a766e526a741.jpg");
      background-size: cover;
      background-position: center center;
      position: relative;
      background-attachment: fixed;
      background-size: cover;
      &::before {
        content: "";
        background: #1b4a98;
        // background: transparent linear-gradient(119deg, #5D59F5 0%, #57D9E6 100%) 0% 0% no-repeat padding-box;
        @include banner-color;
        opacity: 0.8;
        // background: -moz-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .waveImg {
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -1px;
        height: 100%;
        background: url(../../assets/images/wave.png);
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
  }
  .heading-banner {
    padding-top: 18%;
    h1 {
      font-weight: 600;
      color: #fff;
      font-size: 50px;
    }
    h5 {
      margin-top: 20px;
      strong {
        font-size: 22px;
        font-weight: 400;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      color: #fff;
      font-size: 20px;
      padding-top: 20px;
      .fa {
        padding-right: 10px;
      }
    }
    .bg-trans {
      font-size: 18px;
      background: rgba(255, 218, 132, 0.4);
      color: #fff;
      font-style: italic;
      font-weight: 600;
      padding: 20px;
      border-radius: 20px;
      margin-top: 50px;
    }
  }
  .text-contentbanner {
    padding-top: 10%;
    display: flex;
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
      }
    }
  }
  .footer-section ul a:hover,
  .footer-section ul a:focus,
  .footer-section ul a:active {
    color: #e9c87b;
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3 {
    &.noppading {
      &.formnews {
        p {
          color: #ccc;
        }
      }
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: #4094a9;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4 {
      &.text-right {
        p {
          color: #ccc;
        }
      }
    }
  }
  .advertisement-images {
    padding: 0pc;
    padding-top: 0pc;
    list-style: none;
    padding-top: 25px;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: $base_color;
    height: 280px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -130px;
    background: #fff;
    padding: 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 5%;
    min-height: 44.9em;
    border-radius: 15px;
    h2 {
      color: #333;
      text-transform: capitalize;
      // font-weight: 700;
      position: relative;
      font-size: 40px;
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: $base_color;
        position: absolute;
        bottom: -5px;
        left: 0;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      width: 100%;
      li {
        width: 100%;
        list-style: none !important;
        padding-left: 30px;
        position: relative;
        color: rgb(102, 102, 102) !important;
        font-size: 18px;
        margin: 5px 0px;
        &:before {
          background-image: url(../../assets/images/list-bullet.png);
          background-repeat: no-repeat;
          background-position: left top;
          position: absolute;
          left: 0px;
          top: 10px;
          content: "";
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    ul {
      li {
        padding: 5px 0px;
      }
    }
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //Custom css
  a.active.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.inactive.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .navigation {
    transition: 0.5s ease;
  }
  .navigation.active {
    background: $base_color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $base_color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }
  .dynamic-form {
    width: auto;
  }
  .controls {
    background: #f9f9f9;
  }
  .navigation .row {
    width: 100%;
  }
  .header-section .fixed-top ul .nav-item a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.header-button.nav-link {
    // background: #e9c87b;
    // border-radius: 4px;
    // color: #fff !important;
    // opacity: 1;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 9px 30px;
    text-transform: uppercase;
  }

  .navbar-brand {
    margin-right: 0px;
    .img-responsive {
      max-height: 70px;
    }
  }
  .dynamic-form {
    margin: 0px -15px;
    padding: 0px;
    flex-direction: inherit;
    flex-wrap: wrap;
    .space-between {
      padding: 0px 15px;
      margin: 5px 0 5px 0;
      &.half-width {
        width: 50%;
        .date-box__field {
          width: 100%;
          color: #7c7d7d;
        }
      }
      .radio_box_root {
        border: none !important;
        .MuiFormControl-root {
          padding: 0px !important;
          label {
            font-weight: 500 !important;
          }
        }
      }
      .MuiButtonBase-roo {
        height: 60px;
      }
    }
  }
  span.anchorLink {
    color: cornflowerblue;
    cursor: pointer;
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 700;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 11px 25px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    border: 2px solid $base_color;
    &:hover {
      background: #fff;
      color: $base_color;
      border: 2px solid $base_color;
    }
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: #06124e;
    border: 1px solid #06124e;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 40px 0px 0px 0px;
    font-size: 18px;
  }

  /************new css 2020***************/
  .testimonialsArea {
    p {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 40px !important;
      color: #333333 !important;
      span {
        font-size: 40px !important;
        color: #333333 !important;
      }
      strong {
        font-size: 40px !important;
        color: #333333 !important;
        font-weight: 500;
      }
    }
  }
  .content-footer {
    p.testimonialsList {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
        display: inline-block;
        width: 100%;
      }
    }
    blockquote {
      border-radius: 15px;
      border: 1px solid #d4d4d4;
      background: #fff;
      padding: 30px;
      background-image: url(../../assets/images/quote.png);
      background-repeat: no-repeat;
      background-position: 30px 20px;
      text-align: left;
      padding: 50px 50px 50px 150px;
      font-size: 20px;
      color: #8a8a8a;
      min-height: 230px;
    }
  }

  .getlink {
    a {
      background: $base_color;
      color: #fff;
      padding: 15px 40px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: $base_color;
        border: 2px solid $base_color;
      }
    }
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }

  .header {
    .navbar-nav {
      li {
        a.nav-link {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          text-transform: uppercase;
          color: #eaeaea;
          font-weight: 500;
          cursor: pointer;
          &.header-button {
            &:hover {
              background: #fff;
              color: $base_color;
            }
          }
        }
        &.lastbtn {
          padding-left: 20px;
        }
      }
    }
  }

  .innerPageContent {
    .container {
      max-width: 100% !important;
    }
  }

  .surveyForm {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: calc(100vh - 234px);
    padding: 0px 15px;
    margin-top: -100px;
    .form-survey-section {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      padding: 50px 50px 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        // min-height: 300px;
        .questionSteps {
          .MuiFormLabel-root {
            font-size: 20px;
            padding-left: 4px !important;
          }
        }
      }
    }

    input.input-box__field.input-box__field--survey.false,
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
    .date-box__field--survey {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid #c5c5c5;
      border-left: none;
    }
    .date-box__field--survey_hoverColor {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid $base_color;
      border-left: none;
      font-size: 16px !important;
      font-weight: 500;
    }
    .newSurveyFooter {
      display: inline-block;
      width: 100%;
      padding-top: 0;
      .backBtn {
        border: 2px solid #ccc;
        color: #ccc;
        padding: 12px 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 0;
      }
      .nextBtn {
        border: 2px solid $base_color;
        color: #fff;
        padding: 12px 30px;
        text-transform: uppercase;
        background: $base_color;
        letter-spacing: 1px;
        border-radius: 0;
      }
    }
    .progressBar {
      background: $base_color;
      position: absolute;
      bottom: 0;
      height: 40px;
      left: 0;
      width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      text-align: center;
      .progressBarContainer {
        height: 40px;
        overflow: hidden;
        margin: auto 0;
        display: inline-block;
        .progressBarText {
          color: #fff;
          font-size: 15px;
          display: block;
          background-repeat: no-repeat;
          background-position: 50%;
          margin-right: 14px;
          height: 40px;
          line-height: 40px;
          float: left;
        }
        .bar {
          width: 200px;
          border-radius: 2px;
          height: 8px;
          float: left;
          margin-top: 16px;
          border: 1px solid #fff;
          border-radius: 5px;
          span {
            background-color: #fff;
            height: 8px;
            border-radius: 2px;
            float: left;
          }
        }
      }
    }
  }
  .space-between {
    .input-box__field {
      width: 100%;
      height: 45px;
    }
  }

  button.btn.btn-submit {
    /* background-color: #fff; */
    border: 2px solid $base_color;
    border-radius: 0px;
    color: $base_color;
    padding: 12px 30px;
    text-transform: uppercase;
    background: #fff;
    letter-spacing: 1px;
    border-radius: 2px;
  }

  input.date-box__field.false {
    height: 47px;
    width: -webkit-fill-available;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: darken($base_color, 5%);
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $base_color;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;
      /* Icon */
      &:hover {
        background: darken($base_color, 5%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: darken($base_color, 5%);
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: darken($base_color, 5%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }
  //******** Accordian css end**********//

  /*=============media============*/

  @media (max-width: 1200px) {
    .form-wrapper.nav-stacked {
      width: auto;
      margin-right: 15px;
    }
    .navigation.active {
      position: fixed;
    }
  }

  @media (max-width: 1024px) {
    .heading-banner {
      padding-top: 22%;
      h1 {
        font-size: 40px;
      }
      h5 {
        strong {
          font-size: 20px;
        }
      }
    }
    .form-wrapper {
      &.nav-stacked {
        margin-left: 0px;
        margin-right: 0px;
        padding: 20px;
        max-height: calc(100vh - 150px);
        h5 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .dynamic-form {
      .space-between {
        margin: 7px 0 7px 0;
      }
    }
    .brands-section {
      padding-top: 50px;
      .sideContent {
        span {
          line-height: normal;
          font-size: 20px !important;
        }
        ul {
          li {
            margin: 7px 0px;
          }
        }
      }
    }

    .brands-images {
      .featureeText {
        font-size: 20px;
        line-height: 26px;
      }
      .seperation-new {
        .row {
          vertical-align: top;
          align-items: normal;
          .textContent {
            img {
              max-width: 120px;
            }
            p {
              margin-left: 135px;
            }
          }
        }
      }
    }

    .content-footer {
      blockquote {
        background-size: 40px;
        background-position: 15px 15px;
        padding: 15px 15px 15px 70px;
        font-size: 18px;
        min-height: 170px;
      }
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-image: none !important;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .text-contentbanner {
      padding-top: 8rem;
      // display: block !important;
    }
    .col-md-4.right-side {
      max-width: 100% !important;
    }
    .col-sm-8.left-side {
      max-width: 100%;
      padding-top: 30px;
    }
    section.brands-section .row {
      display: block;
    }
    section.brands-section .col-sm-8 {
      max-width: 100%;
      width: 100% !important;
    }
    .paragraph-alignment-new .row .col-sm-6 {
      max-width: 100%;
    }
    .quickeasy .row {
      display: block;
      text-align: center;
    }
    .brands-images .seperation-new .col-sm-4 {
      /* max-width: 100%; */
      display: inline-block;
      vertical-align: middle;
    }
    .header-or-banner.clearfix {
      background: #000;
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      padding: 7px 15px;
      display: inline-block;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }

    .navbar-collapse .navbar-nav:last-child {
      display: block;
      padding-bottom: 20px;
    }
    .quickeasy .col-sm-8 {
      max-width: 100%;
      margin-left: 0px;
    }
    a.inactive.nav-link {
      color: #fff !important;
    }
    a.header-button.nav-link {
      background: #e9c87b;
      text-align: center;
      margin-left: 20px;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      // background: #131313;
      position: absolute;
      right: 15px;
      top: 12px;
    }
    .form-wrapper.nav-stacked {
      float: none;
    }
    .text-contentbanner .col-sm-8.left-side {
      width: 100%;
    }
    .heading-banner {
      padding-bottom: 40px;
    }
    .banner-home.hero {
      padding-bottom: 30px;
    }
    .brands-section .col-sm-8 {
      width: 100%;
    }
    .quickeasy .col-sm-4 {
      width: 50%;
    }
    .content-footer .col-sm-4 {
      width: 50%;
    }
    .content-footer .row .col-sm-4 {
      max-width: 100%;
      width: 100%;
    }
    // .content-footer .row {
    //   display: block;
    // }
    // .navigation.active {
    //   position: relative;
    // }
    .navbar-brand {
      .img-responsive {
        max-height: 50px;
      }
    }
    .banner-home.hero {
      min-height: auto;
      .waveImg {
        background-size: auto;
      }
    }
    .text-contentbanner {
      display: block;
      .left-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .heading-banner {
          padding-top: 15%;
        }
      }
      .right-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .form-wrapper {
          &.nav-stacked {
            width: 600px;
            max-width: 100%;
            margin: auto;
          }
        }
      }
    }
    .brands-section {
      .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .sideContent {
          margin-top: 20px;
        }
      }
    }

    .form-wrapper {
      &.nav-stacked {
        h5 {
          font-size: 34px;
          line-height: 38px;
        }
      }
    }
    .content-footer {
      blockquote {
        min-height: 200px;
      }
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      background: transparent;
      text-align: left;
      margin-left: 0px;
      border: none;
      padding-left: 0px;
    }
    .navbar-collapse .navbar-nav:last-child {
      padding-bottom: 0px;
    }
    .surveyForm {
      margin-bottom: 40px;
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-size: contain;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
    .navigation {
      padding: 10px 15px !important;
    }
  }
  @media (max-width: 767px) {
    .top-header p.text-right {
      float: none;
    }
    .copyright-footer .row {
      display: block;
    }
    .copyright-footer .row .col-sm-6 {
      max-width: 100%;
      text-align: center;
      padding: 5px 15px;
    }
    .quickeasy .col-sm-4 {
      width: 100%;
      text-align: center;
    }
    .header-or-banner.clearfix {
      background: #fff;
    }
    .content-footer .col-sm-4 {
      width: 100%;
    }
    #navbarResponsive ul li a {
      color: #131313;
    }
    .quickeasy ul {
      line-height: normal;
      padding-top: 20px;
    }
    .nav.navbar-nav.navbar-right li {
      padding-left: 15px;
    }

    .header-or-banner.clearfix {
      // position: relative;
    }
    .article-heading h1 {
      font-size: 20px;
    }
    .choosearea-section svg {
      width: 100%;
    }
    .navbar-inverse .navbar-toggle {
      margin-left: 10px;
      background: #131313;
    }
    .nav.navbar-nav.navbar-right li .img-responsive {
      padding: 0px 5px 0px 0px;
    }
    .top-header p {
      display: block;
      text-align: center !important;
    }
    .navigation {
      padding: 12px 10px;
      background: $base_color;
    }
    .navigation a.navbar-brand img.img-responsive {
      width: auto;
      height: 40px;
      padding-left: 15px;
      padding-top: 6px;
      position: relative;
      top: -5px;
    }
    .navigation.active {
      padding: 10px 10px;
      // position: relative;
    }
    #navbarResponsive .nav.navbar-nav.navbar-right li a {
      display: inline-block;
    }
    .text-contentbanner {
      width: 100%;
      padding-top: 2rem;
      .heading-banner {
        h1 {
          font-size: 30px;
        }
        h5 strong {
          font-size: 18px;
        }
      }
    }
    .form-wrapper.nav-stacked {
      h5 {
        font-size: 26px;
        line-height: 30px;
      }
    }
    .brands-section {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .brands-images {
      padding: 15px;
      .seperation-new {
        .row {
          .textContent {
            img {
              float: none;
              margin: auto;
            }
            p {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .paragraph-alignment-new .getlink {
      padding-top: 0px;
    }
    .testimonialsArea h2 strong {
      font-size: 30px !important;
    }
    .form-wrapper.nav-stacked {
      max-height: none;
    }
    .content-innerpages {
      padding: 15px;
    }
    .bgbluew {
      height: 200px;
    }
    .bototmmcontent strong {
      font-weight: 500;
    }
    .bototmmcontent h2 {
      font-size: 24px !important;
      margin-top: 15px !important;
      &::after {
        display: none;
      }
    }
    .bototmmcontent p {
      word-break: break-word;
    }
    .content-innerpages h2 {
      font-size: 30px;
    }
    .surveyForm {
      padding: 15px !important;
      background-image: none !important;
      min-height: auto;
      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .brands-images .seperation-new .col-sm-4 img.img-responsive {
      width: 100%;
    }
    .content-footer blockquote {
      min-height: auto;
    }
    .innerpage .container {
      padding: 0px;
    }
    .bototmmcontent .row .col-sm-12 {
      padding: 0px 0px !important;
      h3 {
        font-weight: 400 !important;
        margin-bottom: 20px;
        margin-top: 0px;
      }
    }
    .innerPageContent {
      padding-top: 20px !important;
    }
    .surveyForm .form-survey-section {
      padding: 15px 15px 60px;
    }
    .newSurveyHeading {
      font-size: 1.5rem;
    }
    .surveyForm
      .form-survey-section
      .newSurveyContent
      .questionSteps
      .MuiFormLabel-root {
      font-size: 16px;
      font-weight: 500;
    }
    .surveyForm .progressBar .progressBarContainer .bar {
      width: 120px;
    }
  }
}

/******layout first css*********/

.layout-fourth-first-theme {
  $base_color: #1b283f;
  @mixin banner-color {
    background: transparent
      linear-gradient(119deg, #1b283f 0%, rgba(19, 111, 172, 0.180392)) 0% 0%
      no-repeat padding-box;
  }

  body {
    color: #000;
    //  font-family: "Open Sans", sans-serif;
  }
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a,
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    top: 20px;
    z-index: 9999 !important;
    & + .container-fluid {
      padding-top: 70px;
    }
  }
  label {
    &.switch {
      &.mob {
        float: right;
        margin-top: 8px;
        display: none;
      }
    }
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation {
    padding: 15px 0px;
    &.header {
      &.clearfix {
        &.active {
          &.affix {
            top: 0px;
            background: #fff;
            box-shadow: 0px 0px 12px 0px #ddd;
            z-index: 99;
            #navbarResponsive {
              ul {
                li {
                  a {
                    color: #131313;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar {
      &.navbar-inverse {
        background: transparent;
        border: none;
      }
    }
    &.active {
      background: #fff !important;
      padding: 10px 0px;
    }
  }
  .top-header {
    background: #fff;
    padding: 6px 0px;
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      &.text-right {
        float: right;
      }
      a {
        color: #000;
      }
    }
  }
  #navbarResponsive {
    ul {
      li {
        font-size: 18px;
        text-transform: capitalize;
        &.active {
          a {
            color: #e9c87b;
            background: transparent;
          }
        }
        a {
          color: #fff;
        }
      }
    }
    .nav {
      &.navbar-nav {
        &.navbar-right {
          li {
            a {
              background: #e9c87b;
              border-radius: 4px;
              padding: 10px 15px;
              color: #fff !important;
              margin-top: 5px;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .header-or-banner {
    &.clearfix {
      width: 100%;
      z-index: 9;
      box-shadow: none;
      position: absolute;
    }
  }
  .active {
    .navbar-header {
      .img-responsive {
        width: auto;
        height: 52px;
      }
    }
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
      .img-responsive {
        max-height: 70px;
      }
    }
  }
  .form-wrapper {
    &.nav-stacked {
      background: #fff;
      padding: 40px;
      border-radius: 15px;
      width: 100%;
      float: right;
      position: relative;
      top: 0px;
      margin-left: 28px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      .btn {
        &.btn-primary {
          margin: 20px 0px;
          background: #e9c87b;
          border: 1px solid #e9c87b;
          font-size: 16px;
        }
      }
      h5 {
        font-size: 30px;
        text-align: left;
        padding-bottom: 13px;
        margin-top: 0px;
        color: #636363;
        line-height: 38px;
      }
    }
  }
  .brands-images {
    background: #f9fbff;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    .featureeText {
      color: #8a8a8a;
      font-size: 25px;
      width: 1034px;
      margin: auto;
      text-align: center;
      max-width: 100%;
    }
    .textContent {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
      img {
        float: left;
        max-width: 170px;
      }
      p {
        margin-left: 200px;
        text-align: left;
        color: #8a8a8a;
        font-size: 18px;
        span {
          color: #8a8a8a !important;
          font-size: 18px !important;
        }
      }
    }
    ul {
      list-style: none;
      margin-top: 8%;
      box-shadow: 0px 0px 7px 0px #bbb;
      padding: 15px 12px;
      text-align: center;
    }
    .seperation-new {
      padding: 20px 0px;
    }
  }
  .quickeasy {
    //background: url("https://cdn.databowlpages.com/assets/54b8676c-ddcf-4ee1-8aa7-033e66e8ccfd.jpg");
    background-position: left top;
    background-size: cover;
    position: relative;
    color: #8a8a8a;
    padding: 3% 0%;
    margin-top: 0%;
    // &::before {
    //   content: "";
    //   background: #1b4a98;
    //   background: -moz-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: -webkit-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
    //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
    //   height: 100%;
    //   position: absolute;
    //   width: 100%;
    //   top: 0px;
    // }
    .image-new-brnad {
      text-align: center;
      background: #fff;
      width: 50%;
      margin: 0px auto;
      border-radius: 22px;
      padding: 15px 0px;
      margin-top: 17px;
      .img-responsive {
        width: 100px;
        height: auto;
        margin: 0px auto;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      font-size: 25px;
      font-weight: 600;
      li {
        padding: 6px 0px;
        .fa {
          margin-right: 9px;
        }
      }
    }
  }
  .content-footer {
    background: #fff;
    color: #8a8a8a;
    font-size: 16px;
    padding: 0% 0% 2% 0%;
  }
  .copyright-footer {
    background: #000;
    color: #fff;
    position: relative;
    z-index: 9999;
    padding: 15px 0px;
    p {
      margin: 0px;
      padding: 0px;
    }
    .col-sm-6 {
      &:last-child {
        text-align: right;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin: 0px;
      li {
        display: inline-block;
        padding-left: 20px;
        a {
          color: #fff;
        }
      }
    }
  }
  .seperation-new {
    text-align: center;
    .getlink {
      vertical-align: middle;
      display: inline-flex;
      padding-top: 5px;
      // a {
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   font-size: 18px;
      //   padding: 7px 20px;
      //   border-radius: 4px;
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   font-size: 16px;
      // }
    }
    .row {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }
  }
  .paragraph-alignment-new {
    .getlink {
      text-align: center;
      padding-top: 24px;
      // a {
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   padding: 7px 20px;
      //   font-size: 16px;
      //   border-radius: 4px;
      //   display: inline-block;
      // }
    }
  }
  .brands-section {
    h2 {
      font-size: 40px;
      color: #333;
      width: 700px;
      max-width: 100%;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 50px;
    }
    .sideContent {
      & > span {
        font-size: 25px !important;
        color: #818181 !important;
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        width: 100%;
        li {
          width: 100%;
          list-style: none;
          padding-left: 30px;
          position: relative;
          color: #8a8a8a;
          font-size: 18px;
          margin: 10px 0px;
          &:before {
            background-image: url(../../assets/images/list-bullet.png);
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            left: 0px;
            top: 10px;
            content: "";
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .brands-images {
      h4 {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0px;
      }
      li {
        width: 140px;
        display: inline-block;
        margin: 0px 15px;
        padding: 5px 0px;
      }
    }
  }
  .step2 {
    &.form-custome-new {
      display: none;
    }
  }
  .mystyle {
    .step2 {
      &.form-custome-new {
        display: block;
      }
    }
    .step1 {
      &.form-custome-new {
        display: none;
      }
    }
  }
  .lock-icon {
    &.d-inline-flex {
      color: #777;
      margin-bottom: 0px;
    }
  }
  .form-group {
    text-align: left;
    label {
      font-weight: 500;
      color: #3b3b3b;
      .mendatory {
        color: red;
      }
    }
  }
  .form-container {
    text-align: center;
  }
  .checkbox {
    text-align: left;
  }
  .form-step-text {
    text-align: left;
    font-size: 12px;
    color: #636363;
    line-height: normal;
    margin-top: -20px;
  }
  .container-footer {
    text-align: center;
    font-size: 12px;
  }
  #navbarResponsive ul li a:hover,
  #navbarResponsive ul li a:active,
  #navbarResponsive ul li a:focus {
    color: #e9c87b !important;
  }
  .nav {
    &.navbar-nav {
      &.navbar-right {
        li {
          .img-responsive {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
  }
  .hero {
    min-height: 100vh;
    .form-wrapper {
      &.stuck {
        .form-container {
          position: fixed;
          top: 20px;
          width: inherit;
          max-width: inherit;
        }
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
          &.active {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .group-editible {
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 20px;
    background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    color: #fff;
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 15px;
      li {
        display: inline-block;
        width: 100%;
        a {
          background: #fff;
          color: #4094a9;
          text-transform: capitalize;
          padding: 7px 15px;
          display: inline-block;
          margin: 4px 0px;
          width: 200px;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }
  .boollets-points {
    ul {
      padding: 0px 0px 0px 15px;
      li {
        padding: 6px 0px;
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
      }
    }
  }
  .choos-boollets-points {
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 30px;
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .map__desc {
    &.clearfix {
      position: relative;
      top: -90px;
      width: 200px;
      left: 36px;
    }
  }
  .comparebtn {
    text-align: center;
    a {
      background: #4094a9;
      color: #fff;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .averagetext {
    text-align: center;
    font-size: 15px;
    color: #777;
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn {
      &.btn-default {
        background: #4094a9;
        border: 1px solid #4094a9;
        color: #fff;
        font-size: 16px;
      }
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 30px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      &:last-child {
        border: none;
      }
    }
  }
  .left-clinet-list {
    ul {
      padding: 0px;
      list-style: none;
      li {
        p {
          background: #e1e1e1;
          padding: 10px 8px;
          font-size: 16px;
          text-transform: capitalize;
          b {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .aqualheight {
    height: 660px;
    display: table;
    padding-top: 5%;
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .col-sm-6 {
    &.left-sec {
      background: #f2f2f2;
      .aqualheight {
        float: right;
        text-align: center;
        margin: 0px auto;
        margin-right: 8%;
      }
    }
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .banner-home {
    &.hero {
      width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      // background-image: url("https://cdn.databowlpages.com/assets/7a051f70-35ea-4022-8226-a766e526a741.jpg");
      background-size: cover;
      background-position: center center;
      position: relative;
      background-attachment: fixed;
      background-size: cover;
      &::before {
        content: "";
        background: #1b4a98;
        // background: transparent linear-gradient(119deg, #5D59F5 0%, #57D9E6 100%) 0% 0% no-repeat padding-box;
        @include banner-color;
        opacity: 0.8;
        // background: -moz-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .waveImg {
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -1px;
        height: 100%;
        background: url(../../assets/images/wave.png);
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
  }
  .heading-banner {
    padding-top: 18%;
    h1 {
      font-weight: 600;
      color: #fff;
      font-size: 50px;
    }
    h5 {
      margin-top: 20px;
      strong {
        font-size: 22px;
        font-weight: 400;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      color: #fff;
      font-size: 20px;
      padding-top: 20px;
      .fa {
        padding-right: 10px;
      }
    }
    .bg-trans {
      font-size: 18px;
      background: rgba(255, 218, 132, 0.4);
      color: #fff;
      font-style: italic;
      font-weight: 600;
      padding: 20px;
      border-radius: 20px;
      margin-top: 50px;
    }
  }
  .text-contentbanner {
    padding-top: 10%;
    display: flex;
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
      }
    }
  }
  .footer-section ul a:hover,
  .footer-section ul a:focus,
  .footer-section ul a:active {
    color: #e9c87b;
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3 {
    &.noppading {
      &.formnews {
        p {
          color: #ccc;
        }
      }
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: #4094a9;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4 {
      &.text-right {
        p {
          color: #ccc;
        }
      }
    }
  }
  .advertisement-images {
    padding: 0pc;
    padding-top: 0pc;
    list-style: none;
    padding-top: 25px;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: $base_color;
    height: 280px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -130px;
    background: #fff;
    padding: 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 5%;
    min-height: 44.9em;
    border-radius: 15px;
    h2 {
      color: #333;
      text-transform: capitalize;
      // font-weight: 700;
      position: relative;
      font-size: 40px;
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: $base_color;
        position: absolute;
        bottom: -5px;
        left: 0;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      width: 100%;
      li {
        width: 100%;
        list-style: none !important;
        padding-left: 30px;
        position: relative;
        color: rgb(102, 102, 102) !important;
        font-size: 18px;
        margin: 5px 0px;
        &:before {
          background-image: url(../../assets/images/list-bullet.png);
          background-repeat: no-repeat;
          background-position: left top;
          position: absolute;
          left: 0px;
          top: 10px;
          content: "";
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    ul {
      li {
        padding: 5px 0px;
      }
    }
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //Custom css
  a.active.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.inactive.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .navigation {
    transition: 0.5s ease;
  }
  .navigation.active {
    background: $base_color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $base_color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }
  .dynamic-form {
    width: auto;
  }
  .controls {
    background: #f9f9f9;
  }
  .navigation .row {
    width: 100%;
  }
  .header-section .fixed-top ul .nav-item a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.header-button.nav-link {
    // background: #e9c87b;
    // border-radius: 4px;
    // color: #fff !important;
    // opacity: 1;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 9px 30px;
    text-transform: uppercase;
  }

  .navbar-brand {
    margin-right: 0px;
    .img-responsive {
      max-height: 70px;
    }
  }
  .dynamic-form {
    margin: 0px -15px;
    padding: 0px;
    flex-direction: inherit;
    flex-wrap: wrap;
    .space-between {
      padding: 0px 15px;
      margin: 5px 0 5px 0;
      &.half-width {
        width: 50%;
        .date-box__field {
          width: 100%;
          color: #7c7d7d;
        }
      }
      .radio_box_root {
        border: none !important;
        .MuiFormControl-root {
          padding: 0px !important;
          label {
            font-weight: 500 !important;
          }
        }
      }
      .MuiButtonBase-roo {
        height: 60px;
      }
    }
  }
  span.anchorLink {
    color: cornflowerblue;
    cursor: pointer;
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 700;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 11px 25px;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    border: 2px solid $base_color;
    &:hover {
      background: #fff;
      color: $base_color;
      border: 2px solid $base_color;
    }
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: #06124e;
    border: 1px solid #06124e;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 40px 0px 0px 0px;
    font-size: 18px;
  }

  /************new css 2020***************/
  .testimonialsArea {
    p {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 40px !important;
      color: #333333 !important;
      span {
        font-size: 40px !important;
        color: #333333 !important;
      }
      strong {
        font-size: 40px !important;
        color: #333333 !important;
        font-weight: 500;
      }
    }
  }
  .content-footer {
    p.testimonialsList {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
        display: inline-block;
        width: 100%;
      }
    }
    blockquote {
      border-radius: 15px;
      border: 1px solid #d4d4d4;
      background: #fff;
      padding: 30px;
      background-image: url(../../assets/images/quote.png);
      background-repeat: no-repeat;
      background-position: 30px 20px;
      text-align: left;
      padding: 50px 50px 50px 150px;
      font-size: 20px;
      color: #8a8a8a;
      min-height: 230px;
    }
  }

  .getlink {
    a {
      background: $base_color;
      color: #fff;
      padding: 15px 40px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: $base_color;
        border: 2px solid $base_color;
      }
    }
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }

  .header {
    .navbar-nav {
      li {
        a.nav-link {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          text-transform: uppercase;
          color: #eaeaea;
          font-weight: 500;
          cursor: pointer;
          &.header-button {
            &:hover {
              background: #fff;
              color: $base_color;
            }
          }
        }
        &.lastbtn {
          padding-left: 20px;
        }
      }
    }
  }

  .innerPageContent {
    .container {
      max-width: 100% !important;
    }
  }

  .surveyForm {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: calc(100vh - 234px);
    padding: 0px 15px;
    margin-top: -100px;
    .form-survey-section {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      padding: 50px 50px 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        // min-height: 300px;
        .questionSteps {
          .MuiFormLabel-root {
            font-size: 20px;
            padding-left: 4px !important;
          }
        }
      }
    }

    input.input-box__field.input-box__field--survey.false,
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
    .date-box__field--survey {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid #c5c5c5;
      border-left: none;
    }
    .date-box__field--survey_hoverColor {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid $base_color;
      border-left: none;
      font-size: 16px !important;
      font-weight: 500;
    }
    .newSurveyFooter {
      display: inline-block;
      width: 100%;
      padding-top: 0;
      .backBtn {
        border: 2px solid #ccc;
        color: #ccc;
        padding: 12px 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 0;
      }
      .nextBtn {
        border: 2px solid $base_color;
        color: #fff;
        padding: 12px 30px;
        text-transform: uppercase;
        background: $base_color;
        letter-spacing: 1px;
        border-radius: 0;
      }
    }
    .progressBar {
      background: $base_color;
      position: absolute;
      bottom: 0;
      height: 40px;
      left: 0;
      width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      text-align: center;
      .progressBarContainer {
        height: 40px;
        overflow: hidden;
        margin: auto 0;
        display: inline-block;
        .progressBarText {
          color: #fff;
          font-size: 15px;
          display: block;
          background-repeat: no-repeat;
          background-position: 50%;
          margin-right: 14px;
          height: 40px;
          line-height: 40px;
          float: left;
        }
        .bar {
          width: 200px;
          border-radius: 2px;
          height: 8px;
          float: left;
          margin-top: 16px;
          border: 1px solid #fff;
          border-radius: 5px;
          span {
            background-color: #fff;
            height: 8px;
            border-radius: 2px;
            float: left;
          }
        }
      }
    }
  }
  .space-between {
    .input-box__field {
      width: 100%;
      height: 45px;
    }
  }

  button.btn.btn-submit {
    /* background-color: #fff; */
    border: 2px solid $base_color;
    border-radius: 0px;
    color: $base_color;
    padding: 12px 30px;
    text-transform: uppercase;
    background: #fff;
    letter-spacing: 1px;
    border-radius: 2px;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: darken($base_color, 5%);
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $base_color;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;
      /* Icon */
      &:hover {
        background: darken($base_color, 5%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: darken($base_color, 5%);
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: darken($base_color, 5%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }
  //******** Accordian css end**********//

  /*=============media============*/

  input.date-box__field.false {
    height: 47px;
    width: -webkit-fill-available;
  }
  @media (max-width: 1200px) {
    .form-wrapper.nav-stacked {
      width: auto;
      margin-right: 15px;
    }
    .navigation.active {
      position: fixed;
    }
  }

  @media (max-width: 1024px) {
    .heading-banner {
      padding-top: 22%;
      h1 {
        font-size: 40px;
      }
      h5 {
        strong {
          font-size: 20px;
        }
      }
    }
    .form-wrapper {
      &.nav-stacked {
        margin-left: 0px;
        margin-right: 0px;
        padding: 20px;
        max-height: calc(100vh - 150px);
        h5 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .dynamic-form {
      .space-between {
        margin: 7px 0 7px 0;
      }
    }
    .brands-section {
      padding-top: 50px;
      .sideContent {
        span {
          line-height: normal;
          font-size: 20px !important;
        }
        ul {
          li {
            margin: 7px 0px;
          }
        }
      }
    }

    .brands-images {
      .featureeText {
        font-size: 20px;
        line-height: 26px;
      }
      .seperation-new {
        .row {
          vertical-align: top;
          align-items: normal;
          .textContent {
            img {
              max-width: 120px;
            }
            p {
              margin-left: 135px;
            }
          }
        }
      }
    }

    .content-footer {
      blockquote {
        background-size: 40px;
        background-position: 15px 15px;
        padding: 15px 15px 15px 70px;
        font-size: 18px;
        min-height: 170px;
      }
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-image: none !important;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .text-contentbanner {
      padding-top: 8rem;
      // display: block !important;
    }
    .col-md-4.right-side {
      max-width: 100% !important;
    }
    .col-sm-8.left-side {
      max-width: 100%;
      padding-top: 30px;
    }
    section.brands-section .row {
      display: block;
    }
    section.brands-section .col-sm-8 {
      max-width: 100%;
      width: 100% !important;
    }
    .paragraph-alignment-new .row .col-sm-6 {
      max-width: 100%;
    }
    .quickeasy .row {
      display: block;
      text-align: center;
    }
    .brands-images .seperation-new .col-sm-4 {
      /* max-width: 100%; */
      display: inline-block;
      vertical-align: middle;
    }
    .header-or-banner.clearfix {
      background: #000;
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      padding: 7px 15px;
      display: inline-block;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }

    .navbar-collapse .navbar-nav:last-child {
      display: block;
      padding-bottom: 20px;
    }
    .quickeasy .col-sm-8 {
      max-width: 100%;
      margin-left: 0px;
    }
    a.inactive.nav-link {
      color: #fff !important;
    }
    a.header-button.nav-link {
      background: #e9c87b;
      text-align: center;
      margin-left: 20px;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      // background: #131313;
      position: absolute;
      right: 15px;
      top: 12px;
    }
    .form-wrapper.nav-stacked {
      float: none;
    }
    .text-contentbanner .col-sm-8.left-side {
      width: 100%;
    }
    .heading-banner {
      padding-bottom: 40px;
    }
    .banner-home.hero {
      padding-bottom: 30px;
    }
    .brands-section .col-sm-8 {
      width: 100%;
    }
    .quickeasy .col-sm-4 {
      width: 50%;
    }
    .content-footer .col-sm-4 {
      width: 50%;
    }
    .content-footer .row .col-sm-4 {
      max-width: 100%;
      width: 100%;
    }
    // .content-footer .row {
    //   display: block;
    // }
    // .navigation.active {
    //   position: relative;
    // }
    .navbar-brand {
      .img-responsive {
        max-height: 50px;
      }
    }
    .banner-home.hero {
      min-height: auto;
      .waveImg {
        background-size: auto;
      }
    }
    .text-contentbanner {
      display: block;
      .left-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .heading-banner {
          padding-top: 15%;
        }
      }
      .right-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .form-wrapper {
          &.nav-stacked {
            width: 600px;
            max-width: 100%;
            margin: auto;
          }
        }
      }
    }
    .brands-section {
      .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .sideContent {
          margin-top: 20px;
        }
      }
    }

    .form-wrapper {
      &.nav-stacked {
        h5 {
          font-size: 34px;
          line-height: 38px;
        }
      }
    }
    .content-footer {
      blockquote {
        min-height: 200px;
      }
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      background: transparent;
      text-align: left;
      margin-left: 0px;
      border: none;
      padding-left: 0px;
    }
    .navbar-collapse .navbar-nav:last-child {
      padding-bottom: 0px;
    }
    .surveyForm {
      margin-bottom: 40px;
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-size: contain;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
    .navigation {
      padding: 10px 15px !important;
    }
  }
  @media (max-width: 767px) {
    .top-header p.text-right {
      float: none;
    }
    .copyright-footer .row {
      display: block;
    }
    .copyright-footer .row .col-sm-6 {
      max-width: 100%;
      text-align: center;
      padding: 5px 15px;
    }
    .quickeasy .col-sm-4 {
      width: 100%;
      text-align: center;
    }
    .header-or-banner.clearfix {
      background: #fff;
    }
    .content-footer .col-sm-4 {
      width: 100%;
    }
    #navbarResponsive ul li a {
      color: #131313;
    }
    .quickeasy ul {
      line-height: normal;
      padding-top: 20px;
    }
    .nav.navbar-nav.navbar-right li {
      padding-left: 15px;
    }

    .header-or-banner.clearfix {
      // position: relative;
    }
    .article-heading h1 {
      font-size: 20px;
    }
    .choosearea-section svg {
      width: 100%;
    }
    .navbar-inverse .navbar-toggle {
      margin-left: 10px;
      background: #131313;
    }
    .nav.navbar-nav.navbar-right li .img-responsive {
      padding: 0px 5px 0px 0px;
    }
    .top-header p {
      display: block;
      text-align: center !important;
    }
    .navigation {
      padding: 12px 10px;
      background: $base_color;
    }
    .navigation a.navbar-brand img.img-responsive {
      width: auto;
      height: 40px;
      padding-left: 15px;
      padding-top: 6px;
      position: relative;
      top: -5px;
    }
    .navigation.active {
      padding: 10px 10px;
      // position: relative;
    }
    #navbarResponsive .nav.navbar-nav.navbar-right li a {
      display: inline-block;
    }
    .text-contentbanner {
      width: 100%;
      padding-top: 2rem;
      .heading-banner {
        h1 {
          font-size: 30px;
        }
        h5 strong {
          font-size: 18px;
        }
      }
    }
    .form-wrapper.nav-stacked {
      h5 {
        font-size: 26px;
        line-height: 30px;
      }
    }
    .brands-section {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .brands-images {
      padding: 15px;
      .seperation-new {
        .row {
          .textContent {
            img {
              float: none;
              margin: auto;
            }
            p {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .paragraph-alignment-new .getlink {
      padding-top: 0px;
    }
    .testimonialsArea h2 strong {
      font-size: 30px !important;
    }
    .form-wrapper.nav-stacked {
      max-height: none;
    }
    .content-innerpages {
      padding: 15px;
    }
    .bgbluew {
      height: 200px;
    }
    .bototmmcontent strong {
      font-weight: 500;
    }
    .bototmmcontent h2 {
      font-size: 24px !important;
      margin-top: 15px !important;
      &::after {
        display: none;
      }
    }
    .bototmmcontent p {
      word-break: break-word;
    }
    .content-innerpages h2 {
      font-size: 30px;
    }
    .surveyForm {
      padding: 15px !important;
      background-image: none !important;
      min-height: auto;
      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .brands-images .seperation-new .col-sm-4 img.img-responsive {
      width: 100%;
    }
    .content-footer blockquote {
      min-height: auto;
    }
    .innerpage .container {
      padding: 0px;
    }
    .bototmmcontent .row .col-sm-12 {
      padding: 0px 0px !important;
      h3 {
        font-weight: 400 !important;
        margin-bottom: 20px;
        margin-top: 0px;
      }
    }
    .innerPageContent {
      padding-top: 20px !important;
    }
    .surveyForm .form-survey-section {
      padding: 15px 15px 60px;
    }
    .newSurveyHeading {
      font-size: 1.5rem;
    }
    .surveyForm
      .form-survey-section
      .newSurveyContent
      .questionSteps
      .MuiFormLabel-root {
      font-size: 16px;
      font-weight: 500;
    }
    .surveyForm .progressBar .progressBarContainer .bar {
      width: 120px;
    }
  }
}

/******layout second css*********/

.layout-fourth-second-theme {
  $base_color: #7773fa;
  @mixin banner-color {
    background: transparent
      linear-gradient(61deg, #7773fa, rgba(19, 111, 172, 0.180392)) 0 0
      no-repeat padding-box;
  }

  body {
    color: #000;
    //  font-family: "Open Sans", sans-serif;
  }
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a,
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    top: 20px;
    z-index: 9999 !important;
    & + .container-fluid {
      padding-top: 70px;
    }
  }
  label {
    &.switch {
      &.mob {
        float: right;
        margin-top: 8px;
        display: none;
      }
    }
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation {
    padding: 15px 0px;
    &.header {
      &.clearfix {
        &.active {
          &.affix {
            top: 0px;
            background: #fff;
            box-shadow: 0px 0px 12px 0px #ddd;
            z-index: 99;
            #navbarResponsive {
              ul {
                li {
                  a {
                    color: #131313;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar {
      &.navbar-inverse {
        background: transparent;
        border: none;
      }
    }
    &.active {
      background: #fff !important;
      padding: 10px 0px;
    }
  }
  .top-header {
    background: #fff;
    padding: 6px 0px;
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      &.text-right {
        float: right;
      }
      a {
        color: #000;
      }
    }
  }
  #navbarResponsive {
    ul {
      li {
        font-size: 18px;
        text-transform: capitalize;
        &.active {
          a {
            color: #e9c87b;
            background: transparent;
          }
        }
        a {
          color: #fff;
        }
      }
    }
    .nav {
      &.navbar-nav {
        &.navbar-right {
          li {
            a {
              background: #e9c87b;
              border-radius: 4px;
              padding: 10px 15px;
              color: #fff !important;
              margin-top: 5px;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .header-or-banner {
    &.clearfix {
      width: 100%;
      z-index: 9;
      box-shadow: none;
      position: absolute;
    }
  }
  .active {
    .navbar-header {
      .img-responsive {
        width: auto;
        height: 52px;
      }
    }
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
      .img-responsive {
        max-height: 70px;
      }
    }
  }
  .form-wrapper {
    &.nav-stacked {
      background: #fff;
      padding: 40px;
      border-radius: 15px;
      width: 100%;
      float: right;
      position: relative;
      top: 0px;
      margin-left: 28px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      .btn {
        &.btn-primary {
          margin: 20px 0px;
          background: #e9c87b;
          border: 1px solid #e9c87b;
          font-size: 16px;
        }
      }
      h5 {
        font-size: 30px;
        text-align: left;
        padding-bottom: 13px;
        margin-top: 0px;
        color: #636363;
        line-height: 38px;
      }
    }
  }
  .brands-images {
    background: #f9fbff;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    .featureeText {
      color: #8a8a8a;
      font-size: 25px;
      width: 1034px;
      margin: auto;
      text-align: center;
      max-width: 100%;
    }
    .textContent {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
      img {
        float: left;
        max-width: 170px;
      }
      p {
        margin-left: 200px;
        text-align: left;
        color: #8a8a8a;
        font-size: 18px;
        span {
          color: #8a8a8a !important;
          font-size: 18px !important;
        }
      }
    }
    ul {
      list-style: none;
      margin-top: 8%;
      box-shadow: 0px 0px 7px 0px #bbb;
      padding: 15px 12px;
      text-align: center;
    }
    .seperation-new {
      padding: 20px 0px;
    }
  }
  .quickeasy {
    //background: url("https://cdn.databowlpages.com/assets/54b8676c-ddcf-4ee1-8aa7-033e66e8ccfd.jpg");
    background-position: left top;
    background-size: cover;
    position: relative;
    color: #8a8a8a;
    padding: 3% 0%;
    margin-top: 0%;
    // &::before {
    //   content: "";
    //   background: #1b4a98;
    //   background: -moz-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: -webkit-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
    //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
    //   height: 100%;
    //   position: absolute;
    //   width: 100%;
    //   top: 0px;
    // }
    .image-new-brnad {
      text-align: center;
      background: #fff;
      width: 50%;
      margin: 0px auto;
      border-radius: 22px;
      padding: 15px 0px;
      margin-top: 17px;
      .img-responsive {
        width: 100px;
        height: auto;
        margin: 0px auto;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      font-size: 25px;
      font-weight: 600;
      li {
        padding: 6px 0px;
        .fa {
          margin-right: 9px;
        }
      }
    }
  }
  .content-footer {
    background: #fff;
    color: #8a8a8a;
    font-size: 16px;
    padding: 0% 0% 2% 0%;
  }
  .copyright-footer {
    background: #000;
    color: #fff;
    position: relative;
    z-index: 9999;
    padding: 15px 0px;
    p {
      margin: auto;
      padding: 0px;
    }
    .col-sm-6 {
      &:last-child {
        text-align: right;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin: 0px;
      li {
        display: inline-block;
        padding-left: 20px;
        a {
          color: #fff;
        }
      }
    }
  }
  .seperation-new {
    text-align: center;
    .getlink {
      vertical-align: middle;
      display: inline-flex;
      padding-top: 5px;
      // a {
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   font-size: 18px;
      //   padding: 7px 20px;
      //   border-radius: 4px;
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   font-size: 16px;
      // }
    }
    .row {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }
  }
  .paragraph-alignment-new {
    .getlink {
      text-align: center;
      padding-top: 24px;
      // a {
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   padding: 7px 20px;
      //   font-size: 16px;
      //   border-radius: 4px;
      //   display: inline-block;
      // }
    }
  }
  .brands-section {
    h2 {
      font-size: 40px;
      color: #333;
      width: 700px;
      max-width: 100%;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 50px;
    }
    .sideContent {
      & > span {
        font-size: 25px !important;
        color: #818181 !important;
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        width: 100%;
        li {
          width: 100%;
          list-style: none;
          padding-left: 30px;
          position: relative;
          color: #8a8a8a;
          font-size: 18px;
          margin: 10px 0px;
          &:before {
            background-image: url(../../assets/images/list-bullet.png);
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            left: 0px;
            top: 10px;
            content: "";
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .brands-images {
      h4 {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0px;
      }
      li {
        width: 140px;
        display: inline-block;
        margin: 0px 15px;
        padding: 5px 0px;
      }
    }
  }
  .step2 {
    &.form-custome-new {
      display: none;
    }
  }
  .mystyle {
    .step2 {
      &.form-custome-new {
        display: block;
      }
    }
    .step1 {
      &.form-custome-new {
        display: none;
      }
    }
  }
  .lock-icon {
    &.d-inline-flex {
      color: #777;
      margin-bottom: 0px;
    }
  }
  .form-group {
    text-align: left;
    label {
      font-weight: 500;
      color: #3b3b3b;
      .mendatory {
        color: red;
      }
    }
  }
  .form-container {
    text-align: center;
  }
  .checkbox {
    text-align: left;
  }
  .form-step-text {
    text-align: left;
    font-size: 12px;
    color: #636363;
    line-height: normal;
    margin-top: -20px;
  }
  .container-footer {
    text-align: center;
    font-size: 12px;
  }
  #navbarResponsive ul li a:hover,
  #navbarResponsive ul li a:active,
  #navbarResponsive ul li a:focus {
    color: #e9c87b !important;
  }
  .nav {
    &.navbar-nav {
      &.navbar-right {
        li {
          .img-responsive {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
  }
  .hero {
    min-height: 100vh;
    .form-wrapper {
      &.stuck {
        .form-container {
          position: fixed;
          top: 20px;
          width: inherit;
          max-width: inherit;
        }
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
          &.active {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .group-editible {
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 20px;
    background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    color: #fff;
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 15px;
      li {
        display: inline-block;
        width: 100%;
        a {
          background: #fff;
          color: #4094a9;
          text-transform: capitalize;
          padding: 7px 15px;
          display: inline-block;
          margin: 4px 0px;
          width: 200px;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }
  .boollets-points {
    ul {
      padding: 0px 0px 0px 15px;
      li {
        padding: 6px 0px;
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
      }
    }
  }
  .choos-boollets-points {
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 30px;
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .map__desc {
    &.clearfix {
      position: relative;
      top: -90px;
      width: 200px;
      left: 36px;
    }
  }
  .comparebtn {
    text-align: center;
    a {
      background: #4094a9;
      color: #fff;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .averagetext {
    text-align: center;
    font-size: 15px;
    color: #777;
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn {
      &.btn-default {
        background: #4094a9;
        border: 1px solid #4094a9;
        color: #fff;
        font-size: 16px;
      }
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 30px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      &:last-child {
        border: none;
      }
    }
  }
  .left-clinet-list {
    ul {
      padding: 0px;
      list-style: none;
      li {
        p {
          background: #e1e1e1;
          padding: 10px 8px;
          font-size: 16px;
          text-transform: capitalize;
          b {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .aqualheight {
    height: 660px;
    display: table;
    padding-top: 5%;
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .col-sm-6 {
    &.left-sec {
      background: #f2f2f2;
      .aqualheight {
        float: right;
        text-align: center;
        margin: 0px auto;
        margin-right: 8%;
      }
    }
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .banner-home {
    &.hero {
      width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      // background-image: url("https://cdn.databowlpages.com/assets/7a051f70-35ea-4022-8226-a766e526a741.jpg");
      background-size: cover;
      background-position: center center;
      position: relative;
      background-attachment: fixed;
      background-size: cover;
      &::before {
        content: "";
        background: #1b4a98;
        // background: transparent linear-gradient(119deg, #5D59F5 0%, #57D9E6 100%) 0% 0% no-repeat padding-box;
        @include banner-color;
        opacity: 0.8;
        // background: -moz-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .waveImg {
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -1px;
        height: 100%;
        background: url(../../assets/images/wave.png);
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
  }
  .heading-banner {
    padding-top: 18%;
    h1 {
      font-weight: 600;
      color: #fff;
      font-size: 50px;
    }
    h5 {
      margin-top: 20px;
      strong {
        font-size: 22px;
        font-weight: 400;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      color: #fff;
      font-size: 20px;
      padding-top: 20px;
      .fa {
        padding-right: 10px;
      }
    }
    .bg-trans {
      font-size: 18px;
      background: rgba(255, 218, 132, 0.4);
      color: #fff;
      font-style: italic;
      font-weight: 600;
      padding: 20px;
      border-radius: 20px;
      margin-top: 50px;
    }
  }
  .text-contentbanner {
    padding-top: 10%;
    display: flex;
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
      }
    }
  }
  .footer-section ul a:hover,
  .footer-section ul a:focus,
  .footer-section ul a:active {
    color: #e9c87b;
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3 {
    &.noppading {
      &.formnews {
        p {
          color: #ccc;
        }
      }
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: #4094a9;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4 {
      &.text-right {
        p {
          color: #ccc;
        }
      }
    }
  }
  .advertisement-images {
    padding: 0pc;
    padding-top: 0pc;
    list-style: none;
    padding-top: 25px;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: $base_color;
    height: 280px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -130px;
    background: #fff;
    padding: 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 5%;
    min-height: 44.9em;
    border-radius: 15px;
    h2 {
      color: #333;
      text-transform: capitalize;
      // font-weight: 700;
      position: relative;
      font-size: 40px;
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: $base_color;
        position: absolute;
        bottom: -5px;
        left: 0;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      width: 100%;
      li {
        width: 100%;
        list-style: none !important;
        padding-left: 30px;
        position: relative;
        color: rgb(102, 102, 102) !important;
        font-size: 18px;
        margin: 5px 0px;
        &:before {
          background-image: url(../../assets/images/list-bullet.png);
          background-repeat: no-repeat;
          background-position: left top;
          position: absolute;
          left: 0px;
          top: 10px;
          content: "";
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    ul {
      li {
        padding: 5px 0px;
      }
    }
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //Custom css
  a.active.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.inactive.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .navigation {
    transition: 0.5s ease;
  }
  .navigation.active {
    background: $base_color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $base_color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }
  .dynamic-form {
    width: auto;
  }
  .controls {
    background: #f9f9f9;
  }
  .navigation .row {
    width: 100%;
  }
  .header-section .fixed-top ul .nav-item a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.header-button.nav-link {
    // background: #e9c87b;
    // border-radius: 4px;
    // color: #fff !important;
    // opacity: 1;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 9px 30px;
    text-transform: uppercase;
  }

  .navbar-brand {
    margin-right: 0px;
    .img-responsive {
      max-height: 70px;
    }
  }
  .dynamic-form {
    margin: 0px -15px;
    padding: 0px;
    flex-direction: inherit;
    flex-wrap: wrap;
    .space-between {
      padding: 0px 15px;
      margin: 5px 0 5px 0;
      &.half-width {
        width: 50%;
        .date-box__field {
          width: 100%;
          color: #7c7d7d;
        }
      }
      .radio_box_root {
        border: none !important;
        .MuiFormControl-root {
          padding: 0px !important;
          label {
            font-weight: 500 !important;
          }
        }
      }
      .MuiButtonBase-roo {
        height: 60px;
      }
    }
  }
  span.anchorLink {
    color: cornflowerblue;
    cursor: pointer;
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 700;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 11px 25px;
    display: inline-block;
    margin-top: 40px;
    border: 2px solid $base_color;
    text-decoration: none;
    &:hover {
      background: #fff;
      color: $base_color;
      border: 2px solid $base_color;
    }
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: #06124e;
    border: 1px solid #06124e;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 40px 0px 0px 0px;
    font-size: 18px;
  }

  /************new css 2020***************/
  .testimonialsArea {
    p {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 40px !important;
      color: #333333 !important;
      span {
        font-size: 40px !important;
        color: #333333 !important;
      }
      strong {
        font-size: 40px !important;
        color: #333333 !important;
        font-weight: 500;
      }
    }
  }
  .content-footer {
    p.testimonialsList {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
        display: inline-block;
        width: 100%;
      }
    }
    blockquote {
      border-radius: 15px;
      border: 1px solid #d4d4d4;
      background: #fff;
      padding: 30px;
      background-image: url(../../assets/images/quote.png);
      background-repeat: no-repeat;
      background-position: 30px 20px;
      text-align: left;
      padding: 50px 50px 50px 150px;
      font-size: 20px;
      color: #8a8a8a;
      min-height: 230px;
    }
  }

  .getlink {
    a {
      background: $base_color;
      color: #fff;
      padding: 15px 40px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: $base_color;
        border: 2px solid $base_color;
      }
    }
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }

  .header {
    .navbar-nav {
      li {
        a.nav-link {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          text-transform: uppercase;
          color: #eaeaea;
          font-weight: 500;
          cursor: pointer;
          &.header-button {
            &:hover {
              background: #fff;
              color: $base_color;
            }
          }
        }
        &.lastbtn {
          padding-left: 20px;
        }
      }
    }
  }

  .innerPageContent {
    .container {
      max-width: 100% !important;
    }
  }

  .surveyForm {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: calc(100vh - 234px);
    padding: 0px 15px;
    margin-top: -100px;
    .form-survey-section {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      padding: 50px 50px 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        // min-height: 300px;
        .questionSteps {
          .MuiFormLabel-root {
            font-size: 20px;
            padding-left: 4px !important;
          }
        }
      }
    }

    input.input-box__field.input-box__field--survey.false,
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
    .date-box__field--survey {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid #c5c5c5;
      border-left: none;
    }
    .date-box__field--survey_hoverColor {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid $base_color;
      border-left: none;
      font-size: 16px !important;
      font-weight: 500;
    }
    .newSurveyFooter {
      display: inline-block;
      width: 100%;
      padding-top: 0;
      .backBtn {
        border: 2px solid #ccc;
        color: #ccc;
        padding: 12px 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 0;
      }
      .nextBtn {
        border: 2px solid $base_color;
        color: #fff;
        padding: 12px 30px;
        text-transform: uppercase;
        background: $base_color;
        letter-spacing: 1px;
        border-radius: 0;
      }
    }
    .progressBar {
      background: $base_color;
      position: absolute;
      bottom: 0;
      height: 40px;
      left: 0;
      width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      text-align: center;
      .progressBarContainer {
        height: 40px;
        overflow: hidden;
        margin: auto 0;
        display: inline-block;
        .progressBarText {
          color: #fff;
          font-size: 15px;
          display: block;
          background-repeat: no-repeat;
          background-position: 50%;
          margin-right: 14px;
          height: 40px;
          line-height: 40px;
          float: left;
        }
        .bar {
          width: 200px;
          border-radius: 2px;
          height: 8px;
          float: left;
          margin-top: 16px;
          border: 1px solid #fff;
          border-radius: 5px;
          span {
            background-color: #fff;
            height: 8px;
            border-radius: 2px;
            float: left;
          }
        }
      }
    }
  }
  .space-between {
    .input-box__field {
      width: 100%;
      height: 45px;
    }
  }

  button.btn.btn-submit {
    /* background-color: #fff; */
    border: 2px solid $base_color;
    border-radius: 0px;
    color: $base_color;
    padding: 12px 30px;
    text-transform: uppercase;
    background: #fff;
    letter-spacing: 1px;
    border-radius: 2px;
  }
  input.date-box__field.false {
    height: 47px;
    width: -webkit-fill-available;
  }

  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: darken($base_color, 5%);
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $base_color;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;
      /* Icon */
      &:hover {
        background: darken($base_color, 5%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: darken($base_color, 5%);
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: darken($base_color, 5%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }
  //******** Accordian css end**********//
  /*=============media============*/

  @media (max-width: 1200px) {
    .form-wrapper.nav-stacked {
      width: auto;
      margin-right: 15px;
    }
    .navigation.active {
      position: fixed;
    }
  }

  @media (max-width: 1024px) {
    .heading-banner {
      padding-top: 22%;
      h1 {
        font-size: 40px;
      }
      h5 {
        strong {
          font-size: 20px;
        }
      }
    }
    .form-wrapper {
      &.nav-stacked {
        margin-left: 0px;
        margin-right: 0px;
        padding: 20px;
        max-height: calc(100vh - 150px);
        h5 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .dynamic-form {
      .space-between {
        margin: 7px 0 7px 0;
      }
    }
    .brands-section {
      padding-top: 50px;
      .sideContent {
        span {
          line-height: normal;
          font-size: 20px !important;
        }
        ul {
          li {
            margin: 7px 0px;
          }
        }
      }
    }

    .brands-images {
      .featureeText {
        font-size: 20px;
        line-height: 26px;
      }
      .seperation-new {
        .row {
          vertical-align: top;
          align-items: normal;
          .textContent {
            img {
              max-width: 120px;
            }
            p {
              margin-left: 135px;
            }
          }
        }
      }
    }

    .content-footer {
      blockquote {
        background-size: 40px;
        background-position: 15px 15px;
        padding: 15px 15px 15px 70px;
        font-size: 18px;
        min-height: 170px;
      }
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-image: none !important;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .text-contentbanner {
      padding-top: 8rem;
      // display: block !important;
    }
    .col-md-4.right-side {
      max-width: 100% !important;
    }
    .col-sm-8.left-side {
      max-width: 100%;
      padding-top: 30px;
    }
    section.brands-section .row {
      display: block;
    }
    section.brands-section .col-sm-8 {
      max-width: 100%;
      width: 100% !important;
    }
    .paragraph-alignment-new .row .col-sm-6 {
      max-width: 100%;
    }
    .quickeasy .row {
      display: block;
      text-align: center;
    }
    .brands-images .seperation-new .col-sm-4 {
      /* max-width: 100%; */
      display: inline-block;
      vertical-align: middle;
    }
    .header-or-banner.clearfix {
      background: #000;
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      padding: 7px 15px;
      display: inline-block;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }

    .navbar-collapse .navbar-nav:last-child {
      display: block;
      padding-bottom: 20px;
    }
    .quickeasy .col-sm-8 {
      max-width: 100%;
      margin-left: 0px;
    }
    a.inactive.nav-link {
      color: #fff !important;
    }
    a.header-button.nav-link {
      background: #e9c87b;
      text-align: center;
      margin-left: 20px;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      // background: #131313;
      position: absolute;
      right: 15px;
      top: 12px;
    }
    .form-wrapper.nav-stacked {
      float: none;
    }
    .text-contentbanner .col-sm-8.left-side {
      width: 100%;
    }
    .heading-banner {
      padding-bottom: 40px;
    }
    .banner-home.hero {
      padding-bottom: 30px;
    }
    .brands-section .col-sm-8 {
      width: 100%;
    }
    .quickeasy .col-sm-4 {
      width: 50%;
    }
    .content-footer .col-sm-4 {
      width: 50%;
    }
    .content-footer .row .col-sm-4 {
      max-width: 100%;
      width: 100%;
    }
    // .content-footer .row {
    //   display: block;
    // }
    // .navigation.active {
    //   position: relative;
    // }
    .navbar-brand {
      .img-responsive {
        max-height: 50px;
      }
    }
    .banner-home.hero {
      min-height: auto;
      .waveImg {
        background-size: auto;
      }
    }
    .text-contentbanner {
      display: block;
      .left-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .heading-banner {
          padding-top: 15%;
        }
      }
      .right-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .form-wrapper {
          &.nav-stacked {
            width: 600px;
            max-width: 100%;
            margin: auto;
          }
        }
      }
    }
    .brands-section {
      .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .sideContent {
          margin-top: 20px;
        }
      }
    }

    .form-wrapper {
      &.nav-stacked {
        h5 {
          font-size: 34px;
          line-height: 38px;
        }
      }
    }
    .content-footer {
      blockquote {
        min-height: 200px;
      }
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      background: transparent;
      text-align: left;
      margin-left: 0px;
      border: none;
      padding-left: 0px;
    }
    .navbar-collapse .navbar-nav:last-child {
      padding-bottom: 0px;
    }
    .surveyForm {
      margin-bottom: 40px;
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-size: contain;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
    .navigation {
      padding: 10px 15px !important;
    }
  }
  @media (max-width: 767px) {
    .top-header p.text-right {
      float: none;
    }
    .copyright-footer .row {
      display: block;
    }
    .copyright-footer .row .col-sm-6 {
      max-width: 100%;
      text-align: center;
      padding: 5px 15px;
    }
    .quickeasy .col-sm-4 {
      width: 100%;
      text-align: center;
    }
    .header-or-banner.clearfix {
      background: #fff;
    }
    .content-footer .col-sm-4 {
      width: 100%;
    }
    #navbarResponsive ul li a {
      color: #131313;
    }
    .quickeasy ul {
      line-height: normal;
      padding-top: 20px;
    }
    .nav.navbar-nav.navbar-right li {
      padding-left: 15px;
    }

    .header-or-banner.clearfix {
      // position: relative;
    }
    .article-heading h1 {
      font-size: 20px;
    }
    .choosearea-section svg {
      width: 100%;
    }
    .navbar-inverse .navbar-toggle {
      margin-left: 10px;
      background: #131313;
    }
    .nav.navbar-nav.navbar-right li .img-responsive {
      padding: 0px 5px 0px 0px;
    }
    .top-header p {
      display: block;
      text-align: center !important;
    }
    .navigation {
      padding: 12px 10px;
      background: $base_color;
    }
    .navigation a.navbar-brand img.img-responsive {
      width: auto;
      height: 40px;
      padding-left: 15px;
      padding-top: 6px;
      position: relative;
      top: -5px;
    }
    .navigation.active {
      padding: 10px 10px;
      // position: relative;
    }
    #navbarResponsive .nav.navbar-nav.navbar-right li a {
      display: inline-block;
    }
    .text-contentbanner {
      width: 100%;
      padding-top: 2rem;
      .heading-banner {
        h1 {
          font-size: 30px;
        }
        h5 strong {
          font-size: 18px;
        }
      }
    }
    .form-wrapper.nav-stacked {
      h5 {
        font-size: 26px;
        line-height: 30px;
      }
    }
    .brands-section {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .brands-images {
      padding: 15px;
      .seperation-new {
        .row {
          .textContent {
            img {
              float: none;
              margin: auto;
            }
            p {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .paragraph-alignment-new .getlink {
      padding-top: 0px;
    }
    .testimonialsArea h2 strong {
      font-size: 30px !important;
    }
    .form-wrapper.nav-stacked {
      max-height: none;
    }
    .content-innerpages {
      padding: 15px;
    }
    .bgbluew {
      height: 200px;
    }
    .bototmmcontent strong {
      font-weight: 500;
    }
    .bototmmcontent h2 {
      font-size: 24px !important;
      margin-top: 15px !important;
      &::after {
        display: none;
      }
    }
    .bototmmcontent p {
      word-break: break-word;
    }
    .content-innerpages h2 {
      font-size: 30px;
    }
    .surveyForm {
      padding: 15px !important;
      background-image: none !important;
      min-height: auto;
      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .brands-images .seperation-new .col-sm-4 img.img-responsive {
      width: 100%;
    }
    .content-footer blockquote {
      min-height: auto;
    }
    .innerpage .container {
      padding: 0px;
    }
    .bototmmcontent .row .col-sm-12 {
      padding: 0px 0px !important;
      h3 {
        font-weight: 400 !important;
        margin-bottom: 20px;
        margin-top: 0px;
      }
    }
    .innerPageContent {
      padding-top: 20px !important;
    }
    .surveyForm .form-survey-section {
      padding: 15px 15px 60px;
    }
    .newSurveyHeading {
      font-size: 1.5rem;
    }
    .surveyForm
      .form-survey-section
      .newSurveyContent
      .questionSteps
      .MuiFormLabel-root {
      font-size: 16px;
      font-weight: 500;
    }
    .surveyForm .progressBar .progressBarContainer .bar {
      width: 120px;
    }
  }
}

/******layout third css*********/

.layout-fourth-third-theme {
  $base_color: #4094a9;
  @mixin banner-color {
    background: transparent
      linear-gradient(61deg, #4094a9, rgba(19, 111, 172, 0.180392)) 0 0
      no-repeat padding-box;
  }

  body {
    color: #000;
    //  font-family: "Open Sans", sans-serif;
  }
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }
  footer {
    background-color: #f2f2f2;
    padding: 25px;
  }
  a,
  button {
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .noppading {
    padding: 0px !important;
  }
  .affix {
    top: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    top: 20px;
    z-index: 9999 !important;
    & + .container-fluid {
      padding-top: 70px;
    }
  }
  label {
    &.switch {
      &.mob {
        float: right;
        margin-top: 8px;
        display: none;
      }
    }
  }
  .affix-top {
    position: static;
    top: -35px;
  }
  .navigation {
    padding: 15px 0px;
    &.header {
      &.clearfix {
        &.active {
          &.affix {
            top: 0px;
            background: #fff;
            box-shadow: 0px 0px 12px 0px #ddd;
            z-index: 99;
            #navbarResponsive {
              ul {
                li {
                  a {
                    color: #131313;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar {
      &.navbar-inverse {
        background: transparent;
        border: none;
      }
    }
    &.active {
      background: #fff !important;
      padding: 10px 0px;
    }
  }
  .top-header {
    background: #fff;
    padding: 6px 0px;
    p {
      display: inline-block;
      margin: 0px;
      font-weight: bold;
      &.text-right {
        float: right;
      }
      a {
        color: #000;
      }
    }
  }
  #navbarResponsive {
    ul {
      li {
        font-size: 18px;
        text-transform: capitalize;
        &.active {
          a {
            color: #e9c87b;
            background: transparent;
          }
        }
        a {
          color: #fff;
        }
      }
    }
    .nav {
      &.navbar-nav {
        &.navbar-right {
          li {
            a {
              background: #e9c87b;
              border-radius: 4px;
              padding: 10px 15px;
              color: #fff !important;
              margin-top: 5px;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .header-or-banner {
    &.clearfix {
      width: 100%;
      z-index: 9;
      box-shadow: none;
      position: absolute;
    }
  }
  .active {
    .navbar-header {
      .img-responsive {
        width: auto;
        height: 52px;
      }
    }
  }
  .navbar-header {
    .navbar-brand {
      padding: 0px 0px;
      .img-responsive {
        max-height: 70px;
      }
    }
  }
  .form-wrapper {
    &.nav-stacked {
      background: #fff;
      padding: 40px;
      border-radius: 15px;
      width: 100%;
      float: right;
      position: relative;
      top: 0px;
      margin-left: 28px;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      .btn {
        &.btn-primary {
          margin: 20px 0px;
          background: #e9c87b;
          border: 1px solid #e9c87b;
          font-size: 16px;
        }
      }
      h5 {
        font-size: 30px;
        text-align: left;
        padding-bottom: 13px;
        margin-top: 0px;
        color: #636363;
        line-height: 38px;
      }
    }
  }
  .brands-images {
    background: #f9fbff;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    .featureeText {
      color: #8a8a8a;
      font-size: 25px;
      width: 1034px;
      margin: auto;
      text-align: center;
      max-width: 100%;
    }
    .textContent {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
      img {
        float: left;
        max-width: 170px;
      }
      p {
        margin-left: 200px;
        text-align: left;
        color: #8a8a8a;
        font-size: 18px;
        span {
          color: #8a8a8a !important;
          font-size: 18px !important;
        }
      }
    }
    ul {
      list-style: none;
      margin-top: 8%;
      box-shadow: 0px 0px 7px 0px #bbb;
      padding: 15px 12px;
      text-align: center;
    }
    .seperation-new {
      padding: 20px 0px;
    }
  }
  .quickeasy {
    //background: url("https://cdn.databowlpages.com/assets/54b8676c-ddcf-4ee1-8aa7-033e66e8ccfd.jpg");
    background-position: left top;
    background-size: cover;
    position: relative;
    color: #8a8a8a;
    padding: 3% 0%;
    margin-top: 0%;
    // &::before {
    //   content: "";
    //   background: #1b4a98;
    //   background: -moz-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: -webkit-linear-gradient(
    //     90deg,
    //     #1b4a98 10%,
    //     rgba(27, 74, 152, 0) 100%
    //   );
    //   background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
    //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
    //   height: 100%;
    //   position: absolute;
    //   width: 100%;
    //   top: 0px;
    // }
    .image-new-brnad {
      text-align: center;
      background: #fff;
      width: 50%;
      margin: 0px auto;
      border-radius: 22px;
      padding: 15px 0px;
      margin-top: 17px;
      .img-responsive {
        width: 100px;
        height: auto;
        margin: 0px auto;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      font-size: 25px;
      font-weight: 600;
      li {
        padding: 6px 0px;
        .fa {
          margin-right: 9px;
        }
      }
    }
  }
  .content-footer {
    background: #fff;
    color: #8a8a8a;
    font-size: 16px;
    padding: 0% 0% 2% 0%;
  }
  .copyright-footer {
    background: #000;
    color: #fff;
    position: relative;
    z-index: 9999;
    padding: 15px 0px;
    p {
      margin: 0px;
      padding: 0px;
    }
    .col-sm-6 {
      &:last-child {
        text-align: right;
      }
    }
    ul {
      padding: 0px;
      list-style: none;
      margin: 0px;
      li {
        display: inline-block;
        padding-left: 20px;
        a {
          color: #fff;
        }
      }
    }
  }
  .seperation-new {
    text-align: center;
    .getlink {
      vertical-align: middle;
      display: inline-flex;
      padding-top: 5px;
      // a {
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   font-size: 18px;
      //   padding: 7px 20px;
      //   border-radius: 4px;
      //   margin: 20px 0px;
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   font-size: 16px;
      // }
    }
    .row {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }
  }
  .paragraph-alignment-new {
    .getlink {
      text-align: center;
      padding-top: 24px;
      // a {
      //   background: #e9c87b;
      //   border: 1px solid #e9c87b;
      //   color: #fff;
      //   text-transform: capitalize;
      //   padding: 7px 20px;
      //   font-size: 16px;
      //   border-radius: 4px;
      //   display: inline-block;
      // }
    }
  }
  .brands-section {
    h2 {
      font-size: 40px;
      color: #333;
      width: 700px;
      max-width: 100%;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 50px;
    }
    .sideContent {
      & > span {
        font-size: 25px !important;
        color: #818181 !important;
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        width: 100%;
        li {
          width: 100%;
          list-style: none;
          padding-left: 30px;
          position: relative;
          color: #8a8a8a;
          font-size: 18px;
          margin: 10px 0px;
          &:before {
            background-image: url(../../assets/images/list-bullet.png);
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            left: 0px;
            top: 10px;
            content: "";
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .brands-images {
      h4 {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0px;
      }
      li {
        width: 140px;
        display: inline-block;
        margin: 0px 15px;
        padding: 5px 0px;
      }
    }
  }
  .step2 {
    &.form-custome-new {
      display: none;
    }
  }
  .mystyle {
    .step2 {
      &.form-custome-new {
        display: block;
      }
    }
    .step1 {
      &.form-custome-new {
        display: none;
      }
    }
  }
  .lock-icon {
    &.d-inline-flex {
      color: #777;
      margin-bottom: 0px;
    }
  }
  .form-group {
    text-align: left;
    label {
      font-weight: 500;
      color: #3b3b3b;
      .mendatory {
        color: red;
      }
    }
  }
  .form-container {
    text-align: center;
  }
  .checkbox {
    text-align: left;
  }
  .form-step-text {
    text-align: left;
    font-size: 12px;
    color: #636363;
    line-height: normal;
    margin-top: -20px;
  }
  .container-footer {
    text-align: center;
    font-size: 12px;
  }
  #navbarResponsive ul li a:hover,
  #navbarResponsive ul li a:active,
  #navbarResponsive ul li a:focus {
    color: #e9c87b !important;
  }
  .nav {
    &.navbar-nav {
      &.navbar-right {
        li {
          .img-responsive {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
  }
  .hero {
    min-height: 100vh;
    .form-wrapper {
      &.stuck {
        .form-container {
          position: fixed;
          top: 20px;
          width: inherit;
          max-width: inherit;
        }
      }
    }
  }
  .slidercode {
    .carousel-caption {
      text-align: left;
      text-align: left;
      top: 32%;
      ul {
        list-style: none;
        padding: 12px 0px;
        li {
          display: inline-block;
          font-size: 18px;
          margin-right: 15px;
          width: 10%;
          border: 1px solid #666;
          text-align: center;
          padding: 5px;
          a {
            padding: 10px 15px;
            color: #666;
            text-transform: capitalize;
            text-decoration: none;
            text-shadow: none;
          }
          &:hover {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
          &.active {
            background: #e9c87b;
            border: 1px solid #e9c87b;
            a {
              color: #000;
            }
          }
        }
      }
      .text-orange {
        color: #e9c87b;
        font-size: 36px;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 72px;
        text-transform: capitalize;
        font-weight: bold;
        margin-top: 0px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .group-editible {
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 20px;
    background: linear-gradient(135deg, #157894 0%, #55bfd1 100%);
    color: #fff;
    ul {
      padding: 0px;
      list-style: none;
      margin-top: 15px;
      li {
        display: inline-block;
        width: 100%;
        a {
          background: #fff;
          color: #4094a9;
          text-transform: capitalize;
          padding: 7px 15px;
          display: inline-block;
          margin: 4px 0px;
          width: 200px;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }
  .boollets-points {
    ul {
      padding: 0px 0px 0px 15px;
      li {
        padding: 6px 0px;
        a {
          text-decoration: none;
          color: #131313;
          display: block;
          b {
            display: block;
          }
        }
      }
    }
  }
  .choos-boollets-points {
    box-shadow: 0px 0px 6px 0px #ddd;
    padding: 30px;
    h4 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .map__desc {
    &.clearfix {
      position: relative;
      top: -90px;
      width: 200px;
      left: 36px;
    }
  }
  .comparebtn {
    text-align: center;
    a {
      background: #4094a9;
      color: #fff;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .averagetext {
    text-align: center;
    font-size: 15px;
    color: #777;
  }
  .contactfomr {
    padding-top: 2%;
    width: 70%;
    h3 {
      margin-top: 0px;
      text-transform: capitalize;
      padding-bottom: 15px;
    }
    .btn {
      &.btn-default {
        background: #4094a9;
        border: 1px solid #4094a9;
        color: #fff;
        font-size: 16px;
      }
    }
    .form-control {
      text-transform: capitalize;
    }
  }
  .article-heading {
    h1 {
      font-size: 30px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
    }
  }
  .mid-content {
    .mi-section-content {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      &:last-child {
        border: none;
      }
    }
  }
  .left-clinet-list {
    ul {
      padding: 0px;
      list-style: none;
      li {
        p {
          background: #e1e1e1;
          padding: 10px 8px;
          font-size: 16px;
          text-transform: capitalize;
          b {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .aqualheight {
    height: 660px;
    display: table;
    padding-top: 5%;
    .content-chaselife {
      vertical-align: middle;
      display: table-cell;
      padding-left: 15px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
      }
      p {
        width: 70%;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .col-sm-6 {
    &.left-sec {
      background: #f2f2f2;
      .aqualheight {
        float: right;
        text-align: center;
        margin: 0px auto;
        margin-right: 8%;
      }
    }
  }
  .content-chaselife {
    h1 {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .banner-home {
    &.hero {
      width: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff;
      // background-image: url("https://cdn.databowlpages.com/assets/7a051f70-35ea-4022-8226-a766e526a741.jpg");
      background-size: cover;
      background-position: center center;
      position: relative;
      background-attachment: fixed;
      background-size: cover;
      &::before {
        content: "";
        background: #1b4a98;
        // background: transparent linear-gradient(119deg, #5D59F5 0%, #57D9E6 100%) 0% 0% no-repeat padding-box;
        @include banner-color;
        opacity: 0.8;
        // background: -moz-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //   90deg,
        //   #1b4a98 10%,
        //   rgba(27, 74, 152, 0) 100%
        // );
        // background: linear-gradient(90deg, #000 10%, rgba(27, 74, 152, 0) 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b4a98", endColorstr="#1b4a98", GradientType=1);
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .waveImg {
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -1px;
        height: 100%;
        background: url(../../assets/images/wave.png);
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
  }
  .heading-banner {
    padding-top: 18%;
    h1 {
      font-weight: 600;
      color: #fff;
      font-size: 50px;
    }
    h5 {
      margin-top: 20px;
      strong {
        font-size: 22px;
        font-weight: 400;
      }
    }
    ul {
      list-style: none;
      padding: 0px;
      color: #fff;
      font-size: 20px;
      padding-top: 20px;
      .fa {
        padding-right: 10px;
      }
    }
    .bg-trans {
      font-size: 18px;
      background: rgba(255, 218, 132, 0.4);
      color: #fff;
      font-style: italic;
      font-weight: 600;
      padding: 20px;
      border-radius: 20px;
      margin-top: 50px;
    }
  }
  .text-contentbanner {
    padding-top: 10%;
    display: flex;
  }
  .footer-section {
    background: #1c1a25;
    color: #fff;
    padding: 2% 0% 1% 0%;
    border-bottom: 1px solid #464646;
    h2 {
      font-size: 20px;
    }
    ul {
      padding: 0px;
      list-style: none;
      a {
        color: #cccccc;
      }
    }
  }
  .footer-section ul a:hover,
  .footer-section ul a:focus,
  .footer-section ul a:active {
    color: #e9c87b;
  }
  .doublelist {
    ul {
      li {
        width: 50%;
        float: left;
      }
    }
  }
  .col-sm-3 {
    &.noppading {
      &.formnews {
        p {
          color: #ccc;
        }
      }
    }
  }
  .formnews {
    form {
      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e9c87b;
        width: 70%;
        height: 39px;
      }
    }
  }
  .subcribe {
    background: #e9c87b;
    border: 1px solid #e9c87b;
    width: 30%;
    margin-left: -4px;
    color: #000;
    font-weight: bold;
    height: 40px;
    text-transform: capitalize;
    padding: 5px 0px;
    font-weight: bold;
  }
  .footer-logo {
    padding-top: 20px;
  }
  .home-section-top {
    padding-top: 1%;
  }
  #Australia-continent {
    g {
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover {
      g {
        opacity: 0.5;
      }
    }
  }
  .foter-bottom {
    color: #fff;
    padding: 20px 0px 20px 0px;
    background: #4094a9;
    ul {
      padding: 0px;
      padding-bottom: 0px;
      list-style: none;
      padding-bottom: 10px;
      li {
        display: inline-block;
        a {
          color: #fff;
          padding: 0px 2px;
        }
      }
      .fa {
        font-size: 20px;
        vertical-align: middle;
        color: #999999;
        padding: 0px 4px;
      }
    }
    .col-sm-4 {
      &.text-right {
        p {
          color: #ccc;
        }
      }
    }
  }
  .advertisement-images {
    padding: 0pc;
    padding-top: 0pc;
    list-style: none;
    padding-top: 25px;
    li {
      .img-responsive {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .bgbluew {
    background: $base_color;
    height: 280px;
    display: block;
  }
  .content-innerpages {
    position: relative;
    margin-top: -130px;
    background: #fff;
    padding: 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 5%;
    min-height: 44.9em;
    border-radius: 15px;
    h2 {
      color: #333;
      text-transform: capitalize;
      // font-weight: 700;
      position: relative;
      font-size: 40px;
      &::after {
        content: "";
        height: 3px;
        width: 100px;
        background: $base_color;
        position: absolute;
        bottom: -5px;
        left: 0;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      width: 100%;
      li {
        width: 100%;
        list-style: none !important;
        padding-left: 30px;
        position: relative;
        color: rgb(102, 102, 102) !important;
        font-size: 18px;
        margin: 5px 0px;
        &:before {
          background-image: url(../../assets/images/list-bullet.png);
          background-repeat: no-repeat;
          background-position: left top;
          position: absolute;
          left: 0px;
          top: 10px;
          content: "";
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .bototmmcontent {
    padding-top: 3%;
    padding-top: 3%;
    color: #4b4b4b;
    ul {
      li {
        padding: 5px 0px;
      }
    }
    h5 {
      padding: 12px 0px 0px 0px;
    }
  }
  //Custom css
  a.active.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.inactive.nav-link {
    color: #fff !important;
    background: transparent;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .navigation {
    transition: 0.5s ease;
  }
  .navigation.active {
    background: $base_color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $base_color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }
  .dynamic-form {
    width: auto;
  }
  .controls {
    background: #f9f9f9;
  }
  .navigation .row {
    width: 100%;
  }
  .header-section .fixed-top ul .nav-item a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  a.header-button.nav-link {
    // background: #e9c87b;
    // border-radius: 4px;
    // color: #fff !important;
    // opacity: 1;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 9px 30px;
    text-transform: uppercase;
  }

  .navbar-brand {
    margin-right: 0px;
    .img-responsive {
      max-height: 70px;
    }
  }
  .dynamic-form {
    margin: 0px -15px;
    padding: 0px;
    flex-direction: inherit;
    flex-wrap: wrap;
    .space-between {
      padding: 0px 15px;
      margin: 5px 0 5px 0;
      &.half-width {
        width: 50%;
        .date-box__field {
          width: 100%;
          color: #7c7d7d;
        }
      }
      .radio_box_root {
        border: none !important;
        .MuiFormControl-root {
          padding: 0px !important;
          label {
            font-weight: 500 !important;
          }
        }
      }
      .MuiButtonBase-roo {
        height: 60px;
      }
    }
  }
  span.anchorLink {
    color: cornflowerblue;
    cursor: pointer;
  }

  /*============thankyou page=============*/
  .bg-blue {
    height: 100px;
    background: #06124e;
  }
  .descript-thankyou {
    text-align: center;
    padding: 21% 0%;
  }
  .descript-thankyou .img-fluid {
    width: auto;
    height: 200px;
    margin-bottom: 20px;
  }
  .descript-thankyou h1 {
    font-weight: 700;
    font-size: 66px;
  }
  .descript-thankyou p {
    font-size: 20px;
    width: 60%;
    margin: 0px auto;
    font-weight: 400;
  }
  .button-home a {
    background: $base_color;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    padding: 11px 25px;
    display: inline-block;
    margin-top: 40px;
    border: 2px solid $base_color;
    text-decoration: none;
    &:hover {
      background: #fff;
      color: $base_color;
      border: 2px solid $base_color;
    }
  }

  .thankyou-section {
    //background: url("../images/bbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    background-position: center;
  }
  .thankyou-section:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .tankkyou-container {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  a.homebtn.section-4 {
    color: #ffffff;
    text-decoration: none;
    background: #06124e;
    border: 1px solid #06124e;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 45px;
    margin: 40px 0px 0px 0px;
    font-size: 18px;
  }

  /************new css 2020***************/
  .testimonialsArea {
    p {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 40px !important;
      color: #333333 !important;
      span {
        font-size: 40px !important;
        color: #333333 !important;
      }
      strong {
        font-size: 40px !important;
        color: #333333 !important;
        font-weight: 500;
      }
    }
  }
  .content-footer {
    p.testimonialsList {
      font-size: 18px !important;
      color: #8a8a8a !important;
      span {
        font-size: 18px !important;
        color: #8a8a8a !important;
      }
      strong {
        font-size: 18px !important;
        color: #8a8a8a !important;
        font-weight: 400;
        display: inline-block;
        width: 100%;
      }
    }
    blockquote {
      border-radius: 15px;
      border: 1px solid #d4d4d4;
      background: #fff;
      padding: 30px;
      background-image: url(../../assets/images/quote.png);
      background-repeat: no-repeat;
      background-position: 30px 20px;
      text-align: left;
      padding: 50px 50px 50px 150px;
      font-size: 20px;
      color: #8a8a8a;
      min-height: 230px;
    }
  }

  .getlink {
    a {
      background: $base_color;
      color: #fff;
      padding: 15px 40px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      border: 2px solid #fff;
      &:hover {
        background: #fff !important;
        color: $base_color;
        border: 2px solid $base_color;
      }
    }
  }

  input.form_button {
    height: 45px;
    width: 100%;
    text-transform: uppercase;
    border: solid 2px $base_color;
    color: #fff;
    background: $base_color;
    margin-bottom: 10px;
    border-radius: 2px;
    &:hover {
      transition: 0.5s;
      transform-origin: center;
      border: solid 2px $base_color;
      color: $base_color;
      background: #ffffff;
    }
  }

  .header {
    .navbar-nav {
      li {
        a.nav-link {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          text-transform: uppercase;
          color: #eaeaea;
          font-weight: 500;
          cursor: pointer;
          &.header-button {
            &:hover {
              background: #fff;
              color: $base_color;
            }
          }
        }
        &.lastbtn {
          padding-left: 20px;
        }
      }
    }
  }

  .innerPageContent {
    .container {
      max-width: 100% !important;
    }
  }

  .surveyForm {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: calc(100vh - 234px);
    padding: 0px 15px;
    margin-top: -100px;
    .form-survey-section {
      width: 700px;
      max-width: 100%;
      background: #fff;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      margin: auto;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      padding: 50px 50px 90px;
      .newSurveyHeading {
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
        color: $base_color;
      }
      .newSurveyContent {
        display: inline-block;
        width: 100%;
        margin-bottom: 40px;
        // min-height: 300px;
        .questionSteps {
          .MuiFormLabel-root {
            font-size: 20px;
            padding-left: 4px !important;
          }
        }
      }
    }

    input.input-box__field.input-box__field--survey.false,
    input.input-box__field.input-box__field--survey.input-box__field--error {
      height: 35px;
    }
    .date-box__field--survey {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid #c5c5c5;
      border-left: none;
    }
    .date-box__field--survey_hoverColor {
      width: 100%;
      height: 44px;
      color: #7c7d7d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 2px solid $base_color;
      border-left: none;
      font-size: 16px !important;
      font-weight: 500;
    }
    .newSurveyFooter {
      display: inline-block;
      width: 100%;
      padding-top: 0;
      .backBtn {
        border: 2px solid #ccc;
        color: #ccc;
        padding: 12px 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 0;
      }
      .nextBtn {
        border: 2px solid $base_color;
        color: #fff;
        padding: 12px 30px;
        text-transform: uppercase;
        background: $base_color;
        letter-spacing: 1px;
        border-radius: 0;
      }
    }
    .progressBar {
      background: $base_color;
      position: absolute;
      bottom: 0;
      height: 40px;
      left: 0;
      width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      text-align: center;
      .progressBarContainer {
        height: 40px;
        overflow: hidden;
        margin: auto 0;
        display: inline-block;
        .progressBarText {
          color: #fff;
          font-size: 15px;
          display: block;
          background-repeat: no-repeat;
          background-position: 50%;
          margin-right: 14px;
          height: 40px;
          line-height: 40px;
          float: left;
        }
        .bar {
          width: 200px;
          border-radius: 2px;
          height: 8px;
          float: left;
          margin-top: 16px;
          border: 1px solid #fff;
          border-radius: 5px;
          span {
            background-color: #fff;
            height: 8px;
            border-radius: 2px;
            float: left;
          }
        }
      }
    }
  }
  .space-between {
    .input-box__field {
      width: 100%;
      height: 45px;
    }
  }

  button.btn.btn-submit {
    /* background-color: #fff; */
    border: 2px solid $base_color;
    border-radius: 0px;
    color: $base_color;
    padding: 12px 30px;
    text-transform: uppercase;
    background: #fff;
    letter-spacing: 1px;
    border-radius: 2px;
  }
  input.date-box__field.false {
    height: 47px;
    width: -webkit-fill-available;
  }
  //******** Accordian css**********//

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: darken($base_color, 5%);
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $base_color;
      font-weight: normal;
      cursor: pointer;
      margin-bottom: 0px;
      font-size: 20px;
      font-family: Helvetica;
      /* Icon */
      &:hover {
        background: darken($base_color, 5%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: #000;
      background: white;
      transition: all 0.35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $base_color;
      cursor: pointer;
      &:hover {
        background: darken($base_color, 5%);
      }
    }
    &:last-child {
      border: none;
    }
  }
  .tab input[type="checkbox"] {
    display: none;
  }
  // :checked
  input:checked {
    + .tab-label {
      background: darken($base_color, 5%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
    }
  }
  //******** Accordian css end**********//
  /*=============media============*/

  @media (max-width: 1200px) {
    .form-wrapper.nav-stacked {
      width: auto;
      margin-right: 15px;
    }
    .navigation.active {
      position: fixed;
    }
  }

  @media (max-width: 1024px) {
    .heading-banner {
      padding-top: 22%;
      h1 {
        font-size: 40px;
      }
      h5 {
        strong {
          font-size: 20px;
        }
      }
    }
    .form-wrapper {
      &.nav-stacked {
        margin-left: 0px;
        margin-right: 0px;
        padding: 20px;
        max-height: calc(100vh - 150px);
        h5 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
    .dynamic-form {
      .space-between {
        margin: 7px 0 7px 0;
      }
    }
    .brands-section {
      padding-top: 50px;
      .sideContent {
        span {
          line-height: normal;
          font-size: 20px !important;
        }
        ul {
          li {
            margin: 7px 0px;
          }
        }
      }
    }

    .brands-images {
      .featureeText {
        font-size: 20px;
        line-height: 26px;
      }
      .seperation-new {
        .row {
          vertical-align: top;
          align-items: normal;
          .textContent {
            img {
              max-width: 120px;
            }
            p {
              margin-left: 135px;
            }
          }
        }
      }
    }

    .content-footer {
      blockquote {
        background-size: 40px;
        background-position: 15px 15px;
        padding: 15px 15px 15px 70px;
        font-size: 18px;
        min-height: 170px;
      }
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-image: none !important;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .text-contentbanner {
      padding-top: 8rem;
      // display: block !important;
    }
    .col-md-4.right-side {
      max-width: 100% !important;
    }
    .col-sm-8.left-side {
      max-width: 100%;
      padding-top: 30px;
    }
    section.brands-section .row {
      display: block;
    }
    section.brands-section .col-sm-8 {
      max-width: 100%;
      width: 100% !important;
    }
    .paragraph-alignment-new .row .col-sm-6 {
      max-width: 100%;
    }
    .quickeasy .row {
      display: block;
      text-align: center;
    }
    .brands-images .seperation-new .col-sm-4 {
      /* max-width: 100%; */
      display: inline-block;
      vertical-align: middle;
    }
    .header-or-banner.clearfix {
      background: #000;
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      padding: 7px 15px;
      display: inline-block;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }

    .navbar-collapse .navbar-nav:last-child {
      display: block;
      padding-bottom: 20px;
    }
    .quickeasy .col-sm-8 {
      max-width: 100%;
      margin-left: 0px;
    }
    a.inactive.nav-link {
      color: #fff !important;
    }
    a.header-button.nav-link {
      background: #e9c87b;
      text-align: center;
      margin-left: 20px;
      &:hover {
        background: #fff !important;
        color: $base_color !important;
      }
    }
    .navbar-dark .navbar-toggler {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      // background: #131313;
      position: absolute;
      right: 15px;
      top: 12px;
    }
    .form-wrapper.nav-stacked {
      float: none;
    }
    .text-contentbanner .col-sm-8.left-side {
      width: 100%;
    }
    .heading-banner {
      padding-bottom: 40px;
    }
    .banner-home.hero {
      padding-bottom: 30px;
    }
    .brands-section .col-sm-8 {
      width: 100%;
    }
    .quickeasy .col-sm-4 {
      width: 50%;
    }
    .content-footer .col-sm-4 {
      width: 50%;
    }
    .content-footer .row .col-sm-4 {
      max-width: 100%;
      width: 100%;
    }
    // .content-footer .row {
    //   display: block;
    // }
    // .navigation.active {
    //   position: relative;
    // }
    .navbar-brand {
      .img-responsive {
        max-height: 50px;
      }
    }
    .banner-home.hero {
      min-height: auto;
      .waveImg {
        background-size: auto;
      }
    }
    .text-contentbanner {
      display: block;
      .left-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .heading-banner {
          padding-top: 15%;
        }
      }
      .right-side {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .form-wrapper {
          &.nav-stacked {
            width: 600px;
            max-width: 100%;
            margin: auto;
          }
        }
      }
    }
    .brands-section {
      .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .sideContent {
          margin-top: 20px;
        }
      }
    }

    .form-wrapper {
      &.nav-stacked {
        h5 {
          font-size: 34px;
          line-height: 38px;
        }
      }
    }
    .content-footer {
      blockquote {
        min-height: 200px;
      }
    }
    .navbar-collapse .navbar-nav:last-child a.header-button.nav-link {
      background: transparent;
      text-align: left;
      margin-left: 0px;
      border: none;
      padding-left: 0px;
    }
    .navbar-collapse .navbar-nav:last-child {
      padding-bottom: 0px;
    }
    .surveyForm {
      margin-bottom: 40px;
    }
    .surveyForm {
      padding: 15px 0px !important;
      background-size: contain;

      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
    .navigation {
      padding: 10px 15px !important;
    }
  }
  @media (max-width: 767px) {
    .top-header p.text-right {
      float: none;
    }
    .copyright-footer .row {
      display: block;
    }
    .copyright-footer .row .col-sm-6 {
      max-width: 100%;
      text-align: center;
      padding: 5px 15px;
    }
    .quickeasy .col-sm-4 {
      width: 100%;
      text-align: center;
    }
    .header-or-banner.clearfix {
      background: #fff;
    }
    .content-footer .col-sm-4 {
      width: 100%;
    }
    #navbarResponsive ul li a {
      color: #131313;
    }
    .quickeasy ul {
      line-height: normal;
      padding-top: 20px;
    }
    .nav.navbar-nav.navbar-right li {
      padding-left: 15px;
    }

    .header-or-banner.clearfix {
      // position: relative;
    }
    .article-heading h1 {
      font-size: 20px;
    }
    .choosearea-section svg {
      width: 100%;
    }
    .navbar-inverse .navbar-toggle {
      margin-left: 10px;
      background: #131313;
    }
    .nav.navbar-nav.navbar-right li .img-responsive {
      padding: 0px 5px 0px 0px;
    }
    .top-header p {
      display: block;
      text-align: center !important;
    }
    .navigation {
      padding: 12px 10px;
      background: $base_color;
    }
    .navigation a.navbar-brand img.img-responsive {
      width: auto;
      height: 40px;
      padding-left: 15px;
      padding-top: 6px;
      position: relative;
      top: -5px;
    }
    .navigation.active {
      padding: 10px 10px;
      // position: relative;
    }
    #navbarResponsive .nav.navbar-nav.navbar-right li a {
      display: inline-block;
    }
    .text-contentbanner {
      width: 100%;
      padding-top: 2rem;
      .heading-banner {
        h1 {
          font-size: 30px;
        }
        h5 strong {
          font-size: 18px;
        }
      }
    }
    .form-wrapper.nav-stacked {
      h5 {
        font-size: 26px;
        line-height: 30px;
      }
    }
    .brands-section {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .brands-images {
      padding: 15px;
      .seperation-new {
        .row {
          .textContent {
            img {
              float: none;
              margin: auto;
            }
            p {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .paragraph-alignment-new .getlink {
      padding-top: 0px;
    }
    .testimonialsArea h2 strong {
      font-size: 30px !important;
    }
    .form-wrapper.nav-stacked {
      max-height: none;
    }
    .content-innerpages {
      padding: 15px;
    }
    .bgbluew {
      height: 200px;
    }
    .bototmmcontent strong {
      font-weight: 500;
    }
    .bototmmcontent h2 {
      font-size: 24px !important;
      margin-top: 15px !important;
      &::after {
        display: none;
      }
    }
    .bototmmcontent p {
      word-break: break-word;
    }
    .content-innerpages h2 {
      font-size: 30px;
    }
    .surveyForm {
      padding: 15px !important;
      background-image: none !important;
      min-height: auto;
      .form-survey-section {
        .newSurveyForm {
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .brands-images .seperation-new .col-sm-4 img.img-responsive {
      width: 100%;
    }
    .content-footer blockquote {
      min-height: auto;
    }
    .innerpage .container {
      padding: 0px;
    }
    .bototmmcontent .row .col-sm-12 {
      padding: 0px 0px !important;
      h3 {
        font-weight: 400 !important;
        margin-bottom: 20px;
        margin-top: 0px;
      }
    }
    .innerPageContent {
      padding-top: 20px !important;
    }
    .surveyForm .form-survey-section {
      padding: 15px 15px 60px;
    }
    .newSurveyHeading {
      font-size: 1.5rem;
    }
    .surveyForm
      .form-survey-section
      .newSurveyContent
      .questionSteps
      .MuiFormLabel-root {
      font-size: 16px;
      font-weight: 500;
    }
    .surveyForm .progressBar .progressBarContainer .bar {
      width: 120px;
    }
  }
}
