.seventh_layout_global{
    svg{
        max-width:100%;
        height:auto;
    }
        .header{
            z-index:11;
                .navbar-nav{
                    .nav-link{
                        border-radius:5px;
                        font-weight:600;
                        letter-spacing:1.5px;
                        text-transform:uppercase;
                        font-size: 14px;
                        padding: 13px 25px;
                    }
                }
            &.active{
                position:fixed;
                left:0;
                top:0;
                width:100%;
            }
        }
        .seventhLayoutBlog{
            float:left;
            width:100%;
            .seventhLayoutBlogHeader{
                max-width: 1020px;
                margin-top: 40px;
                margin-right: auto;
                margin-left: auto;
                -webkit-align-self: center;
                -ms-flex-item-align: center;
                -ms-grid-row-align: center;
                align-self: center;
                h1{
                    max-width: 100%;
                    margin: 0 auto 20px;
                    font-size: 55px;
                    line-height: 1.1;
                    font-weight: 600;
                    text-align: center;
                }
                .profileHeader{
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    margin:20px auto 0 auto;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    .firstLetter{
                        width: 50px;
                        height: 50px;
                        margin-right: 10px;
                        border-radius: 100px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 28px;
                        font-weight: 500;
                    }
                    .autherName{
                        margin-top: 0;
                        margin-bottom: 0;
                        color: #313338;
                        font-size: 18px;
                        font-weight:600;
                    }
                    .blogDate{
                        margin-bottom: 0;
                        color: #9899ad;
                        font-size: 12px;
                    }
                }
            }
            .seventhLayoutFeatureImg{
                margin: 60px auto 60px;
                border-radius: 12px;
                max-width: 1140px;
                overflow:hidden;
                img{
                    max-width:100%;
                }
            }
            .blogSeventhContent{
                width: 100%;
                height: 100%;
                max-width: 880px;
                margin-right: auto;
                margin-bottom: 40px;
                margin-left: auto;
                .ContentSectionBlog{
                    display: block;
                    h2{
                        margin-top: 60px;
                        font-size: 28px;
                        line-height: 140%;
                        text-align: left;
                        font-weight:600;
                    }
                    p{
                        margin-top: 0;
                        margin-bottom: 30px;
                        font-size: 16px;
                        line-height: 1.5em;
                        font-weight: 400;
                        .big-text{
                            float: left;
                            font-size: 70px;
                            font-weight: 700;
                            margin: 17px 0 20px;
                            margin-right:5px;
                        }
                    }
                    .ChooseOptions{
                        max-width:100%;
                        display:flex;
                        flex-wrap: wrap;
                        border-radius:10px;
                        padding:40px;
                        margin:40px auto;
                        h4{
                            font-size: 22px;
                            line-height: 140%;
                            text-align: left;
                            font-weight:600;
                            width:100%;
                            padding-bottom:15px;
                            margin-bottom:15px;
                        }
                        .chooseGroup{
                            display:inline-block;
                            width: 100%;
                            ul{
                                display:flex;
                                flex-wrap:wrap;
                                max-width:100%;
                                margin:0px -10px;
                                padding:0px;
                                li{
                                    flex:0 0 20%;
                                    max-width:20%;
                                    list-style:none;
                                    padding:10px;
                                    a{
                                        padding:15px;
                                        float:left;
                                        width:100%;
                                        border-radius:5px;
                                        position: relative;
                                        text-decoration:none;
                                        transition:0.5s;
                                        &:after{
                                            width: 22px;
                                            height: 22px;
                                            position: absolute;
                                            right: 10px;
                                            top: 18px;
                                            border-radius: 50%;
                                            content: "";
                                            background-size: 16px;
                                            background-repeat: no-repeat;
                                            background-position: 3px 3px;
                                            display:none;
                                        }
                                        &:hover{
                                            box-shadow:none;
                                            transform: translate(0px, -5px);
                                            &::after{
                                                display:block;
                                            }
                                        }
                                    }
                                }
                            }
                            &.block-4{
                                ul{
                                    li{
                                        flex:0 0 25%;
                                        max-width:25%;
                                    }
                                }
                            }
                            &.block-3{
                                ul{
                                    li{
                                        flex:0 0 33.3333%;
                                        max-width:33.3333%;
                                    }
                                }
                            }
                        }
                    }

                    .mapSectionSeventh {
                        text-align: center;
                        padding: 40px;
                        display: inline-block;
                        width: 100%;
                        border-radius: 10px;
                        .compareNowMap{
                            display:inline-block;
                            width:100%;
                            margin-top:40px;
                            .compareNowMapBtn{
                                border-radius:5px;
                                display:inline-block;
                                padding:15px 40px;
                                transition: 0.5s;
                                text-decoration:none;
                                font-weight:600;
                                text-transform:uppercase;
                                letter-spacing: 1.3px;
                                &:hover{
                                    transform: translate(0px, -5px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .section-footer{
            display: inline-block;
            width: 100%;
            padding: 40px 0px;
            text-align: center;
            .copyright{
                p{
                    font-size: 14px;
                    margin-bottom: 0px;
                }
            }
            .footer-content {
                float: left;
                width: 100%;
                .custome-links {
                    display: inline-block;
                    width: 100%;
                    ul {
                        float: left;
                        width: 100%;
                    }
                }
            }

        }



        @media (max-width: 1024px){
            .navbar-toggler{
                position: absolute;
                right: 15px;
                top: 17px;
            }
        }
        
        @media (max-width: 992px) {
            .seventhLayoutBlog {
                .seventhLayoutBlogHeader {
                    h1{
                        font-size:42px;
                    }
                    .profileHeader{
                        .firstLetter{
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
                .blogSeventhContent {
                    .ContentSectionBlog {
                        .ChooseOptions {
                            .chooseGroup{
                                ul{
                                    li{
                                        flex:0 0 33.3333%;
                                        max-width:33.3333%;
                                    }
                                }
                                &.block-4 {
                                    ul {
                                        li{
                                            flex: 0 0 50%;
                                            max-width: 50%;
                                        }
                                    }
                                }
                                &.block-3 {
                                    ul {
                                        li{
                                            flex: 0 0 50%;
                                            max-width: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }

        @media (max-width: 767px) {
            .seventhLayoutBlog {
                .seventhLayoutBlogHeader {
                    h1{
                        font-size:32px;
                    }
                }
                .seventhLayoutFeatureImg{
                    margin: 30px auto 30px;
                }
                .blogSeventhContent {
                    .ContentSectionBlog {
                        h2{
                            font-size:22px;
                        }
                        .ChooseOptions{
                            padding:20px;
                        }
                        .mapSectionSeventh{
                            padding:20px;
                        }
                    }
                }
            }
            .section-footer{
                padding:15px 0px;
            }
        }

        @media (max-width: 560px) {
           .seventhLayoutBlog{
                .seventhLayoutBlogHeader{
                    h1 {
                        font-size: 26px;
                    }
                }
                .blogSeventhContent {
                    .ContentSectionBlog {
                        .ChooseOptions {
                            .chooseGroup{
                                ul{
                                    margin:0px;
                                    li{
                                        flex:0 0 100%;
                                        max-width:100%;
                                        padding:5px 0px;
                                    }
                                }
                                &.block-4 {
                                    ul {
                                        li{
                                            flex: 0 0 100%;
                                            max-width: 100%;
                                        }
                                    }
                                }
                                &.block-3 {
                                    ul {
                                        li{
                                            flex: 0 0 100%;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
           } 
        }


        /*****************************************************
                            Theme Css
        ******************************************************/

        .seventh_blog_default-theme{
            $base-color:#44CB86;
            .header{
                background:$base-color;
                    .navbar-nav{
                        .nav-link{
                            background:#fff;
                            color:$base-color;
                            box-shadow: -5px 22px 10px -17px rgba(46, 89, 204, 0.45);
                        }
                    }
                    .navbar-toggler{
                        background:#fff;
                    }
            }
                .seventhLayoutBlog{
                    background-image:linear-gradient(180deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.95)),url(../../assets/images/rays.svg);
                    .seventhLayoutBlogHeader{
                        h1{
                            color: #0a0535;
                        }
                        .profileHeader{
                            .firstLetter{
                                background-color: #252525;
                                color: #fff;
                            }
                            .autherName{
                                color: #313338;
                            }
                            .blogDate{
                                color: #9899ad;
                            }
                        }
                    }
                    .seventhLayoutFeatureImg{
                        box-shadow: 21px 21px 100px 0 rgba(0,0,0,.1);
                    }
                    .blogSeventhContent{
                        .ContentSectionBlog{
                            p{
                                color: #4e4e4e;
                            }
                            .ChooseOptions{
                                background: $base-color;
                                h4{
                                    color:#fff;
                                    border-bottom:1px dashed rgba(256, 256, 256, 0.7);
                                }
                                .chooseGroup{
                                    ul{
                                        li{
                                            a{
                                                background:#fff;
                                                color: #717171;
                                                box-shadow:0px 5px 5px rgba(0,0,0,.1);
                                                &:after{
                                                    background-color: $base-color;
                                                    background-image: url(../../assets/images/yes-white.svg);
                                                }
                                                &:hover{
                                                    border-color: $base-color;
                                                    color:$base-color;
                                                    box-shadow:none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .mapSectionSeventh {
                                background: #fafafb;
                                .compareNowMap{
                                    .compareNowMapBtn{
                                        background:$base-color;
                                        color:#fff;
                                        box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-footer{
                    border-top: 1px solid #ddd;
                    .copyright{
                        p{
                            color:#A8A8A8;
                        }
                    }
                    .footer-content {
                        .custome-links {
                            ul {
                                li{
                                    border-right:1px solid #ddd;
                                    a{
                                        color:#A8A8A8;
                                    }
                                    &:last-child{
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
            
                }
        }


        .seventh_blog_first-theme{
            $base-color:#1b283f;
            .header{
                background:$base-color;
                    .navbar-nav{
                        .nav-link{
                            background:#fff;
                            color:$base-color;
                            box-shadow: -5px 22px 10px -17px rgba(46, 89, 204, 0.45);
                        }
                    }
                    .navbar-toggler{
                        background:#fff;
                    }
            }
                .seventhLayoutBlog{
                    background-image:linear-gradient(180deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.95)),url(../../assets/images/rays.svg);
                    .seventhLayoutBlogHeader{
                        h1{
                            color: #0a0535;
                        }
                        .profileHeader{
                            .firstLetter{
                                background-color: #252525;
                                color: #fff;
                            }
                            .autherName{
                                color: #313338;
                            }
                            .blogDate{
                                color: #9899ad;
                            }
                        }
                    }
                    .seventhLayoutFeatureImg{
                        box-shadow: 21px 21px 100px 0 rgba(0,0,0,.1);
                    }
                    .blogSeventhContent{
                        .ContentSectionBlog{
                            p{
                                color: #4e4e4e;
                            }
                            .ChooseOptions{
                                background: $base-color;
                                h4{
                                    color:#fff;
                                    border-bottom:1px dashed rgba(256, 256, 256, 0.7);
                                }
                                .chooseGroup{
                                    ul{
                                        li{
                                            a{
                                                background:#fff;
                                                color: #717171;
                                                box-shadow:0px 5px 5px rgba(0,0,0,.1);
                                                &:after{
                                                    background-color: $base-color;
                                                    background-image: url(../../assets/images/yes-white.svg);
                                                }
                                                &:hover{
                                                    border-color: $base-color;
                                                    color:$base-color;
                                                    box-shadow:none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .mapSectionSeventh {
                                background: #fafafb;
                                .compareNowMap{
                                    .compareNowMapBtn{
                                        background:$base-color;
                                        color:#fff;
                                        box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-footer{
                    border-top: 1px solid #ddd;
                    .copyright{
                        p{
                            color:#A8A8A8;
                        }
                    }
                    .footer-content {
                        .custome-links {
                            ul {
                                li{
                                    border-right:1px solid #ddd;
                                    a{
                                        color:#A8A8A8;
                                    }
                                    &:last-child{
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
            
                }
        }

        .seventh_blog_second-theme{
            $base-color:#7773fa;
            .header{
                background:$base-color;
                    .navbar-nav{
                        .nav-link{
                            background:#fff;
                            color:$base-color;
                            box-shadow: -5px 22px 10px -17px rgba(46, 89, 204, 0.45);
                        }
                    }
                    .navbar-toggler{
                        background:#fff;
                    }
            }
                .seventhLayoutBlog{
                    background-image:linear-gradient(180deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.95)),url(../../assets/images/rays.svg);
                    .seventhLayoutBlogHeader{
                        h1{
                            color: #0a0535;
                        }
                        .profileHeader{
                            .firstLetter{
                                background-color: #252525;
                                color: #fff;
                            }
                            .autherName{
                                color: #313338;
                            }
                            .blogDate{
                                color: #9899ad;
                            }
                        }
                    }
                    .seventhLayoutFeatureImg{
                        box-shadow: 21px 21px 100px 0 rgba(0,0,0,.1);
                    }
                    .blogSeventhContent{
                        .ContentSectionBlog{
                            p{
                                color: #4e4e4e;
                            }
                            .ChooseOptions{
                                background: $base-color;
                                h4{
                                    color:#fff;
                                    border-bottom:1px dashed rgba(256, 256, 256, 0.7);
                                }
                                .chooseGroup{
                                    ul{
                                        li{
                                            a{
                                                background:#fff;
                                                color: #717171;
                                                box-shadow:0px 5px 5px rgba(0,0,0,.1);
                                                &:after{
                                                    background-color: $base-color;
                                                    background-image: url(../../assets/images/yes-white.svg);
                                                }
                                                &:hover{
                                                    border-color: $base-color;
                                                    color:$base-color;
                                                    box-shadow:none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .mapSectionSeventh {
                                background: #fafafb;
                                .compareNowMap{
                                    .compareNowMapBtn{
                                        background:$base-color;
                                        color:#fff;
                                        box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-footer{
                    border-top: 1px solid #ddd;
                    .copyright{
                        p{
                            color:#A8A8A8;
                        }
                    }
                    .footer-content {
                        .custome-links {
                            ul {
                                li{
                                    border-right:1px solid #ddd;
                                    a{
                                        color:#A8A8A8;
                                    }
                                    &:last-child{
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
            
                }
        }

        .seventh_blog_second-theme{
            $base-color:#7773fa;
            .header{
                background:$base-color;
                    .navbar-nav{
                        .nav-link{
                            background:#fff;
                            color:$base-color;
                            box-shadow: -5px 22px 10px -17px rgba(46, 89, 204, 0.45);
                        }
                    }
                    .navbar-toggler{
                        background:#fff;
                    }
            }
                .seventhLayoutBlog{
                    background-image:linear-gradient(180deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.95)),url(../../assets/images/rays.svg);
                    .seventhLayoutBlogHeader{
                        h1{
                            color: #0a0535;
                        }
                        .profileHeader{
                            .firstLetter{
                                background-color: #252525;
                                color: #fff;
                            }
                            .autherName{
                                color: #313338;
                            }
                            .blogDate{
                                color: #9899ad;
                            }
                        }
                    }
                    .seventhLayoutFeatureImg{
                        box-shadow: 21px 21px 100px 0 rgba(0,0,0,.1);
                    }
                    .blogSeventhContent{
                        .ContentSectionBlog{
                            p{
                                color: #4e4e4e;
                            }
                            .ChooseOptions{
                                background: $base-color;
                                h4{
                                    color:#fff;
                                    border-bottom:1px dashed rgba(256, 256, 256, 0.7);
                                }
                                .chooseGroup{
                                    ul{
                                        li{
                                            a{
                                                background:#fff;
                                                color: #717171;
                                                box-shadow:0px 5px 5px rgba(0,0,0,.1);
                                                &:after{
                                                    background-color: $base-color;
                                                    background-image: url(../../assets/images/yes-white.svg);
                                                }
                                                &:hover{
                                                    border-color: $base-color;
                                                    color:$base-color;
                                                    box-shadow:none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .mapSectionSeventh {
                                background: #fafafb;
                                .compareNowMap{
                                    .compareNowMapBtn{
                                        background:$base-color;
                                        color:#fff;
                                        box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-footer{
                    border-top: 1px solid #ddd;
                    .copyright{
                        p{
                            color:#A8A8A8;
                        }
                    }
                    .footer-content {
                        .custome-links {
                            ul {
                                li{
                                    border-right:1px solid #ddd;
                                    a{
                                        color:#A8A8A8;
                                    }
                                    &:last-child{
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
            
                }
        }
        

        .seventh_blog_third-theme{
            $base-color:#4094a9;
            .header{
                background:$base-color;
                    .navbar-nav{
                        .nav-link{
                            background:#fff;
                            color:$base-color;
                            box-shadow: -5px 22px 10px -17px rgba(46, 89, 204, 0.45);
                        }
                    }
                    .navbar-toggler{
                        background:#fff;
                    }
            }
                .seventhLayoutBlog{
                    background-image:linear-gradient(180deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.95)),url(../../assets/images/rays.svg);
                    .seventhLayoutBlogHeader{
                        h1{
                            color: #0a0535;
                        }
                        .profileHeader{
                            .firstLetter{
                                background-color: #252525;
                                color: #fff;
                            }
                            .autherName{
                                color: #313338;
                            }
                            .blogDate{
                                color: #9899ad;
                            }
                        }
                    }
                    .seventhLayoutFeatureImg{
                        box-shadow: 21px 21px 100px 0 rgba(0,0,0,.1);
                    }
                    .blogSeventhContent{
                        .ContentSectionBlog{
                            p{
                                color: #4e4e4e;
                            }
                            .ChooseOptions{
                                background: $base-color;
                                h4{
                                    color:#fff;
                                    border-bottom:1px dashed rgba(256, 256, 256, 0.7);
                                }
                                .chooseGroup{
                                    ul{
                                        li{
                                            a{
                                                background:#fff;
                                                color: #717171;
                                                box-shadow:0px 5px 5px rgba(0,0,0,.1);
                                                &:after{
                                                    background-color: $base-color;
                                                    background-image: url(../../assets/images/yes-white.svg);
                                                }
                                                &:hover{
                                                    border-color: $base-color;
                                                    color:$base-color;
                                                    box-shadow:none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .mapSectionSeventh {
                                background: #fafafb;
                                .compareNowMap{
                                    .compareNowMapBtn{
                                        background:$base-color;
                                        color:#fff;
                                        box-shadow: -5px 30px 20px -16px rgba(46, 89, 204, 0.45);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-footer{
                    border-top: 1px solid #ddd;
                    .copyright{
                        p{
                            color:#A8A8A8;
                        }
                    }
                    .footer-content {
                        .custome-links {
                            ul {
                                li{
                                    border-right:1px solid #ddd;
                                    a{
                                        color:#A8A8A8;
                                    }
                                    &:last-child{
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
            
                }
        }

}