/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.noSurvey {
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: 400;
}

.error-container {
  text-align: left;
}

.disclaimer_survey {
  background: #fff;
  p {
    font-size: 16px;
    padding: 10px 0px;
    text-align: left;
  }
}

/************new template first layout css***************/

.layout_first-demo-default-theme {
  $color: #13ac8f;
  $barcolor: #b6eee3;
  $primaryColor: #fff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #b6e4db;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $color;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $color;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              // border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
    max-height: 44px;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }
  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #e2fff9;
  $yellowColor: #fff;
  $newColor: #cef2eb;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      min-height: calc(100vh - 218px);
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .surveyContent {
      min-height: calc(100vh - 243px);
      .surveyPageText.text-left {
        margin: 0px;
        height: auto;
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-first-theme {
  $color: #1b283f;
  $barcolor: #abbcdc;
  $primaryColor: #bfd3fa;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: $color;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              // border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #ebf2ff;
  $yellowColor: #fbc417;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $primaryColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $yellowColor;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: #000;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-second-theme {
  $color: #7773fa;
  $barcolor: #cccbf9;
  $primaryColor: #fff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        // padding-top: 40px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $barcolor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #000;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $barcolor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              //  border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }
  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #f6f5ff;
  $yellowColor: #fff;
  $newColor: #e2dfff;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-third-theme {
  $color: #4094a9;
  $barcolor: #c2e2e8;
  $primaryColor: #fff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            //  width: 124px;
            //  height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            //   margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $barcolor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #000;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $barcolor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              //  border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }
  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #e5faff;
  $yellowColor: #fff;
  $newColor: #bff3ff;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-sixth-theme {
  @import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
  $color: #67268f;
  $barcolor: #d8bbeb;
  $primaryColor: #fff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f2f2f4;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 0px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            //  width: 124px;
            //  height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            //   margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          // border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: linear-gradient(to right, #e10a0a, #cc0858, #80048c);
          color: #ffffff;
          padding: 10px 50px;
          // box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            // color: #fff;
            background: linear-gradient(to right, #80048c, #cc0858, #e10a0a);
          }
          &.submit-btn {
            background: linear-gradient(to right, #e10a0a, #cc0858, #80048c);
            color: #fff;
            position: relative;
            &:hover {
              color: #fff;
              background: linear-gradient(to right, #80048c, #cc0858, #e10a0a);
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: linear-gradient(153deg, #ac0f2c 0%, #67268f 58%);
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $barcolor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #000;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $barcolor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(200, 145, 251, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(200, 145, 251, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              //  border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #f9eeff;
  $yellowColor: #fff;
  $newColor: #eeceff;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 2000px) {
    .container {
      max-width: 2150px;
    }
    img.img-fluid-logo {
      height: 83px !important;
    }
    .newSurveyForm {
      padding-top: 110px;
    }
    .header-section {
      .fixed-top {
        ul {
          .nav-item {
            a {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 1240px;
          padding: 6.5rem;
          .newSurveyHeading {
            margin-bottom: 5rem;
            h2 {
              font-size: 4.5rem;
            }
          }
          .bar {
            height: 10px;
            span {
              height: 10px;
            }
          }
          .newSurveyContent {
            .input-box {
              .MuiFormLabel-root {
                font-size: 1.5rem;
                margin-bottom: 20px;
              }
              .input-box__field.input-box__field--survey {
                font-size: 2.2rem !important;
                border-width: 4px;
              }
            }
            .input-box__field {
              height: 100px !important;
              padding-left: 90px !important;
              font-size: 2.2rem !important;
              &::placeholder {
                font-size: 2rem !important;
              }
            }
            i.demo-icon {
              font-size: 3.3rem;
            }
            .questionSteps {
              .MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  min-height: 100px;
                  .MuiTypography-root.MuiFormControlLabel-label {
                    font-size: 1.7rem;
                  }
                }
              }
            }
            .radioBtnsGroup {
              .MuiFormLabel-root {
                font-size: 1.5rem;
              }
              label.MuiFormControlLabel-root > div {
                min-width: 80px;
                min-height: 80px;
              }
            }
          }
        }
        .topNavigationSurvey {
          .previousBtn {
            font-size: 1.5rem;
          }
          .progressBar {
            .progressBarText {
              font-size: 1.5rem;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            font-size: 2rem;
            letter-spacing: 0.5rem;
            padding: 23px 120px;
            &::after {
              background-image: url(../../assets/images/arrow-icon-big.png);
              background-size: cover;
              right: 40px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .surveyPagelayout-right {
        flex: 0 0 25%;
        .surveyTextFields {
          .whySwitch {
            padding: 3rem;
            h2 {
              font-size: 6rem;
            }
          }
        }
      }
      .incomeListingInfo {
        .perAnum {
          background-size: 40px 33.5px;
          padding-left: 70px;
          margin-bottom: 25px;
          margin-top: 25px;
          h4 {
            font-size: 2.3rem;
          }
          small {
            font-size: 1.3rem;
          }
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right: 0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-fourth-theme {
  $color: #12122d;
  $primaryColor: #f05249;
  $barcolor: #edeaff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f4f2ff;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            //  width: 124px;
            //  height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            //   margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $primaryColor;
                color: $primaryColor !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          border-color: $color;
          &:hover {
            color: #fff;
            background: $primaryColor;
            border-color: $primaryColor;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $primaryColor;
        color: $primaryColor !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyHeading {
        h2 {
          color: $color !important;
        }
      }
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              //  border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $primaryColor;
                color: $primaryColor !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $primaryColor;
          border-color: $primaryColor;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  // .date-box__field--error {
  //   border: 2px solid #db0020 !important;
  // }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  .text-left {
    min-height: calc(100vh - 225px);
  }
  .surveyPageText {
    margin: 25px auto 15px auto;
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }
  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }
  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #ededff;
  $yellowColor: #fff;
  $newColor: #d7d7ff;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right: 0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.layout_first-demo-fifth-theme {
  $color: #262f36;
  $primaryColor: #f47621;
  $barcolor: #edeaff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f4f2ff;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            //  width: 124px;
            //  height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            //   margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $primaryColor;
                color: $primaryColor !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          border-color: $color;
          &:hover {
            color: #fff;
            background: $primaryColor;
            border-color: $primaryColor;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $primaryColor;
        color: $primaryColor !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyHeading {
        h2 {
          color: $color !important;
        }
      }
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              //  border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $primaryColor;
                color: $primaryColor !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $primaryColor;
          border-color: $primaryColor;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  // .date-box__field--error {
  //   border: 2px solid #db0020 !important;
  // }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  .text-left {
    min-height: calc(100vh - 225px);
  }
  .surveyPageText {
    margin: 25px auto 15px auto;
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #fff3eb;
  $yellowColor: #fff;
  $newColor: #ffdfca;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            right: 50px;
            width: 98px;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $newColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    background: $yellowColor;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $color;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: $primaryColor;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: rgba(0, 0, 0, 0.2);
              p {
                color: #fff;
              }
              .customerName {
                color: #fff;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right: 0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

/**seventh theme css**/
.layout_first-demo-seventh-theme {
  $color: #1b283f;
  $barcolor: #abbcdc;
  $primaryColor: #cae7f7;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .makeStyles-root-2 {
    background-color: #ffc417 !important;
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: $color;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              // border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #f2fafd;
  $yellowColor: #fbc417;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            left: 50px;
            width: 98px;
            background: $yellowColor;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #000;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $primaryColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $yellowColor;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: #000;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: $primaryColor;
              p {
                color: #000;
              }
              .customerName {
                color: #000;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

/**eighth theme css**/
.layout_first-demo-eighth-theme {
  $color: #2c2e31;
  $barcolor: #fff2f2;
  $primaryColor: #ffdcde;
  $lightBgColor: #f3f3f3;
  $yellowColor: #cb232e;
  $redColor: #cd232e;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .makeStyles-root-2 {
    background-color: #ffc417 !important;
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.55rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $redColor;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $redColor;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
            border-color:$color;
          }
          &.submit-btn {
            background: $redColor;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: $color;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              // border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    max-height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**eighth Theme update**/
  .footer-content {
    background: #1c1c1c;
  }
  .header-section {
    .fixed-top {
      background: $redColor !important;
    }
  }
  .header-section.pageScroll {
    .fixed-top {
      background: $redColor !important;
    }
  }
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 10px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            left: 50px;
            width: 98px;
            background: $yellowColor;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $primaryColor;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #000 !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    text-align: left;
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #000 !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $yellowColor;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: #fff;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: #fff;
              p {
                color: #000;
              }
              .customerName {
                color: #000;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-red.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right: 0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}


/**ninth theme css**/
.layout_first-demo-ninth-theme {
  $color: #00467f;
  $barcolor: #4d4d4f;
  $primaryColor: #fff;
  .customDropdown-default-theme_span_icon {
    padding: 3px;
    img {
      max-width: 30px;
    }
  }
  .makeStyles-root-2 {
    background-color: #ffc417 !important;
  }
  .header-section {
    .fixed-top {
      background: $color !important;
      padding: 0.5rem 1rem;
    }
  }

  .newSurveyForm {
    background: #f5fffd;
    padding-top: 67px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    &::before {
      display: none;
    }
  }

  .surveyPagelayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      //background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .progressBar {
          float: right;
          .progressBarText {
            font-size: 16px;
            color: #757575;
          }
          .bar {
            display: none;
          }
        }
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            color: $color;
          }
        }
      }
      .newSurveyPageContainer {
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
          .customDropdown-default-theme_span {
            width: 124px;
            min-height: 124px;
            box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
            border: 2px solid #fff;
            border-radius: 6px;
            background: #66c4b1;
            margin: 10px 10px 10px 10px;
            //color: #fff;
            &.effect2::after,
            &.effect2::before {
              display: none;
            }
            &.customDropdown-default-theme_span_selected,
            &:hover {
              background: $color;
            }
          }
          .customDropdown-default-theme_span_icon {
            width: auto;
            background: transparent;
            margin-top: 15px;
          }
          .MuiFormControlLabel-root {
            text-align: left;
            //padding: 10px;
            border-radius: 5px;
            margin: 5px 0px !important;
            //justify-content: center;
            .makeStyles-root-19 {
              margin-right: 10px;
            }
          }
          // .MuiFormLabel-root {
          //   justify-content: center;
          // }
          // .makeStyles-formLabelrootInline-80 {
          //   justify-content: center;
          // }
          .radioBtnsGroup {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .chkbox_survey {
            flex-direction: row;
            justify-content: start;
            .MuiFormLabel-root {
              font-size: 18px;
              padding: 0px;
            }
            .MuiFormGroup-root {
              label {
                border: 2px solid #fff;
                border-radius: 6px;
                box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
                width: 100%;
                // max-width:124px;
                // min-height:124px;
                flex: 0 0 100%;
                text-align: left;
                margin: 3px 0px !important;
                // background: #66c4b1;
                color: #fff;

                .MuiButtonBase-root.MuiIconButton-root {
                  margin-top: 0px;
                  margin-right: 15px;
                }
                .MuiTypography-root.MuiFormControlLabel-label {
                  // width: 100%;
                  display: inline-block;
                  // margin-top:15px;
                  font-size: 16px;
                  font-weight: 600;
                  margin-left: 10px;
                }
                .MuiButtonBase-root {
                  padding: 8px !important;
                }
              }
            }
          }

          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #7c7d7d !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
              &.input-box__field--error {
                border-width: 2px !important;
              }
            }
          }
        }
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $barcolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .ewSurveyFooter {
          text-align: center;
        }
        .customDropdown-default-theme_span_label {
          order: 2;
          center {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $barcolor;
          border-radius: 5px;
          font-weight: 600;
          &:hover {
            color: #fff;
            background: $color;
          }
          &.submit-btn {
            background: $color;
            color: #fff;
            &:hover {
              color: #fff;
              background: $color;
            }
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 0 0 350px;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .surveyTextFields {
        .whySwitch {
          background: $color;
          color: #fff;
          text-align: left;
          padding: 20px;
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
            color: $primaryColor;
          }
          ul {
            padding-left: 0px;
            li {
              font-size: 14px;
              position: relative;
              padding-left: 20px;
              list-style: none;
              &:before {
                content: "✓";
                color: $primaryColor;
                position: absolute;
                left: 0;
                top: 0px;
                font-weight: bold;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }
        p {
          background: $primaryColor;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: $color;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $primaryColor;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .newSurveyForm {
    input.input-box__field.input-box__field {
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .customDropdown-default-theme_span {
    box-shadow: 0px 0px 6px rgba(93, 193, 184, 0.4);
    border: 1px solid #fff;
  }

  //====================================================

  .newSurveyForm {
    .newSurveyPageContainer {
      .newSurveyContent {
        .questionSteps {
          .select-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
            }
          }
          .textarea-box {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            textarea {
              min-height: 80px !important;
              padding: 10px 15px;
              border-width: 2px;
              color: #7a776d;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input_date {
            .MuiFormLabel-root {
              text-align: left;
              font-size: 18px;
              justify-content: start;
              margin-bottom: 8px;
              padding: 0px;
            }
            .date-box__field {
              height: 44px;
              // border-radius: 5px;
              border-width: 2px;
              font-size: 16px !important;
              letter-spacing: 0.5px;
              &:hover,
              &:focus {
                border-color: $color;
                color: $color !important;
              }
            }
          }
          .input-box {
            .MuiFormLabel-root {
              padding: 0px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
  .newSurveyForm input.input-box__field {
    &.input-box__field--survey {
      &.input-box__field {
        height: 45px;
        border: 2px solid #ced4da;
        color: #7a776d;
        &:hover,
        &:focus {
          color: $color;
          border-color: $color;
        }
        &--error {
          height: 45px;
        }
      }
    }
  }

  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .textarea-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .newSurveyForm
    .newSurveyPageContainer
    .newSurveyContent
    .questionSteps
    .MuiFormLabel-root {
    font-size: 18px;
  }
  .date-box__field--error {
    // border: 2px solid #db0020 !important;
  }
  .surveyContent {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    background-size: cover;
    margin-top: 0px;
    .text-left {
      width: 100%;
      text-align: center !important;
    }
    .surveyPageText.text-left {
      text-align: left !important;
      width: 1120px;
      max-width: 100%;
    }
  }
  img.img-fluid-logo {
    height: 50px !important;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
  }

  /***surveypage layout change march 2021**/
  .newSurveyForm-layoutNew {
    .surveyContent {
      background-attachment: fixed;
    }
    .surveyPageContentArea {
      background: #f3fdff;
      max-width: calc((100% / 12 * 7) - 24px);
      padding: 48px 104px;
      display: inline-block;
      margin-top: 120px;
      .whySwitch {
        text-align: left;
        h2 {
          color: #222221;
          font-size: 1.7rem;
          line-height: 1.16667;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: 1px;
          strong {
            color: #222221;
            font-weight: 600;
            //font-size: 2rem;
          }
        }
        p {
          color: #4a4f53;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
      .newSurveyPageContainer {
        h2 {
          color: #222221;
          font-size: 1.5rem;
          line-height: 1.16667;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;

          background: $color;
          border-radius: 4px;
          color: #f0f0f0;
          margin-bottom: 32px;
          min-height: 80px;
          padding: 16px 32px 16px 16px;
          position: relative;
          &:after {
            border: solid transparent;
            border-top-color: $color;
            border-width: 32px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -32px;
            position: absolute;
            top: 90%;
            width: 0;
          }
        }
        .dynamic-form {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          justify-content: left;
          width: 100%;
          background: transparent;
          border: none;
          border-radius: 0px;
          .space-between {
            label {
              font-weight: normal;
              color: #6a6a6a !important;
              letter-spacing: 1.2px;
            }
            // .input-box__field{
            //   border: 1px solid #cdcdcd;
            // }
            .standard_button_fullwidth {
              width: 100%;
              input.form_button {
                height: 54px;
                letter-spacing: 1.4px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 5px;
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  /****survey fourth layout color css*******/
  .newSurveyForm-layoutNewFourth {
    .newSurveyPageContainer {
      .whySwitch {
        h2 {
          color: $color;
        }
        h3 {
          font-size: 20px;
          color: #2b2b2b;
        }
      }
    }
    .surveyTextFields {
      h2 {
        color: $color;
        span {
          color: #f4b028;
        }
      }
      h3 {
        color: $color;
      }

      ul {
        li {
          .stepCount {
            background: $color;
          }
        }
      }
    }
    .surveyPartform {
      border-color: $barcolor;
    }
    .surveyTextFields {
      hr {
        border-color: $barcolor;
      }
      ul {
        li {
          border-color: $barcolor;
          &::before {
            background: $barcolor;
          }
        }
      }
    }
  }

  /**seventh Theme update**/
  $lightBgColor: #f0f0f0;
  $yellowColor: #e97d00;
  $primaryColorBox:#4d4d4f;
  .surveyContent {
    &.newLayoutSurveyContent {
      padding: 0px;
      margin-bottom: 0px;
      .react-autosuggest__suggestions-container--open {
        max-height: 400px;
        overflow: auto;
        text-align: left;
      }
      .newSurveyContent {
        margin-bottom: 0px;
      }
    }
    .horizontalLine {
      height: 3px;
      background: $yellowColor;
      width: 780px;
      max-width: 100%;
      margin: 50px auto 20px auto;
    }
    .surveyPageText.text-left {
      &.newLayoutSurvey {
        box-shadow: none;
        width: 100%;
        min-height: 100vh;
        display: inline-block;
        padding-top: 40px;
        .surveyHeading {
          text-align: center;
          margin: 0px auto;
          width: 1000px;
          max-width: 100%;
          h1 {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: 0.8px;
            color: $yellowColor;
            font-weight: 600;
          }
          span {
            font-size: 24px;
          }
          p {
            font-style: italic;
          }
        }
        .newSurveyPageContainer {
          width: 100%;
          max-width: 100%;
          padding: 0px;
          background: $lightBgColor;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-top: 70px;
          &.SurveyContentClass {
            background: transparent;
            position: relative;
            z-index: 99;
          }
          &::before {
            width: 100%;
            height: 40%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          .progressBar {
            bottom: auto;
            left: auto;
            top: -50px;
            height: 104px;
            left: 50px;
            width: 98px;
            background: $yellowColor;
            .progressBarContainer {
              display: inline-block;
              width: 100%;
              margin-top: 20px;
              height: 50px;
              .progressBarText {
                width: 100%;
                display: inline-block;
                font-size: 51px;
                height: auto;
                line-height:65px;
                span {
                  font-weight: 600;
                  display: inline-block;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }

          .newSurveyFormBox {
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            display: inline-block;
            padding: 46px 50px 50px;
            background: $primaryColorBox;
            position: relative;
            z-index: 1;
            * {
              // font-weight: 600;
            }
            fieldset {
              &.MuiFormControl-root {
                background: transparent !important;
                label {
                  &.MuiFormLabel-root {
                    text-align: center;
                    display: inline-block;
                    margin-bottom: 27px;
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    color: #fff !important;
                  }
                }
                div.MuiFormGroup-root {
                  display: inline-block;
                  label.MuiFormControlLabel-root {
                    max-width: 48%;
                    flex: 0 0 48%;
                    margin: 2% 1%;
                    border-radius: 5px;
                    border: none;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  }
                }
              }
            }
            .input_date {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #fff !important;
                }
              }
            }

            .select-box {
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #fff !important;
                }
              }
            }
            .chkbox_survey {
              .MuiFormControl-root {
                background: transparent;
                padding: 0px;
                label.MuiFormControlLabel-root {
                  border-radius: 5px;
                  border: none;
                  box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                  margin: 2% 0%;
                }
              }
              label {
                &.MuiFormLabel-root {
                  text-align: center;
                  display: inline-block !important;
                  margin-bottom: 27px;
                  font-size: 25px;
                  line-height: 31px;
                  font-weight: 700 !important;
                  color: #fff !important;
                }
              }
            }
            .dynamic-form {
              background: transparent;
              padding: 0px;
              margin: 15px -15px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              border: none;
              width: auto;
              .space-between {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0px 15px;
                .input-box {
                  .MuiFormLabel-root {
                    font-size: 16px;
                  }
                }
                &.surveyFormBtnFull {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 0px;
                  margin-top: 40px;
                  input.form_button {
                    height: 70px;
                    border-radius: 5px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                    background: $yellowColor;
                    box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
                    border: none;
                    color: #fff;
                    font-weight: 700;
                    text-transform: none;
                    &:hover {
                      background: $color;
                      color: #fff;
                    }
                  }
                }
              }
            }
            .newSurveyFooter {
              margin-top: 20px;
            }
          }
        }
        .surveyTextFields {
          width: 100%;
          display: inline-block;
          max-width: 100%;
          background: $color;
          border-radius: 0px;
          position: relative;
          padding: 0px;
          text-align: center;
          &::before {
            width: 100%;
            height: 15%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
          }
          &::after {
            width: 100%;
            height: 22%;
            background: $lightBgColor;
            position: absolute;
            left: 0px;
            bottom: 0px;
            content: "";
          }
          h2 {
            color: #fff;
          }
          .ourCustomers {
            position: relative;
            z-index: 1;
            background: $color;
            width: 780px;
            max-width: 100%;
            margin: 0px auto;
            padding: 40px 40px 60px 40px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 50px;
            h2 {
              margin-bottom: 50px;
              padding-right: 170px;
            }
            .trustpilot {
              float: right;
              position: absolute;
              right: 60px;
              top: 30px;
              img {
                max-width: 150px;
              }
            }
            blockquote {
              margin-top: 15px;
              padding: 20px;
              margin-bottom: 0px;
              background: $primaryColor;
              p {
                color: #000;
              }
              .customerName {
                color: #000;
                margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                .rating {
                  display: inline-block;
                  clear: both;
                  width: 100%;
                  img {
                    max-width: 80px;
                  }
                }
              }
            }
          }
          .whySwitch {
            position: relative;
            width: 780px;
            max-width: 100%;
            background: #fff;
            margin: 0px auto;
            text-align: left;
            padding: 40px;
            margin-top: 20px;
            margin-bottom: 50px;
            h2 {
              color: #000;
            }
            ul {
              margin: 20px 0px 0px 0px;
              padding: 0px;
              li {
                color: #000;
                background: url(../../assets/images/yes-green.svg);
                background-repeat: no-repeat;
                display: inline-block;
                width: 100%;
                padding-left: 40px;
                background-size: 24px;
                margin: 9px 0px;
              }
            }
          }
        }
      }
    }
    .privacypolicy {
      font-size: 12px;
      color: #f0f0f0;
      margin-bottom: 0px;
      a {
        color: #2ca0ff !important;
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyPagelayout {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .surveyPagelayout {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        max-width: 100%;
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyHeading {
          padding: 0px 20px;
        }
        .newSurveyPageContainer {
          padding: 0px 20px;
          box-shadow: none;
          .newSurveyFormBox {
            padding: 46px 20px 20px;
          }
        }
        .surveyTextFields {
          padding: 0px 20px;
          margin-top: 0px;
          .whySwitch {
            padding: 20px 5px;
          }
          .ourCustomers {
            padding: 20px 5px;
            .trustpilot {
              right: 20px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .newSurveyPageContainer {
          .newSurveyFormBox {
            .dynamic-form {
              .space-between {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px 15px;
              }
            }
          }
          .progressBar {
            left: 50%;
            height: 60px;
            width: 60px;
            margin-left: -30px;
            right: auto;
            top: -25px;
            padding: 0px;
            .progressBarContainer {
              margin-top: 0px;
              .progressBarText {
                font-size: 32px;
                line-height:56px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyPagelayout {
      flex-wrap: wrap;
      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    // New css december 23
    .newSurveyForm {
      .surveyPagelayout {
        .newSurveyPageContainer {
          .newSurveyHeading {
            margin-bottom: 20px !important;
          }
        }
      }

      .surveyPagelayout-left {
        .newSurveyContent {
          margin-top: 20px;
          .customDropdown-default-theme_span {
            width: 44% !important;
            margin: 3% !important;
          }
          .input-box {
            .MuiFormLabel-root {
              font-size: 16px !important;
            }
          }
        }
        .newSurveyFooter {
          padding-top: 15px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
        margin-top: 90px;
        .whySwitch {
          h2 {
            font-size: 1.2rem;
            line-height: 1.16667;
            margin-bottom: 20px;
            letter-spacing: 1px;
          }
        }
        .newSurveyPageContainer {
          .dynamic-form
            .space-between
            .standard_button_fullwidth
            input.form_button {
            font-size: 14px;
          }
          h2 {
            font-size: 1rem;
            margin-bottom: 20px;
            min-height: 60px;
            padding: 10px;
            &:after {
              border-width: 16px;
              margin-left: -16px;
            }
          }
        }
      }
    }

    .surveyContent {
      .surveyPageText.text-left.newLayoutSurvey {
        .surveyTextFields {
          .ourCustomers {
            padding-bottom: 50px;
            h2{
              padding-right:0px;
            }
            .trustpilot {
              top: auto;
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 100%;
              display: inline-block;
              background: #cae7f7;
              text-align: center;
            }
            blockquote {
              margin-left: -15px;
              margin-right: -15px;
            }
          }
        }
        .surveyHeading {
          padding: 0px 0px;
          h2 {
            font-size: 20px;
            line-height: normal;
          }
          span {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-top: 15px;
          }
        }
        .newSurveyPageContainer {
          margin-top: 30px;
          &.SurveyContentClass {
            margin-top: 20px;
            .newSurveyFormBox {
              h2 {
                font-size: 20px;
              }
            }
          }
          .newSurveyContent {
            margin-bottom: 0px;
            #right-side-description {
              margin: -20px -15px 0px -15px;
              h3 {
                font-size: 20px;
                margin-top: 20px;
              }
            }
          }
          .newSurveyFormBox {
            padding: 46px 20px 20px;
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root {
                  max-width: 100%;
                  flex: 0 0 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyPagelayout {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

//************ fifth template css **********//
.layout-fourth-default-theme {
  $color: #13ac8f;
  $basecolor: #bbf8ed;
  .navigation {
    background: $color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }

  .surveyForm {
    padding: 0px;
    margin-top: 0px;
    .newSurveyForm {
      background: #f3f3ff;
      padding-top: 100px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      &::before {
        display: none;
      }
    }
  }

  .surveyContainer {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      // background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }
        .progressBar {
          float: right;
          width: auto;
          position: relative;
          background: transparent;
          .progressBarText {
            color: #717171;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            margin-right: 14px;
            height: auto;
            line-height: normal;
            float: left;
          }
          .bar {
            display: none;
          }
        }
      }
      .newSurveyPageContainer {
        max-width: 100%;
        background: #fff;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        margin: auto;
        padding: 50px;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        padding-bottom: 90px;
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $basecolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .newSurveyHeading {
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          color: $color;
          text-align: center;
        }
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .questionSteps {
            display: inline-block;
            width: 100%;
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field.input-box__field--survey {
                height: 60px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                padding: 0px 15px;
                box-sizing: border-box;
                border-width: 2px;
                width: 100%;
                &:hover,
                &:focus {
                  color: $color;
                  border-color: $color;
                }
              }
            }
            .MuiGrid-item {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .react-datepicker-wrapper {
                width: 100%;
                .date-box__field--survey {
                  border-width: 2px;
                  height: 44px;
                  font-size: 18px !important;
                  letter-spacing: 0.5px;
                  color: #6f7073 !important;
                  &:hover,
                  &:focus {
                    border-color: $color;
                    color: $color !important;
                  }
                }
              }
            }
            .MuiFormLabel-root {
              justify-content: center;
              font-size: 18px;
            }
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        text-align: center;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $basecolor;
          border-radius: 5px;
          font-weight: 600;
          border: 2px solid $color;
          &:hover {
            background: $color;
            color: #fff;
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .whySwitch {
        background: $color;
        color: #fff;
        text-align: left;
        padding: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      ul {
        padding-left: 15px;
        li {
          font-size: 14px;
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p {
          background: $color;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $color;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  //new css++++++++++++++++++++++++++++++
  .navbar-brand .img-responsive {
    max-height: 50px;
  }
  .surveyForm .form-survey-section {
    margin-top: -100px;
  }
  .surveyForm .newSurveyForm {
    background: #fff;
    padding-top: 70px;
  }

  .surveyContainer {
    .surveyPagelayout-left {
      .newSurveyPageContainer {
        .newSurveyContent {
          .questionSteps {
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 18px;
                padding: 0px;
                font-weight: 500;
              }
              .input-box__field.input-box__field--survey {
                &:hover,
                &:focus {
                  color: $color !important;
                  border-color: $color;
                }
              }
            }
          }
        }
      }
    }
  }
  .surveyForm {
    .form-survey-section {
      .newSurveyContent {
        .questionSteps {
          display: inline-block;
          width: 100%;
          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
              padding: 0px;
              font-weight: 500;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #6f7073 !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:focus {
                color: $color !important;
                border-color: $color;
              }
            }
          }
          .MuiGrid-item {
            .MuiFormLabel-root {
              float: left;
              font-size: 16px;
              padding: 0px;
            }
            .react-datepicker-wrapper {
              width: 100%;
              .date-box__field--survey {
                border-width: 2px;
                height: 44px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                &:hover,
                &:focus {
                  border-color: $color;
                  color: $color !important;
                }
              }
            }
          }
          .MuiFormLabel-root {
            justify-content: center;
            font-size: 18px;
          }

          .btn-submit {
            background: $color;
            color: #fff;
            &:hover,
            &:focus {
              background: #fff;
              color: $color;
              border: solid 2px $color;
            }
          }
        }
      }
    }
  }
  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }

  .radioBtnsGroup {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .chkbox_survey {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        // color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .textarea-box {
    .MuiFormLabel-root {
      text-align: left;
      font-size: 18px;
      justify-content: start !important;
      margin-bottom: 8px;
      padding: 0px;
    }
    textarea {
      min-height: 80px !important;
      padding: 10px 15px;
      border-width: 2px;
      color: #7a776d;
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyContainer {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .surveyContainer {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyForm .newSurveyForm {
      padding-top: 80px;
    }
    .surveyContainer {
      flex-wrap: wrap;

      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyContainer {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.layout-fourth-first-theme {
  $color: #1b283f;
  $basecolor: #bed4fa;
  .navigation {
    background: $color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }

  .surveyForm {
    padding: 0px;
    margin-top: 0px;
    .newSurveyForm {
      background: #f3f3ff;
      padding-top: 100px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      &::before {
        display: none;
      }
    }
  }

  .surveyContainer {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      // background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }
        .progressBar {
          float: right;
          width: auto;
          position: relative;
          background: transparent;
          .progressBarText {
            color: #717171;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            margin-right: 14px;
            height: auto;
            line-height: normal;
            float: left;
          }
          .bar {
            display: none;
          }
        }
      }
      .newSurveyPageContainer {
        max-width: 100%;
        background: #fff;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        margin: auto;
        padding: 50px;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        padding-bottom: 90px;
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $basecolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .newSurveyHeading {
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          color: $color;
          text-align: center;
        }
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .questionSteps {
            display: inline-block;
            width: 100%;
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field.input-box__field--survey {
                height: 60px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                padding: 0px 15px;
                box-sizing: border-box;
                border-width: 2px;
                width: 100%;
                &:hover,
                &:focus {
                  color: $color;
                  border-color: $color;
                }
              }
            }
            .MuiGrid-item {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .react-datepicker-wrapper {
                width: 100%;
                .date-box__field--survey {
                  border-width: 2px;
                  height: 44px;
                  font-size: 18px !important;
                  letter-spacing: 0.5px;
                  color: #6f7073 !important;
                  &:hover,
                  &:focus {
                    border-color: $color;
                    color: $color !important;
                  }
                }
              }
            }
            .MuiFormLabel-root {
              justify-content: center;
              font-size: 18px;
            }
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        text-align: center;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $basecolor;
          border-radius: 5px;
          font-weight: 600;
          border: 2px solid $color;
          &:hover {
            background: $color;
            color: #fff;
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .whySwitch {
        background: $color;
        color: #fff;
        text-align: left;
        padding: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      ul {
        padding-left: 15px;
        li {
          font-size: 14px;
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p {
          background: $color;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $color;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  //new css++++++++++++++++++++++++++++++
  .navbar-brand .img-responsive {
    max-height: 50px;
  }
  .surveyForm .form-survey-section {
    margin-top: -100px;
  }
  .surveyForm .newSurveyForm {
    background: #fff;
    padding-top: 70px;
  }

  .surveyContainer {
    .surveyPagelayout-left {
      .newSurveyPageContainer {
        .newSurveyContent {
          .questionSteps {
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 18px;
                padding: 0px;
                font-weight: 500;
              }
              .input-box__field.input-box__field--survey {
                &:hover,
                &:focus {
                  color: $color !important;
                  border-color: $color;
                }
              }
            }
          }
        }
      }
    }
  }
  .surveyForm {
    .form-survey-section {
      .newSurveyContent {
        .questionSteps {
          display: inline-block;
          width: 100%;
          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
              padding: 0px;
              font-weight: 500;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #6f7073 !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:focus {
                color: $color !important;
                border-color: $color;
              }
            }
          }
          .MuiGrid-item {
            .MuiFormLabel-root {
              float: left;
              font-size: 16px;
              padding: 0px;
            }
            .react-datepicker-wrapper {
              width: 100%;
              .date-box__field--survey {
                border-width: 2px;
                height: 44px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                &:hover,
                &:focus {
                  border-color: $color;
                  color: $color !important;
                }
              }
            }
          }
          .MuiFormLabel-root {
            justify-content: center;
            font-size: 18px;
          }

          .btn-submit {
            background: $color;
            color: #fff;
            &:hover,
            &:focus {
              background: #fff;
              color: $color;
              border: solid 2px $color;
            }
          }
        }
      }
    }
  }
  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }

  .radioBtnsGroup {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .chkbox_survey {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        // color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .textarea-box {
    .MuiFormLabel-root {
      text-align: left;
      font-size: 18px;
      justify-content: start !important;
      margin-bottom: 8px;
      padding: 0px;
    }
    textarea {
      min-height: 80px !important;
      padding: 10px 15px;
      border-width: 2px;
      color: #7a776d;
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyContainer {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .surveyContainer {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyForm .newSurveyForm {
      padding-top: 80px;
    }
    .surveyContainer {
      flex-wrap: wrap;

      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyContainer {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.layout-fourth-second-theme {
  $color: #7773fa;
  $basecolor: #d2d0fd;
  .navigation {
    background: $color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }

  .surveyForm {
    padding: 0px;
    margin-top: 0px;
    .newSurveyForm {
      background: #f3f3ff;
      padding-top: 100px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      &::before {
        display: none;
      }
    }
  }

  .surveyContainer {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      // background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }
        .progressBar {
          float: right;
          width: auto;
          position: relative;
          background: transparent;
          .progressBarText {
            color: #717171;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            margin-right: 14px;
            height: auto;
            line-height: normal;
            float: left;
          }
          .bar {
            display: none;
          }
        }
      }
      .newSurveyPageContainer {
        max-width: 100%;
        background: #fff;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        margin: auto;
        padding: 50px;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        padding-bottom: 90px;
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $basecolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .newSurveyHeading {
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          color: $color;
          text-align: center;
        }
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .questionSteps {
            display: inline-block;
            width: 100%;
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field.input-box__field--survey {
                height: 60px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                padding: 0px 15px;
                box-sizing: border-box;
                border-width: 2px;
                width: 100%;
                &:hover,
                &:focus {
                  color: $color;
                  border-color: $color;
                }
              }
            }
            .MuiGrid-item {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .react-datepicker-wrapper {
                width: 100%;
                .date-box__field--survey {
                  border-width: 2px;
                  height: 44px;
                  font-size: 18px !important;
                  letter-spacing: 0.5px;
                  color: #6f7073 !important;
                  &:hover,
                  &:focus {
                    border-color: $color;
                    color: $color !important;
                  }
                }
              }
            }
            .MuiFormLabel-root {
              justify-content: center;
              font-size: 18px;
            }
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        text-align: center;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $basecolor;
          border-radius: 5px;
          font-weight: 600;
          border: 2px solid $color;
          &:hover {
            background: $color;
            color: #fff;
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .whySwitch {
        background: $color;
        color: #fff;
        text-align: left;
        padding: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      ul {
        padding-left: 15px;
        li {
          font-size: 14px;
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p {
          background: $color;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $color;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  //new css++++++++++++++++++++++++++++++
  .navbar-brand .img-responsive {
    max-height: 50px;
  }
  .surveyForm .form-survey-section {
    margin-top: -100px;
  }
  .surveyForm .newSurveyForm {
    background: #fff;
    padding-top: 70px;
  }

  .surveyContainer {
    .surveyPagelayout-left {
      .newSurveyPageContainer {
        .newSurveyContent {
          .questionSteps {
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 18px;
                padding: 0px;
                font-weight: 500;
              }
              .input-box__field.input-box__field--survey {
                &:hover,
                &:focus {
                  color: $color !important;
                  border-color: $color;
                }
              }
            }
          }
        }
      }
    }
  }
  .surveyForm {
    .form-survey-section {
      .newSurveyContent {
        .questionSteps {
          display: inline-block;
          width: 100%;
          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
              padding: 0px;
              font-weight: 500;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #6f7073 !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:focus {
                color: $color !important;
                border-color: $color;
              }
            }
          }
          .MuiGrid-item {
            .MuiFormLabel-root {
              float: left;
              font-size: 16px;
              padding: 0px;
            }
            .react-datepicker-wrapper {
              width: 100%;
              .date-box__field--survey {
                border-width: 2px;
                height: 44px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                &:hover,
                &:focus {
                  border-color: $color;
                  color: $color !important;
                }
              }
            }
          }
          .MuiFormLabel-root {
            justify-content: center;
            font-size: 18px;
          }

          .btn-submit {
            background: $color;
            color: #fff;
            &:hover,
            &:focus {
              background: #fff;
              color: $color;
              border: solid 2px $color;
            }
          }
        }
      }
    }
  }
  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }

  .radioBtnsGroup {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .chkbox_survey {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        // color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .textarea-box {
    .MuiFormLabel-root {
      text-align: left;
      font-size: 18px;
      justify-content: start !important;
      margin-bottom: 8px;
      padding: 0px;
    }
    textarea {
      min-height: 80px !important;
      padding: 10px 15px;
      border-width: 2px;
      color: #7a776d;
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyContainer {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .surveyContainer {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyForm .newSurveyForm {
      padding-top: 80px;
    }
    .surveyContainer {
      flex-wrap: wrap;

      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyContainer {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.layout-fourth-third-theme {
  $color: #4094a9;
  $basecolor: #caf4ff;
  .navigation {
    background: $color !important;
    padding: 10px 0px;
    top: 0px;
    box-shadow: 0px 0px 12px 0px $color;
    z-index: 99;
    position: fixed;
    width: inherit;
    transition: 0.5s ease;
  }

  .surveyForm {
    padding: 0px;
    margin-top: 0px;
    .newSurveyForm {
      background: #f3f3ff;
      padding-top: 100px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      &::before {
        display: none;
      }
    }
  }

  .surveyContainer {
    display: flex;
    flex: 1 1 auto;
    width: 100vw;
    max-width: 100%;
    position: relative;
    min-height: calc(100vh - 67px);
    .surveyPagelayout-left {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      max-height: calc(var(--vh, 1vh) * 100 - 64px);
      // background-image: url(../../assets/images/background.png);
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 50px 15px 15px 15px;
      .topNavigationSurvey {
        padding: 10px 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        .previousBtn {
          padding: 0px;
          color: #757575;
          font-weight: 600;
          img {
            width: 9px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }
        .progressBar {
          float: right;
          width: auto;
          position: relative;
          background: transparent;
          .progressBarText {
            color: #717171;
            font-size: 15px;
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            margin-right: 14px;
            height: auto;
            line-height: normal;
            float: left;
          }
          .bar {
            display: none;
          }
        }
      }
      .newSurveyPageContainer {
        max-width: 100%;
        background: #fff;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
        margin: auto;
        padding: 50px;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        padding-bottom: 90px;
        border-radius: 10px;
        padding: 40px;
        width: 550px;
        .bar {
          width: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 5px;
          background: $basecolor;
          border-radius: 5px 5px 0px 0px;
          overflow: hidden;
          span {
            background: $color;
            float: left;
            height: 5px;
          }
        }
        .newSurveyHeading {
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          color: $color;
          text-align: center;
        }
        .newSurveyContent {
          min-height: auto;
          margin-bottom: 20px;
          .questionSteps {
            display: inline-block;
            width: 100%;
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .input-box__field.input-box__field--survey {
                height: 60px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                padding: 0px 15px;
                box-sizing: border-box;
                border-width: 2px;
                width: 100%;
                &:hover,
                &:focus {
                  color: $color;
                  border-color: $color;
                }
              }
            }
            .MuiGrid-item {
              .MuiFormLabel-root {
                float: left;
                font-size: 16px;
                padding: 0px;
              }
              .react-datepicker-wrapper {
                width: 100%;
                .date-box__field--survey {
                  border-width: 2px;
                  height: 44px;
                  font-size: 18px !important;
                  letter-spacing: 0.5px;
                  color: #6f7073 !important;
                  &:hover,
                  &:focus {
                    border-color: $color;
                    color: $color !important;
                  }
                }
              }
            }
            .MuiFormLabel-root {
              justify-content: center;
              font-size: 18px;
            }
          }
        }
      }
      .newSurveyFooter {
        //padding-top: 40px;
        padding-bottom: 10px;
        text-align: center;
        .nextBtn {
          background: transparent;
          color: $color;
          padding: 10px 50px;
          box-shadow: 0 3px 6px $basecolor;
          border-radius: 5px;
          font-weight: 600;
          border: 2px solid $color;
          &:hover {
            background: $color;
            color: #fff;
          }
        }
      }
    }
    .surveyPagelayout-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 350px;
      overflow-y: auto;
      background: #fff;
      box-shadow: -5px 0px 7px rgba(0, 0, 0, 0.1);
      .whySwitch {
        background: $color;
        color: #fff;
        text-align: left;
        padding: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      ul {
        padding-left: 15px;
        li {
          font-size: 14px;
        }
      }
      .ourCustomers {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        h2 {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p {
          background: $color;
          padding: 15px;
          position: relative;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          &::after {
            content: "";
            display: block;
            margin-left: -10px;
            position: absolute;
            bottom: -27px;
            left: 20px;
            border-top: 30px solid $color;
            border-left: 30px solid transparent;
          }
        }
        .customerdetails {
          padding-left: 60px;
          .customerImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            img {
              max-width: 100%;
            }
          }
          .customerName {
            float: left;
            margin-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  //new css++++++++++++++++++++++++++++++
  .navbar-brand .img-responsive {
    max-height: 50px;
  }
  .surveyForm .form-survey-section {
    margin-top: -100px;
  }
  .surveyForm .newSurveyForm {
    background: #fff;
    padding-top: 70px;
  }

  .surveyContainer {
    .surveyPagelayout-left {
      .newSurveyPageContainer {
        .newSurveyContent {
          .questionSteps {
            .input-box {
              .MuiFormLabel-root {
                float: left;
                font-size: 18px;
                padding: 0px;
                font-weight: 500;
              }
              .input-box__field.input-box__field--survey {
                &:hover,
                &:focus {
                  color: $color !important;
                  border-color: $color;
                }
              }
            }
          }
        }
      }
    }
  }
  .surveyForm {
    .form-survey-section {
      .newSurveyContent {
        .questionSteps {
          display: inline-block;
          width: 100%;
          .input-box {
            .MuiFormLabel-root {
              float: left;
              font-size: 18px;
              padding: 0px;
              font-weight: 500;
            }
            .input-box__field.input-box__field--survey {
              height: 60px;
              font-size: 18px !important;
              letter-spacing: 0.5px;
              color: #6f7073 !important;
              padding: 0px 15px;
              box-sizing: border-box;
              border-width: 2px;
              width: 100%;
              &:hover,
              &:focus {
                color: $color !important;
                border-color: $color;
              }
            }
          }
          .MuiGrid-item {
            .MuiFormLabel-root {
              float: left;
              font-size: 16px;
              padding: 0px;
            }
            .react-datepicker-wrapper {
              width: 100%;
              .date-box__field--survey {
                border-width: 2px;
                height: 44px;
                font-size: 18px !important;
                letter-spacing: 0.5px;
                color: #6f7073 !important;
                &:hover,
                &:focus {
                  border-color: $color;
                  color: $color !important;
                }
              }
            }
          }
          .MuiFormLabel-root {
            justify-content: center;
            font-size: 18px;
          }

          .btn-submit {
            background: $color;
            color: #fff;
            &:hover,
            &:focus {
              background: #fff;
              color: $color;
              border: solid 2px $color;
            }
          }
        }
      }
    }
  }
  .input-box__field--error {
    // border: 2px solid #db0020 !important;
  }

  .radioBtnsGroup {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .chkbox_survey {
    flex-direction: row;
    justify-content: start;
    .MuiFormLabel-root {
      font-size: 18px;
      padding: 0px;
      justify-content: start !important;
    }
    .MuiFormGroup-root {
      label {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.4);
        width: 100%;
        // max-width:124px;
        // min-height:124px;
        flex: 0 0 100%;
        text-align: left;
        margin: 3px 0px !important;
        // background: #66c4b1;
        color: #fff;

        .MuiButtonBase-root.MuiIconButton-root {
          margin-top: 0px;
          margin-right: 15px;
        }
        .MuiTypography-root.MuiFormControlLabel-label {
          // width: 100%;
          display: inline-block;
          // margin-top:15px;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .MuiButtonBase-root {
          padding: 8px !important;
        }
      }
    }
  }

  .textarea-box {
    .MuiFormLabel-root {
      text-align: left;
      font-size: 18px;
      justify-content: start !important;
      margin-bottom: 8px;
      padding: 0px;
    }
    textarea {
      min-height: 80px !important;
      padding: 10px 15px;
      border-width: 2px;
      color: #7a776d;
      &:hover,
      &:focus {
        border-color: $color;
        color: $color !important;
      }
    }
  }

  @media (max-width: 1169px) {
    .surveyContainer {
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          width: 550px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .surveyContainer {
      .surveyPagelayout-right {
        flex: 0 0 250px;
      }
      .surveyPagelayout-left {
        .newSurveyPageContainer {
          padding: 40px 15px;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .surveyForm .newSurveyForm {
      padding-top: 80px;
    }
    .surveyContainer {
      flex-wrap: wrap;

      .surveyPagelayout-left {
        padding-bottom: 30px;
      }
      .surveyPagelayout-right {
        flex: 0 0 100%;
        .ourCustomers {
          padding-bottom: 70px;
        }
      }
    }
    .newSurveyForm-layoutNew {
      .surveyPageContentArea {
        padding: 15px;
      }
    }
  }

  @media (max-width: 400px) {
    .topNavigationSurvey .previousBtn {
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
    .surveyContainer {
      .surveyPagelayout-left {
        max-height: none;
        .newSurveyPageContainer {
          padding: 30px 15px;
          .newSurveyHeading {
            margin-bottom: 30px;
          }
        }
        .topNavigationSurvey {
          .progressBar {
            .progressBarText {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

//***********Global CSS***************//
.incomeListingInfo {
  display: inline-block;
  width: 100%;
  .perAnum {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    background-image: url(../../assets/images/checkmark.png);
    background-repeat: no-repeat;
    background-position: left 6px;
    background-size: 20px 17.5px;
    padding-left: 30px;
    h4 {
      // background: #19294f;
      // float: left;
      // clear: both;
      // padding: 5px 10px;
      // border-radius: 3px;
      // border: 1px solid #292b9b;
      margin-bottom: 0px;
      color: #fff;
      font-size: 20px;
    }
    small {
      width: 100%;
      display: inline-block;
      color: #fff;
    }
  }
  p {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
  }
}
a.dialog_text {
  color: #007bff;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
  clear: both;
}
.dialog_text_star {
  color: red;
  text-decoration: none;
}
.privacypolicy {
  font-size: 12px;
  color: #717171;
  margin-bottom: 0px;
  a {
    color: #e00a0d !important;
  }
}

/****fourth layout css 2021 march*******/
.newSurveyForm-layoutNewFourth {
  padding: 30px 0px;
  width: 100%;
  position: relative;
  &:before {
    content: "";
    position: fixed;
    top: 22%;
    right: 0px;
    width: 232px;
    background: url(../../assets/images/dotts.png);
    background-repeat: no-repeat;
    height: 349px;
  }
  &:after {
    content: "";
    position: fixed;
    bottom: 12%;
    left: 0px;
    width: 232px;
    background: url(../../assets/images/dotts.png);
    background-repeat: no-repeat;
    height: 349px;
    z-index: -1;
  }
  .container {
    max-width: 1420px;
  }
  .surveyContent {
    margin-top: 73px;
  }
  .surveyFeatureImage {
    width: 100%;
    display: inline-block;
    text-align: left;
    padding: 0px 0px 0px 0px;
    img {
      max-width: 100%;
    }
  }
  .surveyPartform {
    max-width: 432px;
    flex: 0 0 432px;
    border: 2px solid #c2cfe8;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
    padding: 25px 25px 10px 25px;
    float: right;
    position: relative;
    margin: 0px;
    z-index: 1;
  }
  .dynamic-form {
    // border: 2px solid #c2cfe8;
    // border-radius: 4px;
    // background-color: #fff;
    // box-shadow: 0 0 24px 0 rgba(0,0,0,0.09);
    // padding: 20px 46px 40px;
    // float: right;
    // position: relative;
    // margin:0px;
    width: 100%;
    margin: 0px;
    border: none;
    padding: 0px;
    .MuiFormLabel-root {
      font-weight: normal !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
  .newSurveyPageContainer {
    letter-spacing: 0.5px;
    .whySwitch {
      font-size: 16px;
      color: #555;
      font-weight: 300;
      // h2{
      //   color:#1b283f;
      // }
      h3 {
        font-size: 20px;
        // color: #313131;
      }
    }
  }
  .surveyContent {
    .text-left {
      text-align: left !important;
      position: relative;
      padding: 0px 10px;
      .surveyTextFields {
        background: #fff;
        position: relative;
        margin-top: -60px;
        padding: 0px 15px;
        z-index: 1;
        border-radius: 5px;
      }
    }
    .surveyPartContent {
      padding-right: 40px;
    }
  }
  .surveyTextFields {
    h2 {
      font-size: 40px;
      padding: 20px 00px 10px 0px;
      letter-spacing: 1px;
      // color: #1b283f;
      strong {
        font-weight: 500;
      }
      span {
        color: #f4b028;
        font-weight: 500;
      }
    }
    h3 {
      // color:#1b283f;
      font-weight: 400;
      letter-spacing: 1px;
    }
    hr {
      border-width: 2px;
      border-color: #c2cfe8;
      margin-bottom: 30px;
    }
    ul {
      padding: 0px 00px 0px 0px;
      li {
        list-style: none;
        margin-bottom: 15px;
        font-size: 16px;
        color: #555;
        font-weight: 300;
        border: 2px solid #c2cfe8;
        border-radius: 4px;
        background-color: #fff;
        padding: 20px 20px 20px 100px;
        float: right;
        position: relative;
        min-height: 122px;
        width: 100%;
        &:before {
          content: "";
          width: 2px;
          height: 20px;
          background: #c2cfe8;
          left: 49px;
          top: 100%;
          position: absolute;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
        strong {
          font-size: 20px;
          color: #313131;
          font-weight: 500;
          letter-spacing: 0.5px;
        }
        .stepCount {
          // background: #1b283f;
          position: absolute;
          left: 20px;
          top: 25px;
          border-radius: 4px;
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          line-height: normal;
          width: 60px;
          padding: 0px 10px 10px 10px;
          span {
            font-size: 12px;
            display: inline-block;
            width: 100%;
            letter-spacing: 3px;
          }
        }
      }
    }
  }
  .standard_button_fullwidth {
    width: 100%;
    input.form_button {
      border-radius: 3px;
    }
  }
  .stepsImg {
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    img {
      max-width: 100%;
    }
  }
}


/**********10th layout css*********/
.sixth_layout_global{
  .SurveyLayout10{
    .surveyContent{
      .mainQuestionBlock {
        padding: 20px 0px 40px 0px;
      }
      .surveyPageText.text-left.newLayoutSurvey{
        padding-top: 0px;
        .highlightedText{
          max-width: 550px;
          margin: auto;
          margin-top:30px;
          display: inline-block;
          font-size: 18px;
          font-weight: 600;
          padding: 3px 30px;
          border-radius: 30px;
        }
        .titleBlock{
          h1{
            font-size: 48px;
            font-weight: bold;
            margin: 10px 0px 15px 0px;
          }
          p{
            font-size:26px;
            font-style: normal;
            margin-bottom: 0px;
          }
        }
        .newSurveyPageContainer {
          .progressBar{
            display: block;
            height:20px;
            width:100%;
            margin:20px 0px 40px 0px;
            .progressBarContainer{
              margin-top:0px;
              height:20px;
              .progressBarText{
                display:none;
              }
              .bar{
                overflow: hidden;
                height: 20px;
                margin-bottom: 0px;
                border-radius: 4px;
                span{
                  float: left;
                  width: 0%;
                  height: 100%;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                  transition: width .6s ease;
                  background-size: 40px 40px;
                }
              }
            }
          }
          .newSurveyFormBox{
            width:900px;
            border-radius: 20px;
            margin-top: 30px;
            padding:50px;
            .react-autosuggest__suggestion{
              text-align: left;
            }
            .formTitleHeading{
              margin-bottom:30px;
              display: inline-block;
              position: relative;
              h2{
                font-weight: bold;
                font-size: 36px;
                margin-bottom: 0px;
              }
              p{
                font-size:20px;
                margin-bottom:0px;
              }
              .arrowSvg{
                position: absolute;
                left: 100%;
                top: 10px;
                width:50px;
                height: auto;
                margin-left: 15px;                
              }
            }
            fieldset.MuiFormControl-root {
              div.MuiFormGroup-root {
                label.MuiFormControlLabel-root{
                  min-height:60px;
                }
              }
            }
            .newSurveyContent{
              .questionSteps{
                .space-between{
                  > [class^="makeStyles-root-"]{
                    > .MuiFormLabel-root{
                      color: rgb(0, 0, 0);
                      font-size: 25px;
                      font-weight: 600;
                      justify-content: center;
                      margin:0px;
                      padding:0px;
                    }
                  }
                  [class^="makeStyles-mainRangeValue-"] label{
                    background: transparent;
                    color:#000;
                    font-weight:500 !important;
                    font-size: 28px !important;
                    position: relative;
                    margin-top:30px;
                    &::before{
                      content: "$";
                      display: inline-block;
                    }
                  }
                  .MuiSlider-rail{
                    border: none;
                    box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
                    margin: 0px;
                    padding: 0px;
                    background: #fff;
                  }
                }
              }
            }
            .dynamic-form{
              width: 350px;
              margin: auto;    
              max-width: 100%;       
              .input-box__field{
                border-width: 1px !important;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                height:34px !important;
                padding-left: 35px !important;
              }   
              .space-between {
                margin: 7px 0px 7px 0px;
                [class^="makeStyles-iconDiv-"]{
                  top:0px !important;
                  left: 0px !important;
                  height:34px !important;
                  width: 34px !important;
                }
                .demo-icon{
                  font-size: 23px !important;
                  top: 0px !important;
                  left: 0px !important;
                }
                .textarea-box {
                  padding-top:0px !important;
                  textarea{
                    margin-bottom: 0px !important;
                    border-width: 1px !important;
                    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                    min-height:60px !important;
                    padding-left: 35px !important;
                    padding-top: 5px;
                    padding-bottom:5px;
                  }
                  .MuiFormLabel-root{
                    font-size:14px;
                  }
                }
              }
              
            }
            .newSurveyFooter {
              .nextBtn{
                width: auto;
                max-width:100%;
                min-width: 170px;
              }
            }
          }
          .react-autosuggest__container {
            display: flex;
            justify-content: center;
            .inputContainer {
              width: 400px;
              max-width: 100%;
            }
          }
        }
        .surveyTextFields {
          .whySwitchOutside{
            padding:0px;
            .whySwitch{
              width: 100%;
              padding: 0px;
            }
          }
        }
      }
    }
    .businessOwners{
      width:100%;
      padding:50px 0px;
      h2{
        text-align: center;
        font-weight: bold;
        width: 700px;
        font-size: 38px;
        margin: auto;
        margin-bottom: 40px;
        span{
          font-weight:bold;
        }
      }
      .card{
        padding: 30px;
        margin-top:15px;
        margin-bottom:15px;
        display: inline-block;
        width: 100%;
        h5{
          font-weight:600;
          font-size:26px;
        }
        p {
          font-size: 16px;
          margin-bottom:15px;
        }
      }
      .ownerCard {
        h4{
          font-size: 30px;
          font-weight: 600;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          margin-bottom:5px;
        }
      }            
    }
    .containerSurvey{
      max-width: 1024px !important;        
    }
    .listingBlock {
      display: inline-block;
      width: 100%;
      padding: 50px 0px;
      h2{
        text-align: center;
        font-weight: bold;
        width: 100%;
        font-size: 38px;
        margin: auto;
        margin-bottom: 40px;
        span{
          font-weight:bold;
        }
      }
      ul{
        margin: 0px;
        padding: 0px;
        li{
          background-repeat: no-repeat;
          padding-left: 30px;
          list-style: none;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }
  }


  .layout_sixth-fifth-theme{
    $mainThemeColor:#f47621;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }                
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background: $mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, rgba(239,64,68,1) 100%);
        h2{
          color: #fff;
          span{
            color: #ffd33a;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-sixth-theme{
    $mainThemeColor:#5ab031;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color: #ffd33a;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-seventh-theme{
    $mainThemeColor:#fee005;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #1d2c35;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:#1d2c35;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #1d2c35;
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid rgb(254, 224, 5) !important;
                    &:hover{
                      background-color: rgb(254, 224, 5) !important;
                      color: rgb(27, 40, 63) !important;
                      border: 2px solid rgb(254, 224, 5) !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#000;
                      .dropdownLIstLabel{
                        color:#000;
                      }
                      .demo-icon{
                        color:#000;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #1d2c35;
          span{
            color:#1d2c35;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }


  .layout_sixth-eighth-theme{
    $mainThemeColor:#cd232e;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color: #ffd33a;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-ninth-theme{
    $mainThemeColor:#00467f;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: #f47621;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: #f47621;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:#f47621;
                }
                .arrowSvg{
                  path{
                    fill: #f47621;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                    &:hover{
                      background:#f47621;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:#f47621;
                      border: 2px solid #f47621 !important;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color: #f47621;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#242424;
          span{
            color: #f47621;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-red-white{
    $mainThemeColor:#ae0000;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #fff3f3;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }


  .layout_sixth-green-black{
    $mainThemeColor:#018001;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #fff3f3;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                    &:hover{
                      background:#000;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-green-drawblue{
    $mainThemeColor:#35a753;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: #4486f4;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #4486f4;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:#4486f4;
                }
                .arrowSvg{
                  path{
                    fill: #4486f4;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: #4486f4;
                    color: #fff;
                    &:hover{
                      background:$mainThemeColor;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-blue-white{
    $mainThemeColor:#0000fe;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #efefff;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-pink-white{
    $mainThemeColor:#f699cd;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #ffe5f4;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-pink-black{
    $mainThemeColor:#f699cd;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #ffe5f4;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                    &:hover{
                      background:#000;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }
  .layout_sixth-blue-aqua{
    $mainThemeColor:#0d49cd;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: #00ffff;
            color: $mainThemeColor;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #e0ffff;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: #00f5f5;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: #00f5f5;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: #00f5f5;
                    color: $mainThemeColor;
                    &:hover{
                      background:$mainThemeColor;
                      color: #fff;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#00f5f5;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-red-white-black{
    $mainThemeColor:#e41c2f;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #f5f5f5;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background: $mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, rgba(239,64,68,1) 100%);
        h2{
          color: #fff;
          span{
            color: #ffd33a;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }



  .layout_sixth-red-blue{
    $mainThemeColor:#eb2227;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: #0f2f66;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #0f2f66;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: #0f2f66;
                    color: #fff;
                    &:hover{
                      background:$mainThemeColor;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

 .layout_sixth-aqua-green-black{
    $mainThemeColor:#87cdc5;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #e8fffc;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                    &:hover{
                      background:#1f1c1d;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#1f1c1d;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-midnight-blue-green{
    $mainThemeColor:#033f5d;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: #69bd49;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color: #e8ffdf;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color:#69bd49;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:#69bd49;
                }
                .arrowSvg{
                  path{
                    fill: #69bd49;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: #69bd49;
                    color: #fff;
                    &:hover{
                      background:$mainThemeColor;
                    }
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#69bd49;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#69bd49;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }
  

  .layout_sixth-cerulean{
    $mainThemeColor:#004a8e;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color:#e6f3ff;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
                
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

  .layout_sixth-magenta{
    $mainThemeColor:#562463;
    .SurveyLayout10{
      &.newSurveyForm {
        background: #ffffff;
      }
      .surveyContent{
        .surveyPageText.text-left.newLayoutSurvey{
          .highlightedText{
            background: $mainThemeColor;
            color: #fff;
          }
          .newSurveyPageContainer {
            .progressBar{
              .progressBarContainer{
                .bar{
                  background-color:#fbedff;
                  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
                  span{
                    color: #fff;
                    background-color: $mainThemeColor;
                    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
                    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
                  }
                }
              }
            }
            .newSurveyFormBox{
              border: 1px solid rgb(106,107,108);
              box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
              .formTitleHeading{
                h2{
                  color:$mainThemeColor;
                }
                .arrowSvg{
                  path{
                    fill: $mainThemeColor;
                  }
                }
              }
              .dynamic-form{
                .space-between.surveyFormBtnFull {
                  input.form_button{
                    background: $mainThemeColor;
                    color: #fff;
                  }
                }
              }
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    background-color:#fff !important;
                    color: rgb(27, 40, 63) !important;
                    border: 2px solid $mainThemeColor !important;
                    &:hover{
                      background-color: $mainThemeColor !important;
                      color: #fff !important;
                      border: 2px solid $mainThemeColor !important;
                    }
                  }
                }
              }
              .newSurveyFooter {
                .nextBtn{
                  background: $mainThemeColor;
                  color: #fff;
                }
              }
            }
            .newSurveyContent {
              .questionSteps {
                .dropdownMain {
                  .dropdownMainList{
                    border: 2px solid $mainThemeColor !important;
                    &.dropdownMainListSelected, &:hover{
                      background:$mainThemeColor;
                      color:#fff;
                      .dropdownLIstLabel{
                        color:#fff;
                      }
                      .demo-icon{
                        color:#fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .businessOwners{
        background: $mainThemeColor;
        background: linear-gradient(90deg, $mainThemeColor 0%, $mainThemeColor 100%);
        h2{
          color: #fff;
          span{
            color:#FFC21A;
          }
        }
        .card{
          box-shadow: rgba(0,0,0,0.25) 0px 8px 12px 0px;
          p {
            color: #717171;
          }
        }
        .ownerCard {
          h4{
            border-bottom: 2px solid #000;
          }
          p {
            color: #717171;
          }
        }            
      }
      .listingBlock {
        h2{
          color:#171717;
          span{
            color: $mainThemeColor;
          }
        }
        ul{
          li{
            background-image: url(../../assets/images/checkBox-new.svg);
            color: #717171;
          }
        }
      }
    }
  }

}



/******11th survey theme css*******/
.sixth_layout_global{
    .surveyLayout11{
      padding-top:100px;
      padding-bottom:50px;
      &:before, &:after{
        content: "";
        display: block;
        width: 20vw;
        height: 20vw;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
        z-index: 1;
        opacity: .8;
      }
      &:before {
        right: calc(100% - 10vw);
      }
      &:after {
        left: calc(100% - 9vw);
      }
      .surveyContent{
        z-index: 2;
        position: relative;

        .surveyPageText.text-left.newLayoutSurvey{       
          padding-top: 0px;
          min-height: calc(100vh - 230px);
          .surveyHeading{
            margin-bottom:20px;
          }
          .dropdownMain {
            .dropdownMainList{
              margin: 2% 2%;
              min-height: 50px;
              .icon-span-main {
                width: 70px;
                height: 70px;
                margin: 15px auto;
                border-radius: 50%;
                padding: 5px;
                box-sizing: border-box;
                display: inline-block;
                .demo-icon{
                  font-size: 35px !important;
                }
              }
              .dropdownLIstLabel {
                margin-bottom:10px;
                center{
                  font-weight: 600;
                }
              }
            }
          }
          .newSurveyPageContainer {
            .progressBar{
              display: block;
              width: 100%;
              margin: 20px 0px 20px 0px;
              position: relative;
              top: 0px;
              height: auto;
              right: 0;
              .progressBarContainer{
                margin-top:0px;
                height:auto;
                .progressBarText{
                  display:block;
                  font-size: 14px;
                  height: auto;
                  line-height:normal;
                  position: absolute;
                  top: 24px;
                  span{
                    width: auto;
                    height: auto;
                    font-weight: 600;
                  }
                }
                .bar{
                  overflow: hidden;
                  height: 6px;
                  margin-bottom: 0px;
                  border-radius: 4px;
                  overflow: visible;
                  span{
                    float: left;
                    width: 0%;
                    height: 100%;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    transition: width .6s ease;
                    background-size: 40px 40px;
                    position: relative;
                    &:after{
                      position: absolute;
                      right: -10px;
                      bottom: 0px;
                      content: '';
                      width: 50px;
                      height: 24px;
                      background-size:contain !important;
                      background-repeat:no-repeat !important;
                    }
                  }
                }
              }
            }
            .newSurveyFormBox{
              width:668px;
              padding: 0 15px 1rem 15px;
              border-radius: 14px;
              position: relative;
              .space-between{
                margin-top: 10px;
                label.MuiFormLabel-root{
                  font-size: 1.3rem;
                  margin-bottom: .25rem;
                  padding: 4px 5px;
                  font-weight: 500 !important;
                  display: inline-block;
                  position: relative;
                  width: 100%;
                  text-align: left;
                }
                .date-box__field{
                  box-sizing: border-box;
                  height: 58px;
                }
                &.input_date{
                  label.MuiFormLabel-root{
                    font-size: 1.3rem;
                    margin-bottom: .25rem;
                    padding: 4px 5px;
                    font-weight: 500 !important;
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    text-align: left;
                  }
                  *{
                    border-width: 2px !important;
                  }
                }
                
                
              }
              .chkbox_survey{
                .MuiFormControl-root {
                  label.MuiFormLabel-root{
                    font-size: 1.3rem;
                    margin-bottom: .25rem;
                    padding: 4px 5px;
                    font-weight: 500 !important;
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    text-align: left;
                  }
                  label.MuiFormControlLabel-root{
                    box-shadow: none;
                    margin: 2% 2%;                   
                    max-width: 46%;
                    flex: 0 0 46%;
                    min-height: 50px;
                    .icon-span{
                      font-size: 12px !important;
                      height: 20px !important;
                      width: 20px !important;
                      text-align: center ;
                      line-height: normal;
                      margin-right:10px;
                      i{
                        display: none;
                      }
                    }
                    &.checkbox-selected, &:hover{
                      .icon-span{
                        i{
                          display: block;
                        }
                      }
                    }
                    
                  }
                }
              }
              .react-autosuggest__suggestion{
                text-align: left;
              }
              .newSurveyContent{
                padding: 5px;
              }
              fieldset.MuiFormControl-root {
                label.MuiFormLabel-root{
                  font-size: 1.3rem;
                  margin-bottom: .25rem;
                  padding: 4px 5px;
                  font-weight: 500;
                  display: inline-block;
                  position: relative;
                  width: 100%;
                  text-align: left;
                }
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    min-height:50px;
                    box-shadow: none;
                    margin: 2% 2%;
                    max-width: 46%;
                    flex: 0 0 46%;
                  }
                }
              }
              .dynamic-form{
                width: 550px;
                margin: auto;    
                max-width: 100%;  
                .space-between{
                  margin-top:0px;
                  &.surveyFormBtnFull {
                    margin-top: 20px;
                    input.form_button {
                      border: none;
                      border-radius: 40px;
                    }
                  }
                }
                label.MuiFormLabel-root{
                  font-size:16px;
                  .mendatory{
                  }
                }        
              }
              .completeForm{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:20px;
                font-style:italic;
                img{
                  max-width:15px;
                  margin-right: 10px;
                }
              }
              .newSurveyFooter{
                .nextBtn{
                  border-radius:40px;
                }
              }
            }
            .react-autosuggest__container {
              display: flex;
              justify-content: center;
              .inputContainer {
                width: 100%;
                max-width: 100%;
              }
            }
          }
          .surveyTextFields {
            .whySwitchOutside{
              padding:0px;
              .whySwitch{
                width: 100%;
                padding: 0px;
                .container{
                  max-width: 930px !important;
                }
                .inlineList{
                  h2{
                    font-size: 1.3rem;
                    padding-bottom: .75rem;
                    font-weight: 600;
                    margin-top: 20px;
                  }
                  ul.partnershipList{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-line-pack: center;
                    align-content: center;
                    justify-items: center;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin-bottom:40px;
                    li{
                      margin: .8rem auto;
                      list-style-type: none;
                      -webkit-box-flex: 0;
                      -ms-flex: 0 0 auto;
                      flex: 0 0 auto;
                      padding-left: 0px;
                      width: inherit;
                      display: inherit;
                      img{
                        -webkit-filter: brightness(0) invert(1);
                        filter: brightness(0) invert(1);
                        max-width: 200px;
                        max-height: 50px;
                      }
                    }
                  }
                  ol{
                    padding-left: 20px;
                    padding-top: 1rem;
                    li{
                      margin-bottom: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .submit_outer{
        border-radius: 40px !important;
        text-align: center;
        height: 60px;
        .btn-submit{
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }



    .layout_sixth-fifth-theme{
      $mainThemeColor:#f47621;
      $lightHover:#fff8f4;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #e16510;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }
                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-sixth-theme{
      $mainThemeColor:#5ab031;
      $lightHover:#f4ffef;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #449f18;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }
                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }

    .layout_sixth-seventh-theme{
      $mainThemeColor:#fee005;
      $lightHover:#fffceb;
      $colorThemeControls:#1d2c35;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #eed100;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid $colorThemeControls;
                color: $colorThemeControls;
                background: #f9f9f9;
                &:hover{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }
                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: $colorThemeControls !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: $colorThemeControls !important;
                    }
                    *{
                      border-color: $colorThemeControls  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #1d2c35;
                      color:#1d2c35;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                        color: #1d2c35;
                      }
                    }
                    ol{
                      li{
                        color: #1d2c35;
                        &::marker{
                          color: #1d2c35;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-eighth-theme{
      $mainThemeColor:#cb232e;
      $lightHover:#fff3f4;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #e62733;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }
                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }



    .layout_sixth-ninth-theme{
      $mainThemeColor:#f47621;
      $lightHover:#fff3f4;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #dd6513;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor !important;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-red-white{
      $mainThemeColor:#ae0000;
      $lightHover:#fff3f4;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #970101;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor !important;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-green-black{
      $mainThemeColor:#018001;
      $lightHover:#efffef;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #007100;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$mainThemeColor !important;
                  background:$lightHover;
                  color: $mainThemeColor !important;
                  .icon-span-main{
                    border-color: $mainThemeColor;
                    .demo-icon{
                      color: $mainThemeColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: $lightHover !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-green-drawblue{
      $mainThemeColor:#35a753;
      $lightHover:#e4eaf4;
      $hoverColor:#4486f4;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #2b9547;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-blue-white{
      $mainThemeColor:#0000fe;
      $lightHover:#efefff;
      $hoverColor:#0000fe;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #0000d0;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-pink-white{
      $mainThemeColor:#f699cd;
      $lightHover:#fff4fa;
      $hoverColor:#f699cd;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #df85b7;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-pink-black{
      $mainThemeColor:#f699cd;
      $lightHover:#fff4fa;
      $hoverColor:#f699cd;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #df85b7;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }



    .layout_sixth-blue-aqua{
      $mainThemeColor:#0d49cd;
      $lightHover:#f2f6ff;
      $hoverColor:#0d49cd;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #00ffff;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }

    .layout_sixth-red-white-black{
      $mainThemeColor:#ed1c2e;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #c80e1e;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover{
                  border-color:$mainThemeColor;
                  background: #ffeff1;
                  color: $mainThemeColor;
                  .icon-span-main {
                    border-color: $mainThemeColor;
                  }
                }
                .icon-span-main {
                  border: 2px solid #ddd;
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $mainThemeColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border-color: #ececec !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: #ffeff1 !important;
                        color: $mainThemeColor !important;
                        .icon-span{
                          border: 2px solid $mainThemeColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $mainThemeColor !important;
                        background: #ffeff1 !important;
                        color: $mainThemeColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $mainThemeColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }

    .layout_sixth-red-blue{
      $mainThemeColor:#0f2f66;
      $lightHover:#fff5f6;
      $hoverColor:#eb2227;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #163b79;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-aqua-green-black{
      $mainThemeColor:#87cdc5;
      $lightHover:#effffd;
      $hoverColor:#87cdc5;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #74bbb3;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-midnight-blue-green{
      $mainThemeColor:#033f5d;
      $lightHover:#f5fff2;
      $hoverColor:#69bd49;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #69bd49;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }


    .layout_sixth-cerulean{
      $mainThemeColor:#004a8e;
      $lightHover:#f0f8ff;
      $hoverColor:#004a8e;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #0159aa;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }



    .layout_sixth-magenta{
      $mainThemeColor:#562463;
      $lightHover:#fcf3ff;
      $hoverColor:#562463;
      .surveyLayout11{
        background-color: $mainThemeColor;
        &:before, &:after{
          background-color: #672a77;
        }
        .surveyContent{
  
          .surveyPageText.text-left.newLayoutSurvey{
            .dropdownMain {
              .dropdownMainList{
                box-shadow: 0 1px 1px rgba(0,0,0,.08),0 3px 3px rgba(0,0,0,.16);
                border: 1px solid #b2b2b2;
                color: #4b4b4b;
                background: #f9f9f9;
                &:hover, &.dropdownMainListSelected{
                  border-color:$hoverColor !important;
                  background:$lightHover;
                  color: $hoverColor !important;
                  .icon-span-main{
                    border-color: $hoverColor;
                    .demo-icon{
                      color: $hoverColor;
                    }
                  }

                }
                .icon-span-main {
                  border: 2px solid #ddd;
                  .demo-icon{
                    color: #4b4b4b;
                  }
                }
              }
            }
            .newSurveyPageContainer {
              .progressBar{
                .progressBarContainer{
                  .progressBarText{
                    span{
                      color:#000;
                    }
                  }
                  .bar{
                    background-color: #ddd;
                    span{
                      background: $mainThemeColor;
                      &:after{
                        background:url(../../assets/images/car-bar.svg);
                      }
                    }
                  }
                }
              }
              .newSurveyFormBox{
                background-color: #fff;
                box-shadow: 0 2px 1px rgba(0,0,0,.125),0 4px 4px rgba(0,0,0,.125),0 8px 8px rgba(0,0,0,.125),0 16px 12px rgba(0,0,0,.125),0 32px 24px rgba(0,0,0,.125);
                div {
                  .privacypolicy{
                    color: #414141;
                  }
                }
                .space-between{
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  &.input_date{
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    *{
                      border-color: #b2b2b2  !important;
                    }
                    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
                      background: $mainThemeColor;
                    }
                  }
                  &.input_date:hover{
                    *{
                      border-color: $hoverColor !important;
                    }
                    .react-datepicker__tab-loop{
                      *{
                        border: none !important;
                      }
                    }
                  }
                  
                  
                }
                .chkbox_survey{
                  .MuiFormControl-root {
                    label.MuiFormLabel-root{
                      color: #444 !important;
                    }
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      outline: 6px solid #f2f2f2; 
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      .icon-span{
                        background: transparent;
                        border: 2px solid #b2b2b2 !important;
                      }
                      &.checkbox-selected, &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                        .icon-span{
                          border: 2px solid $hoverColor !important;
                        }
                      }
                      
                    }
                  }
                }
                fieldset.MuiFormControl-root {
                  label.MuiFormLabel-root{
                    color: #444 !important;
                  }
                  div.MuiFormGroup-root {
                    label.MuiFormControlLabel-root{
                      box-shadow: none;
                      border: 2px solid #b2b2b2 !important;
                      color: #4b4b4b !important;
                      background: #f9f9f9 !important;
                      outline: 6px solid #f2f2f2;
                      &:hover{
                        border: 2px solid $hoverColor !important;
                        background: $lightHover !important;
                        color: $hoverColor !important;
                      }
                    }
                  }
                }
                .dynamic-form{
                  .space-between{
                    &.surveyFormBtnFull {
                      input.form_button {
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        border: none;
                        background-image: radial-gradient(circle at 50% 50%, #ff606f, $mainThemeColor);
                      }
                    }
                  }
                  label.MuiFormLabel-root{
                    .mendatory{
                      color: #ff0000;
                    }
                  }        
                }
                .completeForm{
                  color:#323232;
                }
              }
              .react-autosuggest__container {
                .inputContainer {
                  border: 2px solid #b2b2b2 !important;
                  &:hover{
                    border-color: $hoverColor !important;
                  }
                }
              }
            }
            .surveyTextFields {
              background: transparent !important;
              .whySwitchOutside{
                background: transparent !important;
                .whySwitch{
                  background: transparent !important;
                  .inlineList{
                    h2{
                      border-bottom: 1px solid #fff;
                      color:#fff;
                    }
                    ul.partnershipList{
                      li{
                        background: none;
                      }
                    }
                    ol{
                      li{
                        &::marker{
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .submit_outer{
          background: $mainThemeColor !important;
          color: #ffffff !important;
          box-shadow: 0px 3px 6px #00000029;
          .btn-submit{
            color: #fff;
          }
          &:hover{
            color:$mainThemeColor !important; 
            background: #000;
          }
        }
      }
    }





}









@media (max-width: 1424px) {
  .newSurveyForm-layoutNewFourth {
    .container {
      max-width: 1140px;
    }
    .surveyTextFields {
      h2 {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .newSurveyForm-layoutNewFourth {
    .surveyPartform {
      max-width: 362px;
      flex: 0 0 362px;
    }
    .surveyContent {
      margin-top: 63px;
      .surveyPartContent {
        padding-right: 20px;
      }
    }
  }
}

@media (max-width: 992px) {
  .newSurveyForm-layoutNewFourth {
    .surveyContent {
      .surveyPartContent {
        padding-right: 0px;
      }
    }
    .surveyPartform {
      max-width: 322px;
      flex: 0 0 322px;
      padding: 15px 15px 5px 15px;
    }
    .surveyTextFields {
      h2 {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .newSurveyForm-layoutNewFourth {
    .container {
      padding: 0px;
    }
    .surveyContent {
      display: inline-block;
      width: 100%;
      margin-top: 43px;
      padding: 0px 15px;
      .surveyPartContent {
        margin: 0px -15px;
      }
    }
    .surveyTextFields {
      h2 {
        font-size: 30px;
      }
    }
    .surveyPartform {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .sixth_layout_global {
    .surveyLayout11 {
      .surveyContent {
        .surveyPageText.text-left.newLayoutSurvey {
          .newSurveyPageContainer {
            .progressBar{
              left: 0px;
            }
          }
        }
      }
    }
  }
}

@media  (max-width: 560px) {
  .sixth_layout_global {
    .surveyLayout11 {
      .surveyContent {
        .surveyPageText.text-left.newLayoutSurvey {
          .dropdownMain {
            .dropdownMainList{
              width: 46%;
              margin: 2% 2%;
            }
          }

          .newSurveyPageContainer {
            .newSurveyFormBox {
              fieldset.MuiFormControl-root {
                div.MuiFormGroup-root {
                  label.MuiFormControlLabel-root{
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin: 2% 0%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
}

@media (max-width: 480px) {
  .newSurveyForm-layoutNewFourth {
    .surveyTextFields {
      hr {
        margin-bottom: 20px;
        margin-top: 0px;
      }
      h3 {
        font-size: 22px;
      }
      ul {
        li {
          padding: 10px 10px 10px 70px;
          font-size: 14px;
          strong {
            font-size: 18px;
          }
          .stepCount {
            left: 10px;
            top: 15px;
            font-size: 20px;
            width: 50px;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
    .stepsImg {
      margin-top: 10px;
      margin-bottom: 10px;
      display: none;
    }
    .newSurveyPageContainer {
      h2 {
        font-size: 28px;
      }
      .whySwitch {
        font-size: 14px;
      }
    }
  }

  .sixth_layout_global {
    .surveyLayout11 {
      .surveyContent {
        .surveyPageText.text-left.newLayoutSurvey {
          .newSurveyPageContainer {
            .progressBar {
              .progressBarContainer {
                .bar {
                  span{
                    padding: 0px;
                  }
                }
              }
            }
            .newSurveyFormBox {
              .chkbox_survey{
                .MuiFormControl-root {
                  label.MuiFormControlLabel-root{
                    margin: 10px 0%;
                    max-width: 100%;
                    flex: 0 0 100%;
                    .icon-span{
                      padding: 0px;
                    }
                  }
                }
              }
              .completeForm{
                font-size: 12px;
                display: inline-block;
                img{
                  max-width: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
