.date-box {
  &__field {
    margin: 0;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    line-height: inherit;
    padding: 5px;
    color: #7c7d7d;
    width: -webkit-fill-available;
    box-sizing: unset;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ced4da;
    border-left: none;
    margin-bottom: 5px;
    height: 45px;
    &:focus {
      background: themed("input_focus");
      outline: none;
    }
    &:hover {
      outline: none !important;
    }
    &--error {
      border: 1px solid #db0020 !important;
    }
    &--survey {
      font-size: 16px !important;
      font-weight: 500;
    }

    &::-webkit-input-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &::-moz-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &:-ms-input-placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
    &::placeholder {
      font-size: 15px !important;
      font-weight: 500;
    }
  }
}
.react-datepicker-wrapper {
  display: flex !important;
}
// .react-datepicker {
//   font-size: 1rem !important;
//   width: 200px;
// }
// .react-datepicker__header {
//   width: 198px;
// }

.date-box__field {
  width: 100%;
}
.react-datepicker-wrapper {
  height: 57px;
}

// element.style {
//   font-family: Roboto;
// }
.layout_first-demo-default-theme
  .newSurveyForm
  .newSurveyPageContainer
  .newSurveyContent
  .questionSteps
  .input_date
  .date-box__field {
  text-align: left !important;
  background: white !important;
}
