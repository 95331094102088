.dynamic-form {
  display: flex;
  flex-direction: column;
  margin: 15px 15px;
  padding: 20px 0px 20px 0px;
  justify-content: left;
  //align-items: center;
  width: 100%;
  .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 500;
    
    color: #49494a;
  }
  .space-between {
    margin: 10px 0 10px 0;
    text-align: left;
    width: 100%;
  }
  option {
    font-weight: 500;
  }
}
