@font-face {
  font-family: "acquerly-icons";
  src: url("../font/acquerly-icons.eot?59891238");
  src: url("../font/acquerly-icons.eot?59891238#iefix")
      format("embedded-opentype"),
    url("../font/acquerly-icons.woff2?59891238") format("woff2"),
    url("../font/acquerly-icons.woff?59891238") format("woff"),
    url("../font/acquerly-icons.ttf?59891238") format("truetype"),
    url("../font/acquerly-icons.svg?59891238#acquerly-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'acquerly-icons';
    src: url('../font/acquerly-icons.svg?42688144#acquerly-icons') format('svg');
  }
}
*/

/* .demo-icon {
    font-family: "acquerly-icons";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    opacity: .8;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    font-size: 120%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
} */

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "acquerly-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-2people:before {
  content: "\e800";
}
/* '' */
.icon-4people:before {
  content: "\e801";
}
/* '' */
.icon-5people:before {
  content: "\e802";
}
/* '' */
.icon-ahm:before {
  content: "\e803";
}
/* '' */
.icon-arrow-down:before {
  content: "\e804";
}
/* '' */
.icon-arrow-left:before {
  content: "\e805";
}
/* '' */
.icon-arrow-right:before {
  content: "\e806";
}
/* '' */
.icon-arrow-up:before {
  content: "\e807";
}
/* '' */
.icon-au-act:before {
  content: "\e808";
}
/* '' */
.icon-au-nsw:before {
  content: "\e809";
}
/* '' */
.icon-au-nt:before {
  content: "\e80a";
}
/* '' */
.icon-au-qld:before {
  content: "\e80b";
}
/* '' */
.icon-au-sa:before {
  content: "\e80c";
}
/* '' */
.icon-au-tas:before {
  content: "\e80d";
}
/* '' */
.icon-au-vic:before {
  content: "\e80e";
}
/* '' */
.icon-au-wa:before {
  content: "\e80f";
}
/* '' */
.icon-belong:before {
  content: "\e810";
}
/* '' */
.icon-bupa:before {
  content: "\e811";
}
/* '' */
.icon-couple:before {
  content: "\e812";
}
/* '' */
.icon-date:before {
  content: "\e813";
}
/* '' */
.icon-electricity:before {
  content: "\e814";
}
/* '' */
.icon-email:before {
  content: "\e815";
}
/* '' */
.icon-entertainment:before {
  content: "\e816";
}
/* '' */
.icon-family:before {
  content: "\e817";
}
/* '' */
.icon-female:before {
  content: "\e818";
}
/* '' */
.icon-gas-electricity:before {
  content: "\e819";
}
/* '' */
.icon-gas:before {
  content: "\e81a";
}
/* '' */
.icon-hcf:before {
  content: "\e81b";
}
/* '' */
.icon-hif:before {
  content: "\e81c";
}
/* '' */
.icon-high:before {
  content: "\e81d";
}
/* '' */
.icon-location:before {
  content: "\e81e";
}
/* '' */
.icon-long-arrow-left:before {
  content: "\e81f";
}
/* '' */
.icon-long-arrow-right:before {
  content: "\e820";
}
/* '' */
.icon-low:before {
  content: "\e821";
}
/* '' */
.icon-male:before {
  content: "\e822";
}
/* '' */
.icon-mate-communicate:before {
  content: "\e823";
}
/* '' */
.icon-medibank:before {
  content: "\e824";
}
/* '' */
.icon-medium:before {
  content: "\e825";
}
/* '' */
.icon-mobile:before {
  content: "\e826";
}
/* '' */
.icon-mobile-broadband:before {
  content: "\e827";
}
/* '' */
.icon-mobile-video:before {
  content: "\e828";
}
/* '' */
.icon-money1:before {
  content: "\e829";
}
/* '' */
.icon-money2:before {
  content: "\e82a";
}
/* '' */
.icon-money3:before {
  content: "\e82b";
}
/* '' */
.icon-money4:before {
  content: "\e82c";
}
/* '' */
.icon-money5:before {
  content: "\e82d";
}
/* '' */
.icon-more-data:before {
  content: "\e82e";
}
/* '' */
.icon-nib:before {
  content: "\e82f";
}
/* '' */
.icon-no:before {
  content: "\e830";
}
/* '' */
.icon-no-insurance:before {
  content: "\e831";
}
/* '' */
.icon-no-pervious-internet:before {
  content: "\e832";
}
/* '' */
.icon-nothanks:before {
  content: "\e833";
}
/* '' */
.icon-not-sure:before {
  content: "\e834";
}
/* '' */
.icon-optus:before {
  content: "\e835";
}
/* '' */
.icon-other:before {
  content: "\e836";
}
/* '' */
.icon-other-health-insurance:before {
  content: "\e837";
}
/* '' */
.icon-own:before {
  content: "\e838";
}
/* '' */
.icon-own-internet:before {
  content: "\e839";
}
/* '' */
.icon-plus:before {
  content: "\e83a";
}
/* '' */
.icon-price:before {
  content: "\e83b";
}
/* '' */
.icon-quality-reliability:before {
  content: "\e83c";
}
/* '' */
.icon-rent:before {
  content: "\e83d";
}
/* '' */
.icon-rent-internet:before {
  content: "\e83e";
}
/* '' */
.icon-search:before {
  content: "\e83f";
}
/* '' */
.icon-shared:before {
  content: "\e840";
}
/* '' */
.icon-single:before {
  content: "\e841";
}
/* '' */
.icon-speed:before {
  content: "\e842";
}
/* '' */
.icon-telstra:before {
  content: "\e843";
}
/* '' */
.icon-tgp:before {
  content: "\e844";
}
/* '' */
.icon-unsure:before {
  content: "\e845";
}
/* '' */
.icon-unsure-data:before {
  content: "\e846";
}
/* '' */
.icon-user:before {
  content: "\e847";
}
/* '' */
.icon-virgin:before {
  content: "\e848";
}
/* '' */
.icon-vodafone:before {
  content: "\e849";
}
/* '' */
.icon-yes:before {
  content: "\e84a";
}
/* '' */
