// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&display=swap');


/* This stylesheet generated by Transfonter (https://transfonter.org) on December 7, 2017 3:38 PM */
.body{
  font-family:"din-2014";
}
.layout_second{
  background-color: #f2f2f2 ;
  // font-family: 'Open Sans', sans-serif;
  font-family:"din-2014";
  min-height: 100vh;
  padding-top: 74px;


.navigationHeader .container{
  max-width: 1288px !important;
}
.bodyContentArea .container, .section-footer .container{
  max-width: 1470px !important;
}
main{
  width: 100%;
  background-color: #f2f2f2;
}
header{
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
}
.navigationHeader{
  background: #fff;
  flex-wrap: wrap !important;
  padding:.0rem 0rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .navbar-brand{
    padding: 12px 0px;
  }
}
.logoImg{
  max-height: 50px;
}
.loginJoin ul li{
  margin: 0px 8px 0px 0px;
}
.loginJoin ul li a{
  border-radius:8px 0px 8px 0px;
  min-width: 116px;
  height:40px;
  text-align: center;
  font-weight:600;
}
.loginBtn a{
  border: 1px solid #ea0029;
  color: #ea0029;
}
.joinBtn a{
  background: #ea0029;
  color: #fff;
}
.getShopa{
  background: #ea0029;
  width:100%;
  float: left;
  padding: 15px 0px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}
.getShopa a.getShopaApp{
  color:#fff;
  float: right;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
a.getShopaApp svg{
  width: 1.5em;
  height: 1.5em;
  fill: #ffffff;
}
a.getShopaApp:hover{
  color: #fff !important;
}
.navbar-toggler{
  background: #ea0029;
}
.navigationHeader .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.mainMenu ul.navMenu li{
  position: relative;
}
.mainMenu ul.navMenu li a.nav-link{
  color: #fff;
  font-size:1.2rem;
  font-weight: 500;
  padding: .5rem 1em;
}
.mainMenu ul.navMenu li:first-child a.nav-link{
  padding: .5rem 1rem .5rem 0rem;
}
.mainMenu ul.navMenu svg{
  width: 15px;
  fill: #fff;
}
.mainMenu .dropdown-menu{
  width: 725px;
  padding: 15px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}
.mainMenu .dropdown-menu .subMenu{
  max-width:33.3333%;
  flex: 0 0 33.3333%;
  margin: 0px;
  padding: 0px;
  float:left;
}
.mainMenu .dropdown-menu .subMenu li{
  list-style: none;
  float: left;
  width:100%;
  margin: 5px 0px;
}
.mainMenu .dropdown-menu .subMenu li a{
  color: #000;
  font-size: 18px;
  display: inline-block;
  width: 100%;
  text-decoration: none;  
}
.mainMenu .dropdown-menu .subMenu li a:hover{
  color: #ea0029;
}

.formBlock{
  background:#fff;
  padding: 40px;
  border-radius:5px;
  margin-bottom: 50px;
}
.formHead{
  text-align: center;
  margin-bottom: 30px;
}
.formHead h1{
  font-size:44px;
  font-weight: bold;
  color:#ea0029;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'din-2014';
}
.formHead h1 span{
  color: #000;
}
.formHead h5{
  margin-top:20px;
  font-size:22px;
}
.formBlockContainer{
  width: 700px;
  max-width: 100%;
  margin: auto;
}
.formBlockContainer form{
  width:500px;
  max-width: 100%;
  margin: auto;
}
.formBlockContainer{
  .info_sm{
    max-height: 250px;
    overflow: auto;
    background: #eee;
    padding: 15px;
    border-radius: 5px;
    font-family: 'din-2014';
    font-weight: 300;
    ul{
      margin-left:30px;
      li{
        font-family: 'din-2014';
    font-weight: 300;
    margin:8px 0px;
      }
    }
    p{
      font-family: 'din-2014';
    font-weight: 300;
    }
    a{
      font-family: 'din-2014';
      font-weight: 300;
    }
  }
}
.form-group{
  position: relative;
  margin-bottom: 25px;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'din-2014';
  font-weight: 300;
}
.form-group input{
  display: block;
  background: transparent;
  border: solid 1px #ccc;
  transition: all .3s ease;
  padding: 0 15px
}
.form-group input:focus, .form-group input:hover, .form-group select:focus, select:hover{
  border-color: #333;
  box-shadow: none;
}
.form-group label{
  position: absolute;
  cursor: text;
  top: 17px;
  left: 10px;
  font-size: 15px;
  font-weight: 400;  
  padding: 0px 5px;
  color: #999;
  transition: all .3s ease;
  margin-bottom:0px;
}
.form-group label span{
  color: #ea0029;
}
.col-sm-6.form-group label{
  left: 25px;
}
.form-group input:focus + label,
.form-group input:valid + label, 
.form-group select:focus + label,
.form-group select:valid + label,
.form-group:focus input + label
{
  font-size: 12px;
  top: -10px;
  background: #fff;
  z-index: 1;
}
.form-group input:focus + label, .form-group select:focus + label{
  color: #333;
  background: #fff;
}

.info_sm{
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}
.acceptBnt{
  background-color: #EA0029;
  font-size: 1.25rem;
  text-transform: none;
  min-width: 64px;
  padding: 8px 22px;
  line-height: 1.75;
  font-weight: 500;
  cursor: pointer;
  box-shadow: none;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color:#fff;
  margin-top: 30px;
}


.shopaFooter{
  // border-top: 1px solid #e5e5e5;
  padding:0px 0px 15px 0px;
}
.shopaFooter h4{
  font-size: 0.875rem;
  font-family: 'DIN2014-Bold';
  line-height: 1.43;
  margin-bottom: 8px;
}
.shopaFooter ul{
  padding: 0px 0px 0px 10px;
  margin-bottom: 30px;
}
.shopaFooter ul li{
  color: #707070;
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 8px;
  border-right-color: #ddd;
  border-right-width: 1px;
  list-style: none;
}
.shopaFooter ul li a{
  color: #707070;
}
.shopaFooter ul li a:hover{
  color:#ea0029;
  text-decoration: none;
}
.copyRights{
  // border-top: 1px solid #e5e5e5;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.copyRights p{
  margin-bottom:0px;
  text-align: center;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #000;
  margin-top: 15px;
}
.appDownload{
  background-color:#fff;
  background-image: url(https://s3-ap-southeast-2.amazonaws.com/micrositesacquirely/micrositessub/acquirely_5_bff0463d-92aa-4a54-b29f-080ced233b3c.png);  
  padding: 10px;
  width: 215px;
  min-height: 200px;
  max-width:100%;
  display: inline-block;
  background-position: bottom right;
  background-repeat:no-repeat;
  background-size:cover;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius:5px;
}
.appDownload h3{
  font-size: 1.2rem;
  line-height:1.5rem;
  color: rgb(17, 191, 25);
}
.appDownloadImages{
  position: absolute;
  left: 10px;
  bottom: 10px;

}
.appDownloadImages a{
  margin-top:5px;
  display: inline-block;
}
.appDownloadImages img{
  max-height:35px;
}
.shopaDocketAppDownload{
  background-image: url(https://s3-ap-southeast-2.amazonaws.com/micrositesacquirely/micrositessub/acquirely_54_949babee-c196-4d97-95a8-23044a10e645.png); 
}
.shopaDocketAppDownload h3{
  color: rgb(234, 0, 41);
}
.partOfGroup{
  float:right;
  text-align: right;
  margin-top:20px;
}
.partOfGroup p{
  font-size: 0.875rem;
  margin-bottom:0px;
  color:#000;
  font-weight:600;
}
.partOfGroup img{
  max-width: 126px;
}
.socialIcons a{
  width:auto;
  margin-right: 7px;
}
.socialIcons a svg{
  width: 23px;
  fill: #707070;
}
.logoMobileImg{
  display: none;
}



.dynamic-form{
  flex-direction: row;
  flex-wrap: wrap;
  margin:0px -7.5px;
  width: auto;
  .space-between{
    margin: 0px 0px 15px 0px;
    flex:0 0 100%;
    max-width:100%;
    padding:0px 7.5px;
    &.halfColumn{
      flex:0 0 50%;
      max-width:50%;
    }
    & > div{
      position: relative;
      .datePickerIcon{
        position: absolute;
        right: 5px;
        top: 10px;
        font-size: 22px;
      }
    }
    .form-group{
      margin-bottom:0px;
      input{
        height: calc(1.9em + 1rem + 2px);
        background-color: transparent;
        position: relative;
        z-index: 1;
      }
    }
    .select-box{
      .inputContainer{
        border:none !important;
        i{
          position: absolute;
          left:2px;
          top: 5px;
        }
        input{
          border-radius:5px;
          padding-left:40px;
          height: calc(2.1em + 1rem + 2px);
          background-color: transparent;
          position: relative;
          z-index: 1;
        }
      }
    }
    .react-datepicker-popper{
      z-index: 2;
    }
  }
}
.thankYouTitle{
  width: 700px;
  max-width: 100%;
  margin: auto;
  color: #000;
  font-weight: 600;
  padding: 40px 0px;
}


.dynamic-form .space-between .react-datepicker-popper{
  z-index: 9;
}
.react-datepicker{
  background:#fff;
  border: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
.react-datepicker__header{
  background:#fff;
  border:none;
}
.react-datepicker__day-name{
  color:rgb(112, 112, 112);
}
.react-datepicker__current-month{
  display: none;
}
.react-datepicker__month-read-view--down-arrow{
  display: none;
}
.react-datepicker__year-read-view--down-arrow{
  border-width:0.35rem;
  margin-left:10px;
  border-top-color: #000;
  top: 12px;
}
.react-datepicker__header__dropdown{
  display: inline-block;
  width:100%;
  text-align:left;
  padding:5px 15px;
  font-size: 1.2rem;
  top: 12px;
}
.react-datepicker__navigation--previous {
  right: 50px;
  border-right-color: #000;
  top: 22px;
  left: auto;
}
.react-datepicker__navigation--next{
  top:22px;
  border-left-color: #000;
}
.react-datepicker__day{
  color:#000;
}
.react-datepicker__day--outside-month{
  opacity: 0;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 2.3rem;
line-height: 2.3rem;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover {
	background-color: #f5f5f5;
  border-radius:50%;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
  background-color: #ec1c2a;
  border-radius:50%;
  color: #fff;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover{
  background-color: #f5f5f5;
  border-radius:50%;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
  background-color: #ec1c2a;
  border-radius:50%;
  color: #fff;
}
.react-datepicker__triangle{
  display: none;
}
.react-datepicker-popper[data-placement^="bottom"]{
  margin-top:0px;
}
.react-datepicker-popper[data-placement^="top"]{
  margin-bottom:0px;
}
.react-datepicker__year-read-view, .react-datepicker__month-read-view{
  visibility: visible !important;
}
.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
  background: #fff;
  border: none;
  // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  top: 50px;
  width: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding:15px;
  min-height: 320px;
}
.react-datepicker__year-option{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 15px 10px;
  font-size: 14px;
  margin-left: inherit;
  margin-right: inherit;
  border-radius: 0px;
}
.react-datepicker__month-option{
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 15px 10px;
  font-size: 14px;
  margin-left: inherit;
  margin-right: inherit;
  line-height: 40px;
}
// .react-datepicker__year-dropdown > div:first-child, .react-datepicker__year-dropdown > div:last-child{
//   position: relative;
//   flex: 0 0 100%;
//   max-width: 100%;
// }
.react-datepicker__year-option.react-datepicker__year-option--selected_year, .react-datepicker__month-option.react-datepicker__month-option--selected_month{
  background: #ea0029;
  color:#fff;
}

.react-datepicker__month-option--selected, .react-datepicker__year-option--selected{
  display: none;
}





@media (max-width: 992px){
  .navigationHeader .container{
    padding: 0px 15px;
  }
  .shopaFooter{
    border-top:none;
    padding-top: 0px;
  }
  .footerTop{
    display: none;
  }

}

@media (max-width: 767px){ 
  .dynamic-form{
    margin:0px;
  }
}


@media (max-width: 640px){
  padding-top:57px;
  .getShopa{
    display:none;
  }
  .navigationHeader{
    padding: 0.5rem 0rem;
    background-color: #ea0029 !important;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  }
  .navigationHeader .navbar-brand{
    img{
      filter: grayscale(100%) invert(100%) sepia(200%) saturate(0%) hue-rotate(130deg) brightness(200%) contrast(100%);
      max-height: 30px !important;
    }
  }
  .logoImg{
    display: none;
  }
  .logoMobileImg{
    display: block;
    max-width: 178px;
  }
  .joinBtn a{
    background: #fff;
    color: #ea0029;
    height:30px;    
  }
  .loginJoin ul li a{
    height:30px;
    font-size:12px;
    min-width: 64px;
  }
  .formBlock{
    padding: 15px;
  }
  .formHead h1 {
    font-size: 2.125rem;
  }
  .competitionBannerSection .container, .formSection .container{
    padding: 0px 0px;
  }
  .formHead h5{
    font-size:18px;
  }
  .dynamic-form{
    margin:0px;
    .space-between{
      padding:0px;
      &.halfColumn{
        flex:0 0 100%;
        max-width:100%;
      }
    }
  }
  .thankYouTitle{
    font-size:2rem;
  }
}



}