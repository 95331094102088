@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600&display=swap");
@font-face {
  font-family: 'gothambold';
  src: url('../../assets/font/gothambold-webfont.woff2') format('woff2'),
       url('../../assets/font/gothambold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gothambook';
  src: url('../../assets/font/gothambook-webfont.woff2') format('woff2'),
       url('../../assets/font/gothambook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
p {
  box-sizing: content-box;
}
a {
  cursor: pointer;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: left !important;
  background-color: #fff !important;
}
* {
  box-sizing: border-box;
  font-weight: 400;
  padding: 0;
  padding-bottom: 0px;
  margin: 0;
}
.contactDynamicForm .labelColor {
  color: #4c4747;
  float: left;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 400px;
  height: 10px;
  border-radius: 5px;
  background: #90e1b0;
  outline: none;
  opacity: 1;
  -webkit-transition: 1;
  transition: opacity 1;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  clear: both;
}

/**************************************
            Global css
**************************************/
.sixth_layout_global {
  .contactDynamicForm{
    .auto_select{
      margin-bottom:15px;
    }
  }
  .input-box__field {
    border-width: 2px;
  }
  .header-main-div {
    .header-section {
      .fixed-top {
        .navbar-brand {
          img {
            max-height: 44px;
          }
        }
      }
    }
  }
  .bannerSection::after {
    display: none;
  }
  [class^="makeStyles-mainRangeValue-"] {
    // position: absolute;
    // right: 50px;
    // top: 50px;
    label {
      border-radius: 11px;
      align-items: center;
      height: 24px;
      padding: 5px 10px 5px;
      transition: all 0.3s ease 0s;
      font-size: 14px !important;
      font-weight: normal !important;
      padding-right: 10px !important;
    }
  }
  .banner-area {
    padding: 66px 0px 0px 0px;

    .bannerHead {
      position: relative;
      display: flex;
      height: 60vw;
      max-height: 60vh;
      overflow: hidden;
      &::before {
        content: "";
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
      .bannerVideoBackground {
        position: absolute;
        // top: 50%;
        // left: 50%;
        width: 100%;
        height: 100%;
        // padding-bottom: 66.25%;
        // transform: translate(-50%, -50%);
        pointer-events: none;
        img {
          height: 100%;
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
      .bannerVideoContent {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        max-width: 1070px;
        padding: 0 15px;
        margin: 0 auto;
        margin-bottom: 0px;
        text-align: center;
        justify-content: flex-end;
        margin-bottom: 80px;
        z-index: 2;
        h2 {
          font: normal normal 600 45px/50px Montserrat;
          letter-spacing: -0.04px;
          text-shadow: 0px 2px;
        }
        .sub_title {
          letter-spacing: -0.02px;
          font: normal normal 500 20px/25px Montserrat;
        }
      }
    }
  }

  .bannerForm {
    border-radius: 5px;
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding: 50px;
    margin-top: -50px;
    position: relative;
    z-index: 3;
    .formRangeSlider {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      label {
        font: normal normal bold 23px/28px Montserrat;
        letter-spacing: 0px;
      }
      .ageSlider {
        display: inline-block;
        border-radius: 11px;
        align-items: center;
        font: normal normal 500 13px/16px Montserrat;
        line-height: 16px;
        letter-spacing: 0px;
        line-height: 14px;
        height: 24px;
        padding: 5px 10px 0;
        transition: 0.3s all;
      }
      input.rangeInput {
        border-radius: 10px;
        height: 12px;
        width: 100%;
        outline: none;
        -webkit-appearance: none;
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }

    .submitBtn {
      border-radius: 5px;
      text-align: center;
      height: 60px;
      letter-spacing: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      cursor: pointer;
      font: normal normal bold 22px/27px Montserrat;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      transition: 0.2s;
    }
    .sliderAgeText {
      font: normal normal bold 11px/13px Montserrat;
      letter-spacing: 0px;
      text-align: center;
      position: relative;
      padding-left: 35px;
      margin-top: 20px;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        // background-image: url(../images/tick_range.svg);
        position: absolute;
        top: -4px;
        margin-left: -25px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .newSurveyFormBox {
    padding: 100px 0px;
    min-height: 80vh;
    .bannerForm {
      box-shadow: none;
      .questionSteps {
        label.MuiFormLabel-root {
          text-align: center !important;
          font-weight: 600;
          display: inline-block !important;
          width: 100%;
          margin-bottom: 30px;
          //   color: #1d2c35 !important;
          padding-right: 0px;
        }
        .MuiFormControl-root {
          .MuiFormGroup-root {
            label.MuiFormControlLabel-root {
              box-shadow: none;
              // background: transparent;
              // border: 1px solid #707070;
              // color:#888888 !important;
              &:hover {
                //background: $secondry-color;
                //  color: #fff !important;
              }
              span.MuiTypography-root {
                font-size: 20px;
                letter-spacing: 0.5px;
                width: 100%;
                text-align: center;
              }
            }
          }
        }
        .MuiGrid-root {
          width: 328px;
          max-width: 100%;
          margin: 0px auto;
          .react-datepicker-wrapper {
          }
        }
        .date-box__field {
          height: 44px;
          width: 96%;
        }
        .btn.btn-submit {
          margin-top: 30px;
          width: 328px;
          max-width: 100%;
          border-radius: 5px;
          text-align: center;
          height: 60px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .customDropdown-default-theme_span {
          border-radius: 5px;
          font-size: 20px;
          letter-spacing: 0.5px;
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
        }
        .customDropdown-default-theme_span_selected {
          border-radius: 5px;
        }
      }
    }
    .newSurveyFooter {
      text-align: center;
      width: 100%;
      .nextBtn {
        margin-top: 30px;
        width: 328px;
        max-width: 100%;
        border-radius: 5px;
        text-align: center;
        height: 60px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: inline-block !important;
        float: none !important;
        border: none;
      }
    }
  }

  .progressBar {
    display: none;
  }
  .newSurveyContent {
    .questionSteps {
      .MuiFormGroup-root {
        flex-direction: row;
        margin: 0px -1%;
        label {
          &.MuiFormControlLabel-root {
            max-width: 48%;
            flex: 0 0 48%;
            margin: 1%;
            border-radius: 5px;
            border: none;
            text-align: left;
          }
        }
      }
    }
  }

  .firstSection {
    padding: 70px 0px 80px 0px;
    position: relative;
    .firstHeadingDiv {
      width: 25%;
      float: left;
      padding-right: 25px;
    }
    img {
      max-width: 100%;
      height: 140px;
    }
    h2 {
      letter-spacing: 0px;
      font-size: 45px;
      line-height: 45px;
      padding: 0;
      position: relative;
      padding-bottom: 0px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .sub_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 50px;
      margin-top: 45px;
    }
    .boxes {
      width: 65%;
      float: right;
      padding-left: 25px;
    }
    .TimeBoxes {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -15px;
      .timeBoxInside {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding: 0px 15px;
        position: relative;
        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 5%;
          margin-right: 20px;
          padding: 20px;
          text-decoration: none;
          flex-direction: column;
          transition: 0.5s;
          &:hover {
            transform: translateY(-1em);
          }
        }
        p {
          padding-bottom: 0px;
          font-size: 20px;
          line-height: 25px;
          font-weight: bold;
          width: 100%;
          text-align: left;
          margin-top: 30px;
        }
        span {
          font-size: 14px;
          line-height: 17px;
          display: block;
          text-align: left;
          width: 100%;
        }
        + .timeBoxInside {
          top: 50px;
        }
        &:last-child {
          top: 0px;
        }
      }
    }
  }

  .secondSection {
    padding: 70px 0 50px;
    &::before {
      display: none;
    }
    h2 {
      text-align: center;
      font-size: 45px;
      line-height: 28px;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 0px;
      padding-bottom: 20px;
      margin-bottom: 0px;
    }
    .sub_title {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      width: 1000px;
      max-width: 100%;
      margin: 0 auto 40px;
    }
    .brandsList {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .box-logo {
        margin: 0 15px;
      }
    }
    .bannerForm {
      background: none;
      box-shadow: none;
      padding: 0px;
      width: 100%;
      text-align: center;
      margin-top: 0px;
      .submitBtn {
        display: inline-block;
        height: auto;
        padding: 17px 50px;
      }
    }
  }

  .thirdSection {
    padding: 50px 0;
    h2 {
      text-align: center;
      font-size: 40px;
      line-height: 45px;
      font-weight: bold;
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 0px;
      span {
        display: block;
        margin-bottom: 35px;
        font-size: 16px;
        line-height: normal;
      }
    }
    ul {
      max-width: 900px;
      padding: 0 15px;
      margin: 0 auto;
      li {
        list-style: none;
        margin-bottom: 24px;
        min-height: 83px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        font-size: 20px;
        padding-right: 20px;
        padding-left: 20px;
        strong {
          font-size: 25px;
          line-height: 28px;
          height: 83px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          border-radius: 5px 0 0 5px;
          min-width: 122px;
          width: 122px;
          margin-right: 25px;
        }
      }
    }
    .bannerForm {
      width: 870px;
      margin-top: 80px;
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding: 40px 40px;
      &::before {
        content: "";
        position: absolute;
        width: 315px;
        height: 282px;
        left: 33px;
        top: -40px;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .slider_age_step {
        width: calc(100% - 340px);
      }
    }
  }

  .section-footer {
    .footer-content {
      padding: 2% 0%;
      .row{
        align-items: center;
      }
      p {
        font-size: 14px;
      }
      .custome-links {
        margin-top: 0px;
        display: inline-block;
        width: 100%;
        padding-top: 0px;
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          float: right;
          li {
            display: inline-block;
            padding-left: 20px;
            a,span {
              text-decoration: none;
              text-transform: none;
              font-size: 12px;
            }
          }
        }
      }
    }
    .copyright {
      padding: 40px 0px;
      text-align: center;
      p {
        font-size: 10px;
        width: 1000px;
        max-width: 100%;
        margin: 0px auto;
      }
    }
  }

  .formSection {
    padding: 50px 0px;
    .formSteps {
      text-align: center;
      label {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 37px;
        font-weight: 600;
      }
    }
    .brandsLogo {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 15px;
      margin-top: 50px;
      li {
        list-style: none;
        display: inline-block;
        margin: 0px 5px;
        img {
          max-width: 78px;
        }
      }
    }
  }

  .pageTitle {
    padding-top: 100px;
    padding-bottom: 40px;
    text-align: center;
  }
  .innerPageContent {
    padding: 50px 0;
    letter-spacing: 1px;
    position: relative;
    word-break: break-word;
    .body-content1 {
      border-radius: 5px;
      padding: 25px;
      .contentAboutLeft {
        width: 100%;
        display: inline-block;
        padding: 15px 0px 30px 0px;
        margin-bottom: 30px;
      }
      .imgAbout {
        float: left;
        width: 490px;
      }
      .contentAbout {
        margin-left: 490px;
        ul {
          li {
            display: inline-block;
            width: 100%;
            list-style: none;
            padding: 5px 0px;
            border-radius: 5px;
            background-image: url(../../assets/images/yes-green.svg);
            background-repeat: no-repeat;
            background-size: 24px 24px;
            padding-left: 40px;
            background-position: 0px 6px;
          }
        }
      }
    }
    .contactWrapper1 {
      border-radius: 5px 5px 0px 0px;
      padding: 40px;
      display: inline-block;
      width: 100%;
      .form-heading {
        float: left;
        width: 50%;
        padding-right: 40px;
        h2 {
          width: 100%;
          font-size: 57px;
        }
      }
    }
  }
  .innerPageContent .contentAboutLeft h2 {
    font-size: 36px;
    letter-spacing: 1px;
    float: left;
    width: 30%;
  }
  .innerPageContent .contentAboutLeft h4 {
    float: left;
    width: 70%;
  }

  .newSurveyFormBox {
    .bannerForm {
      .newSurveyContent {
        .questionSteps {
          .controls {
            .descrioption-news {
              text-align: center;
              h2 {
                text-align: center;
                font-weight: normal;
                margin: 0 auto;
                background-color: transparent;
                width: 100%;
                max-width: 100% !important;
                letter-spacing: 0px;
                font-size: 36px;
                margin-top: 20px;
                line-height: 37px;
                strong {
                  font-weight: 600;
                }
              }
            }
            .dynamic-form {
              width: auto;
              .space-between {
                label.MuiFormLabel-root {
                  display: none !important;
                }
                textarea {
                  width: 100%;
                }
              }
            }
          }
          .form_button {
            width: 328px;
            margin: auto;
            max-width: 100%;
            display: inline-block;
            margin-top: 30px;
            border-radius: 5px;
            text-align: center;
            height: 60px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            border: none;
          }
        }
      }
    }
  }
  .contactDynamicForm {
    .dynamic-form {
      margin: 0px;
      padding: 0px 0px 00px 0px;
      .space-between {
        margin: 0px 0 0px 0;
      }
    }
  }
  .contactControl {
    width: 50%;
    float: left;
    .contactDynamicForm {
      .dynamic-form {
        margin: 0px;
        padding: 0px 0px 00px 0px;
        width: auto;
        .space-between {
          margin-top: 10px;
          label.MuiFormLabel-root {
            display: block !important;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1px;
          }
          input {
            &.form_button {
              width: 100%;
              font-size: 18px;
              text-transform: uppercase;
              letter-spacing: 2px;
              padding: 15px 30px;
              border-radius: 5px;
              margin-top: 20px;
              display: inline-block;
              font-weight: 600;
              border: none !important;
            }
          }
        }
      }
    }
  }
  .SliderStepImage {
    width: 340px;
    padding-right: 40px;
    margin-top: -70px;
    img {
      max-width: 100%;
    }
  }

  .faqSection {
    display: inline-block;
    width: 100%;
    padding: 60px 0px;
    position: relative;
    padding-top: 130px;
    &:before {
      height: 100px;
      width: 100%;
      left: 0px;
      top: 0px;
      content: "";
      position: absolute;
    }
    .container {
      position: relative;
      z-index: 1;
    }
    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
    //******** Accordian css**********//

    /* Accordion styles */
    .tabs {
      border-radius: 8px;
      margin-bottom: 50px;
      text-align: left;
      display: inline-block;
      width: 100%;
    }
    .tab {
      width: 100%;
      overflow: hidden;
      // border-bottom: 1px solid;
      margin: 3px 0px;
      border-radius: 5px;
      display: inline-block;
      &-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        font-weight: normal;
        cursor: pointer;
        margin-bottom: 0px;
        font-size: 20px;
        font-family: Helvetica;
        position: relative;
        padding-left: 70px;
        b {
          position: absolute;
          left: 0px;
          top: 0px;
          height: 70px;
          width: 60px;
          line-height: 70px;
          text-align: center;
        }

        /* Icon */
        &:hover {
          transition: 0.5s;
        }
        &::after {
          content: "\276F";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all 0.35s;
          font-size: 26px;
          margin-top: -6px;
          margin-left: 5px;
        }
      }
      &-content {
        max-height: 0;
        padding: 0 1em;
        transition: all 0.35s;
      }
      &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        cursor: pointer;
      }
      &:last-child {
        border: none;
      }
    }
    .tab input[type="checkbox"] {
      display: none;
    }
    // :checked
    input:checked {
      + .tab-label {
        &::after {
          transform: rotate(90deg);
        }
      }
      ~ .tab-content {
        max-height: 100vh;
        padding: 1em;
      }
    }
    //******** Accordian css end**********//
  }
  .thankyou-section {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 1;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
    .tankkyou-container {
      height: calc(100vh);
      display: table;
      width: 100%;
      .row {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        .descript-thankyou {
          text-align: center;
          color: #000;
          background: #fff;
          padding: 40px;
          border-radius: 5px;
          width: 100%;
          max-width: 850px;
          display: inline-block;
          margin: auto;
        }
      }
    }
  }
  .button-home a {
    display: inline-block;
    padding: 15px 40px;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 50px;
    text-decoration: none;
  }

  .section-footer{
    .footer-content{
        p{
          margin-bottom:0px;
          font-size:10px;
          color:#ddd;
        }
    }
  }
  
  .section-footer{
    .footer-content{
      .footerDisclaimer{
        padding:30px 0px 0px 0px;
        border-top:1px solid rgba(256, 256, 256, 0.3);
        margin-top:30px;
        text-align: center;
        p{
          max-width:1024px;
          width:100%;
          margin: auto;
          strong{
            font-weight: bold;
            span{
              font-weight: bold;
            }
          }
        }
        
      }
    }
  }

  .secure-ssl{
    padding: 0px 10px;
    border-radius: 5px;
    background: white;
  }
  .secure-ssl-img{
    max-height: 50px;
  }
  
  /*****************responsive css****************/

  @media (max-width: 1365px) {
    .firstSection {
      h2 {
        font-size: 40px;
      }
      .firstHeadingDiv {
        width: 30%;
      }
      .boxes {
        width: 70%;
      }
    }
    .secondSection {
      h2 {
        font-size: 40px;
      }
      .sub_title {
        font-size: 20px;
      }
    }
    .innerPageContent h2 {
      padding-right: 15px;
    }
    .innerPageContent .contactWrapper1 .form-heading h2 {
      font-size: 42px;
    }
  }
  @media (max-width: 1200px) {
    .innerPageContent h2 {
      font-size: 30px;
    }
    .innerPageContent h4 {
      font-size: 20px;
    }
  }

  @media (max-width: 992px) {
    .leftContent {
      max-width: 100%;
      flex: 0 0 100%;
      -ms-flex: 0 0 100%;
    }
    .rightContent {
      display: none;
    }
    .secondSection {
      h2 {
        line-height: normal;
      }
    }
    .thirdSection {
      ul.block-steps {
        li {
          font-size: 16px;
        }
      }
    }
    .firstSection {
      padding: 70px 0px 60px 0px;
      .firstHeadingDiv {
        width: 100%;
        padding-right: 0px;
      }
      .boxes {
        width: 100%;
        padding-left: 0px;
        .TimeBoxes {
          .timeBoxInside {
            + .timeBoxInside {
              top: 0px;
            }
          }
        }
      }
    }
    .banner-area {
      .bannerHead {
        height: auto;
        max-height: 100%;
        .bannerVideoBackground {
          img {
            display: none;
          }
        }
        .bannerVideoContent {
          padding: 40px 20px 20px 20px;
        }
      }
    }

    .innerPageContent h2 {
      width: 100%;
    }
    .innerPageContent h4 {
      width: 100%;
    }
    .innerPageContent .body-content1 .imgAbout {
      width: 350px;
    }
    .innerPageContent .body-content1 .contentAbout {
      margin-left: 350px;
    }
    .section-footer {
      .footer-content {
        .custome-links {
          ul {
            float: right;
            li {
              margin-top: 8px;
              a,span {
                text-transform: none;
              }
            }
          }
        }
      }
    }
   .secure-ssl{
    display: none;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 36px;
    }
    .thirdSection {
      h2 {
        font-size: 36px;
      }
      .bannerForm {
        padding: 20px 30px;
        &::before {
          width: 275px;
        }
        .slider_age_step {
          width: calc(100% - 290px);
        }
      }
    }
    .section-footer {
      .footer-content {
        .custome-links {
          ul {
            float: right;
            li {
              padding-left: 0px;
              margin: 4px 10px;
            }
          }
        }
      }
    }
    .innerPageContent .body-content1 .contentAbout {
      margin-left: 0px;
      width: 100%;
      display: inline-block;
      margin-top: 20px;
    }
    .innerPageContent .body-content1 .contentAbout div {
      margin-left: 0px !important;
    }
    .copyright_footer{
      text-align: center;
    }
    .foter-bottom ul{
      display: inline-block;
      margin-top:15px;
    }
    .footerDisclaimer{
      padding:15px 0px 0px 0px;
      margin-top: 15px;
    }
    .navbar-expand-lg {
      .navbar-nav {
        .nav-link{
          padding-right:0px !important;
          padding-left:0px !important;
        }
        .header-button.nav-link{
          padding-right: 1.5rem !important;
          padding-left: 1.5rem !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .banner-area {
      .bannerHead {
        .bannerVideoContent {
          h2 {
            font-size: 22px;
            line-height: normal;
            text-shadow: none;
          }
          .sub_title {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }
    .firstSection {
      padding: 40px 0px 40px 0px;
      h2 {
        font-size: 32px;
        line-height: normal;
        padding-bottom: 0px;
      }
      .sub_title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .TimeBoxes {
        margin: 0px -5px;
        .timeBoxInside {
          padding: 0px 5px;
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 20px;
          a {
            padding: 20px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .bannerForm {
      width: calc(100% - 30px);
      padding: 15px 20px;
    }
    .secondSection {
      padding: 30px 0 30px;
      h2 {
        font-size: 32px;
      }
      .sub_title {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        margin-bottom: 0px;
      }
      .brandsList {
        .box-logo {
          margin: 0;
          width: 33.33%;
          text-align: center;
          img {
            max-width: 85px;
          }
        }
      }
    }
    .thirdSection {
      padding: 30px 0;
      h2 {
        font-size: 28px;
        line-height: normal;
        padding: 0px;
      }
      ul.block-steps {
        padding: 0px;
        li {
          padding: 10px;
          strong {
            margin-right: 0px;
          }
        }
      }
      .bannerForm {
        margin-top: 0px;
        padding: 0px;
        &::before {
          width: 255px;
          height: 222px;
          left: calc(50% - 127px);
          top: -150px;
          z-index: 0;
        }
        .slider_age_step {
          width: 100%;
          z-index: 10;
          padding: 20px 30px;
          border-radius: 29px;
        }
      }
    }
    .section-footer {
      .footer-content {
        text-align: center;
        .custome-links {
          ul {
            width: 100%;
            margin-top: 15px;
            display: inline-block;
          }
        }
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          label.MuiFormLabel-root {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 20px;
          }
        }
      }
    }
    .thirdSection {
      .bannerForm {
        .SliderStepImage {
          display: none;
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      right: 15px;
      top: 15px;
    }
    .bannerForm .submitBtn {
      font-size: 16px;
    }
    .firstSection h2,
    .secondSection h2,
    .thirdSection h2 {
      font-size: 24px;
    }
    .innerPageContent h2 {
      font-size: 26px;
    }
    .innerPageContent h4 {
      font-size: 18px;
    }
    .innerPageContent .contactWrapper1 .form-heading {
      float: left;
      width: 100%;
      padding-right: 0px;
    }
    .controls.contactControl {
      width: 100%;
      display: inline-block;
      margin-top: 30px;
    }
    .innerPageContent .contactWrapper1 .form-heading h2 {
      font-size: 28px;
    }
    .getStartedSection {
      .bannerForm {
        .space-between {
          [class^="MuiFormLabel-root makeStyles-formLabelrootInline-"] {
            font-size: 22px !important;
          }
        }
      }
    }
    .faqSection h2 {
      font-size: 26px;
    }
    .faqSection .tab-label {
      font-size: 16px;
      b {
        height: 100%;
      }
    }
    .section-footer{
      .footer-content{
         .smallText{
          p{
            margin-top:20px
          }
         } 
      }
    }
    .thankyou-section {
      .tankkyou-container{
        height: auto;
        min-height:100vh;
        padding-top:80px;
        padding-bottom:15px;
      }
    }
  }
  @media (max-width: 420px) {
    .newSurveyContent
      .questionSteps
      .MuiFormGroup-root
      label.MuiFormControlLabel-root {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 5px 0px;
    }
    .innerPageContent .contactWrapper1 {
      padding: 15px;
    }
    .thankyou-section {
      .tankkyou-container{
        padding: 0px;
        padding-top: 80px;
        padding-bottom: 15px;
        height: auto;
        min-height: calc(100vh);
        .row {
          .descript-thankyou{
            padding:15px;
            h1{
              font-size: 28px;
            }
            .button-home {
              a{
                margin-top: 20px;
                padding: 15px;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0;
                font-weight: 600;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  //**************Layouts starts******************

  .layout_sixth-fifth-theme {
    $primary-color: #f47621;
    $secondry-color: #262f36;
    $text-color: #ffffff;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #ffa02b 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $primary-color;
      &:hover {
        background: $primary-color;
        color: $secondry-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $primary-color;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $primary-color;
          color: $secondry-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $secondry-color;
                color: #fff;
                &:hover {
                  background: $primary-color;
                  color: $secondry-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  .layout_sixth-seventh-theme {
    $primary-color: #fee005;
    $secondry-color: #1d2c35;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #ffe100 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $primary-color;
      &:hover {
        background: $primary-color;
        color: $secondry-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $primary-color;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $secondry-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $primary-color;
          color: $secondry-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $secondry-color;
      }
      .sub_title {
        color: #1d2c35;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $secondry-color;
        span {
          color: $secondry-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $secondry-color;
                color: #fff;
                &:hover {
                  background: $primary-color;
                  color: $secondry-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: $secondry-color;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: $secondry-color;
            b {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: $secondry-color;
        }
        ~ .tab-content {
          border: none;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  .layout_sixth-eighth-theme {
    $primary-color: #cd232e;
    $secondry-color: #cd232e;
    $text-color: #ffffff;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #750008 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $primary-color;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: #000000;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $text-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $text-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $primary-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $primary-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $secondry-color;
                color: #fff;
                &:hover {
                  background: $primary-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }
    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  .layout_sixth-sixth-theme {
    $primary-color: #5AB031;
    $secondry-color: #1D1D1B;
    $text-color: #ffffff;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #2da55f 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $primary-color;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $text-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $text-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $secondry-color;
                color: #fff;
                &:hover {
                  background: $primary-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }
    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  .layout_sixth-third-theme {
    $primary-color: #00bad2;
    $secondry-color: #212121;
    $text-color: #ffffff;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #0066a1 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $primary-color;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #00bad2 0%,
            #00bad2 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #028090;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $text-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $text-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $secondry-color;
                color: #fff;
                &:hover {
                  background: $primary-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }
    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  .layout_sixth-ninth-theme {
    $primary-color: #03233d;
    $secondry-color: #00467f;
    $text-color: #ffffff;
    $btnHover: #f47621;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #00467f 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }


/********************************************************************************************************************
                                        ~~~~~~~~New color themes 2022~~~~~~~
********************************************************************************************************************/


  // Red and white color theme
  .layout_sixth-red-white {
    $primary-color: #c70000;
    $secondry-color: #ae0000;
    $text-color: #ffffff;
    $btnHover: #c70000;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, #ae0000 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: #fff;
            transform: scale(1.03);
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  // Green and black color theme
  .layout_sixth-green-black {
    $primary-color: #000000;
    $secondry-color: #018001;
    $text-color: #ffffff;
    $btnHover: #000000;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: $primary-color;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background-color: $primary-color;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  // green and drawblue color theme
  .layout_sixth-green-drawblue {
    $primary-color: #4486F4;
    $secondry-color: #35A753;
    $text-color: #ffffff;
    $btnHover: #4486F4;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  // blue and white color theme
  .layout_sixth-blue-white {
    $primary-color: #0000FE;
    $secondry-color: #0000D0;
    $text-color: #ffffff;
    $btnHover: #4486F4;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }


  // pink and white color theme
  .layout_sixth-pink-white {
    $primary-color: #F474BC;
    $secondry-color: #F699CD;
    $text-color: #ffffff;
    $btnHover:  #F474BC;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
        p{
          color:#ffffff;
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }

  // pink and black color theme
  .layout_sixth-pink-black {
    $primary-color: #000000 ;
    $secondry-color: #F699CD;
    $text-color: #ffffff;
    $btnHover:  #000000;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $primary-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
        p{
          color:#ffffff;
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $primary-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color: #fff;
            b {
              background: $primary-color;
              color: #fff;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $primary-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }


  // blue and aqua color theme
  .layout_sixth-blue-aqua {
    $primary-color: #00FFFF ;
    $secondry-color: #0D49CD;
    $text-color: #ffffff;
    $btnHover:  #00FFFF;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: $secondry-color;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $secondry-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $secondry-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #fff;
        .custome-links {
          ul {
            li {
              a,span {
                color: #ddd;
              }
            }
          }
        }
        p{
          color:#ffffff;
        }
      }
      .copyright {
        background: $secondry-color;
        color: #fff;
      }
    }

    .pageTitle {
      background: $secondry-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #fff;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $secondry-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color:$secondry-color;
            b {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $secondry-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }


  // red, white and black color theme
  .layout_sixth-red-white-black  {
    $primary-color: #ed1b2e ;
    $secondry-color: #000000;
    $footer-color:#cecece;
    $text-color: #ffffff;
    $btnHover:  #ed1b2e;
    @mixin gradient-background {
      background: transparent
        linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
    }
    .button-home a {
      background: $secondry-color;
      color: $text-color;
      &:hover {
        background: $primary-color;
        color: $text-color;
      }
    }
    #navbarResponsive {
      ul {
        li {
          &.active {
            a {
              color: $btnHover;
            }
          }
          a {
            color: #fff;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .header-main-div {
      .header-section {
        .fixed-top {
          background: rgba(0, 0, 0, 0.5) !important;
          ul {
            .nav-item {
              a,span {  cursor: pointer;
                color: #fff;
              }
            }
          }
        }
      }
    }
    [class^="makeStyles-mainRangeValue-"] {
      label {
        background: $secondry-color;
        color: $text-color;
      }
    }
    .banner-area {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;

      .bannerHead {
        background: $secondry-color;
        &::before {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .bannerVideoContent {
          color: #fff;
          h2 {
            color: #ffffff;
          }
          .sub_title {
            color: #ffffff;
            text-shadow: 0px 3px 6px #00000029;
          }
        }
      }
    }

    .bannerForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0px 3px 6px #00000029;
      .formRangeSlider {
        label {
          color: $secondry-color;
        }
        .ageSlider {
          background: $primary-color;
          color: $secondry-color;
        }
        input.rangeInput {
          background: linear-gradient(
            to right,
            #ed9827 0%,
            #ed9827 25%,
            #cecfd0 25%,
            #cecfd0 100%
          );
        }
      }

      .submitBtn {
        background: $secondry-color;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        &:hover {
          background: $btnHover;
          color: $text-color;
        }
      }
      .sliderAgeText {
        color: #523b57;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .questionSteps {
          .btn.btn-submit {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
          .customDropdown-default-theme_span {
            background-color: transparent;
            border: 1px solid #707070;
            color: #888888;
            &:hover {
              background-color: $secondry-color;
              color: #fff;
            }
          }
          .customDropdown-default-theme_span_selected {
            background-color: $secondry-color;
            color: #fff;
          }
        }
      }
      .newSurveyFooter {
        .nextBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #fff;
          &:hover {
            background: $primary-color;
            color: $secondry-color;
          }
        }
      }
    }

    .newSurveyContent {
      .questionSteps {
        .MuiFormGroup-root {
          label {
            &.MuiFormControlLabel-root {
              box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
            }
          }
        }
      }
    }

    .firstSection {
      background: transparent
        linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
        padding-box;
      h2 {
        color: $text-color;
      }
      .sub_title {
        color: $text-color;
      }
      .TimeBoxes {
        .timeBoxInside {
          a {
            background: #fff;
            color: #1d2c35;
            &:hover {
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }

    .secondSection {
      h2 {
        color: #1d2c35;
      }
      .sub_title {
        color: #454545;
      }
    }

    .thirdSection {
      @include gradient-background;
      h2 {
        color: $text-color;
        span {
          color: $text-color;
        }
      }
      ul {
        li {
          background-color: #fff;
          color: $secondry-color;
          strong {
            color: $primary-color;
            background-color: #fff;
          }
        }
      }
    }

    .section-footer {
      .footer-content {
        background: $footer-color;
        border-bottom: 1px solid rgba(256, 256, 256, 0.2);
        color: #777;
        .custome-links {
          ul {
            li {
              a,span {
                color: #777;
              }
            }
          }
        }
        p{
          color:#777;
        }
      }
      .copyright {
        background: $secondry-color;
        color: #777;
      }
    }

    .pageTitle {
      background: $primary-color;
      color: #fff;
    }
    .innerPageContent {
      .body-content1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        .contentAboutLeft {
          border-bottom: dashed 1px #000;
        }
      }
      .contactWrapper1 {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .section-footer {
      .footer-content {
        color: #777;
      }
    }
    .newSurveyFormBox {
      .bannerForm {
        .newSurveyContent {
          .questionSteps {
            .controls {
              .descrioption-news {
                h2 {
                  background-color: transparent;
                  color: #000000;
                }
              }
            }
            .form_button {
              box-shadow: 0px 3px 6px #00000029;
              background: $primary-color;
              color: $secondry-color;
              &:hover {
                background: $secondry-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .contactControl {
      .contactDynamicForm {
        .dynamic-form {
          .space-between {
            input {
              border: 1px solid #ddd !important;
              &:hover {
                border-color: $secondry-color !important;
              }
              &.form_button {
                background: $primary-color;
                color: #fff;
                &:hover {
                  background: $secondry-color;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }

    .faqSection {
      &::before {
        background: url(../../assets/images/curve1.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-color: $primary-color;
      }
      .tabs {
        // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
      }
      .tab {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        color: $secondry-color;
        // border-color:  $secondry-color;
        &-label {
          background: #fff;
          b {
            background: $secondry-color;
            color: #fff;
            border-right: 1px solid #fff;
          }
          /* Icon */
          &:hover {
            background: $primary-color;
            color:$secondry-color;
            b {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
        &-content {
          color: #000;
          background: white;
        }
        &-close {
          background: $secondry-color;
          &:hover {
            background: $secondry-color;
          }
        }
      }
      // :checked
      input:checked {
        + .tab-label {
          background: $secondry-color;
          color: #fff;
        }
        ~ .tab-content {
          border: solid 1px #0e7e69;
        }
      }
    }

    /*****************responsive css****************/

    @media (max-width: 992px) {
      .navbar-light .navbar-toggler {
        background: $primary-color;
      }
    }
    @media (max-width: 600px) {
      .thirdSection {
        .bannerForm {
          .slider_age_step {
            background-color: #fff;
          }
        }
      }
    }
  }


    // red, white and black color theme
    .layout_sixth-red-blue  {
      $primary-color: #eb2227 ;
      $secondry-color: #0f2f66;
      $footer-color:#0f2f66;
      $text-color: #ffffff;
      $btnHover:  #eb2227;
      @mixin gradient-background {
        background: transparent
          linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
      }
      .button-home a {
        background: $secondry-color;
        color: $text-color;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      #navbarResponsive {
        ul {
          li {
            &.active {
              a {
                color: $btnHover;
              }
            }
            a {
              color: #fff;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
  
      .header-main-div {
        .header-section {
          .fixed-top {
            background: #eb2227 !important;
            ul {
              .nav-item {
                a,span {  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      [class^="makeStyles-mainRangeValue-"] {
        label {
          background: $secondry-color;
          color: $text-color;
        }
      }
      .banner-area {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
  
        .bannerHead {
          background: $secondry-color;
          &::before {
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.5) 100%
            );
          }
          .bannerVideoContent {
            color: #fff;
            h2 {
              color: #ffffff;
            }
            .sub_title {
              color: #ffffff;
              text-shadow: 0px 3px 6px #00000029;
            }
          }
        }
      }
  
      .bannerForm {
        background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        .formRangeSlider {
          label {
            color: $secondry-color;
          }
          .ageSlider {
            background: $primary-color;
            color: $secondry-color;
          }
          input.rangeInput {
            background: linear-gradient(
              to right,
              #ed9827 0%,
              #ed9827 25%,
              #cecfd0 25%,
              #cecfd0 100%
            );
          }
        }
  
        .submitBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #ffffff;
          &:hover {
            background: $btnHover;
            color: $text-color;
          }
        }
        .sliderAgeText {
          color: #523b57;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .questionSteps {
            .btn.btn-submit {
              background: $secondry-color;
              box-shadow: 0px 3px 6px #00000029;
              color: #fff;
              &:hover {
                background: $primary-color;
                color: $secondry-color;
              }
            }
            .customDropdown-default-theme_span {
              background-color: transparent;
              border: 1px solid #707070;
              color: #888888;
              &:hover {
                background-color: $secondry-color;
                color: #fff;
              }
            }
            .customDropdown-default-theme_span_selected {
              background-color: $secondry-color;
              color: #fff;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
      }
  
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label {
              &.MuiFormControlLabel-root {
                box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
              }
            }
          }
        }
      }
  
      .firstSection {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
        h2 {
          color: $text-color;
        }
        .sub_title {
          color: $text-color;
        }
        .TimeBoxes {
          .timeBoxInside {
            a {
              background: #fff;
              color: #1d2c35;
              &:hover {
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
  
      .secondSection {
        h2 {
          color: #1d2c35;
        }
        .sub_title {
          color: #454545;
        }
      }
  
      .thirdSection {
        @include gradient-background;
        h2 {
          color: $text-color;
          span {
            color: $text-color;
          }
        }
        ul {
          li {
            background-color: #fff;
            color: $secondry-color;
            strong {
              color: $primary-color;
              background-color: #fff;
            }
          }
        }
      }
  
      .section-footer {
        .footer-content {
          background: $footer-color;
          border-bottom: 1px solid rgba(256, 256, 256, 0.2);
          color: #eee;
          .custome-links {
            ul {
              li {
                a,span {
                  color: #eee;
                }
              }
            }
          }
          p{
            color:#eee;
          }
        }
        .copyright {
          background: $secondry-color;
          color: #eee;
        }
      }
  
      .pageTitle {
        background: $primary-color;
        color: #fff;
      }
      .innerPageContent {
        .body-content1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .contentAboutLeft {
            border-bottom: dashed 1px #000;
          }
        }
        .contactWrapper1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
  
      .section-footer {
        .footer-content {
          color: #eee;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .newSurveyContent {
            .questionSteps {
              .controls {
                .descrioption-news {
                  h2 {
                    background-color: transparent;
                    color: #000000;
                  }
                }
              }
              .form_button {
                box-shadow: 0px 3px 6px #00000029;
                background: $primary-color;
                color: $secondry-color;
                &:hover {
                  background: $secondry-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .contactControl {
        .contactDynamicForm {
          .dynamic-form {
            .space-between {
              input {
                border: 1px solid #ddd !important;
                &:hover {
                  border-color: $secondry-color !important;
                }
                &.form_button {
                  background: $primary-color;
                  color: #fff;
                  &:hover {
                    background: $secondry-color;
                    color: $text-color;
                  }
                }
              }
            }
          }
        }
      }
  
      .faqSection {
        &::before {
          background: url(../../assets/images/curve1.svg);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-color: $primary-color;
        }
        .tabs {
          // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        }
        .tab {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
          color: $secondry-color;
          // border-color:  $secondry-color;
          &-label {
            background: #fff;
            b {
              background: $secondry-color;
              color: #fff;
              border-right: 1px solid #fff;
            }
            /* Icon */
            &:hover {
              background: $primary-color;
              color:$secondry-color;
              b {
                background: $primary-color;
                color: $secondry-color;
              }
            }
          }
          &-content {
            color: #000;
            background: white;
          }
          &-close {
            background: $secondry-color;
            &:hover {
              background: $secondry-color;
            }
          }
        }
        // :checked
        input:checked {
          + .tab-label {
            background: $secondry-color;
            color: #fff;
          }
          ~ .tab-content {
            border: solid 1px #0e7e69;
          }
        }
      }
  
      /*****************responsive css****************/
  
      @media (max-width: 992px) {
        .navbar-light .navbar-toggler {
          background: $primary-color;
        }
      }
      @media (max-width: 600px) {
        .thirdSection {
          .bannerForm {
            .slider_age_step {
              background-color: #fff;
            }
          }
        }
      }
    }


    // Aqua Green and black color theme
    .layout_sixth-aqua-green-black  {
      $primary-color: #87CDC5 ;
      $secondry-color: #1f1c1d;
      $footer-color:#f8f9fa;
      $text-color: #ffffff;
      $btnHover:  #87CDC5;
      @mixin gradient-background {
        background: transparent
          linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
      }
      .button-home a {
        background: $secondry-color;
        color: $text-color;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      #navbarResponsive {
        ul {
          li {
            &.active {
              a {
                color: $btnHover;
              }
            }
            a {
              color: #fff;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
  
      .header-main-div {
        .header-section {
          .fixed-top {
            background: #fff !important;
            ul {
              .nav-item {
                a,span {  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      [class^="makeStyles-mainRangeValue-"] {
        label {
          background: $secondry-color;
          color: $text-color;
        }
      }
      .banner-area {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
  
        .bannerHead {
          background: $secondry-color;
          &::before {
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.5) 100%
            );
          }
          .bannerVideoContent {
            color: #fff;
            h2 {
              color: #ffffff;
            }
            .sub_title {
              color: #ffffff;
              text-shadow: 0px 3px 6px #00000029;
            }
          }
        }
      }
  
      .bannerForm {
        background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        .formRangeSlider {
          label {
            color: $secondry-color;
          }
          .ageSlider {
            background: $primary-color;
            color: $secondry-color;
          }
          input.rangeInput {
            background: linear-gradient(
              to right,
              #ed9827 0%,
              #ed9827 25%,
              #cecfd0 25%,
              #cecfd0 100%
            );
          }
        }
  
        .submitBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #ffffff;
          &:hover {
            background: $btnHover;
            color: $text-color;
          }
        }
        .sliderAgeText {
          color: #523b57;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .questionSteps {
            .btn.btn-submit {
              background: $secondry-color;
              box-shadow: 0px 3px 6px #00000029;
              color: #fff;
              &:hover {
                background: $primary-color;
                color: $secondry-color;
              }
            }
            .customDropdown-default-theme_span {
              background-color: transparent;
              border: 1px solid #707070;
              color: #888888;
              &:hover {
                background-color: $secondry-color;
                color: #fff;
              }
            }
            .customDropdown-default-theme_span_selected {
              background-color: $secondry-color;
              color: #fff;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
      }
  
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label {
              &.MuiFormControlLabel-root {
                box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
              }
            }
          }
        }
      }
  
      .firstSection {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
        h2 {
          color: $text-color;
        }
        .sub_title {
          color: $text-color;
        }
        .TimeBoxes {
          .timeBoxInside {
            a {
              background: #fff;
              color: #1d2c35;
              &:hover {
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
  
      .secondSection {
        h2 {
          color: #1d2c35;
        }
        .sub_title {
          color: #454545;
        }
      }
  
      .thirdSection {
        @include gradient-background;
        h2 {
          color: $text-color;
          span {
            color: $text-color;
          }
        }
        ul {
          li {
            background-color: #fff;
            color: $secondry-color;
            strong {
              color: $primary-color;
              background-color: #fff;
            }
          }
        }
      }
  
      .section-footer {
        .footer-content {
          background: $footer-color;
          border-bottom: 1px solid rgba(256, 256, 256, 0.2);
          color: #1f1c1d;
          .custome-links {
            ul {
              li {
                a,span {
                  color: #1f1c1d;
                }
              }
            }
          }
          p{
            color:#1f1c1d;
          }
        }
        .copyright {
          background: $secondry-color;
          color: #1f1c1d;
        }
      }
  
      .pageTitle {
        background: $primary-color;
        color: #fff;
      }
      .innerPageContent {
        .body-content1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .contentAboutLeft {
            border-bottom: dashed 1px #000;
          }
        }
        .contactWrapper1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
  
      .section-footer {
        .footer-content {
          color: #1f1c1d;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .newSurveyContent {
            .questionSteps {
              .controls {
                .descrioption-news {
                  h2 {
                    background-color: transparent;
                    color: #000000;
                  }
                }
              }
              .form_button {
                box-shadow: 0px 3px 6px #00000029;
                background: $primary-color;
                color: $secondry-color;
                &:hover {
                  background: $secondry-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .contactControl {
        .contactDynamicForm {
          .dynamic-form {
            .space-between {
              input {
                border: 1px solid #ddd !important;
                &:hover {
                  border-color: $secondry-color !important;
                }
                &.form_button {
                  background: $primary-color;
                  color: #fff;
                  &:hover {
                    background: $secondry-color;
                    color: $text-color;
                  }
                }
              }
            }
          }
        }
      }
  
      .faqSection {
        &::before {
          background: url(../../assets/images/curve1.svg);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-color: $primary-color;
        }
        .tabs {
          // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        }
        .tab {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
          color: $secondry-color;
          // border-color:  $secondry-color;
          &-label {
            background: #fff;
            b {
              background: $secondry-color;
              color: #fff;
              border-right: 1px solid #fff;
            }
            /* Icon */
            &:hover {
              background: $primary-color;
              color:$secondry-color;
              b {
                background: $primary-color;
                color: $secondry-color;
              }
            }
          }
          &-content {
            color: #000;
            background: white;
          }
          &-close {
            background: $secondry-color;
            &:hover {
              background: $secondry-color;
            }
          }
        }
        // :checked
        input:checked {
          + .tab-label {
            background: $secondry-color;
            color: #fff;
          }
          ~ .tab-content {
            border: solid 1px #0e7e69;
          }
        }
      }
  
      /*****************responsive css****************/
  
      @media (max-width: 992px) {
        .navbar-light .navbar-toggler {
          background: $primary-color;
        }
      }
      @media (max-width: 600px) {
        .thirdSection {
          .bannerForm {
            .slider_age_step {
              background-color: #fff;
            }
          }
        }
      }
    }


     // midnightblue and green color theme
     .layout_sixth-midnight-blue-green {
      $primary-color: #033f5d ;
      $secondry-color: #69bd49;
      $footer-color:#f8f9fa;
      $text-color: #ffffff;
      $btnHover:  #033f5d;
      @mixin gradient-background {
        background: transparent
          linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
      }
      .button-home a {
        background: $secondry-color;
        color: $text-color;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      #navbarResponsive {
        ul {
          li {
            &.active {
              a {
                color: $btnHover;
              }
            }
            a {
              color: #fff;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
  
      .header-main-div {
        .header-section {
          .fixed-top {
            background: #fff !important;
            ul {
              .nav-item {
                a,span {  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      [class^="makeStyles-mainRangeValue-"] {
        label {
          background: $secondry-color;
          color: $text-color;
        }
      }
      .banner-area {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
  
        .bannerHead {
          background: $secondry-color;
          &::before {
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.5) 100%
            );
          }
          .bannerVideoContent {
            color: #fff;
            h2 {
              color: #ffffff;
            }
            .sub_title {
              color: #ffffff;
              text-shadow: 0px 3px 6px #00000029;
            }
          }
        }
      }
  
      .bannerForm {
        background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        .formRangeSlider {
          label {
            color: $secondry-color;
          }
          .ageSlider {
            background: $primary-color;
            color: $secondry-color;
          }
          input.rangeInput {
            background: linear-gradient(
              to right,
              #ed9827 0%,
              #ed9827 25%,
              #cecfd0 25%,
              #cecfd0 100%
            );
          }
        }
  
        .submitBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #ffffff;
          &:hover {
            background: $btnHover;
            color: $text-color;
          }
        }
        .sliderAgeText {
          color: #523b57;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .questionSteps {
            .btn.btn-submit {
              background: $secondry-color;
              box-shadow: 0px 3px 6px #00000029;
              color: #fff;
              &:hover {
                background: $primary-color;
                color: $secondry-color;
              }
            }
            .customDropdown-default-theme_span {
              background-color: transparent;
              border: 1px solid #707070;
              color: #888888;
              &:hover {
                background-color: $secondry-color;
                color: #fff;
              }
            }
            .customDropdown-default-theme_span_selected {
              background-color: $secondry-color;
              color: #fff;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
      }
  
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label {
              &.MuiFormControlLabel-root {
                box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
              }
            }
          }
        }
      }
  
      .firstSection {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
        h2 {
          color: $text-color;
        }
        .sub_title {
          color: $text-color;
        }
        .TimeBoxes {
          .timeBoxInside {
            a {
              background: #fff;
              color: #1d2c35;
              &:hover {
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
  
      .secondSection {
        h2 {
          color: #1d2c35;
        }
        .sub_title {
          color: #454545;
        }
      }
  
      .thirdSection {
        @include gradient-background;
        h2 {
          color: $text-color;
          span {
            color: $text-color;
          }
        }
        ul {
          li {
            background-color: #fff;
            color: $secondry-color;
            strong {
              color: $primary-color;
              background-color: #fff;
            }
          }
        }
      }
  
      .section-footer {
        .footer-content {
          background: $footer-color;
          border-bottom: 1px solid rgba(256, 256, 256, 0.2);
          color: #1f1c1d;
          .custome-links {
            ul {
              li {
                a,span {
                  color: #1f1c1d;
                }
              }
            }
          }
          p{
            color:#1f1c1d;
          }
        }
        .copyright {
          background: $secondry-color;
          color: #1f1c1d;
        }
      }
  
      .pageTitle {
        background: $primary-color;
        color: #fff;
      }
      .innerPageContent {
        .body-content1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .contentAboutLeft {
            border-bottom: dashed 1px #000;
          }
        }
        .contactWrapper1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
  
      .section-footer {
        .footer-content {
          color: #1f1c1d;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .newSurveyContent {
            .questionSteps {
              .controls {
                .descrioption-news {
                  h2 {
                    background-color: transparent;
                    color: #000000;
                  }
                }
              }
              .form_button {
                box-shadow: 0px 3px 6px #00000029;
                background: $primary-color;
                color: $secondry-color;
                &:hover {
                  background: $secondry-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .contactControl {
        .contactDynamicForm {
          .dynamic-form {
            .space-between {
              input {
                border: 1px solid #ddd !important;
                &:hover {
                  border-color: $secondry-color !important;
                }
                &.form_button {
                  background: $primary-color;
                  color: #fff;
                  &:hover {
                    background: $secondry-color;
                    color: $text-color;
                  }
                }
              }
            }
          }
        }
      }
  
      .faqSection {
        &::before {
          background: url(../../assets/images/curve1.svg);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-color: $primary-color;
        }
        .tabs {
          // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        }
        .tab {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
          color: $secondry-color;
          // border-color:  $secondry-color;
          &-label {
            background: #fff;
            b {
              background: $secondry-color;
              color: #fff;
              border-right: 1px solid #fff;
            }
            /* Icon */
            &:hover {
              background: $primary-color;
              color:$secondry-color;
              b {
                background: $primary-color;
                color: $secondry-color;
              }
            }
          }
          &-content {
            color: #000;
            background: white;
          }
          &-close {
            background: $secondry-color;
            &:hover {
              background: $secondry-color;
            }
          }
        }
        // :checked
        input:checked {
          + .tab-label {
            background: $secondry-color;
            color: #fff;
          }
          ~ .tab-content {
            border: solid 1px #0e7e69;
          }
        }
      }
  
      /*****************responsive css****************/
  
      @media (max-width: 992px) {
        .navbar-light .navbar-toggler {
          background: $primary-color;
        }
      }
      @media (max-width: 600px) {
        .thirdSection {
          .bannerForm {
            .slider_age_step {
              background-color: #fff;
            }
          }
        }
      }
    }
    

    // magenta color theme
    .layout_sixth-magenta {
      $primary-color: #562463 ;
      $secondry-color: #2f0b38;
      $footer-color:#fff;
      $text-color: #ffffff;
      $btnHover:  #033f5d;
      @mixin gradient-background {
        background: transparent
          linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
      }
      .button-home a {
        background: $secondry-color;
        color: $text-color;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      #navbarResponsive {
        ul {
          li {
            &.active {
              a {
                color: $btnHover;
              }
            }
            a {
              color: #fff;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
  
      .header-main-div {
        .header-section {
          .fixed-top {
            background: #fff !important;
            ul {
              .nav-item {
                a,span {  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      [class^="makeStyles-mainRangeValue-"] {
        label {
          background: $secondry-color;
          color: $text-color;
        }
      }
      .banner-area {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
  
        .bannerHead {
          background: $secondry-color;
          &::before {
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.5) 100%
            );
          }
          .bannerVideoContent {
            color: #fff;
            h2 {
              color: #ffffff;
            }
            .sub_title {
              color: #ffffff;
              text-shadow: 0px 3px 6px #00000029;
            }
          }
        }
      }
  
      .bannerForm {
        background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        .formRangeSlider {
          label {
            color: $secondry-color;
          }
          .ageSlider {
            background: $primary-color;
            color: $secondry-color;
          }
          input.rangeInput {
            background: linear-gradient(
              to right,
              #ed9827 0%,
              #ed9827 25%,
              #cecfd0 25%,
              #cecfd0 100%
            );
          }
        }
  
        .submitBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #ffffff;
          &:hover {
            background: $btnHover;
            color: $text-color;
          }
        }
        .sliderAgeText {
          color: #523b57;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .questionSteps {
            .btn.btn-submit {
              background: $secondry-color;
              box-shadow: 0px 3px 6px #00000029;
              color: #fff;
              &:hover {
                background: $primary-color;
                color: $secondry-color;
              }
            }
            .customDropdown-default-theme_span {
              background-color: transparent;
              border: 1px solid #707070;
              color: #888888;
              &:hover {
                background-color: $secondry-color;
                color: #fff;
              }
            }
            .customDropdown-default-theme_span_selected {
              background-color: $secondry-color;
              color: #fff;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
      }
  
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label {
              &.MuiFormControlLabel-root {
                box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
              }
            }
          }
        }
      }
  
      .firstSection {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
        h2 {
          color: $text-color;
        }
        .sub_title {
          color: $text-color;
        }
        .TimeBoxes {
          .timeBoxInside {
            a {
              background: #fff;
              color: #1d2c35;
              &:hover {
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
  
      .secondSection {
        h2 {
          color: #1d2c35;
        }
        .sub_title {
          color: #454545;
        }
      }
  
      .thirdSection {
        @include gradient-background;
        h2 {
          color: $text-color;
          span {
            color: $text-color;
          }
        }
        ul {
          li {
            background-color: #fff;
            color: $secondry-color;
            strong {
              color: $primary-color;
              background-color: #fff;
            }
          }
        }
      }
  
      .section-footer {
        .footer-content {
          background: $footer-color;
          border-bottom: 1px solid rgba(256, 256, 256, 0.2);
          color: #1f1c1d;
          .custome-links {
            ul {
              li {
                a,span {
                  color: #1f1c1d;
                }
              }
            }
          }
          p{
            color:#1f1c1d;
          }
        }
        .copyright {
          background: $secondry-color;
          color: #1f1c1d;
        }
      }
  
      .pageTitle {
        background: $primary-color;
        color: #fff;
      }
      .innerPageContent {
        .body-content1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .contentAboutLeft {
            border-bottom: dashed 1px #000;
          }
        }
        .contactWrapper1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
  
      .section-footer {
        .footer-content {
          color: #1f1c1d;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .newSurveyContent {
            .questionSteps {
              .controls {
                .descrioption-news {
                  h2 {
                    background-color: transparent;
                    color: #000000;
                  }
                }
              }
              .form_button {
                box-shadow: 0px 3px 6px #00000029;
                background: $primary-color;
                color: $secondry-color;
                &:hover {
                  background: $secondry-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .contactControl {
        .contactDynamicForm {
          .dynamic-form {
            .space-between {
              input {
                border: 1px solid #ddd !important;
                &:hover {
                  border-color: $secondry-color !important;
                }
                &.form_button {
                  background: $primary-color;
                  color: #fff;
                  &:hover {
                    background: $secondry-color;
                    color: $text-color;
                  }
                }
              }
            }
          }
        }
      }
  
      .faqSection {
        &::before {
          background: url(../../assets/images/curve1.svg);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-color: $primary-color;
        }
        .tabs {
          // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        }
        .tab {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
          color: $secondry-color;
          // border-color:  $secondry-color;
          &-label {
            background: #fff;
            b {
              background: $secondry-color;
              color: #fff;
              border-right: 1px solid #fff;
            }
            /* Icon */
            &:hover {
              background: $primary-color;
              color:$secondry-color;
              b {
                background: $primary-color;
                color: $secondry-color;
              }
            }
          }
          &-content {
            color: #000;
            background: white;
          }
          &-close {
            background: $secondry-color;
            &:hover {
              background: $secondry-color;
            }
          }
        }
        // :checked
        input:checked {
          + .tab-label {
            background: $secondry-color;
            color: #fff;
          }
          ~ .tab-content {
            border: solid 1px #0e7e69;
          }
        }
      }
  
      /*****************responsive css****************/
  
      @media (max-width: 992px) {
        .navbar-light .navbar-toggler {
          background: $primary-color;
        }
      }
      @media (max-width: 600px) {
        .thirdSection {
          .bannerForm {
            .slider_age_step {
              background-color: #fff;
            }
          }
        }
      }
    }


    // cerulean color theme
    .layout_sixth-cerulean {
      $primary-color: #004A8E ;
      $secondry-color: #0b60b7;
      $footer-color:#004A8E;
      $text-color: #ffffff;
      $btnHover:  #033f5d;
      @mixin gradient-background {
        background: transparent
          linear-gradient(180deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
      }
      .button-home a {
        background: $secondry-color;
        color: $text-color;
        &:hover {
          background: $primary-color;
          color: $text-color;
        }
      }
      #navbarResponsive {
        ul {
          li {
            &.active {
              a {
                color: $btnHover;
              }
            }
            a {
              color: #fff;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
  
      .header-main-div {
        .header-section {
          .fixed-top {
            background: $primary-color !important;
            ul {
              .nav-item {
                a,span {  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      [class^="makeStyles-mainRangeValue-"] {
        label {
          background: $secondry-color;
          color: $text-color;
        }
      }
      .banner-area {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
  
        .bannerHead {
          background: $secondry-color;
          &::before {
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.5) 100%
            );
          }
          .bannerVideoContent {
            color: #fff;
            h2 {
              color: #ffffff;
            }
            .sub_title {
              color: #ffffff;
              text-shadow: 0px 3px 6px #00000029;
            }
          }
        }
      }
  
      .bannerForm {
        background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        .formRangeSlider {
          label {
            color: $secondry-color;
          }
          .ageSlider {
            background: $primary-color;
            color: $secondry-color;
          }
          input.rangeInput {
            background: linear-gradient(
              to right,
              #ed9827 0%,
              #ed9827 25%,
              #cecfd0 25%,
              #cecfd0 100%
            );
          }
        }
  
        .submitBtn {
          background: $secondry-color;
          box-shadow: 0px 3px 6px #00000029;
          color: #ffffff;
          &:hover {
            background: $btnHover;
            color: $text-color;
          }
        }
        .sliderAgeText {
          color: #523b57;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .questionSteps {
            .btn.btn-submit {
              background: $secondry-color;
              box-shadow: 0px 3px 6px #00000029;
              color: #fff;
              &:hover {
                background: $primary-color;
                color: $secondry-color;
              }
            }
            .customDropdown-default-theme_span {
              background-color: transparent;
              border: 1px solid #707070;
              color: #888888;
              &:hover {
                background-color: $secondry-color;
                color: #fff;
              }
            }
            .customDropdown-default-theme_span_selected {
              background-color: $secondry-color;
              color: #fff;
            }
          }
        }
        .newSurveyFooter {
          .nextBtn {
            background: $secondry-color;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            &:hover {
              background: $primary-color;
              color: $secondry-color;
            }
          }
        }
      }
  
      .newSurveyContent {
        .questionSteps {
          .MuiFormGroup-root {
            label {
              &.MuiFormControlLabel-root {
                box-shadow: 0 7px 15px 0 rgba(2, 27, 52, 0.15);
              }
            }
          }
        }
      }
  
      .firstSection {
        background: transparent
          linear-gradient(90deg, $secondry-color 0%, $primary-color 100%) 0% 0% no-repeat
          padding-box;
        h2 {
          color: $text-color;
        }
        .sub_title {
          color: $text-color;
        }
        .TimeBoxes {
          .timeBoxInside {
            a {
              background: #fff;
              color: #1d2c35;
              &:hover {
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
  
      .secondSection {
        h2 {
          color: #1d2c35;
        }
        .sub_title {
          color: #454545;
        }
      }
  
      .thirdSection {
        @include gradient-background;
        h2 {
          color: $text-color;
          span {
            color: $text-color;
          }
        }
        ul {
          li {
            background-color: #fff;
            color: $secondry-color;
            strong {
              color: $primary-color;
              background-color: #fff;
            }
          }
        }
      }
  
      .section-footer {
        .footer-content {
          background: $footer-color;
          border-bottom: 1px solid rgba(256, 256, 256, 0.2);
          color: #fff;
          .custome-links {
            ul {
              li {
                a,span {
                  color: #fff;
                }
              }
            }
          }
          p{
            color:#fff;
          }
        }
        .copyright {
          background: $secondry-color;
          color: #1f1c1d;
        }
      }
  
      .pageTitle {
        background: $primary-color;
        color: #fff;
      }
      .innerPageContent {
        .body-content1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .contentAboutLeft {
            border-bottom: dashed 1px #000;
          }
        }
        .contactWrapper1 {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
  
      .section-footer {
        .footer-content {
          color: #1f1c1d;
        }
      }
      .newSurveyFormBox {
        .bannerForm {
          .newSurveyContent {
            .questionSteps {
              .controls {
                .descrioption-news {
                  h2 {
                    background-color: transparent;
                    color: #000000;
                  }
                }
              }
              .form_button {
                box-shadow: 0px 3px 6px #00000029;
                background: $primary-color;
                color: $secondry-color;
                &:hover {
                  background: $secondry-color;
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .contactControl {
        .contactDynamicForm {
          .dynamic-form {
            .space-between {
              input {
                border: 1px solid #ddd !important;
                &:hover {
                  border-color: $secondry-color !important;
                }
                &.form_button {
                  background: $primary-color;
                  color: #fff;
                  &:hover {
                    background: $secondry-color;
                    color: $text-color;
                  }
                }
              }
            }
          }
        }
      }
  
      .faqSection {
        &::before {
          background: url(../../assets/images/curve1.svg);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-color: $primary-color;
        }
        .tabs {
          // box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        }
        .tab {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
          color: $secondry-color;
          // border-color:  $secondry-color;
          &-label {
            background: #fff;
            b {
              background: $secondry-color;
              color: #fff;
              border-right: 1px solid #fff;
            }
            /* Icon */
            &:hover {
              background: $primary-color;
              color:$secondry-color;
              b {
                background: $primary-color;
                color: $secondry-color;
              }
            }
          }
          &-content {
            color: #000;
            background: white;
          }
          &-close {
            background: $secondry-color;
            &:hover {
              background: $secondry-color;
            }
          }
        }
        // :checked
        input:checked {
          + .tab-label {
            background: $secondry-color;
            color: #fff;
          }
          ~ .tab-content {
            border: solid 1px #0e7e69;
          }
        }
      }
  
      /*****************responsive css****************/
  
      @media (max-width: 992px) {
        .navbar-light .navbar-toggler {
          background: $primary-color;
        }
      }
      @media (max-width: 600px) {
        .thirdSection {
          .bannerForm {
            .slider_age_step {
              background-color: #fff;
            }
          }
        }
      }
    }

  
/********************************************************************************************************************
                                      ~~~~~~~~New color themes 2022 end~~~~~~~
********************************************************************************************************************/


}
